import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  mx: '0',
  borderTop: 'none',
  flexDir: 'column',
  alignItems: 'start',
  mb: '2',
  p: '2',
  px: '2',
  py: '2',
  borderRadius: 'md',
  gap: '1',
  bgGradient: 'linear (to-b, #49808B, #3A727E)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  _last: {
    mb: '0',
  },
};

export const RacerName: CSSObject = {
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
  color: 'white',
  fontWeight: 'bold',
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'white',
  position: 'relative',
  top: '-0.5',
  fontWeight: 'normal',
  fontSize: '10px',
  fontFamily: 'accent',
};
