import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['3', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};
export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  bg: 'rgba(78, 178, 255, 0.14)',
  boxShadow:
    '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  borderRadius: 'md',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
  mt: '4',
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? 'blackAlpha.500' : 'transparent',
  color: isActive ? ['beta.300', null, 'alpha.100'] : 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  textTransform: ['uppercase', null, 'capitalize'],
  fontWeight: ['bold', null, 'semiBold'],
  fontSize: 'sm',
  lineHeight: 'normal',
  py: ['2', '3'],
  px: ['2', '4'],
  fontFamily: 'accent',
  borderBottom: [isActive && '3px solid', null, '1px solid'],
  borderBottomColor: [
    isActive && 'beta.300',
    null,
    isActive ? 'beta.600' : 'blackAlpha.400',
  ],
  borderTop: [isActive && '2px solid', null, '1px solid'],
  borderTopColor: [isActive && 'beta.500', null, 'whiteAlpha.200'],
  _first: {
    borderTopRadius: ['none', null, 'md'],
  },
  _last: {
    borderBottomRadius: 'md',
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    bg: !isActive && 'blackAlpha.300',
  },
});

export const NavLinkLabel: CSSObject = {
  color: 'beta.300',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  border: 'none',
  borderBottom: '3px solid',
  borderBottomColor: 'beta.300',
  fontSize: 'sm',
  textAlign: 'center',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '110px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '40px',
  ml: ['2', '5'],
};

export const TopNavLabelWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  w: ['95%', '98%'],
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
  borderRadius: 'md',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};
