import * as sterlingparker from './MysteryBet.styles.sterlingparker';
import * as buffalobet from './MysteryBet.styles.buffalobet';
import * as betgalaxy from './MysteryBet.styles.betgalaxy';
import * as betprofessor from './MysteryBet.styles.betprofessor';
import * as puntcity from './MysteryBet.styles.puntcity';
import * as puntgenie from './MysteryBet.styles.puntgenie';
import * as junglebet from './MysteryBet.styles.junglebet';
import * as vikingbet from './MysteryBet.styles.vikingbet';
import * as questbet from './MysteryBet.styles.questbet';
import * as volcanobet from './MysteryBet.styles.volcanobet';
import * as wellbet from './MysteryBet.styles.wellbet';
import * as betroyale from './MysteryBet.styles.betroyale';
import * as fiestabet from './MysteryBet.styles.fiestabet';
import * as goldenrush from './MysteryBet.styles.goldenrush';
import * as juicybet from './MysteryBet.styles.juicybet';
import * as oldgill from './MysteryBet.styles.oldgill';
import * as sugarcastle from './MysteryBet.styles.sugarcastle';
import * as titanbet from './MysteryBet.styles.titanbet';
import * as slambet from './MysteryBet.styles.slambet';
import * as gigabet from './MysteryBet.styles.gigabet';
import * as betstride from './MysteryBet.styles.betstride';
import * as templebet from './MysteryBet.styles.templebet';
import * as goldenbet888 from './MysteryBet.styles.goldenbet888';
import * as hyperbet from './MysteryBet.styles.hyperbet';
import * as bet777 from './MysteryBet.styles.bet777';
import * as chromabet from './MysteryBet.styles.chromabet';
import * as bet575 from './MysteryBet.styles.bet575';
import * as betblast from './MysteryBet.styles.betblast';
import * as pyramidbet from './MysteryBet.styles.pyramidbet';
import * as magnumbet from './MysteryBet.styles.magnumbet';
import { themeName } from '@/helpers/getThemeConfig';

const styleImports: Partial<Record<string, any>> = {
  juicybet,
  goldenrush,
  fiestabet,
  betroyale,
  betprofessor,
  betgalaxy,
  sterlingparker,
  buffalobet,
  puntcity,
  puntgenie,
  junglebet,
  vikingbet,
  questbet,
  volcanobet,
  wellbet,
  oldgill,
  sugarcastle,
  titanbet,
  slambet,
  gigabet,
  betstride,
  templebet,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
  pyramidbet,
  magnumbet,
};

export const styleImport = styleImports[themeName];
