/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useStyleConfig } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChevronDown } from '@styled-icons/heroicons-outline';
import { EmojiEvents } from '@styled-icons/material-outlined/EmojiEvents';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FEATURE_FLAGS } from '../../../constants/featureFlags';
import { useAppDispatch } from '../../../hooks/useRedux';
import {
  getIconBySportName,
  getStrings,
  sortedRaceOfferings,
  startPassedEndNotPassed,
} from '@/helpers/utils';
import {
  clearPunterOverview,
  clearPunterProfile,
  reset,
} from '../../../views/account/services/account.slices';
import { recordUserAction } from '@/lib/api/activityLogging/actions';
import { ACTIVITY_LOG_SUMMARY, ACTIVITY_LOG_TYPE } from '@/lib/Constants';

import { signUserOut } from '@/lib/firebase/Authentication';
import { TSideNavTheme } from '../../../theme/base/customComponents/sideNav';
import { useAuth } from '../../../hooks/useAuth';
import { keys } from '../../../api/api.keys';
import { queryPunterAccountOverview } from '../../../api/punter/punter';
import { ERaceTypeDisplayNames, TListItem } from '../SideNav.types';
import { clearBetSlip } from '../../../redux/Betslip.slices';
import { overrideFilter } from '../../../redux/raceTable.slice';
import { useLiveChat } from '@/hooks/useLivechat';
import { ENewRelicActions } from '../../../constants/newRelicActions';
import { HTTPError } from '@/lib/api/types';
import { useQueryOfferings } from '../../../api/offerings/offerings.hooks';
import { TPlatformOfferings } from '../../../views/HomeDeprecated/services/Home.types';
import { AppDispatch } from '../../../redux/store';
import { apiGetRequest } from '@/lib/api/api';
import { setGeneralOfferings } from '../../../views/HomeDeprecated/services/Home.slices';
import { queryCMS } from '@/api/cms/cms';
import { TLinks } from '@/api/cms/cms.types';
import { newRelicLog } from '@/lib/newRelic';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';

// deprecated call
const getGeneralOfferings = createAsyncThunk<
  TPlatformOfferings,
  undefined,
  { dispatch: AppDispatch }
>(
  'home/getPunterGeneralOfferings',
  async (_, thunkAPI): Promise<TPlatformOfferings> => {
    const response = await apiGetRequest<TPlatformOfferings>(
      '/punter/general/offerings'
    );

    thunkAPI.dispatch(setGeneralOfferings(response));

    return response;
  }
);

/**
 * Hook to get the offerings data
 */
export const useSideNav = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGeneralOfferings() as any);
  }, [dispatch]);
};

/**
 * Hook to log the user out
 */
export const useLogout = () => {
  const dispatch = useAppDispatch();
  const { clearBetSlip: _clearBetSlip } = getBetSlipStoreActions();
  const { punterData } = useAuth();
  const client = useQueryClient();

  return async () => {
    if (window.ZohoHCAsap) {
      window.ZohoHCAsap.Logout();
    }

    await dispatch(
      recordUserAction({
        action_type: ACTIVITY_LOG_TYPE.LOGOUT,
        action_summary: ACTIVITY_LOG_SUMMARY[ACTIVITY_LOG_TYPE.LOGOUT],
      }) as any
    );

    await signUserOut()
      .then(() => {
        newRelicLog(ENewRelicActions.LogOut, punterData?.punter_id, {
          status_code: 200,
        });
        dispatch(clearBetSlip());
        _clearBetSlip();
        dispatch(clearPunterProfile());
        dispatch(clearPunterOverview());
        dispatch(reset());
      })
      .catch((error) => {
        const e = axios.isAxiosError(error) ? (error as HTTPError) : null;

        newRelicLog(ENewRelicActions.LogOut, punterData?.punter_id, {
          status_code: e?.response.status ?? '',
          error,
        });
      });

    await client.invalidateQueries();
  };
};

/**
 * Hook to get the list of navigation items
 */
export const useSideNavList = () => {
  const {
    linkSX,
    dropDownInner,
    headerSX,
    dropDownContainerClosed,
    dropDownContainerOpen,
    borderBottomRadius,
  } = useStyleConfig('SideNav') as TSideNavTheme;
  const [{ SideNav: strings }] = getStrings();
  const logout = useLogout();
  const { isAuthenticated } = useAuth();
  const { data: accountOverview } = useQuery(
    [keys.punterAccountOverview, isAuthenticated],
    queryPunterAccountOverview,
    {
      enabled: !!isAuthenticated,
      staleTime: 60 * 5000, // 5 minutes
    }
  );
  const { data: offerings } = useQueryOfferings();
  const hasSports = !!offerings?.offered_sports?.length;

  const [dropDownExpanded, setDropDownExpanded] = useState(false);

  const getDropDownSX = () => {
    if (dropDownExpanded) return dropDownInner;
    if (!accountOverview?.is_onboarded) return linkSX;

    return {};
  };

  const dispatch = useAppDispatch();

  const { showLiveChat } = useLiveChat();
  const depositFundsLink = () => {
    if (!accountOverview) return '/login';
    return accountOverview.is_onboarded ? '/account/deposit' : '/deposit-limit';
  };

  const intl = useIntl();

  const { data: cmsLinks } = useQuery([keys.cms], () => queryCMS());

  const topLevelLinks = cmsLinks
    ?.filter((link) => link.top_level === false)
    ?.filter((link) => link.name !== '')
    ?.filter((event) => startPassedEndNotPassed(event))
    ?.sort((a: TLinks, b: TLinks) => Number(a?.order) - Number(b?.order))
    ?.map((link) => {
      // Remove hostname from the custom link
      const customLinkTo = link.url.replace(window.location.origin, '');

      return {
        text: link.name,
        dataCy: link.name,
        icon: EmojiEvents,
        to: customLinkTo,
      };
    });

  const cmsSubLinks = cmsLinks
    ?.filter((link) => link.top_level !== false)
    ?.filter((link) => link.name !== '')
    ?.filter((event) => startPassedEndNotPassed(event))
    ?.sort((a: TLinks, b: TLinks) => Number(a?.order) - Number(b?.order))
    ?.map((link) => {
      const customLinkTo = link.url.replace(window.location.origin, '');

      return {
        text: link.name,
        dataCy: link.name,
        icon: EmojiEvents,
        to: customLinkTo,
      };
    });

  const nav: TListItem[] = [
    {
      to: '/',
      text: intl.formatMessage({ id: 'sidenav.home' }),
      dataCy: 'home-sideNav',
    },

    {
      to: depositFundsLink(),
      text: intl.formatMessage({ id: 'sidenav.deposit' }),
      dataCy: 'depositFundsSideNav',
    },

    ...(offerings?.offered_race_types?.length
      ? [
          ...(FEATURE_FLAGS.HAS_RACE_VIEW
            ? [
                ...sortedRaceOfferings(offerings?.offered_race_types).map(
                  (type, i) => ({
                    to: `/Racing/Today?raceType=${type}`,
                    svg: getIconBySportName(type),
                    text: intl.formatMessage({ id: `raceType.${ERaceTypeDisplayNames[type]}`, defaultMessage: ERaceTypeDisplayNames[type] }),
                    /*
                     * Here we give the last link a bottom border radius by giving it a linkSX styling.
                     * 1 checking for the last in the list.
                     * 2 check if user is logged out, if not the promo link will be the last with border radius.
                     * 3 check if we show sports, the sport dropdown link will be the last in the list.
                     */
                    ...(sortedRaceOfferings.length === i - 1 &&
                      !accountOverview?.is_onboarded &&
                      !FEATURE_FLAGS.HAS_SPORTS_VIEW && { sx: linkSX }),
                    dataCy: `racing-${type}-sideNav`,
                    onClick: () => {
                      dispatch(overrideFilter(type));
                    },
                  })
                ),
              ]
            : []),

          ...(hasSports && FEATURE_FLAGS.HAS_SPORTS_VIEW
            ? [
                {
                  to: '/sports',
                  text: intl.formatMessage({ id: 'sports.allsports' }),
                  dataCy: 'all-sports-sideNav',
                  sub: false,
                },
              ]
            : []),
        ]
      : []),
    {
      to: '/help-centre/promotions',
      text: intl.formatMessage({ id: 'sideNav.promotions' }),
      dataCy: 'promotionsSideNav',
      sx: linkSX,
    },
    ...(cmsSubLinks?.length
      ? [
          {
            text: intl.formatMessage({ id: 'sidenav.topEvents' }),
            isDropDown: true,
            dropDownExpanded,
            sx: getDropDownSX(),
            dataCy: 'dropdown-sideNav',
            icon: EmojiEvents,
            dropDownIcon: ChevronDown,
            onClick: () => setDropDownExpanded((prevState) => !prevState),
            dropDownContainerSX: dropDownExpanded
              ? dropDownContainerOpen
              : {
                  ...dropDownContainerClosed,
                  ...{
                    borderBottomRadius: accountOverview?.is_onboarded
                      ? {}
                      : borderBottomRadius,
                  },
                },
            subs: [...(cmsSubLinks ?? [])],
          },
        ]
      : []),
    ...(topLevelLinks?.length ? [...topLevelLinks] : []),
  ];

  const quickLinks: TListItem[] = [
    ...(FEATURE_FLAGS.HAS_RACE_VIEW && offerings?.offered_race_types?.length
      ? [
          {
            text: intl.formatMessage({ id: 'sideNav.quickLinks' }),
            sx: headerSX,
          },
        ]
      : []),
    {
      onClick: () => showLiveChat(),
      text: intl.formatMessage({ id: 'sidenav.contactus' }),
      dataCy: 'contactSideNav',
    },

    {
      to: isAuthenticated ? '/account/overview' : '/login',
      text: intl.formatMessage({ id: 'sidenav.myaccount' }),
      dataCy: 'myAccountSideNav',
    },

    ...(isAuthenticated
      ? [
          {
            to: '/account/settings/responsible-gambling',
            text: intl.formatMessage({ id: 'generic.responsiblegamblingtools' }),
            dataCy: 'responsibleSideNav',
          },
          {
            text: intl.formatMessage({ id: 'generic.logout' }),
            onClick: logout,
            svg: 'log_out',
            dataCy: 'logOutSideNav',
            sx: linkSX,
          },
        ]
      : []),
  ];

  return { nav, quickLinks };
};
