import * as betgalaxy from './Info.styles.betgalaxy';
import * as betprofessor from './Info.styles.betprofessor';
import * as goldenrush from './Info.styles.goldenrush';
import * as juicybet from './Info.styles.juicybet';
import * as vikingbet from './Info.styles.vikingbet';
import * as sugarcastle from './Info.styles.sugarcastle';
import * as buffalobet from './Info.styles.buffalobet';
import * as titanbet from './Info.styles.titanbet';
import * as puntgenie from './Info.styles.puntgenie';
import * as slambet from './Info.styles.slambet';
import * as gigabet from './Info.styles.gigabet';
import * as betstride from './Info.styles.betstride';
import * as goldenbet888 from './Info.styles.goldenbet888';
import * as oldgill from './Info.styles.oldgill';
import * as chromabet from './Info.styles.chromabet';
import * as templebet from './Info.styles.templebet';
import * as hyperbet from './Info.styles.hyperbet';
import * as bet777 from './Info.styles.bet777';
import * as bet575 from './Info.styles.bet575';
import * as betblast from './Info.styles.betblast';
import * as sterlingparker from './Info.styles.sterlingparker';
import * as magnumbet from './Info.styles.magnumbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  goldenrush,
  juicybet,
  vikingbet,
  sugarcastle,
  buffalobet,
  titanbet,
  puntgenie,
  slambet,
  gigabet,
  betstride,
  goldenbet888,
  oldgill,
  chromabet,
  templebet,
  hyperbet,
  bet777,
  bet575,
  betblast,
  sterlingparker,
  magnumbet,
};
