import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: '22px',
  fontWeight: 'bold',
  fontFamily: 'heading',
  color: 'beta.300',
  pb: ['0', null, '2'],
  textTransform: 'uppercase',
  mx: ['3', null, '0'],
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
