import { FlucsSchema } from './Flucs.styles';

export const flucsStyles: FlucsSchema = {
  wrapper: {
    pos: 'relative',
    alignItems: 'center',
    pt: '2.5',
    pb: '1',
  },
  innerWrapper: {
    alignItems: 'center',
    bg: 'blackAlpha.300',
    width: 'fit-content',
    borderRadius: 'md',
    boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.2) inset',
    p: '2',
    mt: ['1', null, '0'],
    mr: ['1', '2'],
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2xs',
  },
  priceTitle: {
    as: 'p',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2sx',
    mr: '1',
  },
  priceNumber: {
    color: 'white',
  },
  priceText: {
    color: 'white',
    mr: ['1', '2'],
    fontSize: '2xs',
    fontWeight: 'medium',
    display: 'flex',
    alignItems: 'center',
  },
  priceTextIncreased: {
    color: 'white',
  },
  priceIcon: {
    color: 'red.500',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    ml: '1',
    filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
  },
  priceIconIncreased: {
    color: 'beta.300',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    transform: 'rotate(180deg)',
    filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
  },
  lottieWrapper: {
    bg: 'transparent',
    boxShadow: 'none',
    alignItems: 'center',
    boxSize: '7',
    border: 'none',
    borderRadius: 'md',
    ml: '1',
  },
  tooltipWrapper: {
    borderRadius: 'md',
    bg: '#ED8936',
    p: '3',
    display: 'block',
    position: 'relative',
  },
};
