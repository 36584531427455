import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexContainer = (isHome: boolean) => ({
  pos: ['relative', null, 'unset'],
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },
  bg: [isHome && '#349bb1', null, 'transparent'],

  w: ['100%', null, 'unset'],
  p: ['1', null, null, '0'],
  h: ['auto', null, '50px'],
  pb: '2',
});

export const ButtonGroup: CSSObject = {
  overflow: 'hidden',
  pb: ['0', null, '2'],
};

export const ButtonLogIn: CSSObject = {
  bg: 'transparent',
  color: 'white',
  fontSize: 'xs',
  border: '2px solid',
  borderColor: 'alpha.400',
  borderRadius: 'md',
  textShadow: '0px 1px 5px #2c2c2c',

  '&:hover, &:active': {
    bg: 'alpha.500',
    color: 'alpha.900',
    textShadow: 'none',
  },
};

export const ButtonSignUp: CSSObject = {
  bg: 'linear-gradient(180deg, #1F689B 0%, #0F4770 100%)',
  color: 'white',
  fontSize: 'xs',
  border: '2px solid',
  borderColor: 'alpha.400',
  borderRadius: 'md',
  textShadow: '0px 1px 5px #2c2c2c',

  '&:hover, &:active': {
    bg: 'alpha.400',
    color: 'alpha.900',
    textShadow: 'none',
  },
};

export const ButtonBetSlip: CSSObject = {
  bg: 'linear-gradient(180deg, #1F689B 0%, #0F4770 100%)',
  color: 'white',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'alpha.300',
  boxShadow: 'md',
  bgSize: '400% 400%',
  transition: 'all .3s ease-in-out',
  height: ['37px', null, '45px'],
  fontSize: ['2xs', null, 'xs'],

  '.span-bet-count': { fontSize: ['xs', null, 'md'] },

  '&:hover, &:active': {
    bgSize: '400% 400%',
    bg: 'linear-gradient(180deg, rgba(70, 78, 139, 0.44) 0%, rgba(70, 78, 139, 0.8) 100%)',
    color: 'white',
  },
};

export const ButtonBets: CSSObject = {
  bg: 'linear-gradient(180deg, #1F689B 0%, #0F4770 100%)',
  color: 'white',
  border: '2px solid',
  borderColor: 'alpha.300',
  boxShadow: 'md',
  bgSize: '400% 400%',
  transition: 'all .3s ease-in-out',
  height: ['37px', null, '45px'],
  borderRadius: 'md',
  textTransform: 'uppercase',

  '.span-bet': {
    textTransform: ['uppercase', null, 'capitalize'],
    fontSize: ['2xs', null, 'xs'],
  },
  '.span-bet-count': { fontSize: ['xs', null, 'md'] },

  '&:hover, &:active': {
    bgSize: '400% 400%',
    bg: 'linear-gradient(180deg, rgba(70, 78, 139, 0.44) 0%, rgba(70, 78, 139, 0.8) 100%)',
    color: 'white',
  },
};

export const buttonQuickAccessProps: CustomButtonProps = {
  sx: {
    '&&': {
      minW: '100px',
      bg: 'linear-gradient(180deg, #DFB300 0%, #FFD116 100%)',
      bgSize: '100% 100%',
      alignItems: 'flex-start',
      animation: '.5s ease-in-out',
      borderRadius: 'md',
      border: '2px solid',
      borderColor: 'white',
      boxShadow: 'md',
      textShadow: 'none',
      height: ['37px', null, '45px'],

      '&:hover': {
        bgPos: 'bottom center',
        bgSize: '250% 250%',
        boxShadow: 'sm',
      },

      '&[aria-expanded="true"]': {
        bg: 'white',

        '.chevron-icon': { color: 'gamma.900' },
        '.span-balance': { color: 'gamma.900' },
        '.account-text': { color: 'gamma.900' },
      },

      '&:after': { bg: 'white' },

      '.span-balance': { color: 'gamma.900', fontSize: ['xs', null, 'md'] },
      '.span-bonus': {
        color: 'gamma.900',
        fontSize: ['2xs', null, 'sm'],
      },
      '.account-text': { color: 'gamma.900' },
      '.chevron-icon': { color: 'gamma.900' },
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'white',
  boxSize: '8',
  w: '8',
  h: '8',
  ml: '-1.5',

  '& svg': {
    boxSize: '8',
  },
});

export const LinkLogo: CSSObject = {
  w: ['120px', null, '160px'],
  display: ['block', null, 'none'],
};

export const FlexButtonGroup: CSSObject = {
  pb: ['0', null, '2'],
};

export const TextButtonNumber: CSSObject = {
  fontSize: ['xs', null, 'md'],
};
