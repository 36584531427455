import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  headerSportText: {
    fontSize: 'lg',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: 'widest',
  },
  boxWrapper: {
    px: 0,
    mt: '-2',
  },
  accordionButton: {
    sx: {
      display: 'flex',
      p: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      fontSize: 'sm',
    },
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'normal',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      fontFamily: 'body',
      color: 'white',
      fontsize: 'md',
      mt: '2',
      mb: '3',
      fontWeight: 'extrabold',
    },
  },
  topLeaguesAccordionItem: {
    mt: '3',
    borderBottomRadius: 'base',
    p: '1px',
    borderRadius: 'md',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(255, 255, 255, 0.16)',
  },
  marketGroupsByLetterAccordionItem: {
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(255, 255, 255, 0.16)',
    borderRadius: 'md',
    mb: '2',
  },
  marketGroupsByLetterAccordionButton: {
    color: 'white',
    sx: {
      display: 'flex',
      fontFamily: 'accent',
      fontWeight: 'bold',
      fontSize: 'xs',
      p: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      svg: {
        color: 'white',
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'accent',
    sx: {
      img: {
        borderRadius: 'base',
        filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))',
        marginRight: '2',
      },
    },
  },
  marketGroupsByLetterCountryFlag: {
    width: '2em',
    height: '1.6em',
  },
  marketGroupsByLetterAccordionPanel: {
    px: '2',
    pb: '3',
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'beta.300',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'normal',
    sx: { _hover: { textDecoration: 'none' } },
  },
  accordionIcon: { color: 'white' },
  accordionPanel: {
    px: '2',
    py: '0',
    fontWeight: 'normal',
    fontFamily: 'body',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'beta.300',
    px: '2',
  },
  topLeaguesText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'accent',
    color: 'white',
    fontSize: 'xs',
    lineHeight: 'normal',
    px: 0,
  },
  navWrapper: {
    pb: '0',
  },
};
