import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  svg: {
    color: 'beta.500',
    fill: 'black',
  },
};

export const RunnerDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  'span:nth-child(2)': {
    color: 'whiteAlpha.500',
  },
};

export const BetOdds: CSSObject = {
  color: 'white',
};

export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
  position: 'relative',
  top: '-1',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'gamma.100';
      case 'pending':
        return 'gamma.300';
      default:
        return 'gamma.200';
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'blackAlpha.700';
      case 'pending':
        return 'blackAlpha.700';
      default:
        return 'white';
    }
  },
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  py: '0',
});

export const HeaderSvg: CSSObject = {
  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
  boxSize: '5',
};

export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};

export const MultiIcon = () => (
  <IconSvg
    name="multi_betslip"
    className="svgIcon"
    color="gamma.300"
    boxSize="5"
    mb="1.5"
  />
);

export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
  color: 'beta.400',
};

export const headerRowStyles: HeaderRowSchema = {
  originalOdds: {
    color: 'gray.100',
  },
  headerRowSvg: {
    fill: 'beta.600',
  },
};
