import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1',
    mt: '3',
    mb: ['3', null, '0'],
    mx: ['1.5', null, '0'],
  },

  container: { display: 'contents' },
  racingBtnsContainer: { display: 'contents' },
  sportBtnsContainer: { display: 'contents' },

  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.64)',
    boxSize: '20px',
    mr: 0,
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.6))',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.64)',
    boxSize: '20px',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.6))',
    mr: 0,
  },

  linkIconBox: {
    flex: '1 0 0',
    minW: '0',
    h: '60px',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    boxShadow:
      '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(10, 15, 19, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.5) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      bgGradient: 'linear(to-b, alpha.500, alpha.300)',
    },
    display: {
      base: 'none',
      xl: 'none',
    },
    _last: { display: 'flex' },

    sx: {
      '&:nth-child(1), &:nth-child(2)': {
        display: { sm: 'flex' },
      },

      '&:nth-child(3), &:nth-child(4)': {
        display: { md: 'flex' },
      },
    },
  },

  linkBox: {
    display: 'flex',
    flex: '1 0 0',
    minW: '0',
    h: '60px',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    boxShadow:
      '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(10, 15, 19, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.5) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      bgGradient: 'linear(to-b, alpha.500, alpha.300)',
    },
  },

  linkOverlay: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1',
    pb: '1',
    pt: '1.5',
    justifyContent: 'center',
    alignItems: 'center',
  },

  linkBoxText: {
    color: 'rgba(0, 0, 0, 0.8)',
    fontFamily: 'accent',
    fontSize: '8px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    maxW: '70px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.4)',
    overflow: 'hidden',
  },

  racingHeader: { display: 'none' },
  sportsHeader: { display: 'none' },

  sportsHeaderTile: {
    color: 'rgba(0, 0, 0, 0.8)',
    fontFamily: 'accent',
    fontSize: '8px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'center',
    maxW: '70px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.4)',
    overflow: 'hidden',
  },
  linkOverlayOverride: {
    color: 'rgba(0, 0, 0, 0.8)',
    fontFamily: 'accent',
    fontSize: '8px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.4)',
    w: '58px',
  },
  sportIconOverride: {
    color: 'white',
    textAlign: 'center',
    w: '56px',
  },
};
