import { CSSObject } from '@chakra-ui/react';
import BetStrideBorder from '@/assets/betstride/images/backgrounds/BetStrideBorder.svg';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'md',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: '0',
  '&&': { px: ['2', '4'], pb: ['1', null, '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'bold',
  fontSize: 'lg',
  textTransform: 'uppercase',
  color: 'white',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  fontFamily: 'accent',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: 'md',
  minHeight: '0',
  bg: ['transparent', null, 'rgba(0, 209, 255, 0.25)'],
  pos: 'relative',
  boxShadow: [
    'unset',
    null,
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset, 0px 2px 20px 0px rgba(0, 0, 0, 0.4)',
  ],
  _before: {
    content: ['none', null, '""'],
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: '0px solid transparent',
    borderImage: `url(${BetStrideBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};

export const WrapperContainer: CSSObject = {
  px: ['2', null, '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
  mb: '3',
};
