import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'alpha.100';
      }
      if (state === 'ended') {
        return 'red.500';
      }
      // 'prior'
      return 'blackAlpha.600';
    },
    color: () => {
      if (state === 'inprogress') {
        return 'blackAlpha.800';
      }
      if (state === 'ended') {
        return 'white';
      }
      // 'prior'
      return 'beta.300';
    },
    fontFamily: 'accent',
    fontWeight: 'bold',
    borderRadius: 'base',
    px: 1,
    boxShadow: () => {
      if (state === 'ended' || state === 'inprogress') {
        return '0px 2px 4px 0px rgba(0, 0, 0, 0.25)';
      }
      return 'none';
    },
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
});

export const CountdownFlex: CSSObject = {
  bg: 'transparent',
  h: '4',
  borderRadius: 'base',
};

export const TextTimestamp: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '9px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
};
