import { BoxProps, ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import headerImg from '@/assets/betstride/images/header-img.png';

export const ButtonOutsideArea = {
  display: 'none',
};

export const BoxWrapper: BoxProps = {
  pr: '0',
};

export const FlexContainer = (): CSSObject => ({
  px: ['2.5', null, null, '0'],
  _before: {
    content: '""',
    position: 'absolute',
    top: 2.5,
    left: 0,
    // The image only appears in large screen
    width: [0, null, null, null, '90%'],
    height: [0, null, null, null, '8%'],
    backgroundImage: `url('${headerImg}')`,
    backgroundSize: 'cover',
    zIndex: -1,
  },
});

export const buttonLogInProps: ButtonProps = {
  variant: 'solid',
  height: '46px',
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  height: '46px',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'primaryAccess',
  height: '46px',
  p: '2.5',
  sx: {
    color: 'alpha.900',
    // ':hover': {
    //   '.span-balance': {
    //     color: 'alpha.900',
    //   },
    //   '.span-bonus': {
    //     color: 'alpha.900',
    //   },
    //   '.chevron-icon': {
    //     color: 'alpha.900',
    //   },
    // },
    '.account-text': {
      fontWeight: 'bold',
    },
    '.btn-span': {
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      mr: '1',
      gap: '0',
      mt: '0.5',
    },
    '.span-balance': {
      fontSize: 'md',
      fontWeight: 'extrabold',
      lineHeight: '14px',
      color: 'beta.500',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    },
    '.span-bonus': {
      fontSize: '2xs',
      lineHeight: '10px',
      fontWeight: 'bold',
      color: 'beta.500',
      alignSelf: 'start',
    },

    'svg:not(.chevron-icon)': {
      pos: 'relative',
      top: '0.5',
      mr: '0.5',
    },
    '.chevron-icon': {
      pos: 'absolute',
      right: '1.5',
      top: '0',
      bottom: '0',
      margin: 'auto 0',
      color: 'beta.500',
    },

    '&&[aria-expanded="true"]': {
      borderBottom: 'none',
      boxShadow: 'none',
      color: 'alpha.600',
      zIndex: 'dropdown',
      '.chevron-icon': { transform: 'rotate(180deg)', color: 'beta.500' },
      ':hover': {
        '.span-balance': {
          color: 'alpha.500',
        },
        '.span-bonus': {
          color: 'alpha.500',
        },
        '.chevron-icon': {
          color: 'alpha.500',
        },
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  variant: 'solid',
  color: 'beta.500',
  height: '46px',
  padding: '0px 5px',
  pt: '12px',
  pb: '13px',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      gap: '1.5',
      textTransform: 'uppercase',
    },
    '.span-bet': {
      fontWeight: 'bold',
      fontSize: '10px',
    },
    '.span-bet-count': {
      fontWeight: 'extrabold',
      fontSize: 'lg',
    },
  },
};

export const buttonBetSlipProps: ButtonProps = {
  variant: 'solid',
  color: 'alpha.800',
  height: '46px',
  padding: '0px 5px',
  pt: '12px',
  pb: '13px',
  mr: '8px',
  borderRadius: 'md',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '1.5',
    },
    ':hover': {
      '.chakra-text': {
        color: 'alpha.900',
      },
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'beta.300',
  boxSize: '12',
  ml: '-1',

  '& svg': {
    boxSize: '8',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '1.5',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const buttonQuickAccess: ButtonProps = {
  variant: 'primaryAccess',
};

export const ButtonGroupBets: CSSObject = {
  gap: '1',

  // TODO: Temporary fix, find the root cause later
  '>*:first-of-type:not(:last-of-type)': {
    borderRightRadius: 'md',
  },
  '>*:not(:first-of-type):last-of-type': {
    borderLeftRadius: 'md',
  },
};

export const ButtonBetSlip: CSSObject = {
  ml: '0',
};

export const ButtonGroup: CSSObject = {
  gap: '1',
};

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  ml: '-1',
};

export const TextButton: CSSObject = {
  fontWeight: 'bold',
  fontSize: '10px',
  color: 'beta.500',
};

export const TextButtonNumber: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'lg',
  color: 'beta.500',
};
