import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flextEventSubTitleProps: FlexProps = {
  alignItems: 'center',
};
export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  px: '2',
  pt: '2',
  w: 'fill-available',
  borderRadius: 'none',
  color: 'white',
});
export const FlexIconPropName: CSSObject = {
  gap: '2',
};
export const SpanBarrierNumber: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'medium',
  pos: 'relative',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  ml: '0.5',
  bottom: '3px',
};
export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.400',
});
export const LinkMarket: CSSObject = {
  color: 'white',
  fontFamily: 'body',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'medium',
};
export const CountdownTimerBox: CSSObject = {
  fontFamily: 'body',
};
export const vsWrapper: CSSObject = {
  fontFamily: 'body',
};
