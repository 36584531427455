import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pl: ['10px', null, null, '8px'],
    pr: '10px',
    h: '38px',
    display: 'flex',
    alignItems: 'center',
    sx: { '&::-webkit-scrollbar': { display: 'none' } },
  },
  breadcrumbLinkItem: {
    fontSize: '2xs',
    fontWeight: 'extrabold',
    color: 'white',
    textDecoration: 'none',
    fontFamily: 'accent',

    _hover: {
      textDecoration: 'underline',
    },

    sx: {
      '&:not([href])': {
        color: 'beta.300',
        textDecoration: 'none',
      },
    },
  },
};
export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
