import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'alpha.400',
  border: '2px solid',
  borderColor: 'alpha.500',
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.15),0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  borderRadius: 'md',
  p: '3',
};
export const HeaderText: CSSObject = {
  color: 'beta.300',
  fontSize: 'md',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  p: '0',
  fontFamily: 'accent',
};
export const HeaderContainer: CSSObject = {
  p: '0',
};
export const CloseButton: CSSObject = {
  display: 'none',
};
