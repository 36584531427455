import { CSSObject } from '@chakra-ui/react';
import overviewBannerDesktop from '@/assets/magnumbet/images/background/overviewBannerDesktop.png';
import overviewBannerMobile from '@/assets/magnumbet/images/background/overviewBannerMobile.png';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'solid',
    lineHeight: 'normal',
    pb: '0.5',
    pt: '0',
    px: '3',
    fontSize: 'xs',
    fontWeight: 'bold',
    borderRadius: 'md',
  },
};
export const FlexBannerContainer: CSSObject = {
  borderRadius: 'md',
  bg: [`url(${overviewBannerMobile})`, null, `url(${overviewBannerDesktop})`],
  '&&': {
    backgroundSize: 'cover',
    backgroundPosition: ['center top', null, 'center'],
    backgroundRepeat: 'no-repeat',
  },
  color: 'white',
  gap: '0',
  p: ['2', null, '3'],
  minH: '117px',
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.25) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
};
export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
};
export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'bold',
  color: 'beta.300',
  textTransform: 'capitalize',
};
export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  pb: ['3', null, '2'],
  pt: ['3', null, '0'],
};
export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
  alignItems: ['start', null, 'end'],
};
export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontFamily: 'accent',
  textAlign: ['start', null, 'end'],
};
export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'beta.300',
});
export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'beta.300',
});
export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'beta.300',
});
export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderTop: '1px dashed',
  borderTopColor: 'rgba(255, 255, 255, 0.1)',
  pt: '2',
};
export const TextButton: CSSObject = {
  h: '4',
  fontSize: 'xs',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
};
export const FlexBalanceBetInfo: CSSObject = {
  bg: 'transparent',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.400',
  color: 'white',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
  fontSize: 'xs',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  alignItems: 'center',
  h: '10',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
};
export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
  gap: '1.5',
};
