import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'md',
  bgGradient: 'linear (to-b, #49808B, #3A727E)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  _last: {
    mb: '0',
  },
};
export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'normal',
  position: 'relative',
  top: '-0.5',
};
export const FlexDeductionsContainer: CSSObject = {
  mx: '2',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
  alignItems: 'center',
};
export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    _notFirst: {
      borderLeft: '1px solid',
      borderColor: ['transparent', null, 'whiteAlpha.200'],
      pl: ['0', null, '2'],
    },
  },
};
export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
};
export const TextDeductions: CSSObject = {
  color: 'white',
  w: 'max-content',
};
export const TextScratchedInfo: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const ScratchedText: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
};

export const RunnerPropositionWrapper: CSSObject = {
  columnGap: '1.5',
};
