import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  p: '12',
  py: ['4', null, '6'],
  px: ['2', null, '6'],
  flex: [null, null, 1],
  justifyContent: [null, null, 'center'],
  minW: ['unset', null, '460px'],
  maxW: ['100vw', null, null],
  bg: 'alpha.300',
  borderRightRadius: [null, null, '10px'],
  borderTop: ['2px', '2px', '0px'],
  borderColor: 'beta.500',
  fontFamily: 'accent',
  form: {
    '.chakra-form-control': {
      '.chakra-form__label': {
        color: 'white',
        fontSize: 'xs',
      },
    },
    'a[href*="forgot-password"]': {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 'xs',
    },
    '.chakra-button[data-cy*="loginSubmit"]': {
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 'xs',
    },
    '.chakra-form__error-message': {
      bg: 'red.600',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
      borderRadius: 'base',
      p: '1',
      color: 'white',
      fontWeight: 'medium',
      fontSize: 'xs',
    },
    'button[aria-label*="password toggle"]': {
      top: '-1px',
      color: 'beta.400',
    },
    'input:-webkit-autofill, input:-webkit-autofill:focus': {
      transition: 'background-color 0s 600000s, color 0s 600000s',
    },
    '#email': {
      borderRadius: 'md',
      color: 'white',
      bg: 'alpha.500',
      fontSize: 'xs',
      fontWeight: 'normal',
    },
  },
};

export const Title: CSSObject = {
  color: 'beta.300',
  fontSize: 'lg',
  fontStyle: 'normal',
  fontFamily: 'heading',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  mb: '4',
  mt: [null, null, '9'],
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};
