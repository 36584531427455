import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { CustomButtonProps } from '@/components/Button/Button';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const FlexBetslipHeading: CSSObject = {
  h: '12',
  borderBottomColor: 'rgba(255, 255, 255, 0.06)',
  borderBottom: '1px',
  color: [null, null, null, null, 'white'],
};

export const BetslipTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'extrabold',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  color: 'beta.300',
};

export const BetLengthIndicator: CSSObject = {
  bg: 'yellow.400',
  color: 'yellow.900',
  fontSize: 'xs',
  borderRadius: 'base',
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'beta.300',
};

export const closeIcon: TCloseIcon = {
  boxSize: '8',
  as: CloseCircle,
  color: 'white',
};

export const CloseIconButton: CustomButtonProps = {
  border: 'none',
  bg: 'transparent',
  _hover: {
    border: 'none',
    bg: 'none',
  },
};
