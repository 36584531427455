import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
    bg: 'transparent',

    '&:disabled': { bg: 'transparent' },

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px',
      borderTopColor: 'blackAlpha.200',
      p: '2',

      _first: { borderTop: 0 },
    }),
    ...(!!isSelected && {}),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',
    fontWeight: 'bold',
    fontFamily: 'accent',
    color: 'white',
    _first: {
      fontWeight: 'bold',

      'button:hover &': { color: 'white' },

      ...((propLength ?? 0) === 2 && {
        color: 'white',
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'sm',
        fontWeight: 'semibold',
      }),
    },
    _hover: {
      bg: 'none',
    },

    '&.return-amount': {
      bgGradient: isSelected
        ? 'linear(to-b, alpha.500, beta.700)'
        : 'linear(to-b, beta.300, beta.400)',
      p: 2,
      w: 'full',
      borderRadius: 'md',
      color: isSelected ? 'beta.300' : 'beta.500',
      fontWeight: 'bold',
      textShadow: isSelected
        ? '0px 0px 8px rgba(254, 208, 119, 0.5)'
        : '0px 1px 0px rgba(255, 255, 255, 0.35)',
      boxShadow: isSelected
        ? '0px 2px 4px 0px rgba(0, 0, 0, 0.35) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)'
        : '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
      border: 'none',

      transition: 'all, .3s ease-in-out',

      _hover: {
        bgGradient: 'linear(to-b, beta.400, beta.400)',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
