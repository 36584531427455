import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'primary',
  height: '38px',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};

export const FlexWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  alignItems: 'center',
  borderBottomRadius: 'md',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};
export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
  },
  'chakra-input__left-addon': {
    mt: '2px',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.3)',
    border: 'none',
    _hover: {
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  '.chakra-form-control': {
    display: 'flex',
    alignItems: 'center',
    mb: 0,
    w: ['100%', null, 'unset'],
    bgGradient: 'none',
  },
  '.chakra-form__label': {
    mt: '0',
  },
  '.chakra-input': {
    m: '2px',
  },
};
export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  mx: [0, null, '-3'],
  mb: [0, null, '-6'],
  bg: 'alpha.700',
  zIndex: '10',
};
export const InfoHeader: CSSObject = {
  h: '9',
  alignItems: 'center',
  px: '4',
  py: '3',
  borderTop: '4px solid',
  borderColor: 'beta.300',
};
export const InfoText: CSSObject = {
  _first: {
    fontSize: 'xs',
    color: 'beta.300',
    fontFamily: 'accent',
    fontWeight: 'bold',
  },
  _last: {
    fontSize: 'sm',
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'bold',
  },
};
