import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  pt: '1',
};
export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
  fontWeight: 'medium',
};
export const PageInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  color: 'white',
};
export const SmallHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
  fontWeight: 'medium',
};
export const Text: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  color: 'white',
};
export const ImageGamblersHelp: CSSObject = {
  bg: 'white',
  p: '2',
  minW: '184px',
  w: '184px',
  borderRadius: 'lg',
  boxShadow: '0px 3px 8px 0px #00000033',
};
export const FlexResponsibleGamblingWrapper: CSSObject = {
  my: '2',
};
export const Link: CSSObject = {
  color: 'beta.300',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecoration: 'none',
  },
};
export const SubHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
  fontWeight: 'medium',
};
export const GridWrapper: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2',
  maxW: 'container.sm',
  pb: '0',
};
