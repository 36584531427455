export const en = {
  'generic.error':
    'Sorry, Mystery Bet is not currently available at this price on this race',
  'mysteryBet.viewInMyBets': 'VIEW IN MY BETS',
  'mysteryBet.rollOver': 'Roll Over',
  'mysteryBet.rolledOver': 'Rolled Over',
  'generic.nopolicyfound': 'No policy found',
  'generic.submit': 'Submit',
  'generic.viewMore': 'View more',
  'generic.refresh': 'Refresh',
  'generic.home': 'Home',
  'generic.winplace': 'Win/Place',
  'generic.potentialWinnings': 'Potential Winnings',
  'generic.fixedWin': 'Fixed Win',
  'generic.mysteryBet': 'Mystery Bet',
  'generic.fixedPlace': 'Fixed Place',
  'generic.winSP': 'Win - SP',
  'generic.placeSP': 'Place - SP',
  'generic.winTote': 'TOTE Win',
  'generic.placeTote': 'TOTE Place',
  'generic.mystery': 'MYSTERY',
  'generic.marketNameWithSP': '{marketName} (SP)',
  'generic.toteWinMarket': 'Mid Div Racing Win',
  'generic.totePlaceMarket': 'Mid Div Racing Place',
  'generic.bestToteWinMarket': 'Best TOTE Racing Win',
  'generic.bestTotePlaceMarket': 'Best TOTE Racing Place',
  'generic.fixedeachway': 'Fixed Each Way',
  'generic.pickdate': 'Pick a Date',
  'generic.close': 'Close',
  'generic.email': 'Email',
  'generic.bet': 'Bet',
  'generic.bets': 'Bets',
  'generic.moneyBack': 'Money Back',
  'generic.password': 'Password',
  'generic.termsconditions': 'Terms & Conditions',
  'generic.privacyPolicy': 'Privacy Policy',
  'generic.promoPolicyTandC': 'Promotion Terms & Conditions',
  'generic.responsiblegambling': 'Responsible Gambling',
  'generic.responsiblegamblingtools': 'Responsible Gambling Tools',
  'generic.and': 'and',
  'generic.policy': 'Policy',
  'generic.restrictedfuture': 'Date cannot be in the future',
  'generic.restrictedpast': 'Date cannot be in the past',
  'generic.logout': 'Logout',
  'generic.version': 'Version',
  'generic.savechanges': 'Save Changes',
  'generic.verified': 'Verified',
  'generic.unverified': 'Unverified',
  'generic.markets': ' Markets',
  'generic.race': 'Race',
  'generic.seeall': 'See All',
  'generic.showmore': 'Show More',
  'generic.bonus': 'Bonus: ',
  'generic.loading': 'Loading...',
  'generic.required': 'Required',
  'generic.helpline': '1800 858 858',
  'generic.gamblershelp': 'Gambler`s Help',
  'generic.gamblingvic': 'Responsible Gambling Victoria',
  'generic.erroroccurred': 'An error occurred. Please try again',
  'generic.selectplaceholder': 'Please Select',
  'generic.login': 'Log In',
  'generic.join': 'Join Now',
  'generic.signup': 'Sign up',
  'generic.stake': 'Stake',
  'generic.stakeWithValue': 'Stake: {stake}',
  'generic.emptystake': '0.00',
  'generic.flexi': 'Flexi',
  'generic.scratched': 'Scratched',
  'generic.runners': 'Runners',
  'generic.win': 'Win',
  'generic.hello': 'Hello!',
  'generic.show': 'Show',
  'generic.hide': 'Hide',
  'generic.na': 'N/A',
  'generic.sus': 'SUS',
  'generic.toggleBalance': '{state} balance',
  'generic.account': 'Account',
  'generic.betTypeNotSupported': 'You do not have access to this bet type',
  'generic.popups':
    'Please disable your pop-up blocker or allow pop-ups for this site to view the content.',
  'generic.startingPriceAcronym': 'SP',
  'generic.toteAcronym': 'MD',
  'generic.bestToteAcronym': 'BT',
  'generic.addtoBetSlip': 'Add to Betslip',
  'generic.loginToView': 'Login to view',
  'generic.allMarkets': 'All Markets',
  'generic.all': 'All',
  'generic.cancel': 'Cancel',
  'generic.clear': 'Clear',
  'generic.favorites': 'Favourites',
  'generic.sameGameMulti': 'Same Game Multi',
  'generic.fav': 'Fav',
  'generic.recent': 'Recent',
  'generic.open': 'Open',
  'generic.racing': 'Racing',
  'generic.upcoming': 'Upcoming',
  'generic.sortBy': 'Sort By',
  'generic.sortBy.time': 'Time',
  'generic.sortBy.sport': 'Sport',
  'generic.sport': 'Sports',
  'generic.sportAZ': 'A-Z Sports',
  'generic.topLeagues': 'Top leagues',
  'generic.countryOthers': 'Others',
  'generic.teamOrEventSearch': 'FIND YOUR TEAM OR EVENT...',
  'generic.marketCount':
    '{marketCount, plural, one {1 Market} other {# Markets}}',
  'generic.cashOut': 'Cash Out',
  'generic.cashedOut': 'Cashed Out',
  'generic.accept': 'Accept',
  'generic.decline': 'Decline',
  'generic.done': 'Done',
  'generic.errors.error': 'Error',
  'generic.errors.accesDenied': 'Access Denied',
  'generic.errors.rateLimit': 'Please wait a few moments and try again',
  'generic.errors.default': 'An error occurred. Please try again later.',
  'onboarding.step1.tabTitle': '| Signup',
  'onboarding.step1.pageTitle': "Let's get you started.",
  'onboarding.step1.pageSubtitle':
    'PunterBet, the ultimate destination for racing and sports betting. Create an account and start placing bets now!',
  'onboarding.step1.header': 'Welcome, Let’s get you set up!',
  'onboarding.step1.welcome': 'Welcome to {getThemeName}',
  'onboarding.step1.subheader': 'Please enter your details.',
  'onboarding.step1.emailinputplaceholder': 'eg. john@email.com',
  'onboarding.step1.emailinputerror': 'Email requirements not met',
  'onboarding.step1.emailinputempty': 'An email address is needed',
  'onboarding.step1.passwordinputlabel': 'Create a password',
  'onboarding.step1.passwordinputplaceholder': '••••••••',
  'onboarding.step1.passwordinputerror': 'Password requirements not met',
  'onboarding.step1.passwordnospaces': 'Password cannot contain any spaces',
  'onboarding.step1.passwordempty': 'A password is needed',
  'onboarding.step1.passwordrule1': 'Includes lower and uppercase',
  'onboarding.step1.passwordrule2': 'Includes at least 1 number',
  'onboarding.step1.passwordrule3':
    'Password is more than 8 characters in length',
  'onboarding.step1.passwordrule4': "Password doesn't contain spaces",
  'onboarding.step1.ageacknowledgement':
    'I acknowledge that I am over 18 and have read, understood and agree to be bound by the',
  'onboarding.step1.ageacknowledgementerror':
    'Sorry, you need to agree before you can create an account',
  'onboarding.step1.promotionacknowledgement':
    'I agree to receive promotional material from {getThemeName} via email, phone and other means',
  'onboarding.register.createAccount': 'Create My Account',
  'onboarding.register.verification': 'Verify Your Account',
  'onboarding.step1.button': 'Create My Account',
  'onboarding.step1.login': 'Already have an account? <link>Log In</link>',
  'onboarding.step1.logincta': 'Log In',
  'onboarding.step2.pagetitle': '| Account Verification',
  'onboarding.step2.header': "Let's get you verified!",
  'onboarding.step2.subheader':
    'This info will allow us to make sure you are a real person and lets us check that you are OK to start betting with us.',
  'onboarding.step2.titleInputLabel': 'Title',
  'account.personalDetails.titlePlaceholder': 'Select',
  'onboarding.step2.titleoptions.0': 'Mr',
  'onboarding.step2.titleoptions.1': 'Mrs',
  'onboarding.step2.titleoptions.2': 'Miss',
  'onboarding.step2.titleoptions.3': 'Ms',
  'onboarding.step2.titleoptions.4': 'Dr',
  'onboarding.step2.titleoptions.5': 'Other',
  'onboarding.step2.fieldTooLongErrorText': 'Field is too long',
  'onboarding.step2.firstnameinputlabel': 'First Name',
  'onboarding.step2.firstnameerrortext': 'First Name is required',
  'onboarding.step2.middleNameInputLabel': 'Middle Name',
  'onboarding.step2.middleNameInputPlaceholder': 'Optional',
  'onboarding.step2.lastnameinputlabel': 'Last Name',
  'onboarding.step2.lastnameerrortext': 'Last Name is required',
  'onboarding.step2.validnamerequirederrortext': 'Valid name is required',
  'onboarding.step2.validMiddleNameRequiredErrorText':
    'Valid middle name is required',
  'onboarding.step2.mobileinputlabel': 'Mobile',
  'onboarding.step2.mobileplaceholder': 'eg. 0412345678',
  'onboarding.step2.mobilerequirederrortext': 'Mobile is required',
  'onboarding.step2.mobilenumberonlyerror':
    'Mobile field can only contain numbers',
  'onboarding.step2.mobilenumberauonlyerror':
    'Must be an Australian mobile number.',
  'onboarding.step2.addressinputlabel': 'Residential Address',
  'onboarding.step2.addressinputplaceholder': '* Address must match your ID',
  'onboarding.step2.addressinputmanualplaceholder': 'Manual Entry',
  'onboarding.step2.addressrequirederrortext':
    'Residential Address is required',
  'onboarding.step2.addressinputmanualentry':
    'Can’t see your address? Enter it manually',
  'onboarding.step2.streetnumberinputlabel': 'Street number',
  'onboarding.step2.streetnumberrequirederror': 'Street number is required',
  'onboarding.step2.streetnameinputlabel': 'Street name',
  'onboarding.step2.streetnamerequirederror': 'Street name is required',
  'onboarding.step2.suburbinputlabel': 'Suburb',
  'onboarding.step2.suburbrequirederror': 'Suburb is required',
  'onboarding.step2.stateinputlabel': 'State',
  'onboarding.step2.staterequirederror': 'State is required',
  'onboarding.step2.postcodeinputlabel': 'Postcode',
  'onboarding.step2.postcoderequirederror': 'Postcode is required',
  'onboarding.step2.postcodelengtherror': 'Postcode must be 4 digits',
  'onboarding.step2.dobinputlabel': 'Date of Birth',
  'onboarding.step2.dobinputerror':
    'Sorry, you need to be over 18 years of age to use this platform',
  'onboarding.step2.dobrequirederrortext': 'Date of Birth is required',
  'onboarding.step2.verificationerrortext':
    "Sorry, we couldn't verify you. Please try again later.",
  'onboarding.step2.skipverificationoption':
    'Please skip for now and manually verify me',
  'onboarding.step2.verificationerror': 'There was an error verifying you',
  'onboarding.step2.button': 'Get Verified',
  'onboarding.step3.pagetitle': '| Deposit Limit',
  'onboarding.step3.header': 'Congrats! You are ready to go',
  'onboarding.step3.subheader': 'Would you like to set up a deposit limit?',
  'onboarding.step3.radiooption1':
    'No, I have read and understand the benefits and do not want to set a Deposit Limit',
  'onboarding.step3.radiooption2': 'Yes, I would like to set a Deposit Limit',
  'onboarding.step3.limitamountinputlabel': 'Limit amount',
  'onboarding.step3.limitperiodinputlabel': 'Limit period',
  'onboarding.step3.limitperiodoptions.0': '1 Day',
  'onboarding.step3.limitperiodoptions.1': '7 Days',
  'onboarding.step3.limitperiodoptions.2': '14 Days',
  'onboarding.step3.limitperiodoptions.3': '28 Days',
  'onboarding.step3.limitamountrequiredtext':
    'As you have chosen to add a deposit limit, your limit amount is required',
  'onboarding.step3.limitdurationrequiredtext':
    'As you have chosen to add a deposit limit, your limit duration is required',
  'onboarding.step3.limitnumbersonlyerror':
    'Deposit limit can only contain numbers',
  'onboarding.step3.depositlimiterror':
    'There was an error creating your deposit limit',
  'onboarding.step3.button': 'Continue',
  'onboarding.depositLimit.completeSignup': 'Complete signup',
  'onboarding.depositLimit.formTitle': 'Deposit limit',
  'onboarding.locationnotallowed.pagetitle': '| Location not allowed',
  'onboarding.locationnotallowed.header': 'Location not available',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing {themeName} from outside of Australia.',
  'onboarding.refreshpage': 'Refresh the page, or try again later',
  'onboarding.locationnotallowed.bodycopy':
    '{themeName} is only available to customers from Australia. If you are based in Australia and believe that you are seeing this page in error, please contact us at ',
  'onboarding.locationnotallowed.bodycopyntone':
    '{themeName} is only available to customers from Australia. If you are based in Australia and believe that you are seeing this page in error, please contact ',
  'onboarding.locationnotallowed.bodycopynttwo':
    '{themeName} is licensed and regulated by the ',
  'onboarding.locationnotallowed.nt':
    '<link> Northern Territory Racing Commission</link>.',
  'onboarding.locationnotallowed.ouroffices': 'Our Offices',
  'onboarding.locationnotallowed.locationenquiry': 'Location Enquiry - ',
  'onboarding.locationnotallowed.registration':
    "{themeName} is a licensed bookmaker holding a bookmaking registration with the <commissionLink>Victorian Gambling and Casino Control Commission of Australia</commissionLink>, as well as being a member of the <bookmakersLink>Victorian Bookmakers Association</bookmakersLink>. Furthermore, {themeName} holds a club bookmaker's license as approved by <racingLink>Racing Victoria Limited.</racingLink>",
  'onboarding.firebaseerrormessages.errorcreatingaccount':
    'There was an issue creating your account:',
  'onboarding.firebaseerrormessages.wrongcredentials':
    "Your credentials don't seem to be correct. Please try again.",
  'onboarding.firebaseerrormessages.emailinuse':
    'This email address is already in use.',
  'onboarding.firebaseerrormessages.userdisabled':
    'Your account has been disabled.',
  'onboarding.firebaseerrormessages.unspecifiederror':
    'An unspecified error occurred. Please try again later.',
  'onboarding.firebaseerrormessages.usernotfound':
    'Unable to find your account.',
  'onboarding.signuperrormessages.contactcustomerservice':
    'Error: Contact Customer Service',
  'onboarding.signuperrormessages.emailexists':
    'This email address is already in use.',
  'onboarding.signuperrormessages.streetnumberrequired':
    'Please enter an address that contains a street number.',
  'onboarding.signupErrorMessages.betstopTitle': 'Betsop',
  'onboarding.signupErrorMessages.betstopDescription':
    'As you are currently registered on the <betstop>BetStop</betstop> national register you are unable to open a new interactive wagering services account.\n Find out more about services that can assist with <gamblingHelpOnline>your gambling here</gamblingHelpOnline>.  Gambling Help is available to you 24/7. Please call <phone>1800 858 858</phone> or visit <gamblingHelpOnline>https://www.gamblinghelponline.org.au</gamblingHelpOnline>. Confidential, professional, free help, 24 hours a day, 7 days a week. Talk to someone who understands your issues.',
  'onboarding.forgotpassword.pagetitle': '| Forgot Password',
  'onboarding.forgotpassword.header': 'Forgot Password',
  'onboarding.forgotpassword.bodytext':
    'Please enter your email below and we will send you a link to reset your password.',
  'onboarding.forgotpassword.successtext':
    'Thank you. We’ve sent a reset link to ',
  'onboarding.forgotpassword.title': 'Forgot Password?',
  'onboarding.forgotpassword.loginlink': 'Click here to login',
  'onboarding.forgotpassword.loginCta': 'Back to login',
  'onboarding.forgotpassword.button': 'Send reset link',
  'onboarding.login.pagetitle': '| Login',
  'onboarding.login.formTitle': 'Sign in to continue',
  'onboarding.login.header': 'Welcome Back',
  'onboarding.login.title': 'Welcome back!',
  'onboarding.login.subtitle':
    "The ultimate destination for racing and sports betting. Let's sign you back in so you can <b>start placing bets now!</b>",
  'onboarding.login.footerInfo': 'Need an account? <link>Join now</link>',
  'onboarding.login.footerForgotInfo': 'Need an account? <link>Join now</link>',
  'onboarding.login.joinNowCTA': 'Join now',
  'onboarding.login.forgotpassword': 'Forgot password',
  'onboarding.login.button': 'Login',
  'onboarding.login.notsignedin': 'You are not signed in.',
  'onboarding.login.autologoutmessage':
    'You have been automatically signed out due to inactivity. Please sign in again.',
  'onboarding.login.signup': "Don't have an account? <cta>Join Now</cta>",
  'onboarding.login.signupcta': 'Join Now',
  'onboarding.login.error_temporary_exclusion':
    'Sorry... Your account has been locked until DATE',
  'onboarding.login.error_suspended_kyc':
    'We are unable to verify your identity. Please contact customer service to continue',
  'onboarding.login.error_closed_account':
    'Your account has been closed. Please contact support to reactivate the account',
  'onboarding.login.error_permanent_exclusion':
    'Your account has been permanently excluded',
  'onboarding.signuperrormessages.addresslookuppartial':
    'An error occurred during address lookup, please fill in the remaining details.',
  'betslip.button.placebet': 'Place Bet',
  'betslip.betslipheading': 'Bet Slip',
  'betslip.betslipreviewheading': 'Review & Confirm',
  'betslip.betslipreceiptheading': 'Bet Receipt',
  'betSlip.potentialReturns': 'Est. Return:',
  'betSlip.estPayout': 'Est. Payout:',
  'betSlip.combinedEstPayout': 'Combined Est. Payout:',
  'betslip.negativeerror': 'Negative stake',
  'betslip.usebonusbet': 'Use Bonus Bet',
  'betslip.eachway': 'Each Way',
  'betslip.bonusbetapplied': 'Bonus Bet Applied!',
  'betslip.eventrules.ruleheading': 'Event Rules: ',
  'betslip.eventrules.noplace': 'No place betting available on this race',
  'betslip.eventrules.twoplacedividends': 'No third dividend',
  'betslip.eventrules.threeplacedividends': 'Three place dividends paid',
  'betslip.emptybetsmodal.warningheading': 'Warning',
  'betslip.emptybetsmodal.emptymodalmessage':
    'You must enter a stake to place a bet',
  'betslip.emptybetsmodal.modalbutton': 'Ok',
  'betslip.betslipbetcard.stakeprefix': '+',
  'betslip.betslipbetcard.stake': 'STAKE',
  'betslip.betslipbetcard.bal': 'Bal',
  'betslip.betslipbetcard.placed': 'Bet Placed: ',
  'betslip.betslipbetcard.emptystakeerror': 'Stake required',
  'betslip.betslipbetcard.negativevalueerror': 'Negative value',
  'betslip.betslipbetcard.emptyflexerror': 'Flexi required',
  'betslip.betslipbetcard.betidlabel': 'Bet ID:',
  'betslip.betslipbetcard.fixedpricewinlabel': 'Win (Fixed Price)',
  'betslip.betslipbetcard.fixedpriceplacelabel': 'Place (Fixed Price)',
  'betslip.betslipbetcard.winlabel': 'Win',
  'betslip.betslipbetcard.combos': 'Combos',
  'betslip.betslipbetcard.stakepercombo': 'Stake per combo',
  'betslip.betslipbetcard.partialbetapprovalinfo': '% has been ',
  'betslip.betslipbetcard.betapprovalinfo': 'Your bet has been ',
  'betslip.betslipbetcard.approved': 'approved.',
  'betslip.betslipbetcard.rejected': 'rejected.',
  'betSlip.betSlipBetCard.rejected': '{amount} has been rejected',
  'betslip.betslipbetcard.pendingapproval': 'Pending approval',
  'betslip.betslipbetcard.thiseventisclosed': 'This event is closed',
  'betslip.betslipbetcard.multis.estreturns': 'Est. Return',
  'betslip.betslipbetcard.multis.estnoreturns': 'Est. Return $0.00',
  'betslip.betslipbetcard.multis.legmulti': 'Leg Multi',
  'betslip.betslipbetcard.multis.leg': 'Leg',
  'betslip.betslipbetcard.multis.multiaddmorecta':
    'To build a Multi, please add more selections to increase your potential returns.',
  'betslip.betslipbetcard.multis.ineligiblepropositions':
    'Some selections in your betslip are not eligible for a multibet',
  'betslip.betslipbetcard.multis.multimorethan20props':
    'You can only place up to 20 legs in a single Multi',
  'betslip.betslipbetcard.srm.top': 'Top {index}:',
  'betslip.betslipbetcard.srm.error': 'Something went wrong',
  'betSlip.betSlipBetCard.approved': 'approved',
  'betslip.betslipbetcard.errors.oddsChange.increase':
    'Congratulations! Your odds have increased.',
  'betslip.betslipbetcard.errors.oddsChange.decrease':
    'Your odds have changed. Please review your selections before confirming.',
  'betslip.betslipbetcard.errors.insufficentfund':
    'Your balance is too low. Lower your stake or make a deposit to cover your bets.',
  'betslip.betslipbetcard.errors.propositionclosed':
    'Betting has closed on your selection.',
  'betslip.betslipbetcard.errors.multi.propositionClosed.updateConfirm':
    'Some propositions in your multi have closed. Please review your selections before confirming',
  'betslip.betslipbetcard.errors.multi.propositionClosed.removeBet':
    'Some propositions in your multi have closed. Add more bets to create a new multi.',
  'betslip.betslipbetcard.errors.highrisk':
    'We were unable to process your request. Contact support if the issue persists.',
  'betslip.betslipbetcard.errors.accountclosed':
    'We were unable to process your request. Contact support for further assistance.',
  'betslip.betslipbetcard.errors.manualrejection':
    'Unfortunately, your bet has been rejected.',
  'betslip.betslipbetcard.errors.unknownerror':
    'Your submission was unsuccessful. Please try again.',
  'betslip.betslipbetcard.errors.unsupportedbettype':
    'Unable to place this bet type.',
  'betslip.betslipbetcard.errors.negativestake': 'Invalid Stake.',
  'betslip.betslipbetcard.errors.placebetinvalidfieldsize':
    'Betting is no longer available on your selection.',
  'betslip.betslipbetcard.errors.duplicaterequest':
    'Bet Rejected, duplicate identified.',
  'betslip.betslipbetcard.errors.betsliprejection':
    'We were unable to process your request. Contact support for further assistance.',
  'betslip.betslipbetcard.errors.alreadyprocessedrequest':
    'We were unable to process your request. Contact support if the issue persists.',
  'betslip.betslipbetcard.errors.selectionexpansionrejection':
    'We were unable to process your request. Contact support if the issue persists.',
  'betslip.betslipbetcard.errors.insufficientlegsformulti':
    'Insufficient legs to place a Multi.',
  'betslip.betslipbetcard.errors.exceededmaximumlegsformulti':
    'A Multi Bet must not exceed 20 legs.',
  'betslip.betslipbetcard.errors.multiplelegsineventformulti':
    'Your selections cannot be combined into a Multi.',
  'betslip.betslipbetcard.errors.invalidproposition':
    'Betting is no longer available on your selection.',
  'betSlip.betSlipBetCard.errors.startingPrice':
    'A Multi Bet cannot include SP bets.',
  'betslip.betslipmodal.genericerror':
    'An unknown error occurred. Please try again',
  'betslip.betslipmodal.boxed': 'Boxed',
  'betslip.betslipmodal.types.single': 'Singles',
  'betslip.betslipmodal.types.exotics': 'Exotics',
  'betslip.betslipmodal.types.eachway': 'Each Way',
  'betslip.betslipmodal.types.multi': 'Multis',
  'betslip.betslipmodal.types.combomulti': 'Combo Multis',
  'betslip.betslipmodal.types.sgmulti': 'Same Game Multi',
  'betslip.betslipmodal.types.srmulti': 'Same Race Multi',
  'betslip.betslipmodal.types.blended': 'Blended',
  'betslip.betslipmodal.types.totemulti': 'Multiples',
  'betslip.betslipmodal.exotictypes.firstfour': 'First 4',
  'betslip.betslipmodal.exotictypes.quinella': 'Quinella',
  'betslip.betslipmodal.exotictypes.exacta': 'Exacta',
  'betslip.betslipmodal.exotictypes.trifecta': 'Trifecta',
  'betslip.betslipmodal.totalstake': 'Total stake:',
  'betslip.betslipmodal.bet': 'Bet',
  'betslip.betslipmodal.confirmbets': 'Confirm Bets',
  'betslip.betslipmodal.placebets': 'Place bet',
  'betslip.betslipmodal.placedbetsconfirmation': 'Your bets have been placed.',
  'betslip.betslipmodal.mybets': 'My Bets',
  'betslip.betslipmodal.updateconfirmbets': 'Update & Confirm',
  'betslip.betslipmodal.login': 'Login & Bet',
  'betslip.betslipmodal.editbets': 'Edit Bets',
  'betslip.betslipmodal.clearbets': 'Clear',
  'betslip.betslipmodal.makeadeposit': 'Make a deposit',
  'betslip.betslipmodal.lowerstakeheading': 'Your balance is too low',
  'betslip.betslipmodal.lowerstakedescription': 'Lower your stake or deposit',
  'betslip.betslipmodal.emptybetslipheading': 'Your bet slip is empty!',
  'betslip.betslipmodal.betgalaxyemptybetslipheading':
    'Houston, your bet slip is empty!',
  'betslip.betslipmodal.emptybetslipsubheading':
    'To get started, add your racing and sports selections.',
  'betslip.betslipmodal.emptymultibets':
    'Add more single bets to create a multi, or combine with a same game multi and increase your potential win',
  'betslip.betslipmodal.stakeRoundedDown':
    'Your stake has been rounded down to the nearest valid stake of {stake}',
  'betSlip.betSlipModal.betRecoveryAlert':
    'This bet is eligible for <b>Bet Recovery!</b>',
  'betSlip.betSlipModal.sgmHeader':
    '{legCount} Leg <span>Same Game Multi</span>',
  'betSlip.betSlipModal.srmHeader':
    '{legCount} Leg <span>Same Race Multi</span>',
  'betSlip.betSlipModal.multiHeader': '{legCount} Leg Multi',
  'betSlip.betSlipModal.blendedHeader': 'Blended',
  'betSlip.oddsBoosted': 'Odds Boosted',
  'home.pagetitle': '| Home',
  'home.carousel.accessbility': 'Carousel content displaying a banner.',
  'home.nexttojumpheading': 'Next to Jump',
  'home.upcomingsportsheading': 'Upcoming Sports',
  'home.horsesheading': 'Thoroughbreds',
  'home.greyhoundsheading': 'Greyhounds',
  'home.harnessheading': 'Harness',
  'home.viewracecard': 'View Race Card',
  'home.nextToPlay': 'Next to play',
  'home.haveAPunt': 'Have a punt!',
  'helpcentre.policy.association': `{themeName} is a member of the Victorian Bookmakers' Association and adheres to the Victorian Bookmakers Association Responsible Gambling Code of Conduct. A copy of the Code of Conduct can be downloaded from the <website>https://vicbookmakers.com.au/responsible-gambling/</website> `,
  'helpcentre.policy.getHelp':
    'https://www.responsiblegambling.vic.gov.au/getting-help',
  'helpcentre.promotions.specials': 'Specials',
  'helpcentre.promotions.promotions': 'Promotions',
  'helpcentre.promotions.termsAndConditions': 'Terms and Conditions',
  'helpcentre.promotions.description':
    'Promotions and Featured Markets are subject to our Promotion Terms and Conditions.',
  'helpcentre.promotions.modal.terms':
    'All promotional offers are subject to {bookieName} Terms & Conditions and Promotional Terms & Conditions',
  'maintenance.contentheaderbetgalaxy': 'Punter, we have a problem',
  'maintenance.contentheaderwellbet': 'Give us a minute!',
  'maintenance.openingtagline':
    'We are currently having some technical issues at ',
  'maintenance.closingtaglinebetgalaxy':
    '. Ground control are looking into the issue as a high priority. We apologise and thank you for your patience.',
  'maintenance.closingtaglinewellbet':
    '. Our technical teams are looking for a solution as a high priority. We apologise and thank you for your patience.',
  'maintenance.ouroffices': 'Our Offices',
  'maintenance.investigation':
    'We are currently investigating the issue. Please try again soon.',
  'account.lifetimeExclusionConfirmation': 'You have been excluded for life',
  'account.personaldetails.pageTitle': '| Personal Details',
  'account.personaldetails.header': 'Personal Details',
  'account.personaldetails.button': 'Save Changes',
  'account.personaldetails.titleInputLabel': 'Title',
  'account.personaldetails.firstNameInputLabel': 'First Name',
  'account.personaldetails.middleNameInputlabel': 'Middle Name',
  'account.personaldetails.lastNameInputLabel': 'Last Name',
  'account.personaldetails.emailInputLabel': 'Email',
  'account.personaldetails.mobileInputLabel': 'Mobile',
  'account.personaldetails.addressInputLabel': 'Residential Address',
  'account.personaldetails.dobInputLabel': 'Date of Birth',
  'account.personaldetails.streetnumberinputlabel': 'Street number',
  'account.personaldetails.streetnameinputlabel': 'Street name',
  'account.personaldetails.suburbinputlabel': 'Suburb',
  'account.personaldetails.stateinputlabel': 'State',
  'account.personaldetails.postcodeinputlabel': 'Postcode',
  'account.personaldetails.postcodelengtherror': 'Postcode must be 4 digits',
  'account.personaldetails.submitsuccess':
    'Personal details successfully updated.',
  'account.personaldetails.submiterror':
    'There was an error updating your personal details',
  'account.updateemail.pagetitle': '| Update Email',
  'account.updateemail.header': 'Update your email',
  'account.updateemail.emailrequired': 'Your email address is required',
  'account.updateemail.successmessage': 'Updated email successfully.',
  'account.updateemail.failmessage': 'There was an issue updating your email',
  'account.verifyidentity.pagetitle': '| Verify Identity',
  'account.verifyidentity.header': 'Verify Your Identity',
  'account.verifyidentity.failed': 'Unable to verify account',
  'account.verifyidentity.statusinprogress':
    'Your identity verification is in progress.',
  'account.verifyidentity.statuspending':
    'Your identity verification is pending manual review.',
  'account.verifyidentity.statuscomplete': 'Your identity is verified.',
  'account.verifyidentity.statusfailed':
    'Unable to verify your identity. Please',
  'account.verifyidentity.statusfailedlink': 'contact support',
  'account.overview': 'Overview',
  'account.sidenav.mybets': 'My Bets',
  'account.sidenav.deposit': 'Deposit',
  'account.sidenav.withdraw': 'Withdrawal',
  'account.sidenav.limit': 'Deposit limit',
  'account.sidenav.transactions': 'Transactions',
  'account.sidenav.account': 'Manage Account',
  'account.sidenav.settings': 'Settings',
  'account.overview.myAccount': 'My Account',
  'account.overview.pagetitle': '| Account Overview',
  'account.overview.verificationstatus': 'Verification Status',
  'account.overview.getverified': 'Get Verified',
  'account.overview.accountbalance': 'Account Balance: ',
  'account.overview.accountnumber': 'Account Number: ',
  'account.overview.deposit': 'Deposit',
  'account.overview.withdrawablebalance': 'Withdrawable Balance: ',
  'account.overview.withdrawable': 'Withdrawable',
  'account.overview.withdraw': 'Withdraw',
  'account.overview.bonusbalance': 'Bonus Balance: ',
  'account.overview.balance': 'Balance',
  'account.overview.bonusBets': 'Bonus Bets',
  'account.overview.mustverify':
    'You must verify your account in order to make a withdrawal',
  'account.overview.bonus': 'Bonus',
  'account.overview.account': 'Account',
  'account.overview.verification.verified': 'Verified',
  'account.overview.verification.pending': 'Pending',
  'account.overview.verification.unverified': 'Get Verified',
  'account.overview.verification.mustBeVerified':
    'Your account <u>must</u> be verified to make withdrawals.',
  'account.overview.details': 'Details',
  'account.overview.logout': 'Log Out',
  'account.overview.marketingPreferences': 'Marketing Preferences',
  'account.overview.promotionalPreferences': 'Promotional Preferences',
  'account.overview.pendingBets': 'Pending Bets',
  'account.overview.firstName': 'First name',
  'account.overview.middleName': 'Middle name',
  'account.overview.lastName': 'Last name',
  'account.overview.title': 'Title',
  'account.overview.mobile': 'Mobile',
  'account.overview.email': 'Email',
  'account.overview.residentialAddress': 'Residential Address',
  'account.overview.dateOfBirth': 'Date of Birth',
  'account.overview.toChange':
    'To change your details, contact <bu>{themeName} support</bu>',
  'account.mybets.pagetitle': '| My Bets',
  'account.mybets.pendingview': 'Pending',
  'account.mybets.resultedview': 'Resulted',
  'account.mybets.nobetstext': 'You have no {filterName} bets',
  'account.mybets.pending': 'pending',
  'account.mybets.resulted': 'resulted',
  'account.mybets.loading': 'Loading...',
  'account.mybets.showmore': 'Show more',
  'account.mybets.nobets': 'You have no more bets to display',

  // Cashout Status
  'account.mybets.cashout.success': 'You have successfully Cashed Out.',
  'account.mybets.cashout.error': 'Something went wrong.',
  'account.mybets.cashout.oddsChangeAutoAccept':
    'Your Cash Out offer has increased and you have successfully Cashed Out.',
  'account.mybets.cashout.oddsChangeRequireAccept':
    'Your Cash Out amount has decreased, please review before accepting.',
  'account.mybets.cashout.CustomerNotEligible':
    'We are unable to process your request',
  'account.mybets.cashout.BetNotEligible':
    'We are unable to process your request',
  'account.mybets.cashout.BetDoesntExist':
    'We are unable to process your request',
  'account.mybets.cashout.BetAlreadySettled':
    'We are unable to process your request',
  'account.mybets.cashout.CashoutDisabled':
    'We are unable to process your request',
  'account.mybets.cashout.EstReturn': 'EST. RETURN',
  'account.mybets.cashout.CashOut': 'Cash Out',
  'account.mybets.cashout.CashedOut': 'CASHED OUT',
  'account.mybets.cashout.CashOutQuestion':
    ' Are you sure you want to Cash Out for ',
  'account.mybets.cashout.CashoutSuspended':
    'Your Cash Out could not be processed as the match has been Suspended',
  'account.mybets.cashout.Done': 'DONE',
  'account.mybets.cashout.Accept': 'Accept',
  'account.mybets.cashout.Decline': 'Decline',
  'account.mybetscard.betidtext': 'Bet ID:',
  'account.mybetscard.potentialreturns': 'Est. Return',
  'account.mybetscard.combinedEstReturn': 'Combined Est. Return',
  'account.mybetscard.raceabbv': 'R',
  'account.mybetscard.noreturn': 'No Return',
  'account.mybetscard.bonus': 'Bonus {winnings}',
  'account.mybetscard.won': 'Won <PrePromoPayout> </PrePromoPayout> {winnings}',
  'account.mybetscard.lost': 'Lost',
  'account.mybetscard.void': 'Void',
  'account.mybetscard.cancelled': 'Cancelled',
  'account.mybetscard.stake': 'Stake',
  'account.mybetscard.betplaced': 'Bet Placed On:',
  'account.mybetscard.more': 'More',
  'account.mybetscard.less': 'Less',
  'account.mybetscard.legmulti': 'Leg Multi',
  'account.mybetscard.legs': 'Legs',
  'account.mybetscard.bonusapplied': 'Bonus Applied',
  'account.mybetscard.badges.bonusbet': 'Bonus Bet',
  'account.mybetscard.badges.deductions': 'Deductions applied',
  'account.mybetscard.badges.deductionsmayapply': 'Deductions may apply',
  'account.myBetsCard.atStartingPriceAbbrev': '@SP',
  'account.myBetsCard.boostedOdds': 'Boosted Odds',
  'account.myBetsCard.moneyBack': 'Money Back',
  'account.myBetsCard.atToteAbbrev': '@MD',
  'account.myBetsCard.atBestToteAbbrev': '@BT',
  'account.myBetsCard.msyteryBetHeader': 'Mystery Bet',
  'account.myBetsCard.msyteryBetHeaderWithRollover':
    'Mystery Bet With Rollover',
  'account.signedout.title': 'The user is signed out',
  'account.signedout.subtitle': ' ',
  'account.closeaccount.title': 'Account Closure',
  'account.closeaccount.clickhere': 'click here.',
  'account.closeaccount.text1': 'You may close your account from this screen.',
  'account.closeaccount.text2':
    'If you only wish to change your marketing preferences (including unsubscribing) please ',
  'account.closeaccount.text3':
    'If you wish to close your account because you no longer gamble safely, please ',
  'account.closeaccount.confirmopenaccounts':
    'You agree not to open any further accounts while your account is closed. You may re-open your account at any time by contacting our Customer Service team at ',
  'account.closeaccount.confirmpendingbets':
    'Any pending bets will stand and any pending withdrawals will be actioned.',
  'account.closeaccount.confirmpendingbalance':
    'Any balance that remains in your account can be withdrawn by contacting our Customer Service team at ',
  'account.closeaccount.confirmpassword':
    'Confirm account closure with password',
  'account.closeaccount.closebutton': 'Close account',
  'account.closeaccount.errormessage': 'Please try again',
  'account.closeaccount.validationerrormessage':
    'Please complete the field to continue',
  'account.closeaccount.passworderrormessage':
    'Please check your password and try again.',
  'account.closeaccount.currentpassword': 'Current Password',
  'account.makeaclaim.contactus': 'How to contact us:',
  'account.makeaclaim.contactusdesc': 'Email us at: ',
  'account.makeaclaim.resolveclaims': 'How we resolve claims?',
  'account.makeaclaim.resolveclaimsdesc':
    'By email: We will respond to your complaint within 48 hours and provide you with an indication of how long it will take to resolve.',
  'account.makeaclaim.howweresolve': 'How we resolve claims?',
  'account.lifetimeexclusion.pagetitle': 'Lifetime Exclusion',
  'account.lifetimeexclusion.information':
    'If you are considering limiting your betting, you have the option to take a ',
  'account.lifetimeexclusion.informationshortbreak': 'break from 1 day ',
  'account.lifetimeexclusion.informationtext2': 'up to ',
  'account.lifetimeexclusion.informationlongbreak': '6 months.',
  'account.lifetimeexclusion.informationtext3':
    'If you have decided to not bet for a longer term, we provide the option for a lifetime exclusion. A lifetime exlusion is permanent and cannot be undone. All of your {themeName} accounts will be closed permanently. You will also not be able to set up a new account in the future. Your exclusion will start immediately upon confirmation.',
  'account.lifetimeexclusion.informationtext4':
    'By continuing, you will no longer be able to bet with {themeName} or have an active account with {themeName}.',
  'account.lifetimeexclusion.importantdetailstitle': 'Important Details',
  'account.lifetimeexclusion.importantdetailsbalancetitle':
    '1. {themeName} balance',
  'account.lifetimeexclusion.importantdetailsbalancedesc':
    'Any funds you have in your {themeName} account will be automatically transferred to your last active withdrawal method. If you have never withdrawn before or wish to withdraw these funds to an alternative account, please contact Customer Service on 1800 888 888.',
  'account.lifetimeexclusion.importantdetailsalertstitle':
    '2. Emails and alerts',
  'account.lifetimeexclusion.importantdetailsalertsdesc':
    'You will no longer receive emails and other alerts from {themeName}. These will cease within 24 hours of commencement of your break confirmation.',
  'account.lifetimeexclusion.confirmlifetimeexclusion':
    'Confirm Lifetime Exclusion',
  'account.betStop.title': 'BetStop - National Self Exclusion Register',
  'account.betStop.description':
    'If you feel you need to take a break or permanently self exclude from all bookmakers licensed in Australia, you can do this through the BetStop service. See the <cta>BetStop Website</cta> for more information.',
  'account.lifetimeexclusion.confirm':
    'By pressing on “Continue”, I understand that:',
  'account.lifetimeexclusion.confirmstepfunds':
    'All funds in my {themeName} account will be transferred to my last payment method',
  'account.lifetimeexclusion.confirmstepimmediately':
    'The exclusion will take effect immediately',
  'account.lifetimeexclusion.confirmstepundone':
    'The exclusion is permanent and all of my accounts with {themeName} will be closed for life',
  'account.lifetimeexclusion.errortoast': 'Please try again',
  'account.lifetimeexclusion.validationerrormessage':
    'Please complete the field to continue',
  'account.takeabreak.pagetitle': 'Take A Break',
  'account.takeabreak.information':
    'You can choose to take a break from 1 day to 6 months and give yourself some time away from betting. Your break will start immediately once confirmed.',
  'account.takeabreak.importantdetailstitle': 'Important Details',
  'account.takeabreak.importantdetailsemailtitle': '1. Emails and alerts',
  'account.takeabreak.importantdetailsemaildesc':
    'You will no longer receive emails and other alerts from {themeName}. These will cease within 24 hours of commencement of your break confirmation.',
  'account.takeabreak.importantdetailslogintitle': '2. Login',
  'account.takeabreak.importantdetailslogindesc':
    'You will be able to log back in to your account as soon as your break has finished.',
  'account.takeabreak.continuebutton': 'Continue',
  'account.takeabreak.confirmbutton': 'Confirm break',
  'account.takeabreak.breakstartdate': 'Break start date:',
  'account.takeabreak.limitperiod': 'Limit period',
  'account.takeabreak.confirm':
    'By pressing on “Confirm Break”, I understand that:',
  'account.takeabreak.confirmsteppendingbets':
    'All funds in my {themeName} account will be transferred to my last payment method',
  'account.takeabreak.confirmstepimmediately':
    'My break will take effect immediately',
  'account.takeabreak.confirmstepundone': 'The break cannot be undone',
  'account.takeabreak.today': 'Today',
  'account.takeabreak.dayperiod': 'In X day',
  'account.takeabreak.dayperiodplural': 'In X days',
  'account.takeabreak.errortoast': 'Please try again',
  'account.takeabreak.validationerrormessage':
    'Please complete the field to continue',
  'account.takeabreak.period24': '24 hrs',
  'account.takeabreak.period48': '48 hrs',
  'account.takeabreak.period72': '72 hrs',
  'account.takeabreak.periodweek': '1 week',
  'account.takeabreak.periodfortnight': '2 weeks',
  'account.takeabreak.period1month': '1 month',
  'account.takeabreak.period3months': '3 months',
  'account.takeabreak.period6months': '6 months',
  'account.takeabreak.periodformat': '(in X)',
  'account.settings.pagetitle': 'Settings',
  'account.settings.accountverification': 'Account Verification',
  'account.settings.promotionsnotifications': 'Marketing preferences',
  'account.settings.personaldetails': 'Personal Details',
  'account.settings.updateemail': 'Update Email',
  'account.settings.responsible': 'Responsible Gambling',
  'account.settings.terms': 'Terms & Conditions',
  'account.settings.privacy': 'Privacy Policy',
  'account.settings.makeaclaim': 'Make a Claim',
  'account.promotionsnotifications.pagetitle': 'Marketing preferences',
  'account.promotionsnotifications.notificationsenabled':
    'Yes, I would like to receive promotional offers',
  'account.promotionsnotifications.via': 'via',
  'account.promotionsnotifications.email': 'Email',
  'account.promotionsnotifications.sms': 'SMS',
  'account.promotionsnotifications.phone': 'Phone',
  'account.promotionsnotifications.post': 'Post',
  'account.promotionsnotifications.submitsuccess':
    'Marketing Preferences successfully updated!',
  'account.promotionsnotifications.submiterror':
    'Error updating marketing preferences...',
  'account.promotionpreferences.coinjar': 'Enable BetSlip Promotions',
  'account.promotionpreferences.success':
    'Promotional preferences were updated',
  'account.promotionpreferences.error':
    'Failed to update promotional preferences',
  'account.transactions.transactions.pagetitle': 'Transactions',
  'account.transactions.bonusbets.pagetitle': 'Bonus Bets',
  'account.transactions.activitystatements.pagetitle': 'Activity Statements',
  'account.transactions.activitystatements.description':
    'An Activity Statement for your {themeName} account will also be emailed to you every month when a transaction has occured.',
  'account.transactions.activitystatements.title':
    'Monthly activity statements',
  'account.transactions.activitystatements.heading':
    'Show all your bets, balance and result totals quickly.',
  'account.transactions.downloadaction': 'Download Spreadsheet',
  'account.transactions.notransactions': 'You have no transactions',
  'account.transactions.noresults': 'You have no {pageName}',
  'account.transactions.download': 'Download',
  'account.transactions.noactivity': 'No Activity',
  'account.transactions.downloaderrormessage':
    "Something went wrong, couldn't download transactions...",
  'account.transactions.tableheadings.0': 'Status',
  'account.transactions.tableheadings.1': 'Date',
  'account.transactions.tableheadings.2': 'Type',
  'account.transactions.tableheadings.3': 'Bet Details',
  'account.transactions.tableheadings.4': 'Odds',
  'account.transactions.tableheadings.5': 'Stake',
  'account.transactions.tableheadings.6': 'Amount',
  'account.transactions.tableheadings.7': 'Balance',
  'account.transactions.bonus': 'Bonus',
  'account.transactions.legs': 'Legs',
  'account.transactions.refund': 'Refund',
  'account.transactions.exotics.boxed': 'Boxed',
  'account.transactions.type.refund': 'Refund',
  'account.transactions.type.exotics.boxed': 'Boxed',
  'account.transactions.type.deposit': 'Deposit',
  'account.transactions.type.bet': 'Bet Stake',
  'account.transactions.type.winnings': 'Winnings',
  'account.transactions.type.bonus': 'Bonus',
  'account.transactions.type.withdrawal': 'Withdrawal',
  'account.transactions.type.void': 'Void',
  'account.transactions.type.adjustment': 'Manual Adjustment',
  'account.transactions.type.credit': 'Credit',
  'account.transactions.type.cashout': 'Cash Out',
  'account.transactions.betId': 'Bet ID',
  'account.transactions.eventDateTime': 'Event Date/Time',
  'account.transactions.id': 'ID',
  'account.transactions.leg': 'Leg Multi',
  'account.transactions.propDetailsForSP': '{proposition} @ SP',
  'account.transactions.betIdWithValue': 'Bet ID: {value}',
  'account.transactions.eventDateTimeWithValue': 'Event Date/Time: {value}',
  'account.transactions.sgmTransactionTitle':
    '{hasOdds, select, true {{legCount} Leg Same Game Multi @{odds}} other {{legCount} Leg Same Game Multi}}',
  'account.transactions.srmTransactionTitle':
    '{hasOdds, select, true {{legCount} Leg Same Race Multi @{odds}} other {{legCount} Leg Same Race Multi}}',
  'account.transactions.blended': 'Blended @{odds}',
  'account.bonusbets.pagetitle': 'Bonus Bets',
  'account.bonusbets.downloadaction': 'Download Bonus Bets Transaction History',
  'account.bonusbets.nobonusbets': 'You have no Bonus Bets activity',
  'account.bonusbets.downloaderrormessage':
    "Something went wrong, couldn't download bonus bets transactions...",
  'account.promos.pageTitle': 'Promos',
  'account.terms.pagetitle': 'Terms & Conditions',
  'account.terms.otherlinks.privacy': 'Privacy Policy',
  'account.terms.otherlinks.responsiblegambling': 'Responsible Gambling',
  'account.terms.otherlinks.depositlimits': 'Deposit Limits',
  'account.privacy.pageTitle': 'Privacy Policy',
  'account.privacy.otherlinks.terms': 'Terms & Conditions',
  'account.privacy.otherlinks.responsiblegambling': 'Responsible Gambling',
  'account.privacy.otherlinks.depositlimits': 'Deposit Limits',
  'account.depositlimit.depositlimit': 'Deposit Limit',
  'account.depositlimit.setlimit.heading': 'Set a Deposit Limit',
  'account.depositlimit.setlimit.subheading':
    'A Deposit Limit allows you to limit the amount of money that you are able to deposit into your account within a chosen time frame.  Setting a Deposit Limit helps you gamble responsibly and stay in control of the amount you spend.',
  'account.depositlimit.setlimit.subheading2':
    'If you wish to change your existing Deposit Limit, you can do so at any time.  Increasing your limit does require a 7 day cooling-off period before it will take effect.  Lowering your limit, however will come into effect immediately.',
  'account.depositlimit.setlimit.inputplaceholder':
    'Enter a new deposit limit amount',
  'account.depositlimit.setlimit.depositlimitlabel': 'Limit amount',
  'account.depositlimit.setlimit.limitperiodlabel': 'Limit period',
  'account.depositlimit.setlimit.formsubtext': 'You can deposit ',
  'account.depositlimit.setlimit.formsubtextbold': 'up to % every & days',
  'account.depositlimit.setlimit.setlimitbuttontext': 'Set Deposit Limit',
  'account.depositlimit.setlimit.limitupdated':
    'Your deposit limit has been successfully updated.',
  'account.depositlimit.setlimit.error':
    'There was an error updating your deposit limit. Please try again.',
  'account.currentlimit.heading': 'Deposit Limit',
  'account.currentlimit.depositlimitlabel': 'Current limit',
  'account.currentlimit.pendingLimitLabel': 'Pending limit',
  'account.currentlimit.newLimitInfo':
    'Your new Deposit Limit will come into effect on the {date} at {time}.',
  'account.currentlimit.limitRemovalInfo':
    'The removal of your Deposit Limit will come into effect on the {date} at {time}.',
  'account.currentlimit.limitperiodlabel': 'Restriction period',
  'account.currentlimit.limitperioduntil':
    'The limit period cannot be changed until ',
  'account.currentlimit.coolingoffends': 'Cooling off term ends',
  'account.currentlimit.days': '% days',
  'account.currentlimit.dollars': '$',
  'account.currentlimit.updatelimitheading': 'Update Deposit Limit',
  'account.currentlimit.candecreasesubheading':
    'You can decrease your deposit limit within the 7 day restriction period.',
  'account.currentlimit.newlimitlabel': 'Limit amount',
  'account.currentlimit.savelimitbuttontext': 'Update limit',
  'account.currentlimit.removelimitbuttontext': 'Remove limit',
  'account.currentlimit.coolingoffstatement':
    'Your limit will increase to % on the &',
  'account.updateconfirmmodal.title': 'Update Deposit Limit',
  'account.removeconfirmmodal.title': 'Remove Deposit Limit',
  'account.updateConfirmModal.subheadingIncreasing':
    'You will be able to deposit up to {amount} every {frequency} day(s). {br} <note><noteTitle>Note:</noteTitle> Your update will come into effect after the 7 day cooling-off period.</note>',
  'account.updateConfirmModal.subheadingDecreasing':
    'You will be able to deposit up to {amount} every {frequency} day(s). {br}This will come into effect immediately.',
  'account.updateConfirmModal.subheadingRemoval':
    'Are you sure you want to remove your Deposit Limit? {br} <note><noteTitle>Note:</noteTitle> Your update will come into effect after the 7 day cooling-off period.</note>',
  'account.updateconfirmmodal.cancel': 'Cancel',
  'account.updateconfirmmodal.confirm': 'Confirm',
  'account.limitperiodoptions.0': '1 Day',
  'account.limitperiodoptions.1': '7 Days',
  'account.limitperiodoptions.2': '14 Days',
  'account.limitperiodoptions.3': '28 Days',
  'account.increaseupdateconfirmed':
    'Deposit Limit updated. This change will come into effect in 7 days.',
  'account.decreaseupdateconfirmed':
    'Deposit Limit updated. This change will come into effect immediately.',
  'account.errorstrings.requiredamount': 'Deposit limit amount is required',
  'account.errorstrings.cannotincrease':
    'Deposit limit cannot be increased until %',
  'account.errorstrings.requiredperiod': 'Deposit limit period is required',
  'account.errorstrings.numbersonly': 'Deposit limit can only contain numbers',
  'account.errorstrings.samevalue':
    'Amount entered is the same as your current deposit limit',
  'account.withdraw.pagetitle': 'Withdraw',
  'account.withdraw.withdrawfundstitle': 'Withdraw Funds',
  'account.withdraw.addbank': 'Add new',
  'account.withdraw.bankcard.acc': 'ACC',
  'account.withdraw.bankcard.bsb': 'BSB',
  'account.withdraw.bankcard.hiddenbsb': '*** ***',
  'account.withdraw.bankcard.hiddenaccnumber': '*****',
  'account.withdraw.withdrawalamountlabel': 'Amount',
  'account.withdraw.withdrawalbalancelabel': 'Available Balance: ',
  'account.withdraw.requestwithdrawalbutton': 'Withdraw',
  'account.withdraw.withdrawalsuccess': 'Withdrawal request successful',
  'account.withdraw.withdrawsubfooter1':
    'Withdrawals may take up to 1-3 days to reach your account',
  'account.withdraw.withdrawsubfooter2':
    'Only turned over funds can be withdrawn.',
  'account.withdraw.withdrawalcancelled': 'Request successfully cancelled',
  'account.withdraw.errorstrings.amountrequired': 'Withdraw amount is required',
  'account.withdraw.errorstrings.bankrequired': 'Bank account is required',
  'account.withdraw.errorstrings.minimumAmount':
    'Withdrawal amount must be more than $10',
  'account.withdraw.errorstrings.amountexceeds':
    'Amount exceeds withdrawable of ',
  'account.withdraw.errorstrings.cannotwithdrawerror':
    'You cannot withdraw funds until they’ve been turned over',
  'account.withdraw.errorstrings.integer':
    'Withdraw amount can only contain numbers',
  'account.withdraw.addaccount.heading': 'Add Bank Account',
  'account.withdraw.addaccount.holdernamelabel': 'Account Holder Name',
  'account.withdraw.addaccount.makeawithdrawal': 'Make a withdrawal',
  'account.withdraw.addaccount.withdrawaldescription':
    'Requests may take up to 1-3 days to reach your account. Only turned over funds can be withdrawn.',
  'account.withdraw.addaccount.holdernamesubfooter':
    "Bank account must be in registered account holder's name",
  'account.withdraw.addaccount.banknamelabel': 'Bank Name',
  'account.withdraw.addaccount.bsblabel': 'BSB Number',
  'account.withdraw.addaccount.accnumberlabel': 'Account Number',
  'account.withdraw.addaccount.nicknamelabel': 'Nickname (Optional)',
  'account.withdraw.addaccount.nicknamesubfooter':
    'Add a nickname to help distinguish this bank account from others',
  'account.withdraw.addaccount.addaccountbutton': 'Add New Account',
  'account.withdraw.addaccount.addaccountsubfooter':
    'Please note: Funds can only be withdrawn to Australian bank accounts. If you require assistance, ',
  'account.withdraw.addaccount.contactus': 'contact us.',
  'account.withdraw.addaccount.errorstrings.namerequired':
    'Account Holders name is required',
  'account.withdraw.addaccount.errorstrings.banknamerequired':
    'Bank name is required',
  'account.withdraw.addaccount.errorstrings.bsbrequired': 'BSB is required',
  'account.withdraw.addaccount.errorstrings.bsbformat':
    'Must be a valid Australian BSB Number',
  'account.withdraw.addaccount.errorstrings.accountnumberrequired':
    'Account Number is required',
  'account.withdraw.addaccount.errorstrings.accountnumberformat':
    'Must be a valid Australian Account Number',
  'account.withdraw.addaccount.errorstrings.genericerror':
    'There was an error adding this bank account',
  'account.withdraw.addaccount.errorstrings.accountnumbernospaces':
    'Account Number cannot contain spaces',
  'account.withdraw.addaccount.errorstrings.bsbnumbernospaces':
    'BSB Number cannot contain spaces',
  'account.withdraw.requests.heading': 'Pending Requests',
  'account.withdraw.requests.cancelbutton': 'Cancel',
  'account.withdraw.requests.status.withdraw': 'Withdrawal',
  'account.withdraw.requests.status.dash': ' - ',

  'account.withdraw.requests.status.pending': 'Pending',
  'account.withdraw.requests.status.cancelled': 'Cancelled',
  'account.withdraw.requests.status.approved': 'Approved',
  'account.withdraw.requests.status.processed': 'Processed',
  'account.withdraw.requests.status.error': 'Error',
  'account.withdraw.requests.status.unsuccessful': 'Unsuccessful',

  'account.withdraw.requests.requestunsuccessful':
    'Request unsuccessful please contact customer support.',
  'account.withdraw.requests.remove': 'Remove',
  'account.withdraw.request.heading': 'Withdrawal Request',
  'account.withdraw.increaseamountbutton.ten': '+$10',
  'account.withdraw.increaseamountbutton.twentyfive': '+$25',
  'account.withdraw.increaseamountbutton.fifty': '+$50',
  'account.withdraw.increaseamountbutton.hundred': '+$100',
  'account.withdraw.increaseamountbutton.twohundred': '+$200',
  'account.withdraw.increaseamountbutton.fivehundred': '+$500',
  'account.deposit.titles.main': 'Make a deposit',
  'account.deposit.labels.selectdeposit': 'Select a Deposit Method',
  'account.deposit.labels.selectcard': 'Select a Card',
  'account.deposit.labels.or': 'OR',
  'account.deposit.labels.amount': 'Amount',
  'account.deposit.labels.securedwithssl':
    'Secured with SSL encryption technology.',
  'account.deposit.labels.minimumdepositamount':
    'The minimum deposit amount is $10',
  'account.deposit.card.expired': 'Expired',
  'account.deposit.card.savecard': 'Save card details',
  'account.deposit.buttons.deposit': 'Deposit now',
  'account.deposit.buttons.addnewcard': 'Add New Card',
  'account.deposit.buttons.addcardanddeposit': 'Add Card and deposit',
  'account.deposit.buttons.maxcards': 'You may only have up to * cards',
  'account.deposit.validation.minamount': 'Min amount is $10',
  'account.deposit.validation.maxamount':
    'Your deposit limit has been reached.',
  'account.deposit.confirmation.done': 'Done',
  'account.deposit.confirmation.pleasetryagain': 'Please, Try Again',
  'account.deposit.confirmation.success': 'Success!',
  'account.deposit.confirmation.complete': 'Deposit Complete',
  'account.deposit.confirmation.completemessage':
    'Deposit of % was successfully added to your account.',
  'account.deposit.confirmation.failed': 'Deposit Failed',
  'account.deposit.confirmation.cardNotSupported':
    'Transaction failed: this card is not supported. Please try a different card',
  'account.deposit.confirmation.cardexist': 'The card already exists!',
  'account.deposit.confirmation.failedmessage':
    'Your deposit of % was not successful.',
  'account.deposit.toasts.paymentsuccessful':
    'Successfully deposited your funds',
  'account.deposit.toasts.paymentunsuccessful':
    'Payment was unsuccessful, you have not been charged',
  'account.deposit.toasts.cardremovesuccessful': 'Card successfully deleted',
  'account.deposit.toasts.unabletoprocesscard':
    'We were unable to process your card, please try again',
  'account.deposit.toasts.unabletodepositfunds':
    'We were unable deposit your funds',
  'account.deposit.toasts.somethingWentWrong':
    'Something went wrong, please try again',
  'account.deposit.toasts.unabletogetcards': 'Unable to get your saved cards',
  'account.deposit.toasts.unabletoremovecard':
    'Unable to remove your saved card',
  'account.deposit.toasts.unabletoaddcard': 'Unable to add a new card',
  'account.deposit.toasts.fzerrors.12':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.13':
    'Invalid amount, contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.14':
    'Invalid card number, contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.15':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.19': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.22': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.23':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.25':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.30':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.31':
    'Your bank is not supported, contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.33':
    'Card has expired, please use a different card',
  'account.deposit.toasts.fzerrors.34':
    'Card has expired, please use a different card',
  'account.deposit.toasts.fzerrors.35':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.36':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.37':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.38': 'Please use another card',
  'account.deposit.toasts.fzerrors.39':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.41':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.42':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.43':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.44':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.51': 'Insufficient funds',
  'account.deposit.toasts.fzerrors.52':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.53':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.54':
    'Expired card, please use a different card',
  'account.deposit.toasts.fzerrors.55': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.56':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.57':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.59':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.60':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.61': 'Please try another card',
  'account.deposit.toasts.fzerrors.62':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.75': 'Please use another card',
  'account.deposit.toasts.fzerrors.82': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.90': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.91': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.92': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.93':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.94':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.96': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.99': 'Payment failed, please try again',
  'account.deposit.toasts.fzerrors.002':
    'Unable to verify 3DS enrolment status with you issuer.',
  'account.deposit.toasts.fzerrors.003':
    '3DS authentication is unavailable with your issuer.',
  'account.deposit.toasts.fzerrors.004':
    'Your issuer deems this transaction as risky.',
  'account.deposit.toasts.fzerrors.005':
    'Your frictionless authentication has been rejected.',
  'account.deposit.toasts.fzerrors.006':
    'Unsuccessful authentication due to failed OTP (one time password) challenge.',
  'account.deposit.toasts.fzerrors.007':
    'You are enrolled for 3DS, but authentication is not available with your issuer.',
  'account.deposit.toasts.fzerrors.01':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.02':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.03': 'Check your merchant details provided',
  'account.deposit.toasts.fzerrors.04':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.05':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.06':
    'Contact your card issuer for more information',
  'account.deposit.toasts.fzerrors.07':
    'Contact your card issuer for more information',
  'account.deposit.increaseamountbutton.ten': '+$10',
  'account.deposit.increaseamountbutton.fifty': '+$50',
  'account.deposit.increaseamountbutton.hundred': '+$100',
  'account.deposit.increaseamountbutton.fivehundred': '+$500',
  'account.deposit.increaseamountbutton.thousand': '+$1000',
  'account.deposit.depositMatchPromoBannerHeader':
    'You have a Deposit Match available!',
  'account.deposit.depositMatchPromoBannerText': `{willExpire, select,
    true {
      <p>We will match your next deposit of up to {maxAmount, number, ::currency/USD unit-width-narrow} with an additional {percentage, number, ::percent} in bonus bets. That’s up to {total, number, ::currency/USD unit-width-narrow} in bonus bets!</p> Offer expires <time>{expiry, date, medium} at {expiry, time, short}</time>.
    }
    other {
      <p>We will match your next deposit of up to {maxAmount, number, ::currency/USD unit-width-narrow} with an additional {percentage, number, ::percent} in bonus bets. That’s up to {total, number, ::currency/USD unit-width-narrow} in bonus bets!</p>
    }
  }`,
  'account.deposit.creditCardMessage':
    'Please note that we are unable to accept credit card deposits. Please ensure that you utilise your preferred debit card to continue to enjoy uninterrupted betting with us!',
  'account.deposit.depositMatch.activate': 'Activate now',
  'account.deposit.depositMatch.activated': 'Deposit match activated',
  'sports.errormessages.getcompetitionsfailmessage':
    "Couldn't load competitions for this sport",
  'sports.errormessages.getmarketsfailmessage':
    "Couldn't load markets for this match",
  'sports.errormessages.getmatchesfailmessage':
    "Couldn't load matches for this competition",
  'sports.allsports.azheading': 'All Sports A-Z',
  'sports.allsports': 'All Sports',
  'sports.allsports.topsports': 'Top Sports',
  'sports.allsports.sportslisttitle': 'Sports A-Z',
  'sports.upcoming.noPropositons': 'Propositions unavailable.',
  'sports.events.noPropositons': 'N/A',
  'sports.competitionsbysport.featuredcompetitionstitle': 'Featured',
  'sports.competitionsbysport.allcompetitionstitle': 'All',
  'sports.competitionsBySport.events': 'Events',
  'sports.competitionsBySport.schedule': 'Schedule',
  'sports.competitionsBySport.all': 'All {name}',
  'sports.competitionsBySport.allLeagues': 'All Leagues',
  'sports.marketsbymatch.pagetitlefallback': 'Markets',
  'sports.marketsbymatch.nomarkets':
    'There are no markets for this event. Please check the {sport} page for other active markets.',
  'sports.marketsbymatch.nomarketsbutton': 'Go to {sport}',
  'sports.marketsbymatch.okaybuttontext': 'Okay',
  'sports.marketsbymatch.matchclosedTitle': 'Match closed',
  'sports.marketsbymatch.matchclosed':
    'Match {match} has now closed, have a punt on a different game!',
  'sports.matchcard.versus': 'VS',
  'sports.matchDetailPage.addToBetSlip': 'Add to Bet Slip',
  'sports.matchDetailPage.clearAll': 'Clear All',
  'sports.matchDetailPage.clearSelections': 'Clear Selections',
  'sports.matchDetailPage.clearSelectionsPrompt':
    'Are you sure you want to clear all of your current selections?',
  'sports.matchDetailPage.hideSelections': 'Hide selections',
  'sports.matchDetailPage.legs': 'Legs <strong>{legs}</strong>',
  'sports.matchDetailPage.maxSelectionsError':
    'A Multi Bet must not exceed 20 legs.',
  'sports.matchDetailPage.odds': `{hasOdds, select, true {Odds <strong>{odds}</strong>} other {Odds <strong>N/A</strong>}}`,
  'sports.matchDetailPage.selectionError': 'This selection cannot be combined.',
  'sports.matchDetailPage.viewSelections': 'View selections',
  'sports.propositions.seemore': 'See more',
  'sports.propositions.seeless': 'See less',
  'sports.tabs.events': 'Events',
  'sports.tabs.schedule': 'Schedule',
  'sports.tabs.futures': 'Futures',
  'racing.errormessages.getracingvenuelistfailmessage': "Couldn't load venues",
  'racing.errormessages.getracemetafailmessage': "Couldn't load race meta data",
  'racing.errormessages.getracerunnerlistfailmessage':
    "Couldn't load race runner list",
  'racing.errormessages.getotherracesbyvenuefailmessage':
    "Couldn't load other races for this venue",
  'racing.errormessages.getraceresultsfailmessage':
    "Couldn't load race results",
  'racing.errormessages.getracemarketsfailmessage':
    "Couldn't load race markets",
  'racing.errorMessages.noRacesAvailable': 'No races are currently available.',
  'racing.errorMessages.noRacesAvailableSubtext':
    'Please consider selecting a different day to try again.',
  'racing.errormessages.racetypeavailable':
    'No <types>type</types> available at this moment',
  'racing.generic.addToBetSlip': 'Add to Bet Slip',
  'racing.generic.win': 'Win',
  'racing.generic.winBT': 'Win BT',
  'racing.generic.winMD': 'Win MD',
  'racing.generic.place': 'Place',
  'racing.generic.placeMD': 'Place MD',
  'racing.generic.odds': 'Odds',
  'racing.generic.insights': 'Insights',
  'racing.generic.tote': 'TOTE',
  'racing.generic.flucs': 'Flucs',
  'racing.generic.betPlaced': 'Bet Placed',
  'racing.generic.betAwaitingApproval': 'Bet Awating Approval',
  'racing.evenShot.win': 'Even Shot - Win',
  'racing.evenShot.top': 'Even Shot - Top',
  'racing.generic.clearSelections': 'Clear Selection',
  'racing.generic.numberRunner': 'No. / Runner',
  'racing.exotics.raceresults': 'Race Results',
  'racing.exotics.addtobetslip': 'Add to Bet Slip',
  'racing.exotics.flexi': 'Flexi',
  'racing.exotics.totalstake': 'Total Stake',
  'racing.exotics.bettype': 'Bet Type',
  'racing.exotics.dividend': 'Dividend',
  'racing.exotics.boxed': 'Boxed',
  'racing.exotics.clearselection': 'Clear Selection',
  'racing.exotics.combos': 'Combos',
  'racing.exotics.selectfield': 'Select Field',
  'racing.srm.viewSelections': 'View Selection',
  'racing.srm.hideSelections': 'Hide Selection',
  'srm.clearSelections': 'Clear',
  'racing.srm.win': 'Win',
  'racing.srm.top': 'Top {number}',
  'racing.evenShot.heading': 'Even Shot',
  'racing.evenShot.slogan':
    'Get straight to the heart of the action with one, even money chance.',
  'racing.evenShot.betPlaced.heading':
    'You have already placed a bet for this race.',
  'racing.evenShot.betPlaced.subHeading':
    'Return to this page once the race has ended to see if you have won.',
  'racing.racedetails.moneyBack': 'Money Back',
  'racing.racedetails.pagetitle': '| Race Details',
  'racing.racedetails.runnerheading': 'Runner',
  'racing.racedetails.fixedheading': 'Fixed',
  'racing.raceDetails.winHeading': 'Win',
  'racing.raceDetails.winHeadingFO': 'Win FO',
  'racing.raceDetails.placeHeading': 'Place',
  'racing.raceDetails.placeHeadingFO': 'Place FO',
  'racing.raceDetails.toteWinHeading': 'Win MD',
  'racing.raceDetails.bestToteWinHeading': 'Win BT',
  'racing.raceDetails.totePlaceHeading': 'Place MD',
  'racing.racedetails.racestatus': '{status}',
  'racing.racedetails.resultedstatus': 'Resulted',
  'racing.racedetails.racecardheading': 'Race Card',
  'racing.racedetails.markettabs.winplace': 'Win/Place',
  'racing.racedetails.markettabs.raceresults': 'Win/Place',
  'racing.racedetails.resultstabs.results': 'Results',
  'racing.racedetails.eventrules.ruleheading': 'Event Rules: ',
  'racing.racedetails.eventrules.noplace':
    'No place betting available on this race',
  'racing.raceDetails.weather': 'Weather: ',
  'racing.raceDetails.track': 'Track: ',
  'racing.raceDetails.distance': 'Distance: ',
  'racing.racedetails.eventrules.twoplacedividends': 'No Third Dividend',
  'racing.racedetails.eventrules.threeplacedividends':
    'Three place dividends paid',
  'racing.raceDetails.winSPHeading': 'Win SP',
  'racing.raceDetails.placeSPHeading': 'Place SP',
  'racing.raceDetails.evenShotTime':
    'Sorry, Even Shot is available {time} minutes from the race start time.',
  'racing.evenShot.UnavailableTimeTitleBackup':
    'Sorry, Even Shot is available closer to race start time.',
  'racing.evenShot.top1': 'Win',
  'racing.evenShot.top2': 'Top 2',
  'racing.evenShot.top3': 'Top 3',
  'racing.evenShot.top4': 'Top 4',
  'racing.raceDetails.evenShotNotAvailable':
    'Sorry, Even Shot is not currently available for this race.',
  'racing.raceDetails.comeBackLater': 'Please come back later.',
  'racing.raceDetails.selectAnotherRace': 'Please select another race.',
  'racing.raceDetails.winTabInfo':
    'Back a runner to Win or Place across Fixed Odds (FO) or TOTE markets. TOTE markets offer either Middle Dividend (MD) or Best TOTE (BT) odds.',
  'racing.raceresults.raceresults': 'Race Results',
  'racing.raceresults.win': 'Win',
  'racing.raceresults.place': 'Place',
  'racing.raceresults.positions.0': '1st',
  'racing.raceresults.positions.1': '2nd',
  'racing.raceresults.positions.2': '3rd',
  'racing.raceresults.positions.3': '4th',
  'racing.raceresults.exotics.firstfour': 'First Four',
  'racing.raceResults.eventRules.twoPlaceDividends': 'No Third Dividend',
  'racing.raceRunnerItem.deductions': 'Deductions applied',
  'racing.raceRunnerItem.runnerName':
    '{isHarnessRacing, select, true {{number}. {displayName}} other {{number}. {displayName} <span>({barrierNumber})</span>}}',
  'racing.raceRunnerItem.scratched': 'SCRATCHED',
  'racing.raceRunnerItem.scratchedInfo':
    '{hasScratchTime, select, true {Scratched <time>{scratchTime, time, ::dd/MM/yy, H:mm}</time>} other {Scratched}}',
  'racing.raceRunnerItem.suspended': 'SUS',
  'racing.racemeetings.pagetitle': '| Race Meetings',
  'racing.racemeetings.horses': 'Horses',
  'racing.racemeetings.greys': 'Greys',
  'racing.racemeetings.harness': 'Harness',
  'racing.racemeetings.noraces': 'No Races',
  'racing.racemeetings.abandoned': 'ABND',
  'racing.racemeetings.closed': 'CLSD',
  'racing.featured.race': 'Featured race',
  'racing.featured.moreRaces': 'More races',
  'racing.mysteryBet.rollover': 'Rollover: ',
  'racing.mysteryBet.yourSelection': 'Your Selection: ',
  'racing.mysteryBet.info':
    'Select from 2.00, 4.00 or 8.00 odds and reveal your mystery single proposition in the Betslip.',
  'racing.mysteryBet.wantRollover': 'Want to rollover?',
  'racing.mysteryBet.mysteryBetRolloverInfo':
    ' If your bet wins, your winning funds will rollover to place another Mystery Bet at the same odds on the next available race.',
  'racing.mysteryBet.error':
    'Sorry, Mystery Bet is not currently available at this price on this race',
  'racing.mysteryBet.viewInMyBets': 'VIEW IN MY BETS',
  'racing.tabbar.yesterday': 'Yesterday',
  'racing.tabbar.nexttojump': 'Next To Jump',
  'racing.tabbar.today': 'Today',
  'racing.tabbar.tomorrow': 'Tomorrow',
  'racing.tabbar.Monday': 'Monday',
  'racing.tabbar.Tuesday': 'Tuesday',
  'racing.tabbar.Wednesday': 'Wednesday',
  'racing.tabbar.Thursday': 'Thursday',
  'racing.tabbar.Friday': 'Friday',
  'racing.tabbar.Saturday': 'Saturday',
  'racing.tabbar.Sunday': 'Sunday',
  'contactus.title': 'Help & Support',
  'contactus.support': 'Support - ',
  'contactus.phonelocal': '(Local)',
  'contactus.chat': 'Live Chat',
  'contactus.phoneinternational': '(International)',
  'legal.responsible.heading': 'Responsible Gambling',
  'legal.responsible.subheading':
    'We are committed to protecting and helping our customers stay in control of their gambling. We offer various support options that suit different needs.',
  'legal.responsible.titles.findoutmore': 'Find out more',
  'legal.responsible.titles.getintouch': 'Get in touch',
  'legal.responsible.titles.takeaction': 'Take Action',
  'legal.responsible.section.findoutmore.one':
    'Chat with a professional from Gambler’s Help',
  'legal.responsible.section.findoutmore.two':
    'Unsure about your gambling habits?',
  'legal.responsible.section.findoutmore.three':
    ' Have a chat over the phone, face-to-face or online with the friendly staff at Gambler’s Help. It’s free, confidential, professional and available 24/7.',
  'legal.responsible.section.findOutMore.four.heading':
    'BetStop - National Self Exclusion Register',
  'legal.responsible.section.findOutMore.four.description':
    'If you feel you need to take a break or permanently self exclude from all bookmakers licensed in Australia, you can do this through the BetStop service. See the <cta>BetStop Website</cta> for more information. ',
  'legal.responsible.section.takeaction.depositlimit.one': ' ',
  'legal.responsible.section.takeaction.depositlimit.two':
    'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.depositlimit.button':
    'Set a deposit limit',
  'legal.responsible.section.takeaction.longterm.one': ' ',
  'legal.responsible.section.takeaction.longterm.two':
    'Stop betting by suspending your account for over 6 months or any other period up to a maximum of 5 years.',
  'legal.responsible.section.takeaction.longterm.button': 'Long Term Exclusion',
  'legal.responsible.section.takeaction.deactivate.one': ' ',
  'legal.responsible.section.takeaction.deactivate.two':
    'You can choose to close your account by deactivating it here, or by calling {phonenumber}.',
  'legal.responsible.section.takeaction.deactivate.button': 'Close Account',
  'legal.responsible.section.takeaction.lifeexclusion.one': ' ',
  'legal.responsible.section.takeaction.lifeexclusion.two':
    'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.lifeexclusion.button':
    'Lifetime Exclusion',
  'legal.responsible.section.takeaction.break.one': ' ',
  'legal.responsible.section.takeaction.break.two':
    'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.section.takeaction.break.button': 'Take a Break',
  'legal.responsible.tagline1': "Chances are you're about to lose.",
  'legal.responsible.tagline2':
    'Think. Is this a bet you really want to place?',
  'legal.responsible.tagline3': "What's gambling really costing you?",
  'legal.responsible.tagline4':
    'What are you prepared to lose today? Set a deposit limit.',
  'legal.responsible.tagline5': 'Imagine what you could be buying instead.',
  'legal.responsible.tagline6': 'What are you really gambling with?',
  'legal.responsible.caption':
    'For free and confidential support call 1800 858 858 or visit <cta>gamblinghelponline.org.au</cta>',
  'footer.subheading': 'Must be 18+',
  'footer.enquiries': 'For any enquiries email us at <link>{email}</link>',
  'footer.support': 'Support - {themeName}',
  'footer.bodymain2phonenumber': '1800 262 376',
  'footer.footerparagraph':
    "{themeName} gambling operations are governed by our <rglink>Responsible Gambling Code of Conduct</rglink>. Think! About your choices. Call Gambler's Help or Gambling Help on <telLink>1800 858 858</telLink> or visit <link>gamblershelp.com.au</link> or <link>gamblinghelponline.org.au</link>. Call Gambler's Help Youthline on <telLink>1800 262 376</telLink>. Gamble Responsibly.",
  'footer.footerbottomtext':
    'In relation to South Australian residents, our gambling operations are also governed by the South Australian Responsible Gambling <link>Code of Practice</link>. Think of the people who need your support. Gamble Responsibly.',
  'footer.footerbottomtextSaferTools':
    'If you would like to make use of our Safer Gambling Tools including setting deposit limits, applying for take a breaks, permanently self excluding your account, or to close your account - please <link>login and manage your account</link>, email <email>help@{bookie}.com.au</email> or call {phonenumber}.',
  'footer.footerbottomtextnt':
    'We are licensed and regulated by the <link>Northern Territory Racing Commission</link>.  For South Australian residents, we are governed by the Authorised Betting Operations Gambling <code>Code Of Practice.</code>',
  'footer.newfootertext1':
    '{getThemeName} are committed to the support of responsible gambling. All bets are accepted by {getThemeName} who are licensed in Victoria by the Victorian Commission for Gambling and Liquor.',
  'footer.newfootertext3':
    'Think of the people who need your support. Gamble Responsibly.',
  'footer.newfootertext4':
    'Please gamble responsibly and if you need help call Gambling Help services on',
  'footer.betStop':
    'BetStop - the National Self-Exclusion Register is a free service for people who want to exclude from all Australian online wagering providers. For more info visit <cta>www.betstop.gov.au</cta>',
  'footer.ourpartners': 'Our partners',
  'footer.weaccept': 'We accept',
  'footer.quicklinks': 'Quick links',
  'footer.bodysecondary': 'Licensed and regulated by Racing Victoria.',
  'footer.copyright': 'Copyright © {themeName} Pty Ltd | v{appVersion}',
  'footer.copyrightNoPty': 'Copyright © {themeName} | v{appVersion}',
  'footer.copyrightNoTheme': 'Copyright © {abn} | v{appVersion}',
  'footer.appversion': 'v{appVersion}',
  'sidenav.home': 'Home',
  'sidenav.allsports': 'All Sports',
  'sidenav.myaccount': 'My Account',
  'sidenav.contactus': 'Contact Us',
  'sidenav.racing': 'Racing',
  'sidenav.sportaz': 'Sports',
  'sideNav.quickLinks': 'QUICK LINKS',
  'sidenav.nexttojump': 'Next To Jump',
  'sidenav.viewall': 'View All',
  'sidenav.sports': 'Sports',
  'sidenav.deposit': 'Deposit Funds',
  'sideNav.promotions': 'Promos',
  'sidenav.withdraw': 'Withdraw Funds',
  'sidenav.depositlimits': 'Deposit Limits',
  'sidenav.responsible': 'Responsible Gambling',
  'sidenav.logout': 'Logout',
  'sidenav.login': 'Login',
  'sidenav.join': 'Join',
  'sidenav.topEvents': 'Top Events',
  'sidenav.support': 'Support - ',
  'bonus.youWon': 'You won',
  'bonus.claimBonus': 'Claim bonus',
  'bonus.browserSupport': 'Your browser does not support the video tag.',
  'error.heading': 'Theres been an error!',
  'error.description': 'Please refresh and try again.',
  'maintenance.heading': `We're down for maintenance`,
  'maintenance.error': `Please refresh and try again.`,
  'errorboundary.title': 'Theres been an error!',
  'errorboundary.desc':
    'Theres been an unknown error, please refresh and try again.',
  'header.betslipBtn.desc': 'Bet Slip',
  'qam.quickDeposit.processing': 'Requesting payment...',
  'qam.quickDeposit.processing.footer': 'This might take a moment or two...',
  'qam.quickDeposit.completed.footer': 'Closing in <timer></timer> seconds...',
  'qam.quickDeposit.successful': 'Payment successful',
  'qam.quickDeposit.failed': 'Payment unsuccessful',
  'qam.quickDeposit.failed.desc':
    'Please check your payment information and try again.',
  'qam.getVerified.desc':
    'Your account <underline>must</underline> be verified to make withdrawals.',
  'qam.getVerified.btn': 'Get verified',
  'qam.quickDeposit.desc': 'Quick Deposit',
  'qam.quickDeposit.btnCard': 'Add a card',
  'qam.quickDeposit.footer.addCard':
    'To make a Quick Deposit, <underline>add a card</underline> to your account.',
  'qam.quickDeposit.footer.chargeCard':
    'By placing a Quick Deposit, we will charge card (****<maskedcard></maskedcard>) immediately.',
  'qam.nav.accountOverview': 'Account',
  'qam.nav.support': 'Support',
  'qam.nav.myBets': 'My Bets',
  'qam.nav.logOut': 'Log out',
  'qam.balances.btn': 'Balance',
  'qam.balances.bonus': 'Bonus bets:',
  'qam.quickDeposit.confirm.msg': 'Add $<amount></amount> to your account?',
  'qam.quickDeposit.confirm': 'Confirm',
  'qam.quickDeposit.cancel': 'Cancel',
  'qam.quickDeposit.otherAmount': 'OTHER',
  'depositLimit.modal.title.hasLimit':
    'Would you like to change your existing Deposit Limit?',
  'depositLimit.modal.title.noLimit': 'Would you like to set a Deposit Limit?',
  'depositLimit.modal.toast.success.simple':
    'Your deposit limit has been successfully updated',
  'depositLimit.modal.toast.success.props':
    'Your deposit limit has been successfully updated and will come into effect on {formattedDate} {formattedTime}',
  'depositLimit.modal.toast.fail.simple':
    'Unable to update your deposit limit. Please try again.',

  'depositLimit.modal.radio.limit.keep':
    'Keep my existing Deposit Limit of {limit} every {frequency} days',
  'depositLimit.modal.radio.limit.change': 'Change my Deposit Limit',
  'depositLimit.modal.radio.limit.remove': 'Remove my existing Deposit Limit',
  'Fixed Win': 'Fixed Win',
  'raceType.Thoroughbreds': 'Thoroughbred',
  'raceType.Greyhounds': 'Greyhounds',
  'raceType.Harness': 'Harness',
  'filters.location.ausnz': 'Aus/NZ',
  'filters.location.intl': 'Int\'l',
};

type KeysProps = keyof typeof en;
export type EnKeysProps = Partial<{ [K in KeysProps]: string }>;
