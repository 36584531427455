import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'body',
  fontWeight: 'bold',
};

export const LinkItem: CSSObject = {
  color: 'white',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  h: '42px',
  mb: '1.5',
  _last: { mb: '0' },

  _hover: {
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.03))',
  },
};

export const IconSport: CSSObject = {
  color: 'alpha.300',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'white',
};

export const TextName: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
