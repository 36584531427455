import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'alpha.400', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'alpha.400'] },
};

export const FlexHeadingRow: CSSObject = {
  'div:nth-of-type(1)': {
    bg: 'rgba(0, 0, 0, 0.16)',
  },
};

export const FlexHeading: CSSObject = {
  color: 'white',
  top: ['12', '84px'],
  position: 'unset',
  mb: '0',
};

export const TextHeading: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'capitalize',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  p: '2',
  color: 'beta.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.4)',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  borderRadius: 'base',
};

export const BoxPageWrapper: CSSObject = {
  m: '0',
  mb: '4',
  p: '0.5',
  bg: 'rgba(0, 209, 255, 0.25)',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
};

export const BoxTableWrapper: CSSObject = {
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontFamily: 'accent',
  fontWeight: 'extrabold',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'semibold',
  color: 'beta.300',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextRaceResults: CSSObject = {
  color: 'white',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  bg: state === 'result' ? 'black' : 'orange.400',
  color: state === 'result' ? 'white' : 'orange.800',
});

export const LinkRaceItem = () => ({
  bg: ['transparent', null, null],
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
  _hover: {
    background: 'blackAlpha.300',
  },
  _last: {
    borderRight: 'none',
  },
});

export const FlexRow: CSSObject = {
  mb: '0',
  borderRadius: 'none',

  _last: {
    borderBottom: 'none',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  bg: 'blackAlpha.100',
  py: '1.5',
  borderColor: 'unset',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderRight: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
};

export const CenterTH = () => ({
  bg: 'blackAlpha.500',
  color: 'white',
  textAlign: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'extrabold',
  lineHeight: 'normal',
  py: '2.5',
  borderBottom: '1px',
  borderColor: 'whiteAlpha.200',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _first: {
    textTransform: 'uppercase',
  },
});

export const ButtonChevron: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  bg: 'blackAlpha.200',
  borderRadius: 'base',
  boxSize: '7',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BadgeRaceNumber: CSSObject = {
  bg: 'gray.300',
  color: 'gray.600',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
