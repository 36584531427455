import {
  footerBaseStyle,
  TFooterTheme,
} from '../../base/customComponents/footer';
import { TStyleConfig } from '../../base/types';

export const Footer: TStyleConfig<TFooterTheme> = {
  baseStyle: {
    ...footerBaseStyle,
    footer: {
      ...footerBaseStyle.footer,
      pb: '0',
      bg: 'transparent',
      bgSize: 'cover',
      bgPos: 'bottom',
      py: '0',
      color: 'white',
    },
    stackGap: '1',
    footerBar: {
      display: 'flex',
      flexDir: ['column', null, 'row'],
      borderRadius: 'md',
      alignItems: 'center',
      justifyContent: ['center', null, 'unset'],
      '& > img': {
        h: '40px',
        ml: '-1.5',
      },
    },
    footerLink: {
      color: 'beta.300',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    footerLinkTel: {
      color: 'white',
      textTransform: 'lowercase',
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
    },
    footerMobile: {
      border: '2px',
      borderColor: 'white',
      borderRadius: 'lg',
      w: '130px',
      cursor: 'pointer',
      boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.3)',
    },
    customFooterLink: {
      cursor: 'pointer',
      display: 'inline-block',
      mt: '0',
      whiteSpace: 'nowrap',
      fontSize: 'xs',
      color: 'beta.300',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      h: 'unset',
      mb: '1px',
    },
    footerLinks: {
      ml: ['unset', null, 'auto'],
      pb: '0',
      alignItems: 'center',
      justifyContent: ['space-around', null, 'unset'],
      color: 'white',
      fontWeight: 'medium',
      flexDir: ['row', null, 'unset'],
      display: 'inline-flex',
      mt: ['18px', null, '0'],
      gap: '1',
    },
    footerContentContainer: {
      ...footerBaseStyle.footerContentContainer,
      gap: '6',
      mb: 'unset',
      bg: [null, null, 'transparent'],
      justifyContent: 'space-between',
      '&': {
        flexDirection: ['column', null, 'column'],
      },
    },
    nav: {
      ...footerBaseStyle.nav,
      fontWeight: 'medium',
      minW: [null, null, '370px'],
      display: ['flex', 'grid'],
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto',
      columnGap: '3',
      alignItems: ['center', 'end'],
      gridTemplateAreas: `
        ". ."
      `,

      '> a': {
        color: 'white',
        fontSize: 'xs',
      },
    },
    blurb: {
      ...footerBaseStyle.blurb,
      flex: 'unset',
      textAlign: 'left',
      color: 'white',
      width: 'unset',
      mb: '0',
      ml: [null, '0'],
      mr: [null, null, '0'],
      pr: '0',
    },
    blurbHeading: {
      ml: 'unset',
      mt: 'unset',
      color: 'whiteAlpha.700',
    },
    blurbHeadingAge: {
      ...footerBaseStyle.blurbHeadingAge,
      color: 'white',
      borderColor: 'blackAlpha.50',
      border: '2px',
      mb: '2',
      boxSize: '8',
      fontSize: 'sm',
      fontFamily: 'Staatliches',
      fontWeight: 'normal',
    },
    blurbHeadingThink: {
      color: 'white',
      fontSize: 'xs',
      fontWeight: 'bold',
      mb: '4',
    },
    blurbContent: {
      ...footerBaseStyle.blurbContent,
      ml: 'unset',
      px: ['2', null, '0'],
      fontSize: ['xs', null, null, 'xs'],
      lineHeight: 'tall',
    },
    copyright: {
      ...footerBaseStyle.copyright,
      color: 'white',
      textAlign: ['center', 'left'],
      mb: ['2', 'unset'],
      px: ['8', 'unset'],
      py: '0',
      fontWeight: ['semibold', 'medium'],
    },
    container: {
      bg: 'transparent',
      gap: '4',
      display: 'flex',
      flexDir: 'column',
      '&': {
        px: 'unset',
      },
      mt: '6',
    },
    emailUs: {
      fontSize: ['xs', null, null, 'sm'],
      fontWeight: 'bold',
    },
    quickLinks: {
      textAlign: ['center', 'left'],
      color: 'beta.300',
      textTransform: 'uppercase',
      fontSize: 'md',
      fontFamily: 'Staatliches',
      fontWeight: 'normal',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
      pb: '0.5',
    },
    weAcceptBox: {
      mb: '0',
    },
    weAccept: {
      color: 'beta.300',
      textTransform: 'uppercase',
      fontSize: 'md',
      fontFamily: 'Staatliches',
      fontWeight: 'normal',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    },
    imageWrapper: {
      gap: '0',
    },
    partnerWrapper: {
      mr: '1',
      boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.30)',
    },
    ourPartners: {
      color: 'beta.300',
      textTransform: 'uppercase',
      fontSize: 'md',
      fontFamily: 'Staatliches',
      fontWeight: 'normal',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    },
  },
  sizes: {},
  variants: {},
};
