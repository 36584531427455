import { Skeleton } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { sortByRaceType } from '@/helpers/sortRaces';
import { useNTJ } from '../../../../../hooks/useNTJ';
import { ERaceType } from '../../../../../lib/DBModels';
import RaceLink from './Tile';
import {
  BoxColumn,
  GridRaceItems,
  TextRaceType,
} from './styles/RaceTiles.styles';

export default function RaceTiles() {
  const { queries } = useNTJ();

  return (
    <GridRaceItems>
      <AnimatePresence>
        {queries
          .sort((a, b) =>
            sortByRaceType(a.data?.[0]?.race_type, b.data?.[0]?.race_type)
          )
          .map((q, i) => (
            <BoxColumn key={`${q.data?.[0]?.race_type ?? i}-column`}>
              <TextRaceType>
                {(() => {
                  const raceType = q.data?.[0]?.race_type;
                  if (raceType === ERaceType.Horse) {
                    return <FormattedMessage id='home.horsesheading' />;
                  }
                  if (raceType === ERaceType.Harness) {
                    return <FormattedMessage id='home.harnessheading' />;
                  }
                  if (raceType === ERaceType.Greyhound) {
                    return <FormattedMessage id='home.greyhoundsheading' />;
                  }
                  return raceType;
                })()}
              </TextRaceType>

              {(() => {
                if (q.isInitialLoading) {
                  return [...new Array(8)].map((_, idxSkel) => (
                    <Skeleton
                      key={`ntj-${idxSkel}-skel`}
                      w="full"
                      h="40px"
                      mb="1"
                    />
                  ));
                }

                return [...(q.data ?? [])].slice(0, 8).map((d, index) => (
                  <motion.div
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={`${d.race_id ?? index}-motion`}
                  >
                    <RaceLink key={`ntj-${d.race_id ?? index}-item`} race={d} />
                  </motion.div>
                ));
              })()}
            </BoxColumn>
          ))}
      </AnimatePresence>
    </GridRaceItems>
  );
}
