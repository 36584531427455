import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  borderRadius: 'md',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  flex: '1',
};
export const TypeItem: CSSObject = {
  color: 'beta.300',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: 'base',
  mb: '1',
  p: '1.5',
  gap: '0.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset',
  bg: 'blackAlpha.300',
  '&:last-child': {
    mb: '2',
  },
};
export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};
export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  _last: {
    fontWeight: 'bold',
  },
};
