import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/magnumbet/images/close-filter.svg';

const primary = {
  position: 'relative',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.400, beta.300)',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  zIndex: 1,
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '2px',
    left: '2px',
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
    zIndex: '3',
    borderRadius: 'base',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
  },
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
    _before: {
      bgGradient: 'linear(to-b, beta.200, beta.200)',
    },
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.300',
    textShadow: 'none',
    _before: {
      display: 'none',
    },
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const secondary = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
  color: 'beta.300',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.2)',
  zIndex: 1,
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '2px',
    left: '2px',
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
    zIndex: '3',
    borderRadius: 'base',
    bg: 'alpha.600',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
  },
  _hover: {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.2)',
    color: 'white',
    _before: {
      bg: 'alpha.300',
    },
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    textShadow: 'none',
    _before: {
      display: 'none',
    },
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'beta.400',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  _hover: {
    color: 'beta.300',
    border: '2px',
    borderColor: 'beta.400',
    bg: 'whiteAlpha.200',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.100',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const primaryAccess = {
  ...secondary,
  bgGradient: 'linear(to-b, beta.400, beta.300)',
  _before: {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '2px',
    left: '2px',
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
    zIndex: '3',
    borderRadius: 'base',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  },
  _hover: {
    'span, svg': {
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      color: 'white',
    },
    _before: {
      bgGradient: 'linear(to-b, alpha.300, alpha.300)',
    },
  },
  ':active, &[aria-expanded=true]': {
    bgGradient: 'linear(to-b, white, white)',
    color: 'blackAlpha.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    borderBottomRadius: 'none',
    _before: {
      display: 'none',
    },
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const odds = {
  ...primary,
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'beta.300',
    textShadow: 'none',
    _before: {
      display: 'none',
    },
  },
  _disabled: {
    opacity: 1,
    pointerEvents: 'none',
    bgGradient: 'linear(to-b, blackAlpha.300, blackAlpha.300)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    color: 'whiteAlpha.300',
    textShadow: 'none',
    _before: {
      display: 'none',
    },
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const tab = {
  fontSize: 'xs',
  textShadow: 'none',
  fontWeight: 'bold',
  borderRadius: 'none',
  fontFamily: 'accent',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '2.5',
  pt: '1',
  ':active, &[data-active="true"]': {
    color: 'beta.300',
    fontWeight: 'bold',
    borderBottom: '3px solid',
    borderBottomColor: 'beta.300',
    textShadow: 'none',
  },
};
const filter = {
  borderRadius: 'base',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: '7',
  minW: 'auto',
  p: '2',
  textTransform: 'uppercase',
  border: '2px',
  borderColor: 'beta.300',
  bg: 'transparent',
  _hover: {
    borderColor: 'beta.400',
  },
  _active: {
    bg: 'beta.400',
    color: 'blackAlpha.800',
    border: 'none',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      border: 'none',
      boxSize: '12px',
      top: '8px',
      right: '2.5',
      borderRadius: 'full',
      pr: '2',
      backgroundImage: `url(${CloseButton})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
};
const quaddiesTab = {
  boxShadow: 'none',
  fontSize: 'xs',
  textShadow: 'none',
  fontWeight: 'normal',
  borderRadius: 'none',
  fontFamily: 'accent',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '3',
  pt: '1',
  _hover: {
    color: 'alpha.200',
  },
  ':active, &[data-active="true"]': {
    color: 'alpha.600',
    fontWeight: 'normal',
    borderBottom: '3px solid',
    borderBottomColor: 'alpha.600',
  },
};
export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    primaryAccess: () => ({
      ...primaryAccess,
    }),
    odds: () => ({
      ...odds,
    }),
    tab: () => ({
      ...tab,
    }),
    filter: () => ({
      ...filter,
    }),
    quaddiesTab: () => ({
      ...quaddiesTab,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
