import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.12))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  px: '2',
  py: '4',
  mb: '2',
  color: 'white',
  _first: {
    borderTop: '1px solid',
    borderColor: 'whiteAlpha.300',
  },
  _hover: {
    bg: 'alpha.500',
    color: 'white',
  },
  _focus: {
    bg: 'alpha.500',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
