import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'beta.400',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'sm',
    color: 'white',
    position: 'relative',
    sx: {
      '> span': {
        fontSize: '2xs',
        fontWeight: 'medium',
        position: 'absolute',
        top: 'px',
        pl: '1',
      },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    py: '2',
    _last: {
      borderBottom: 'none',
    },
    _first: {
      borderTop: '1px dashed',
      borderTopColor: 'rgba(0, 0, 0, 0.25)',
      boxShadow: '0px 1px 0px inset rgba(255, 255, 255, 0.07)',
    },
  },
  eventMarketName: {
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  eventSubtitle: {
    fontSize: 'xs',
    fontWeight: 'bold',
  },
};
