import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexWrapper: CSSObject = {
  boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.3)',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};
export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};
export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: '2xs',
  textTransform: 'capitalize',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.08) inset',
  textOverflow: 'ellipsis',
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
  },
  '.chakra-badge': {
    mr: '1.5',
    mt: '1.5',
    maxW: '10',
  },
};
export const RaceNumber: CSSObject = {
  color: 'white',
};
export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '70px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
};
export const Icon: CSSObject = {
  color: 'alpha.300',
};
export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};
export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  color: 'alpha.300',
  p: '0',
  border: '1px solid',
  borderColor: 'alpha.400',
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
  },
  '& > svg': {
    boxSize: '6',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
  },
};
