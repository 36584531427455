import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  accordionButton: {
    color: 'gray.700',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      borderTopColor: 'var(--bc-beta-200, #4B5A6D)',
      background: 'white',
      fontSize: 'sm',

      _hover: {
        background: 'gray.200',
      },
    },
  },
  topLeaguesText: { flex: '1', textAlign: 'left', fontWeight: 'semibold' },
  accordionIcon: { color: 'gray.700' },
  accordionPanel: { bg: 'white', pb: 3, borderBottomRadius: 'md' },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'beta.500',
    px: '2',
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: 'sm',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      color: 'white',
      fontsize: 'md',
      my: '3',
      fontWeight: 'bold',
    },
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    sx: {
      _last: {
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'gray.700',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopWidth: '1px',
      borderTopColor: '#353f4e',
      background: 'white',
      borderRadius: 'unset',

      _hover: {
        background: 'gray.200',
      },
      '&&&': {
        _expanded: {
          borderBottomLeftRadius: 'none',
          borderBottomRightRadius: 'none',
        },
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionIcon: {
    color: 'gray.700',
  },
  marketGroupsByLetterAccordionPanel: {
    bg: 'white',
    pb: 4,
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'alpha.500',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'gray.700',
    fontSize: 'sm',
    sx: { _hover: { textDecoration: 'none' } },
  },
  topLeaguesAccordionItem: {
    mt: '4',
  },
  navWrapper: {
    pb: 3,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: 'blackAlpha.400',
    borderRadius: 'none',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
  },
};
