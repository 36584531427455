import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import horse from '@/assets/bet575/images/toggle/thumbHorse.png';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, 'unset'],
    my: '4',
    gap: '2',
    boxShadow: [
      null,
      null,
      '0px 0px 0px 1px rgba(255, 255, 255, 0.16) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    ],
    background:
      'linear-gradient(147.35deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.05) 99.14%)',
    backdropFilter: 'blur(8px)',
    h: '14',
    pos: 'relative',
  }),
});

const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'accent',
    fontWeight: 'bold',
    fontSize: 'md',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 2px 5px rgba(0, 0, 0, 0.4)'
      : '0px 1px 0px rgba(255, 255, 255, 0.1)',
    color: isActive ? 'beta.400' : 'blackAlpha.600',
    letterSpacing: 'wide',
    lineHeight: 'normal',
    pt: '0.5',
    px: '4',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
    '--switch-track-width': '46px',
    bg: 'transparent',
    pos: 'relative',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: `url(${horse})`,
        boxSize: '9',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-4',
        top: '-1.5',
        right: '0',
      },
    },
    '.chakra-switch__track': {
      boxShadow:
        '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      bg: 'blackAlpha.400',
      borderRadius: '5px',

      _before: {
        bg: 'blackAlpha.600',
        h: '7px',
        w: '34px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
        content: '""',
        pos: 'absolute',
        left: '2',
        top: '2.5',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </TextToggle>
    </FlexWrapper>
  );
}
