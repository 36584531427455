import * as betprofessor from './TabbedDisplay.styles.betprofessor';
import * as betroyale from './TabbedDisplay.styles.betroyale';
import * as fiestabet from './TabbedDisplay.styles.fiestabet';
import * as goldenrush from './TabbedDisplay.styles.goldenrush';
import * as juicybet from './TabbedDisplay.styles.juicybet';
import * as puntcity from './TabbedDisplay.styles.puntcity';
import * as puntgenie from './TabbedDisplay.styles.puntgenie';
import * as questbet from './TabbedDisplay.styles.questbet';
import * as sterlingparker from './TabbedDisplay.styles.sterlingparker';
import * as vikingbet from './TabbedDisplay.styles.vikingbet';
import * as volcanobet from './TabbedDisplay.styles.volcanobet';
import * as wellbet from './TabbedDisplay.styles.wellbet';
import * as forsport from './TabbedDisplay.styles.forsport';
import * as junglebet from './TabbedDisplay.styles.junglebet';
import * as betgalaxy from './TabbedDisplay.styles.betgalaxy';
import * as sugarcastle from './TabbedDisplay.styles.sugarcastle';
import * as oldgill from './TabbedDisplay.styles.oldgill';
import * as templebet from './TabbedDisplay.styles.templebet';
import * as buffalobet from './TabbedDisplay.styles.buffalobet';
import * as titanbet from './TabbedDisplay.styles.titanbet';
import * as slambet from './TabbedDisplay.styles.slambet';
import * as gigabet from './TabbedDisplay.styles.gigabet';
import * as betstride from './TabbedDisplay.styles.betstride';
import * as goldenbet888 from './TabbedDisplay.styles.goldenbet888';
import * as hyperbet from './TabbedDisplay.styles.hyperbet';
import * as chromabet from './TabbedDisplay.styles.chromabet';
import * as bet777 from './TabbedDisplay.styles.bet777';
import * as bet575 from './TabbedDisplay.styles.bet575';
import * as betblast from './TabbedDisplay.styles.betblast';
import * as pyramidbet from './TabbedDisplay.styles.pyramidbet';
import * as magnumbet from './TabbedDisplay.styles.magnumbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  forsport,
  junglebet,
  betgalaxy,
  sugarcastle,
  oldgill,
  templebet,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  betstride,
  goldenbet888,
  hyperbet,
  chromabet,
  bet777,
  bet575,
  betblast,
  pyramidbet,
  magnumbet,
};
