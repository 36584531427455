import { Image } from '@chakra-ui/react';
import React from 'react';
import footerLogo from '@/assets/pyramidbet/images/logoMb.png';

export default function index() {
  return (
    <Image
      src={footerLogo}
      minW="140px"
      maxW="161px"
      minH="53px"
      marginLeft="-1"
    />
  );
}
