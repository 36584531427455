import { CSSObject, IconProps, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'primary',
  _hover: {
    bg: 'blue.400',
    _after: {
      opacity: 0,
    },
  },
  _active: {
    bg: 'blue.600',
    _after: {
      opacity: 0,
    },
  },
  _disabled: {
    bg: 'blue.700',
    pointerEvents: 'none',
  },
};

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.4) ',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  borderStyle: 'dashed',
};

export const FlexWrapper: CSSObject = {
  py: '3',
  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};

export const BoxCardsContainer: CSSObject = {
  mb: '2',
};

export const BoxInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    border: 'none',
    '#amount-input': {
      pl: 12,
      color: 'white',
      fontWeight: 'bold',
      caretColor: 'currentColor',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 3px 6px 0px rgba(0, 0, 0, 0.2) inset',

      '&&': {
        border: '1px solid',
        borderRadius: 'md',
        borderColor: 'transparent',
        bg: 'blackAlpha.700',
      },

      '&::placeholder': {
        color: 'whiteAlpha.800',
      },

      _hover: {
        '&&': {
          border: '1px solid',
          borderColor: 'transparent',
          bg: 'whiteAlpha.400',
          boxShadow: 'none',
        },
      },

      _focus: {
        '&&': {
          border: '1px solid',
          borderColor: 'gamma.100',
          boxShadow: 'none',
        },
      },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
  },
  '.chakra-input__left-addon': {
    color: 'blackAlpha.700',
    position: 'absolute',
    zIndex: 10,
    h: '34px',
    w: '34px',
    p: '13px',
    top: '50%',
    left: 1,
    transform: 'translateY(-50%)',
    lineHeight: 'normal',
    fontWeight: 'bold',
    borderRadius: 'base',
    fontFamily: 'accent',
    fontSize: 'md',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    bgGradient: 'linear(to-b, #FFBF36, #EEA200)',
    border: 'none',
    boxShadow:
      '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  },
  '.chakra-form__error-message': {
    bg: 'red.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    pt: '0',
    svg: {
      fontSize: 'sm',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const RadioCard: CSSObject = {
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    borderColor: 'white',
    _hover: {
      bg: 'blue.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'beta.300',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'white',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'odds',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  color: 'alpha.800',
};

export const TrashIcon: IconProps = {
  bg: 'none',
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  color: 'alpha.500',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  boxSize: '7',
  p: '1',
  borderRadius: 'base',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};
