import React from 'react';
import { Button, CustomButtonProps } from '@/components/Button/Button';
import { componentSwitch } from '@/helpers/componentSwitch';
import { EThemesOG } from '@/constants/themeConfig';
import {
  buttonBatchKeysProps,
  buttonDoneKeyProps,
  ButtonKeys,
  buttonKeysProps,
} from '../styles/Keypad.styles';

export const buttonThemeList = [
  EThemesOG.Sterlingparker,
  EThemesOG.Betroyale,
  EThemesOG.Juicybet,
  EThemesOG.Junglebet,
  EThemesOG.Fiestabet,
  EThemesOG.Sugarcastle,
  EThemesOG.Oldgill,
  EThemesOG.Buffalobet,
  EThemesOG.Titanbet,
  EThemesOG.Slambet,
  EThemesOG.Templebet,
  EThemesOG.GoldenBet888,
  EThemesOG.Gigabet,
  EThemesOG.Betstride,
  EThemesOG.Chromabet,
  EThemesOG.Bet777,
  EThemesOG.Betbetbet,
  EThemesOG.Hyperbet,
  EThemesOG.Betblast,
  EThemesOG.Bet575,
  EThemesOG.Magnumbet,
  EThemesOG.Pyramidbet,
];

export const ButtonKeysSwitched = (
  props: CustomButtonProps & { isBatch: boolean; key: string }
) => {
  const { isBatch, className, ...rest } = props;
  const isDoneKey = className === 'keypad-btn-done';

  let keyProps = buttonKeysProps;
  if (isBatch) {
    keyProps = buttonBatchKeysProps;
  } else if (isDoneKey) {
    keyProps = buttonDoneKeyProps;
  }

  return componentSwitch(
    buttonThemeList,
    <ButtonKeys {...props} />,
    <Button {...rest} {...keyProps} />
  );
};
