import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  flexExoticsHeadingRight: { alignItems: ['flex-end', 'center'] },
  buttonStyledGroupedProps: {
    variant: 'odds',
    boxSize: '34px',
    minWidth: '34px',
    minHeight: '34px',
    borderRadius: 'md',
    lineHeight: 'normal',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-closed="true"][data-active="false"]': {
        border: '2px solid rgba(255, 255, 255, 0.08)',
        bg: 'transparent',
        boxShadow: 'none',
        _before: {
          display: 'none',
        },
        '.btn-span': {
          color: 'white',
        },
      },
    },
  },
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mx: ['2', '0'],
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
  '&&': {
    '.quaddie': {
      _before: {
        display: 'none',
      },
      border: '2px solid',
      borderColor: 'beta.300',
      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
      '.btn-span': {
        gap: '0',
      },

      '&[data-active="false"]': {
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
        bg: 'transparent',
        color: 'white',
        textShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        border: '2px solid',
        borderColor: 'beta.300',
      },

      '&.active': {
        border: '2px solid',
        borderColor: 'beta.300',
        bg: 'alpha.800',
        color: 'beta.300',
        textShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        px: '2',
      },
      '&:hover': {
        bg: 'alpha.600',
      },
    },
  },
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  py: '4px',
  borderRadius: 'base',
  color: 'white',
  flexDirection: 'row',
};

export const TextRaceConditions: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    w: '1px',
    h: '4',
    bg: 'whiteAlpha.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'blackAlpha.700',
  bg: 'green.400',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
  pb: 'px',
};

export const RaceStatusText: CSSObject = {
  color: 'blackAlpha.700',
  bg: 'red.500',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  py: '0.5',
  textTransform: 'uppercase',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  textTransform: 'none',
  fontFamily: 'heading',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  minW: '40px',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  pt: '3',
};

export const RaceRunnerList: CSSObject = {
  bg: 'transparent',
  p: '0',
  '> div.chakra-stack:first-child': {
    pb: 2,
    '> div.chakra-stack': {
      '> div.chakra-stack': {
        '> div.chakra-stack:last-child': {
          mt: 0,
        },
      },
    },
  },
};

export const TextTitle: CSSObject = {
  fontFamily: 'heading',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: 'beta.300',
};

export const RaceName: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
  color: 'white',
};

export const FlexHeaderLeft: CSSObject = {
  gap: '0',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  mt: '1',
  mx: ['2', 0],
  pb: '1.5',
  pt: '1',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  '.chakra-switch__track': {
    background: 'blackAlpha.600',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
    '& .chakra-switch__thumb': {
      bg: 'white',
      '&[data-checked]': { bg: 'beta.300' },
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
  pb: '2',
  alignItems: 'center',
  '> div > p': {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  '> div:last-child': {
    mr: '0.5',
  },
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '12',
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1.5',
  '&&&': {
    p: {
      color: 'white',
      textTransform: 'none',
      fontFamily: 'accent',
      fontSize: '2xs',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: 'normal',
      minW: '48px',
      svg: {
        color: 'white',
      },
    },
  },
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};

export const ShowMoreText: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};
