
export const links = [
  { name: 'account.overview', href: '/account/overview', dataCyValue: 'overviewLink' },
  { name: 'account.sidenav.mybets', href: '/account/my-bets', dataCyValue: 'myBetsLink' },
  { name: 'account.sidenav.deposit', href: '/account/deposit', dataCyValue: 'depositLink' },
  {
    name: 'account.sidenav.withdraw',
    href: '/account/withdrawal',
    dataCyValue: 'withdrawalLink',
  },
  {
    name: 'account.sidenav.limit',
    href: '/account/deposit-limit',
    dataCyValue: 'depositLimitLink',
  },
  {
    name: 'account.sidenav.transactions',
    href: '/account/transactions?type=transactions',
    dataCyValue: 'transactionsLink',
  },
  {
    name: 'account.sidenav.account',
    href: '/account/settings/responsible-gambling',
    dataCyValue: 'manageAccountLink',
  },
  { name: 'account.sidenav.settings', href: '/account/settings', dataCyValue: 'settingsLink' },
];