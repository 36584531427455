import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (): CSSObject => ({
  transition: 'all .2s ease-in-out',
  borderRadius: 'md',
  bg: 'linear-gradient(180deg, #FFD665 0%, #DFB02D 100%)',
  color: 'blackAlpha.800',
  fontSize: 'sm',
  fontWeight: 'black',
  p: '1',
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gamma.800',
};

export const ReviewStakeText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'beta.300',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
  fontFamily: 'accent',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: '22px',
  color: 'beta.300',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
};

export const InputBetSlip: CSSObject = {
  pl: '12',
  color: 'beta.200',
  borderRadius: 'md',
  bg: 'alpha.800',
  border: 'none',
  fontSize: 'sm',
  fontFamily: 'accent',
  fontWeight: 'bold',
  ':first-child': {
    pl: '3',
    borderLeft: '2px',
  },
  _hover: {
    '&&': {
      bg: 'alpha.700',
    },
    '.chakra-input__left-element': {
      bg: 'linear-gradient(180deg, #FFD665 0%, #DFB02D 100%)',
      
    },
  },
  _focus: {
    borderColor: 'beta.500',
    '&&': {
      background: 'epsilon.300',
    },
    '&::placeholder': { color: 'white' },
  },
  '&::placeholder': { 
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'bold', 
  },
};

export const TreasureChestIcon: CSSObject = {
  color: 'beta.500',
};
