import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'base',
  bg: 'rgba(0, 209, 255, 0.25)',
  border: 'none',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
};

export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};

export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'normal',
};

export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};

export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    borderLeft: '1px solid',
    borderColor: ['transparent', null, 'whiteAlpha.200'],
    pl: ['0', null, '2'],
  },
};

export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'semibold',
  fontFamily: 'accent',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
};

export const TextDeductions: CSSObject = {
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const TextScratchedInfo: CSSObject = {
  color: 'gray.400',
  fontSize: '2xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
};
export const ScratchedText: CSSObject = {
  color: 'gray.700',
  fontWeight: 'semibold',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1.5',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.3);',
};
