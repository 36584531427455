import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {
      boxSize: '4',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    },
  },
  iconButtonScrollRight: {
    bgGradient: 'linear(to-b, gamma.400, gamma.400)',
    color: 'alpha.800',
    border: 'none',
    borderRadius: 'sm',
    borderLeftRadius: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    _hover: {
      bgGradient: 'linear(to-b, gamma.300, gamma.300)',
    },
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {
      boxSize: '4',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    },
  },
  iconButtonScrollLeft: {
    bgGradient: 'linear(to-b, gamma.400, gamma.400)',
    color: 'alpha.800',
    border: 'none',
    borderRadius: 'sm',
    borderRightRadius: 'none',
    h: 9,
    w: 'auto',
    minW: '4',
    _hover: {
      bgGradient: 'linear(to-b, gamma.300, gamma.300)',
    },
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
    },
  },
  flexWrapper: {
    bg: 'alpha.500',
    borderRadius: 'md',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  buttonGroupSports: {
    display: 'inline-flex',
    flex: '1',
    overflowX: 'auto',
    p: '1',
    spacing: '1',
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    whiteSpace: 'nowrap',
    pos: 'relative',
    w: ['120px', 'auto', null],
    borderRadius: [null, null, 'md'],
    inset: 0,
    right: [null, null, null, '38px'],
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    pr: '2px',
    pos: 'relative',
    borderRadius: 'md',
    spacing: '1',
    ml: 'auto',
    gap: 0.5,
    isAttached: true,
  },
  scrollIndicator: {
    w: 2,
    h: '0.5',
    bg: 'gamma.400',
    pos: 'absolute',
    bottom: 'px',
    transition: 'all 0.2s ease',
  },
};
