import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = {
  borderColor: 'transparent',
};

export const LinkItem = (
  isFeatured: boolean,
  isFirstItem: number,
  isLastItem: number
) => ({
  p: '2',
  py: '3',
  borderTop: '1px',
  borderTopColor: 'gray.300',
  borderX: '1px',
  borderLeftColor: 'gray.300',
  borderRightColor: 'gray.300',
  borderBottom: isLastItem ? '1px' : 0,
  borderBottomColor: isLastItem ? 'gray.300' : 'transparent',
  bg: isFeatured ? 'white' : 'white',
  color: isFeatured ? 'epsilon.600' : 'epsilon.600',
  transition: 'all, .1s ease-in-out',
  borderTopRadius: isFirstItem ? 'md' : 'none',
  borderBottomRadius: isLastItem ? 'md' : 'none',

  _hover: {
    bg: 'epsilon.50',
  },

  svg: {
    boxSize: '6',
    p: '1',
    color: isFeatured ? 'beta.700' : 'beta.700',
    border: '1px',
    borderColor: 'beta.700',
    borderRadius: 'full',
  },
});

export const TextTile: CSSObject = {
  fontWeight: 'semibold',
};
