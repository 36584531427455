import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Check } from '@styled-icons/fa-solid';
import { FormattedMessage } from 'react-intl';
import {
  ButtonFilter,
  Divider,
  IconCheck,
  TextFilterLabel,
} from './styles/Filters.styles';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { EFilters, setFilter } from '../../../../../redux/ntjFilters.slice';

export default function Location() {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.ntjFilters);

  return (
    <Flex>
      <Divider />
      <Flex sx={{ columnGap: '2.5' }}>
        {[
          { label: 'filters.location.ausnz', value: EFilters.Aus },
          { label: 'filters.location.intl', value: EFilters.Intl },
        ].map((v) => (
          <ButtonFilter
            data-cy={`${v.value}ButtonFilter`}
            key={v.label}
            variant="unstyled"
            onClick={() =>
              dispatch(setFilter({ [v.value]: !filters[v.value] }))
            }
            selected={filters[v.value] === true}
          >
            <IconCheck as={Check} selected={filters[v.value] === true} />
            <TextFilterLabel>
              <FormattedMessage id={v.label} />
            </TextFilterLabel>
          </ButtonFilter>
        ))}
      </Flex>
    </Flex>
  );
}
