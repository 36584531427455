import * as betgalaxy from './en.betgalaxy';
import * as betprofessor from './en.betprofessor';
import * as betroyale from './en.betroyale';
import * as buffalobet from './en.buffalobet';
import * as fiestabet from './en.fiestabet';
import * as forsport from './en.forsport';
import * as goldenrush from './en.goldenrush';
import * as juicybet from './en.juicybet';
import * as junglebet from './en.junglebet';
import * as oldgill from './en.oldgill';
import * as puntcity from './en.puntcity';
import * as puntgenie from './en.puntgenie';
import * as questbet from './en.questbet';
import * as sterlingparker from './en.sterlingparker';
import * as sugarcastle from './en.sugarcastle';
import * as vikingbet from './en.vikingbet';
import * as volcanobet from './en.volcanobet';
import * as wellbet from './en.wellbet';
import * as titanbet from './en.titanbet';
import * as slambet from './en.slambet';
import * as templebet from './en.templebet';
import * as goldenbet888 from './en.goldenbet888';
import * as gigabet from './en.gigabet';
import * as betstride from './en.betstride';
import * as chromabet from './en.chromabet';
import * as bet777 from './en.bet777';
import * as betbetbet from './en.betbetbet';
import * as hyperbet from './en.hyperbet';
import * as betblast from './en.betblast';
import * as bet575 from './en.bet575';
import * as magnumbet from './en.magnumbet';
import * as pyramidbet from './en.pyramidbet';
import { themeName } from '@/helpers/getThemeConfig';

const exports = {
  betgalaxy,
  betprofessor,
  betroyale,
  buffalobet,
  fiestabet,
  forsport,
  goldenrush,
  juicybet,
  junglebet,
  oldgill,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  sugarcastle,
  vikingbet,
  volcanobet,
  wellbet,
  titanbet,
  slambet,
  templebet,
  goldenbet888,
  gigabet,
  betstride,
  chromabet,
  bet777,
  betbetbet,
  hyperbet,
  betblast,
  bet575,
  magnumbet,
  pyramidbet,
} as Record<string, any>;

export default exports[themeName];
