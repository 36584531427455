import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  'button[data-cy="updateDepositLimitButton"]': {
    position: 'relative',
    borderRadius: 'md',
    border: 'none',
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'beta.500',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    _after: {
      content: `''`,
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      inset: 0,
      zIndex: 8,
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
      borderRadius: 'md',
      m: '2px',
      boxShadow:
        '0px 0px 0px 1px rgba(92, 68, 19, 1) inset, 0px 1px 0px 1px rgba(255, 255, 255, 0.5) inset',
    },
    _hover: {
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
      color: 'beta.700',
      _after: {
        bgGradient: 'linear(180deg, beta.400 0%, beta.400 100%)',
      },
    },
    ':active, &[data-active=true]': {
      bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      color: 'beta.300',
      textShadow: '0px 0px 8px rgba(255, 210, 123, 0.5)',

      _after: {
        display: 'none',
      },
    },
    '&[data-active=false]': {
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    },
    _disabled: {
      opacity: 0.4,
      pointerEvents: 'none',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },

    '&&&': {
      '& > svg, & > span': { zIndex: 9 },
    },
  },
};
