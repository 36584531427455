import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { resetFilters } from '../../../../redux/raceTable.slice';
import { useQueryPunterAccount } from '@/api/punter/punter.hooks';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { useQueryCompetitions } from '@/api/sports/competitions.hooks';
import { useQuerySportMatches } from '@/api/sportDetailsMatches/sportDetailsMatches.hooks';

export const useRaceMeetingsNew = () => {
  const dispatch = useAppDispatch();
  const { raceMeetingsTime } = useParams();
  const intl = useIntl();

  const { data: punterAccount } = useQueryPunterAccount();
  const { data: offerings } = useQueryOfferings({
    params: {
      pc: punterAccount?.pc,
    },
  });

  const futureId =
    offerings?.offered_racing_futures &&
    offerings?.offered_racing_futures?.sport_id;

  const queryCompetitions = useQueryCompetitions(
    {
      sport_id: futureId ?? '',
    },
    { enabled: !!futureId }
  );

  const competitionId =
    queryCompetitions.data?.data.competitions &&
    queryCompetitions.data?.data.competitions[0].competition_id;

  const { data: matches } = useQuerySportMatches(
    {
      competition_id: competitionId,
    },
    {
      enabled: !!futureId && !!competitionId,
      staleTime: 60 * 5000,
    }
  );

  const tabs = [
    { key: 'Yesterday', label: intl.formatMessage({ id: 'racing.tabbar.yesterday', defaultMessage: 'Yesterday' }) },
    { key: 'Next To Jump', label: intl.formatMessage({ id: 'racing.tabbar.nexttojump', defaultMessage: 'Next To Jump' }) },
    { key: 'Today', label: intl.formatMessage({ id: 'racing.tabbar.today', defaultMessage: 'Today' }) },
    { key: 'Tomorrow', label: intl.formatMessage({ id: 'racing.tabbar.tomorrow', defaultMessage: 'Tomorrow' }) },
    { key: dayjs().add(2, 'day').format('dddd'), label: intl.formatMessage({ id: `racing.tabbar.${dayjs().add(2, 'day').format('dddd')}`, defaultMessage: dayjs().add(2, 'day').format('dddd') })},
    { key: dayjs().add(3, 'day').format('dddd'), label: intl.formatMessage({ id: `racing.tabbar.${dayjs().add(3, 'day').format('dddd')}`, defaultMessage: dayjs().add(3, 'day').format('dddd') })},
  ];

  if (matches?.length) {
    tabs.push({ key: 'Futures', label: 'Futures' });
  }

  /**
   * On exit reset the filters
   */
  useEffect(
    () => () => {
      dispatch(resetFilters());
    },
    [dispatch]
  );

  return {
    tabs,
    matches,
    activeTab: raceMeetingsTime?.replaceAll('%20', ' '),
  };
};
