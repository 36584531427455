import * as betprofessor from './Header.styles.betprofessor';
import * as betroyale from './Header.styles.betroyale';
import * as fiestabet from './Header.styles.fiestabet';
import * as puntcity from './Header.styles.puntcity';
import * as puntgenie from './Header.styles.puntgenie';
import * as questbet from './Header.styles.questbet';
import * as sterlingparker from './Header.styles.sterlingparker';
import * as vikingbet from './Header.styles.vikingbet';
import * as volcanobet from './Header.styles.volcanobet';
import * as wellbet from './Header.styles.wellbet';
import * as goldenrush from './Header.styles.goldenrush';
import * as juicybet from './Header.styles.juicybet';
import * as junglebet from './Header.styles.junglebet';
import * as oldgill from './Header.styles.oldgill';
import * as buffalobet from './Header.styles.buffalobet';
import * as sugarcastle from './Header.styles.sugarcastle';
import * as gigabet from './Header.styles.gigabet';
import * as goldenbet888 from './Header.styles.goldenbet888';
import * as slambet from './Header.styles.slambet';
import * as betstride from './Header.styles.betstride';
import * as chromabet from './Header.styles.chromabet';
import * as templebet from './Header.styles.templebet';
import * as hyperbet from './Header.styles.hyperbet';
import * as bet777 from './Header.styles.bet777';
import * as titanbet from './Header.styles.titanbet';
import * as bet575 from './Header.styles.bet575';
import * as betblast from './Header.styles.betblast';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  goldenrush,
  juicybet,
  junglebet,
  oldgill,
  buffalobet,
  sugarcastle,
  gigabet,
  goldenbet888,
  slambet,
  betstride,
  chromabet,
  templebet,
  hyperbet,
  bet777,
  titanbet,
  betblast,
};
