import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  px: 3,
  py: 1.5,
  mb: 2,
  mx: 0,
  borderRadius: 'md',
  bgGradient: `linear(to-b, alpha.300, alpha.500)`,
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};
export const TextListName: CSSObject = {
  pt: '1.5',
  color: 'white',
  fontSize: 'xs',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'alpha.400',
};
export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  ...(isVS && { color: 'beta.300', mx: '1' }),
  fontFamily: 'accent',
  fontWeight: 'bold',
  fontSize: 'xs',
});
export const FlexSubTextWrapper: CSSObject = {
  color: 'gamma.300',
  fontSize: '2xs',
  fontWeight: 'medium',
  fontFamily: 'body',
  flex: 1,
};
export const IconSport: CSSObject = {
  color: 'beta.300',
  boxSize: '32px',
  bgGradient: 'linear(to-b, alpha.400, alpha.200)',
  borderRadius: 'md',
  p: '2',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
};
export const IconChevron: CSSObject = {
  color: 'white',
  ml: '0',
  w: 4,
  h: 4,
  mb: '0.5',
};
export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: 'xs',
    bg: 'transparent',
  },
};
export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.400',
    boxSize: '5',
  },
};
