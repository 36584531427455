import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};
export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', null, '0'],
};
export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
  contain: 'none',
};
export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
};
export const LinkItem: CSSObject = {
  color: 'white',
  bgGradient: `linear(to-b, alpha.300, alpha.500)`,
  boxShadow: '0px 0px 0px 1px #FFFFFF1A inset, 0px 4px 4px 0px #00000040',
  border: 'none',
  borderRadius: 'lg',
  h: '10',
  mb: ['1', '2'],
  _last: {
    mb: '0',
  },
  _hover: {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    color: 'white',
  },
};
export const IconSport: CSSObject = {
  color: 'beta.300',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'white',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
export const TextName: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  lineHeight: 'normal',
};
