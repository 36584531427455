import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  headerSportText: {
    fontSize: 'lg',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    color: 'gamma.200',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: 'widest',
  },
  boxWrapper: {
    px: 0,
    mt: '-2',
  },
  accordionButton: {
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderTopRadius: 'md',
      border: 'none',
      fontSize: 'sm',
    },
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'normal',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      fontFamily: 'accent',
      color: 'white',
      fontSize: 'xl',
      mt: '4',
      mb: '2',
      fontWeight: 'bold',
    },
  },
  topLeaguesAccordionItem: {
    mt: '3',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    borderRadius: 'md',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    bgGradient: 'linear(to-b, #4a838F, #38727E)',
    borderRadius: 'md',
    boxShadow:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
    mb: '2',
    sx: {
      _last: {
        button: {
          _last: {
            borderBottomRadius: 'md',
          },
        },
      },
      _first: {
        button: {
          _first: { borderTopRadius: 'md', border: 'none' },
        },
      },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'white',
    sx: {
      display: 'flex',
      px: '3',
      py: '2',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: 'unset',
      svg: {
        color: 'white',
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '2',
    sx: {
      img: {
        borderRadius: 'base',
        filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))',
      },
    },
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '28px',
    height: '20px',
  },
  marketGroupsByLetterAccordionPanel: {
    px: '3',
    pb: '1',
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '2px',
    borderColor: 'alpha.200',
    px: '2.5',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'normal',
    fontFamily: 'accent',
    sx: { _hover: { textDecoration: 'none' } },
  },
  accordionIcon: { color: 'white' },
  accordionPanel: {
    px: '3',
    pb: '1',
    pt: '0',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '2px',
    borderColor: 'alpha.200',
    px: '2',
  },
  topLeaguesText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'accent',
    textTransform: 'uppercase',
    color: 'white',
    fontSize: 'xs',
    px: 0,
  },
  navWrapper: {
    mt: ['5', null, '3'],
    pb: '0',
  },
};
