import horse from '@/assets/bet575/images/toggle/thumbHorse.png';

export const FlexWrapper = {
  borderRadius: 'lg',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: '1.5',
  my: '2',
  gap: '0',
  bgGradient: 'linear(to-b, #FFFFFF1F, #FFFFFF0D)',
  backgroundPosition: '-300px 0px',
  bgSize: '364px',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.16) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  h: '14',
  pos: 'relative',
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'KHInterference',
  fontWeight: 'bold',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 2px 4px rgba(0, 0, 0, 0.25)'
    : '0px 1px 0px rgba(255, 255, 255, 0.1)',
  color: isActive ? 'beta.400' : 'blackAlpha.600',
  px: '4',
});

export const Switcher = () => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-2))',
  '--switch-track-width': '46px',
  bg: 'transparent',
  pos: 'relative',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${horse})`,
      boxSize: '9',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-4',
      top: '-1.5',
      right: '0',
    },
  },
  '.chakra-switch__track': {
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    bg: 'blackAlpha.400',
    borderRadius: '5px',
    _before: {
      bg: 'blackAlpha.600',
      h: '7px',
      w: '34px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: '2em',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
      content: '""',
      pos: 'absolute',
      left: '2',
      top: '2.5',
    },
  },
});
