import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/betstride/icons/mysteryBetLogo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 2,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: { variant: 'odds' },
  imageLogo: {
    w: '172px',
    h: '40px',
    src: mysteryLogo,
  },
  iconMystery: {},
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    gap: '2',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    borderRadius: 'base',
    borderRightRadius: 'none',
    p: '2',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'xs',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow: 'sm',
    px: '3',
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
  },
  flexWrapperMysteryPlacedBetView: {},
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear-gradient(to bottom, alpha.200 0%, alpha.300 100%)',
    boxShadow:
      '0px 1px 2px 0px rgba(255, 255, 255, 0.4) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'base',
    boxSize: '10',
    p: '2',
    color: 'alpha.600',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    cursor: 'pointer',
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    textTransform: 'uppercase',
    fontSize: 'xs',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'semibold',
  },
  textPriceInfo: {
    textTransform: 'uppercase',
    fontFamily: 'accent',
    fontSize: 'xs',
    color: 'beta.300',
    fontWeight: 'semibold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  textRollover: {
    textTransform: 'uppercase',
    fontFamily: 'accent',
    fontSize: 'xs',
    color: 'beta.300',
    fontWeight: 'semibold',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.5)',
  },
  textError: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '6',
    color: 'beta.300',
  },
  iconRace: { color: 'alpha.100', mb: '1.5' },
  textSpanWhite: {
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'semibold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  buttonRollOver: {
    variant: 'secondary',
    h: '6',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)',
    _after: {
      display: 'none',
    },
  },
  buttonAddToBetslip: {
    variant: 'primary',
    textTransform: 'uppercase',
    sx: {
      '&&': {
        ms: '0',
        borderLeftRadius: 'none',
        _after: {
          borderLeftRadius: 'none',
        },
      },
    },
  },
  spanSelectionText: {
    color: 'beta.300',
    fontFamily: 'accent',
    fontWeight: 'semibold',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.5)',
  },
  spanPositionType: {
    color: 'alpha.100',
    fontFamily: 'accent',
    fontWeight: 'semibold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    fontWeight: 'bold',
  },
};
