import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'odds',
    minW: '16',
    px: '1',
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    fontFamily: 'accent',
    fontSize: '9px',
    top: 'px',
  },
  buttonBaseBox: {
    fontSize: 'xs',
    fontWeight: 'bold',
    fontFamily: 'accent',
  },
  textBase: {
    textAlign: 'center',
    color: 'blackAlpha.800',
  },
};
