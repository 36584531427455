import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  mb: '0',
  px: '2',
  bg: 'rgba(0, 209, 255, 0.25)',
  borderRadius: 'base',
  flex: '1',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
};

export const RaceResultsHeader: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

export const RunnerResultRow: CSSObject = {
  borderTop: '1px dashed rgba(255, 255, 255, 0.15)',
  borderBottom: '1px dashed rgba(0, 0, 0, 0.15)',
  py: '1.5',
  h: '12',
  _last: {
    borderBottom: 'none',
  },
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  color: 'beta.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.50)',
  textTransform: 'uppercase',
  padding: '2',
  fontSize: '2xs',
  '&&': {
    h: '8',
    w: '9',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'normal',
  fontSize: '2xs',
  color: 'white',
  pos: 'relative',
  bottom: '2px',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '12',
  h: '34px',
  bg: 'blackAlpha.400',
  border: 'none',
  borderRadius: 'md',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.20) inset',
  color: 'gray.300',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  fontWeight: 'bold',
  fontFamily: 'accent',
  fontSize: 'xs',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  py: '2',
  alignItems: 'center',
  '.chakra-switch__track': {
    background: 'alpha.700',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
    '& .chakra-switch__thumb': {
      background: 'white',
      '&[data-checked]': {
        bg: 'alpha.100',
      },
    },
  },
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
  color: 'white',
};
