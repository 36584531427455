import React from 'react';

import { useNavigate } from 'react-router-dom';
import PrimaryWrapper from '../../../wrappers/PrimaryWrapper';
import TabbedDisplay from '../../../components/TabbedDisplay';

import { RaceMeetingsList } from './styles/RaceMeetings.styles';

import {
  BoxWrapper,
  buttonTabBarMeetingsProps,
} from '@/components/TabBar/styles/TabBar.styles';
import Filters from './components/Filters/Filters';
import { getStrings } from '@/helpers/utils';
import { useRaceMeetingsNew } from './services/RaceMeetings.hooks';
import RaceTable from './RaceTable/RaceTable';
import NextToJump from './NextToJump/NextToJump';
import Futures from './Futures/Futures';
import { Button } from '@/components/Button/Button';
import { useQueryPromotions } from '@/api/punter/deposit/deposit.hooks';

const RaceMeetings: React.FC = () => {
  const navigate = useNavigate();
  const [
    {
      Racing: { RaceMeetings: Strings },
    },
  ] = getStrings();

  const { tabs, activeTab, matches } = useRaceMeetingsNew();
  const { data: promos } = useQueryPromotions({ promo_type: 'money_back' });
  return (
    <PrimaryWrapper
      fullWidthMobile
      fullWidth
      pageTitle={Strings.pageTitle}
      pageHeader={activeTab}
      pageHeaderChildren={
        <BoxWrapper>
          {tabs.map(({ key, label }) => (
            <Button
              key={key}
              data-cy={`tab-${key}`}
              onClick={() => navigate(`/racing/${key}`)}
              data-active={key === activeTab}
              {...buttonTabBarMeetingsProps}
            >
              {label}
            </Button>
          ))}
        </BoxWrapper>
      }
    >
      <RaceMeetingsList>
      <TabbedDisplay
        tabs={tabs.map(tab => tab.key)} // Use stable keys for TabbedDisplay
        initTab={activeTab}
        content={tabs.reduce((acc, { key }) => {
          let component;
          if (key === 'Next To Jump') {
            component = <NextToJump promotions={promos?.data} />;
          } else if (key === 'Futures' && matches?.length) {
            component = <Futures matches={matches} />;
          } else {
            component = <RaceTable promotions={promos?.data} />;
          }
          return {
            ...acc,
            [key]: component,
          };
        }, {})}
        layout="fullWidth"
        preContent={<Filters />}
      />
      </RaceMeetingsList>
    </PrimaryWrapper>
  );
};

export default RaceMeetings;
