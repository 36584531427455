import { CSSObject } from '@chakra-ui/react';
import { TAccordionItem, TAccordionPanel } from './MarketsByMatch.styles';

export const BoxWrapper: CSSObject = {
  px: '2',
  mt: ['3', null, '0'],
};

export const AccordionItem = ({ isNested }: TAccordionItem): CSSObject => ({
  bg: 'rgba(0, 209, 255, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: 'md',
  mb: '2',
  border: 'none',
  borderBottomRadius: 'md',

  _expanded: {
    boxShadow: 'none',
  },

  ...(isNested && {
    boxShadow: 'none',

    _last: { mb: 0 },
  }),
});

export const AccordionPanel = ({ isNested }: TAccordionPanel): CSSObject => ({
  borderBottomRadius: 'md',
  p: '2',
  ...(isNested && {
    p: '2',
    mb: '2',
    borderTop: 'none',
  }),
});

export const AccordionButton = (): CSSObject => ({
  color: 'white',
  px: '2',
  py: '2.5',
  border: 'none',
  borderRadius: 'md',
  fontWeight: 'bold',
  fontSize: 'sm',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  display: 'flex',
  justifyContent: 'space-between',
  _hover: {
    bg: 'alpha.600',
  },
  '.chakra-accordion__icon': {
    boxSize: '5',
    color: 'white',
    transition: '.3s ease-in-out',
  },

  _expanded: {
    p: '2',
    borderBottomRadius: 'none',
  },
});
