import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { TIndex } from '@/components/FormElements/Input';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'secondary',
  _hover: {
    bg: 'blue.400',
    _after: {
      opacity: 0,
    },
  },
  _active: {
    bg: 'blue.600',
    _after: {
      opacity: 0,
    },
  },
  _disabled: {
    bg: 'blue.700',
    pointerEvents: 'none',
  },
};

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderColor: 'whiteAlpha.300',
  borderStyle: 'dashed',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};

export const BoxCardsContainer: CSSObject = {
  mb: '2',
};

export const BoxInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const RadioCard: CSSObject = {
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    borderColor: 'white',
    _hover: {
      bg: 'blue.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'beta.800',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'beta.800',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  lineHeight: 'normal',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 'sm',
};

export const depositInputProps: Partial<TIndex> = {
  sxWrapper: {
    '&&': {
      '.chakra-input__left-addon': {
        bgGradient: 'linear(to-b, beta.300, beta.400)',
        px: '4',
        fontWeight: 'bold',
      },
      '.chakra-input': {
        bg: 'alpha.700',
        boxShadow: 'none',
        border: '2px solid',
        borderLeft: 'none',
        borderColor: 'beta.300',
        _hover: {
          bg: 'alpha.800',
          border: '2px solid',
          borderLeft: 'none',
          borderColor: 'beta.300',
        },
        _focus: {
          bg: 'alpha.800',
          border: '2px solid',
          borderLeft: 'none',
          borderColor: 'beta.300',
        },
        _placeholder: {
          color: 'gray.500',
        },
      },
    },
  },
};
