import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@chakra-ui/react';
import { TListItem } from '../../SideNav.types';
import { TSideNavTheme } from '@/theme/base/customComponents/sideNav';
import {
  ContactIcon,
  DepositIcon,
  HomeIcon,
  MyAccountIcon,
  PromoIcon,
  ResponsibleGamblingIcon,
  SportsIcon,
} from '../styles/NavIcon/NavIcon.styles';

type TNavIcon = {
  link: TListItem;
  styles: TSideNavTheme;
};

const NavIcon: React.FC<TNavIcon> = ({ link, styles }) => {
  const intl = useIntl();

  if (link.text === intl.formatMessage({ id: 'sidenav.home' })) {
    return <HomeIcon styles={styles} />;
  }
  if (link.text === intl.formatMessage({ id: 'sidenav.deposit' })) {
    return <DepositIcon styles={styles} />;
  }
  if (link.text === intl.formatMessage({ id: 'sideNav.promotions' })) {
    return <PromoIcon styles={styles} />;
  }
  if (link.text === intl.formatMessage({ id: 'sidenav.contactus' })) {
    return <ContactIcon styles={styles} />;
  }
  if (link.text === intl.formatMessage({ id: 'sidenav.myaccount' })) {
    return <MyAccountIcon styles={styles} />;
  }
  if (link.text === intl.formatMessage({ id: 'generic.responsiblegamblingtools' })) {
    return <ResponsibleGamblingIcon styles={styles} />;
  }
  if (link.text === 'Sports') {
    return <SportsIcon styles={styles} />;
  }
  if (link.text === intl.formatMessage({ id: 'sports.allsports' })) {
    return <SportsIcon styles={styles} />;
  }
  return <Box as="span" />;
};

export default NavIcon;
