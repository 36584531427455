import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    m: '0',
    mb: '4',
    p: '0.5',
    bg: 'rgba(0, 209, 255, 0.25)',
    borderRadius: 'md',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
    mt: '3',
  },
  body: {
    px: '3',
    pt: '3',
    pb: '0',
    fontFamily: 'accent',
    fontWeight: 'semibold',
    fontSize: 'sm',
  },
};
