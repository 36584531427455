import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  alignContent: 'center',
  pt: '16',
  textAlign: 'center',
  width: '100%',
  pr: '0',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'lg',
  fontFamily: 'heading',
  textTransform: 'uppercase',
  color: ' #DFB02D',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'sm',
  color: 'white',
};
