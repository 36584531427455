import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    color: 'alpha.700',
    fontWeight: 'medium',
    textTransform: 'capitalize',
    fontSize: 'md',
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: '2',
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'gray.700',
  },
  scrollButtonGroup: {
    bg: 'transparent',
    variant: 'filter',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: 'blackAlpha.400',
    borderRadius: 'none',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
    pb: '3',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    gap: '1',
    color: 'alpha.700',
    fontWeight: 'medium',
    textTransform: 'capitalize',
  },
  headingIcon: {
    color: 'alpha.500',
    sx: {},
  },
  propositionButton: {
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    border: '1px',
    borderColor: 'alpha.100',
  },
};
