import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};
export const BoxTakeActionTable: CSSObject = {
  bgGradient:
    'linear(to-r, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  p: '3',
};
export const TextTakeAction: CSSObject = {
  color: 'white',
};
