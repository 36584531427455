/* These rules should be disabled in the future */
/* eslint-disable no-return-assign, arrow-body-style */
import {
  Box,
  Icon,
  Skeleton,
  Stack,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular';
import { getCurrentRace } from './services/RaceTable.utils';
import RaceItem from './components/RaceItem';
import RaceTypeNotAvailable from './components/RaceTypeNotAvailable';
import {
  BoxPageWrapper,
  BoxTableWrapper,
  BoxVenueName,
  ButtonChevron,
  CenterTH,
  FlexButtonWrapper,
  FlexHeading,
  FlexHeadingRow,
  FlexRow,
  IconHeading,
  raceTableStyles,
  TextHeading,
  TextVenueCountry,
  TextVenueName,
} from './styles/RaceTable/RaceTable.styles';
import { useQueryRaceTable, useRaceConfig } from './services/RaceTable.hooks';
import { ERaceType } from '@/lib/DBModels';
import { getIconBySportName } from '@/helpers/utils';
import Loading from './components/Loading';
import NoRaces from '../NoRaces/NoRaces';
import IconSvg from '@/components/common/IconSvg';
import { TQueryPromotionsResponse } from '@/api/punter/deposit/deposit.types';
import { updateSelections } from '../utils';
import { Button } from '@/components/Button/Button';
import { SUB_TABS_DISPLAY_NAME } from '@/components/TabBar/TabBar';
import { useSupportedBets } from '@/components/Betslip/Services/Betslip.hooks';
import { useAuth } from '@/hooks/useAuth';
import { raceTypeAvailableStyles } from './styles/RaceTypeAvailable/RaceTypeAvailable.styles';

type TRaceTable = {
  promotions?: TQueryPromotionsResponse;
};

const TOTE_MULTI_BUTTON_ORDER = [
  'Quaddie',
  'EarlyQuaddie',
  'Treble',
  'DailyDouble',
];

export default function RaceTable({ promotions }: TRaceTable) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const { supportedBets } = useSupportedBets();
  const { isAuthenticated } = useAuth();

  let canUserSeeMultiples = false;

  if (
    (isAuthenticated && supportedBets && supportedBets?.tote_multi) ||
    isAuthenticated === false
  )
    canUserSeeMultiples = true;

  const { text: dayOfWeek } = useRaceConfig();
  const [toteMultiTypesPerRace, setToteMultiTypesPerRace] = useState<{
    [key: string]: { selections: string[]; selected: string };
  }>({});

  const { query, selections } = useQueryRaceTable();

  useEffect(() => {
    const data = updateSelections(toteMultiTypesPerRace, selections);
    if (data.hasChanges) setToteMultiTypesPerRace(data.state);
  }, [selections, toteMultiTypesPerRace]);

  const scrollRef = useRef<HTMLDivElement[]>([]);
  const raceTypesAvailable = query.some((q) => q.query.data.length > 0);

  function raceHasPromo(
    race_id: string,
    active_runner_count: number | undefined
  ) {
    const racePromotions = promotions?.map((promotion) =>
      promotion.race_ids?.includes(race_id) ? promotion : undefined
    );
    return racePromotions
      ?.map(
        (promotion) =>
          promotion &&
          active_runner_count &&
          !(
            promotion.min_active_runners &&
            active_runner_count &&
            promotion.min_active_runners > active_runner_count
          )
      )
      .some((value) => value);
  }
  if (query.some((q) => q.query.isLoading)) {
    return (
      <Stack mx="2">
        {Array.from({ length: 5 }, (_, i) => (
          <Skeleton key={i} height="20" fadeDuration={4} />
        ))}
      </Stack>
    );
  }

  return !raceTypesAvailable ? (
    <NoRaces />
  ) : (
    <>
      {query.map((q, it) => {
        const { clientWidth } = scrollRef.current?.[it] ?? {};

        const maxCount = q.query.data.reduce<number>((a, b) => {
          const venueLength = b.venue_races?.length ?? 0;
          if (venueLength > a) return venueLength;
          return a;
        }, 0);

        if (q.query.isLoading)
          return (
            <Stack mx="2">
              {Array.from({ length: 6 }, (_, i) => (
                <Skeleton key={i} height="20" fadeDuration={4} />
              ))}
            </Stack>
          );

        const initWidth = ((clientWidth ?? 1024) - 110) / maxCount;
        return !q.query.data.length ? (
          <RaceTypeNotAvailable type={q.type} />
        ) : (
          <BoxPageWrapper key={`rt-f-${q.type}`}>
            <FlexHeading>
              <IconHeading name={getIconBySportName(q.type)} />
              <TextHeading>
                {(() => {
                  if (q.type === ERaceType.Horse) {
                    return <FormattedMessage id='home.horsesheading' />;
                  }
                  if (q.type === ERaceType.Harness) {
                    return <FormattedMessage id='home.horsesheading' />;
                  }
                  if (q.type === ERaceType.Greyhound) {
                    return <FormattedMessage id='home.greyhoundsheading' />;
                  }
                })()}
              </TextHeading>
              <Flex gap={2} w="full" flexDir="row-reverse" pr="4" height="">
                {canUserSeeMultiples &&
                  !isMobile &&
                  toteMultiTypesPerRace[q.type]?.selections
                    ?.sort((a, b) => {
                      return (
                        TOTE_MULTI_BUTTON_ORDER.indexOf(b) -
                        TOTE_MULTI_BUTTON_ORDER.indexOf(a)
                      );
                    })
                    .map((s) => (
                      <Button
                        {...raceTypeAvailableStyles.raceTypeButton}
                        isSelected={
                          toteMultiTypesPerRace[q.type]?.selected === s
                        }
                        data-active={
                          toteMultiTypesPerRace[q.type]?.selected === s
                        }
                        key={s}
                        h="20px"
                        onClick={() => {
                          setToteMultiTypesPerRace({
                            ...toteMultiTypesPerRace,
                            [q.type]: {
                              ...toteMultiTypesPerRace[q.type],
                              selected:
                                s === toteMultiTypesPerRace[q.type]?.selected
                                  ? ''
                                  : s,
                            },
                          });
                        }}
                      >
                        {SUB_TABS_DISPLAY_NAME[s] ?? s}
                      </Button>
                    ))}
              </Flex>
              <FlexButtonWrapper>
                {/* Scroll buttons */}
                {['right', 'left'].map((dir) => (
                  <ButtonChevron
                    key={`rt-w-${dir}`}
                    variant="unstyled"
                    aria-label={`scroll ${dir}`}
                    icon={
                      <Icon as={dir === 'right' ? ChevronRight : ChevronLeft} />
                    }
                    onClick={() => {
                      const { scrollLeft, scrollWidth } =
                        scrollRef.current?.[it] ?? {};

                      scrollRef.current?.[it]?.scroll({
                        left:
                          dir === 'right'
                            ? Number(scrollLeft) + Number(scrollWidth)
                            : Number(scrollLeft) - Number(scrollWidth),
                        behavior: 'smooth',
                      });
                    }}
                  />
                ))}
              </FlexButtonWrapper>
            </FlexHeading>

            {/* Table content */}
            <BoxTableWrapper
              ref={(el: HTMLDivElement) => {
                return (scrollRef.current[it] = el);
              }}
            >
              {/* Table headings */}
              <FlexHeadingRow>
                {[...new Array(maxCount + 1)].map((_, i) => (
                  <CenterTH
                    key={`rt-h-${i}`}
                    isFirst={i === 0}
                    initWidth={initWidth}
                  >
                    {i === 0 ? dayOfWeek : `R${i}`}
                  </CenterTH>
                ))}
              </FlexHeadingRow>

              {/* Table body */}
              {(() => {
                if (q.query.isInitialLoading) {
                  return <Loading maxCount={maxCount} />;
                }

                return [...(q.query.data ?? [])].map((v) => {
                  const venueHasPromotion = v?.venue_races?.some((race) =>
                    raceHasPromo(
                      race?.race_id ?? '',
                      race?.active_runner_count ?? undefined
                    )
                  );

                  return (
                    <FlexRow key={v.venue_id}>
                      <BoxVenueName>
                        <Box>
                          <TextVenueName noOfLines={1} title={v.venue_name}>
                            {v.venue_name}
                          </TextVenueName>
                          <TextVenueCountry noOfLines={1}>
                            {v.venue_country}
                          </TextVenueCountry>
                        </Box>

                        {venueHasPromotion && (
                          <IconSvg
                            name="money-back"
                            className="svgIcon"
                            boxSize="6"
                            {...raceTableStyles.iconSvgMoneyBackProps}
                          />
                        )}
                      </BoxVenueName>

                      {[...new Array(maxCount)].map((_, i) => {
                        const race = getCurrentRace(v.venue_races, i, maxCount);

                        return (
                          <RaceItem
                            key={`rt-ri-${v.venue_id}-${race?.race_id ?? i}`}
                            venue={v}
                            race={race ?? {}}
                            initWidth={initWidth}
                            selectedToteMulti={
                              toteMultiTypesPerRace[q.type]?.selected
                            }
                            hasPromo={raceHasPromo(
                              race?.race_id ?? '',
                              race?.active_runner_count ?? undefined
                            )}
                          />
                        );
                      })}
                    </FlexRow>
                  );
                });
              })()}
            </BoxTableWrapper>
          </BoxPageWrapper>
        );
      })}
    </>
  );
}
