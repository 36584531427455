import { DividerSchema } from '@/views/sports/components/Divider/styles/Divider.styles';

export const dividerStyles: DividerSchema = {
  boxWrapper: {
    border: 'none',
    sx: {
      '&&': {
        my: '2',
      },
    },
  },
};
