import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pl: ['2', null, '4'],
    h: ['20px', null, '38px'],
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    sx: {
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbLinkItem: {
    fontSize: '10px',
    fontWeight: '700',
    color: 'gamma.200',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
    sx: {
      '&:not([href])': {
        color: 'gamma.500',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'gamma.200',
};
