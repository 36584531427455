import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
    columnGap: '3.5',
  },
  FlexCard: {
    my: '1',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow:
      '0px 7px 20px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    position: 'relative',
    zIndex: '0',
  },
  LinkCardHeader: {
    bg: 'transparent',
    borderBottom: '2px solid',
    borderColor: 'beta.300',
  },
  TextCardHeading: {
    color: 'white',
    fontFamily: 'accent',
    fontSize: '13px',
    fontWeight: 'semibold',
    textTransform: 'capitalize',
    lineHeight: 'normal',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.2)',
    pt: '0.5',
    ml: '1',
  },
  TextRaceNumber: {
    bg: 'gray.300',
    borderRadius: '3px',
    color: 'gray.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
    lineHeight: '16px',
    fontFamily: 'accent',
  },
  GridCardContent: {
    color: 'white',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontFamily: 'accent',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    px: '2',
    fontSize: '2xs',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px dashed',
    borderBottomColor: 'rgba(0, 0, 0, 0.3)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    px: '2',
    mx: 'px',
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'accent',
  },
  TextRunnerNumber: {
    fontWeight: 'bold',
    sx: {
      _last: {
        color: 'white',
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },
  TextScratched: {
    color: 'white',
    fontFamily: 'accent',
    fontSize: '2xs',
    fontWeight: 'normal',
  },
  LinkRaceDetails: {
    lineHeight: '27px',
    h: '42px',
    position: 'relative',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.400, beta.300)',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
    color: 'blackAlpha.800',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    zIndex: '-2',
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '2px',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      zIndex: '-1',
      borderRadius: 'base',
      bgGradient: 'linear(to-b, beta.300, beta.400)',
    },
    _hover: {
      bgGradient: 'linear(to-b, beta.500, beta.500)',
      _before: {
        bgGradient: 'linear(to-b, beta.200, beta.200)',
      },
    },
    m: 2,
  },
  buttonRaceCardOddsProps: {
    variant: 'odds',
    fontSize: 'xs',
    fontWeight: 'bold',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    h: '8',
    w: '46px',
  },
};
export const TextRunner: CSSObject = {
  color: 'white',
};
export const IconCardHeader: CSSObject = {
  color: 'beta.300',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
