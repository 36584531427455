import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .07)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px rgba(0, 0, 0, 0.3) inset',
    mx: '0.5',
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
      pb: '0',
    },
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.400, beta.300)',
    boxShadow: `0px 3px 4px 0px rgba(0, 0, 0, 0.2)`,
    minW: '90px',
    py: '2px',
    px: '2',
    h: '10',
  },
  btnGroupText: {
    fontSize: 'xs',
    color: 'blackAlpha.800',
    fontWeight: 'bold',
    fontFamily: 'accent',
    h: 'full',
    lineHeight: '36px',
    bg: 'whiteAlpha.700',
    px: '3',
    textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.3)',
  },
  btnGroupIcon: {
    color: 'blackAlpha.800',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.25))',
  },
  name: {
    color: 'white',
  },
};
export default styles;
