import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
  fontFamily: 'accent',
  fontWeight: 'semiBold',
};
export const Subheading: CSSObject = {
  color: 'white',
};
export const DividerWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontFamily: 'accent',
};
export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'bold',
  fontSize: 'sm',
  textTransform: 'uppercase',
  pt: '0.5',
};
export const ButtonWithdrawal: CSSObject = {
  variant: 'solid',
  fontWeight: 'bold',
  fontSize: 'sm',
  textTransform: 'uppercase',
};
export const ButtonIncrease: CSSObject = {
  variant: 'solid',
  fontSize: 'sm',
  textTransform: 'uppercase',
  '&&': {
    fontWeight: 'bold',
  },
};
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
};
export const AmountInputWrapperWithdrawal: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.100, alpha.500)',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    px: '4',
    fontWeight: 'bold',
  },
  '&&': {
    '.chakra-input': {
      bg: 'alpha.700',
      boxShadow: 'none',
      border: '2px solid',
      borderLeft: 'none',
      borderColor: 'beta.300',
      _hover: {
        bg: 'alpha.800',
        border: '2px solid',
        borderLeft: 'none',
        borderColor: 'beta.300',
      },
      _focus: {
        bg: 'alpha.800',
        border: '2px solid',
        borderLeft: 'none',
        borderColor: 'beta.300',
      },
      _placeholder: {
        color: 'gray.500',
      },
    },
  },
};
/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    border: '3px solid',
    borderColor: 'white',
    _hover: {
      bg: 'blue.600',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};
export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};
export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};
/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'alpha.600',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  borderRadius: 'md',
  mt: '2',
  px: '2',
  py: '0',
  w: '100%',
};
export const ListContainerHeading: CSSObject = {
  color: 'beta.300',
  fontFamily: 'accent',
  textShadow: 'box-shadow: 0px 0px 8px rgba(254, 208, 119, 0.5)',
  fontSize: 'lg',
  mt: '3',
  '&&': {
    fontWeight: 'bold',
  },
};
/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};
export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};
export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'alpha.200',
  color: status === EWithdrawalStatus.Pending && 'alpha.900',
  textTransform: 'uppercase',
  fontSize: '2xs',
});
export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};
export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '2',
  variant: 'solid',
  fontWeight: 'bold',
  pt: '0.5',
};
export const ButtonWithdrawalCardCancel: CSSObject = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'white',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: 'none',
  _hover: {
    border: '2px',
    bg: 'transparent',
    borderColor: 'beta.300',
    color: 'beta.300',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: '0px 0px 8px rgba(255, 210, 123, 0.5)',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    borderColor: 'alpha.100',
    color: 'alpha.100',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
  _after: {
    opacity: 0,
  },
};
export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};
export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  p: '2',
  bg: '#086075',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
};
export const WithdrawalCardContent: CSSObject = {
  color: 'white',
};
export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
};
export const WithdrawalCardDetailRequested: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
