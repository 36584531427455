import { CSSObject } from '@chakra-ui/react';

export const TextCheckbox: CSSObject = {
  color: 'white',
};
export const buttonCloseAccountProps: CSSObject = {
  pt: '0.5',
  variant: 'solid',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 'sm',
};
