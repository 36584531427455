import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  color: 'beta.300',
  fontWeight: 'bold',
  height: 'auto',
  border: 'none',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  fontSize: 'lg',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  '> p': {
    fontWeight: 'normal',
  },
  svg: {
    pt: '1',
    pos: 'relative',
    bottom: '1',
  },
};

export const FlexHeadingMiscWrapper: CSSObject = {
  zIndex: 'overlay',
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', 'row'],
    alignItems: ['flex-start', 'center'],

    '.timestamp': {
      bg: 'alpha.600',
      borderRadius: 'base',
      color: 'alpha.900',
      fontWeight: 'bold',
      fontFamily: 'body',
      p: '1',
      fontSize: '2xs',
      mb: '0.5',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
  bg: 'whiteAlpha.400',
  border: 'none',
};

export const TextSportName: CSSObject = {
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

export const TextVS: CSSObject = {
  alignSelf: 'end',
  color: 'white',
};
