import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/pyramidbet/images/onboardingBg.png';

export const WrapperFlexOnboarding: CSSObject = {
  bgImage: `url(${bg})`,
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, '4'],
  borderRadius: [null, null, '12px'],
  boxShadow:
    '0px 0px 0px 5px #FFFFFF80 inset, 0px 0px 0px 4px #0A0F13 inset, 0px 0px 0px 2px #FCD360 inset, 0px 7px 20px 0px #0000004D',
  pos: 'relative',
  w: ['100dvw', null, 'unset'],
  p: '1px',
  fontFamily: 'accent',
  borderWidth: ['0px', '0px', '2px'],
  borderColor: 'alpha.400',
  '.chakra-input': {
    color: 'white',
    fontWeight: 'normal',
    fontSize: 'xs',
    '&:focus, &:active, &:hover': {
      color: 'white',
    },
  },
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '6'],
  px: ['2', null, '6'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '500px'],
  h: '100%',
  borderRightRadius: [null, null, '12px'],
  bg: 'beta.600',
};

export const Title: CSSObject = {
  color: 'alpha.300',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  display: 'flex',
  mb: '4',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  fontSize: 'xs',
  '&&&': {
    bg: 'blackAlpha.500',
    borderRadius: 'base',
    boxShadow: '0px 1px 5px 0px #00000026 inset, 0px 1px 0px 0px #FFFFFF1A',
    border: 'none',
    color: 'white',
    fontWeight: 'normal',
    '&&:hover, &&:focus': {
      color: 'white',
      opacity: 0.95,
    },
    _placeholder: {
      color: 'white',
    },
  },
};
