import { CSSObject } from '@chakra-ui/react';

export const TextPrimary: CSSObject = {
  textTransform: 'uppercase',
  fontSize: ['xl', '2xl'],
  fontWeight: 'bold',
  fontFamily: 'accent',
  textAlign: 'center',
  color: 'beta.300',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};
export const TextSecondary: CSSObject = {
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  w: ['288px', null, '420px'],
  textAlign: 'center',
  p: {
    w: '100%',
    fontWeight: 'normal',
  },
};
export const ImageNoRaces: CSSObject = {
  w: ['100%', null, '500px'],
  borderRadius: 'md',
  filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15))',
  mb: '4',
};
