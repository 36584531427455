import { CSSObject } from '@chakra-ui/react';

export const SideNavHeading: CSSObject = {
  fontFamily: 'KHInterference',
  textTransform: 'uppercase',
  color: ['beta.500', null, null, 'white'],
  border: ['none', '1px solid #00000030'],
};

export const SideNavLink: CSSObject = {
  fontFamily: 'KHInterference',
  textTransform: 'uppercase',
  fontSize: '14px',
};

export const FlexSideLinkWrapper: CSSObject = {
  fontFamily: 'KHInterference',
  fontSize: ['xs', null, null, 'sm'],
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
