import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bg: 'rgba(0, 209, 255, 0.25)',
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  border: 'none',
  pl: '3',
  pr: '2',
  py: '4',
  mb: '2',
  color: 'white',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
  svg: {
    color: 'beta.300',
  },
};
