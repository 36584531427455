import React from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  TopNavContainer,
  TopNavWrapper,
  NavLink,
} from './styles/TopNav.styles';

import { links } from './services/AccountNavigation.config';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';

/**
 * TODO:
 *
 * NEED TO ADD NAV BACK IN FOR ACCOUNT VIEW
 */

const TopNav = () => {
  const currentPath = useLocation().pathname;
  const getActiveStatus = (href: string) => href.split('?')[0] === currentPath;

  if (IS_MOBILE_APP) return null;

  return (
    <TopNavContainer>
      <TopNavWrapper>
        {links.map(({ href, name, dataCyValue }) => (
          <NavLink
            data-cy={dataCyValue}
            to={href}
            key={name}
            isActive={getActiveStatus(href)}
          >
            <FormattedMessage id={name} />
          </NavLink>
        ))}
      </TopNavWrapper>
    </TopNavContainer>
  );
};

export default TopNav;
