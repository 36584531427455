import React from 'react';
import { Image, Link } from '@chakra-ui/react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/pyramidbet/images/logoOnboardingError.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: (
      <Link
        aria-label="aside logo Mobile"
        as={LinkDom}
        to="/"
        maxW={['70vw', '500px', '250px']}
        alignSelf="center"
      >
        <Image src={logo} />
      </Link>
    ),
    config: {
      before: 'slogan',
    },
  },
]);
