import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'alpha.300';
      }
      if (state === 'ended') {
        return 'red.500';
      }
      return 'beta.100';
    },
    color: 'blackAlpha.700',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    px: 1,
    fontWeight: 'bold',
    fontFamily: 'accent',
    fontSize: '8px',
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '17px',
  },
});

export const TextTimestamp: CSSObject = {
  fontSize: '0.625rem',
  fontWeight: 500,
  color: 'white',
};