import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bgGradient: 'linear(180deg, #FFFFFF1F, #FFFFFF0D)',
  px: 3,
  py: 2,
  mx: 2,
  mb: 2,
  borderRadius: 'md',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  boxShadow: '0px 2px 5px 0px #00000040',
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'capitalize',
};
export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'alpha.300',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  ...(isVS && { color: 'alpha.300', mx: '1', textTransform: 'none' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  color: 'blackAlpha.800',
  boxSize: '32px',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 2px 1px rgba(255, 255, 255, 0.25) inset',
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-300), var(--bc-colors-alpha-500))',
  borderRadius: 'md',
  p: '1.5',
};

export const IconChevron: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.300',
    boxSize: '5',
  },
};
