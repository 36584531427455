import { CSSObject } from '@chakra-ui/react';

export const TextCheckbox: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
};

export const buttonCloseAccountProps: CSSObject = {
  variant: 'primary',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};
