import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { 
    spacing: '1', 
    pt: '2',
    sx: {
      '&[data-isonsportsall="true"]': {
        pb: '4',
        mb: '2',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderColor: '#192B3B',
        borderRadius: 'none',
        boxShadow: '0px 1px 0px 0px #FFFFFF12',
      },
    },
  },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'md',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    lineHeight: 'tall',
    bg: 'alpha.700',
    boxShadow:
      '0px 3px 10px 0px rgba(0, 0, 0, 0.40), 0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #0C2549 inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset ',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2xs',
    sx: {
      '&.active': {
        bg: 'blackAlpha.600',
        color: 'beta.500',
        boxShadow: '0px 1px 0px 0px #000 inset',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
        svg: {
          color: 'beta.500',
        },
      },
    },
  },
};
