import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  m: '6px',
  mb: 0,
  p: 0,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  '> div': {
    '> div:last-child': {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: '1',
    },
  },
};
export const CardContainer: CSSObject = {
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(8px)',
  borderRadius: 'lg',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  p: 1.5,
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  mx: '6px',
  'p[class*="headerRow-RunnerDescription"]': {
    'span:not(:nth-child(2))': {
      color: 'white',
    },
    fontWeight: 'medium',
    fontSize: 'xs',
  },
  'p[class*="headerRow-RunnerDetails"]': {
    fontSize: 'xs',
  },
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  display: 'flex',
  alignItems: 'start',
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
};
export const dividerExoticProps: DividerProps = {
  display: 'none',
};

export const dividerExoticLastProps: DividerProps = {
  borderTop: '1px dashed',
  borderTopColor: 'rgba(0, 0, 0, 0.25)',
  borderBottom: '1px solid',
  borderBottomColor: 'rgba(255, 255, 255, 0.08)',
};
