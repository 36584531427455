import { CSSObject } from '@chakra-ui/react';
import tokens from '../../../../../../assets/pyramidbet/images/token.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxBallance: CSSObject = {
  borderX: '2px',
  borderColor: 'white',
  '> div:first-of-type': {
    color: 'alpha.300',
    fontSize: 'sm',
    '> p': {
      fontWeight: 'semibold',
    },
  },
  '> div:last-of-type': {
    color: 'white',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'semibold',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
      },
    },
  },
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  textTransform: 'uppercase',
  w: 'full',
  mt: '2',
  borderRadius: 'base',
  sx: {
    '& svg': {
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
      mb: '1',
    },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  fontSize: '13px',
  bg: 'white',
  color: 'gray.700',
  borderTop: '1px',
  borderColor: 'gray.300',
  fontFamily: 'accent',
  '&& > p': { transition: 'all 0.2s ease-in-out' },
  ':first-child': {
    borderTop: 'none',
  },
  '&:hover, &:active': {
    bg: 'gray.200',
  },
});

export const BoxMascotImage: CSSObject = {
  bgImage: `url(${tokens})`,
  bgSize: '160px 118px',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '-1',
  display: ['none', null, null, null, 'block'],
  w: '32',
  h: '24',
  pos: 'absolute',
  top: '0',
  left: '-2',
};

export const PopoverHeaderQA: CSSObject = {
  bg: 'beta.700',
  borderTop: '2px',
  borderX: '2px',
  borderColor: 'white',
  borderBottom: 'none',
  borderTopRadius: 'md',
  pb: '0',
  color: 'white',
  lineHeight: 'normal',
};

export const PopoverContentQA: CSSObject = {
  boxShadow: '2xl',
  fontFamily: 'accent',
  top: -2,
  _focus: {
    boxShadow: '2xl',
  },
};

export const PopoverBodyQA: CSSObject = {
  bgGradient: 'linear(to-b, beta.700, beta.900)',
};

export const FlexBallanceItem: CSSObject = {
  p: {
    _last: { fontWeight: 'extrabold' },
  },
};

export const TextBalanceValue: CSSObject = {
  color: 'alpha.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'xs',
  h: 'unset',
};
