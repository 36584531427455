import { CSSObject } from '@chakra-ui/react';

export const ButtonSaveChanges: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const FormWrapper: CSSObject = {
  color: 'white',
  bg: 'transparent',
  p: '2',
  '.chakra-checkbox__label': {
    color: 'white',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    border: '1px solid',
    borderColor: 'gray.300',
    width: '1rem',
    height: '1rem',
    borderRadius: 'base',
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _hover: {
      bg: 'alpha.100',
      boxShadow: '0px 0px 8px 0px rgba(43, 108, 176, 0.40)',
    },
    _checked: {
      boxShadow: 'none',
      color: 'blue.600',
      _hover: {
        bg: 'alpha.100',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
  'button[data-cy="promotionsNotificationsSubmit"]': {
    position: 'relative',
    borderRadius: 'md',
    border: 'none',
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'beta.500',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    _after: {
      content: `''`,
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      inset: 0,
      zIndex: 8,
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
      borderRadius: 'md',
      m: '2px',
      boxShadow:
        '0px 0px 0px 1px rgba(92, 68, 19, 1) inset, 0px 1px 0px 1px rgba(255, 255, 255, 0.5) inset',
    },
    _hover: {
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
      color: 'beta.700',
      _after: {
        bgGradient: 'linear(180deg, beta.400 0%, beta.400 100%)',
      },
    },
    ':active, &[data-active=true]': {
      bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      color: 'beta.300',
      textShadow: '0px 0px 8px rgba(255, 210, 123, 0.5)',

      _after: {
        display: 'none',
      },
    },
    '&[data-active=false]': {
      bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    },
    _disabled: {
      opacity: 0.4,
      pointerEvents: 'none',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },

    '&&&': {
      '& > svg, & > span': { zIndex: 9 },
    },
  },
};
