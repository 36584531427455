import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/magnumbet/images/onboarding-aside.png';
import bgMobile from '@/assets/magnumbet/images/onboarding-aside-mb.png';

export const WrapperAside: CSSObject = {
  bg: [`url(${bgMobile})`, null, `url(${bgDesktop}), rgba(36, 24, 54, 1)`],
  bgSize: ['cover', null, 'contain'],
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['beta.300', null, 'unset'],
  h: ['50vw', '55vw', 'full'],
  borderLeftRadius: [null, null, '10px'],
  borderRightRadius: ['unset'],
  flex: 1,
  a: {
    h: 52,
    img: {
      position: 'absolute',
      maxW: ['58vw', '265px', '220px'],
      top: '100px',
      left: ['200px', '280px', '150px'],
      transform: 'translate(-50%, -50%)',
    },
  },
  fontFamily: 'accent',
};
