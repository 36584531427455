import { ButtonProps, CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  borderBottomColor: 'gray.300',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};

export const DepositButton: CSSObject = {
  border: 'none',
  borderRadius: 'base',
  bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    color: 'beta.900',
    boxShadow:
      '0px 2px 8px 0px rgba(0, 0, 0, 0.20) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.50) inset',
  },
  _active: {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px -1px 0px 0px rgba(255, 255, 255, 0.16) inset',
    color: 'beta.500',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  _disabled: {
    bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30) inset',
    color: 'white',
    textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
    opacity: 0.4,
  },
};

export const CancelButton: CSSObject = {
  variant: 'secondary',
};

export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};

export const DepositLimitContainer: CSSObject = {
  bg: 'white',
  borderRadius: 'base',
  fontFamily: 'accent',
  p: '4',
  color: 'gray.600',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.5) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  fontWeight: '700',
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.100, alpha.500)',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    px: '4',
    fontWeight: 'bold',
  },
  '&&': {
    '.chakra-input': {
      bg: 'alpha.700',
      boxShadow: 'none',
      border: '2px solid',
      borderLeft: 'none',
      borderColor: 'beta.300',
      _hover: {
        bg: 'alpha.800',
        border: '2px solid',
        borderLeft: 'none',
        borderColor: 'beta.300',
      },
      _focus: {
        bg: 'alpha.800',
        border: '2px solid',
        borderLeft: 'none',
        borderColor: 'beta.300',
      },
      _placeholder: {
        color: 'gray.500',
      },
    },
  },
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalDivider: CSSObject = {
  display: 'none',
};

export const buttonRemoveProps: CSSObject = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'white',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: 'none',
  _hover: {
    bg: 'transparent',
    border: '2px',
    borderColor: 'beta.300',
    color: 'beta.300',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(180deg, alpha.800 0%, alpha.800 100%)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: '0px 0px 8px rgba(255, 210, 123, 0.5)',
    border: 'none',
  },
  '&[data-active=false]': {
    bg: 'transparent',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    borderColor: 'alpha.100',
    color: 'alpha.100',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  textTransform: 'uppercase',
  fontSize: 'sm',
  w: '50%',
};

export const buttonConfirmModalProps: ButtonProps = {
  variant: 'solid',
  textTransform: 'uppercase',
  fontSize: 'sm',
  w: '50%',
};

export const LimitValue: CSSObject = {
  fontWeight: 'normal',
};
