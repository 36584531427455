import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  'button[data-cy="updateDepositLimitButton"]': {
    variant: 'primary',
    fontWeight: 'bold',
    color: 'blackAlpha.800',
    textTransform: 'uppercase',
    mt: '1',
  },
};
