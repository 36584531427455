import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
    px: ['2', null, '0'],
    pb: '3',
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: 'heading',
    color: 'beta.300',
    textTransform: 'uppercase',
    pb: ['1', null, '0'],
    pl: '0',
    pr: ['3', null, '0'],
  },
  headerIconButton: {
    color: 'white',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    fontFamily: 'heading',
    color: 'white',
    fontSize: '22px',
    textTransform: 'uppercase',
    sx: {
      '+ *': {
        whiteSpace: 'unset',
      },
    },
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'beta.500',
    h: '1.1em',
    ml: '2',
    mb: '0.5',
    w: 'auto',
  },
  scrollButtonGroup: {
    h: '10',
    variant: 'filter',
    boxShadow: 'none',
    w: 'full',
  },
  wrapperScrollSgm: {
    gap: '2',
    alignItems: 'center',
    display: 'flex',
    w: 'full',
  },
  toMarketButton: {
    sx: {
      ':active': {
        _after: {
          pos: 'unset',
        },
      },
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
    _last: { mr: 2 },
  },
  toMarketButtonV2: {
    variant: 'tab',
    h: 'full',
    sx: {
      '&[data-active]': {
        color: 'beta.300',
        fontWeight: 'bold',
        borderBottom: '3px solid',
        borderBottomColor: 'beta.300',
      },
    },
    minW: 'fit-content',
    _last: { mr: 2 },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    border: '1px solid',
    borderRadius: 'md',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    overflow: 'hidden',
    sx: {
      '&&': {
        borderColor: 'whiteAlpha.300',
      },
    },
  },
  accordionButton: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    fontFamily: 'accent',
    p: '2',
    mx: '1',
    w: '99%',
    pos: 'relative',
    textAlign: 'left',
    textTransform: 'uppercase',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
    fontFamily: 'accent',
    textTransform: 'uppercase',
    fontSize: 'xs',
    fontWeight: 'bold',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    variant: 'solid',
    px: '3',
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: 'normal',
  },
  clearButton: {
    variant: 'outline',
    ml: '2',
    px: '3',
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: 'normal',
  },
  countdown: {
    sx: {
      '&&&.state-prior': {
        textTransform: 'capitalize',
        lineHeight: 'initial',
      },
    },
  },
  scrollButtonGroupV2: {
    variant: 'tab',
    flex: 1,
    position: 'relative',
    minH: '34px',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    sx: {
      '> div:first-child': {
        pl: '3',
      },
      ':has(+ button)': {
        w: 'calc(100% - 90px)',
      },
    },
  },
  sgmLogoV2: {
    w: '3em',
    h: '1.5em',
  },
  sgmBtn: {
    sx: {
      px: '3',
      'svg[class*="StyledIconBase"]': {
        w: '1.5em',
        h: '1.5em',
        color: 'blackAlpha.800',
      },
    },
  },
  sgmBtnActive: {
    sx: {
      _before: {
        display: 'none',
      },
      '&&': {
        px: '3',
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      },
      svg: {
        w: '3em',
        color: 'beta.300',
      },
      'svg[class*="StyledIconBase"]': {
        w: '1.5em',
        h: '1.5em',
        fill: 'beta.300',
        filter: 'none',
      },
    },
  },
};
