import { MarketLayoutStylesSchema } from './MarketLayout.styles';

export const matchDetailsStyles: MarketLayoutStylesSchema = {
  headerText: {
    flex: '1',
    color: 'white',
    align: 'center',
    fontSize: '2xs',
    fontWeight: 'bold',
  },
  rowWrapper: {
    mx: '0.5',
    px: '2',
    py: '2',
    alignItems: 'center',
    display: 'flex',
    fontSize: 'xs',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    borderColor: 'transparent',
    borderTopWidth: undefined,
    gap: '2',
    pos: 'relative',
    color: 'white',
    _before: {
      borderColor: 'blackAlpha.400',
      borderBottomWidth: '1px',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      w: 'full',
      left: '0',
      pos: 'absolute',
      top: '-1px',
    },
  },
  rowWrapperFirst: {
    borderTopWidth: '1px',
    px: '2',
    _before: {
      display: 'none',
    },
    sx: {
      button: {
        minW: '54px',
        px: 2,
        lineHeight: 'normal',
      },
    },
  },
  rowWrapperWithBtn: {
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderTopWidth: '1px',
  },
  tab: {
    bg: 'alpha.300',
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'extrabold',
    _notFirst: { borderLeftWidth: '1px', borderLeftColor: 'blackAlpha.400' },
    _selected: {
      bg: 'beta.300',
      borderBottomColor: 'transparent',
      color: 'blackAlpha.800',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    },
  },
};
