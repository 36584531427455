import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/pyramidbet/images/background/bgTop.png';
import left from '@/assets/pyramidbet/images/background/borderLeft.png';
import right from '@/assets/pyramidbet/images/background/borderRight.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), url(${left}), url(${right}), linear-gradient(180deg, var(--bc-colors-beta-700), var(--bc-colors-beta-900))`,
          backgroundPosition: 'top, left top, right top, center',
          backgroundRepeat: 'no-repeat, repeat-y, repeat-y',
          backgroundSize: 'contain, 10px 590px, 10px 590px',
          backgroundAttachment: 'fixed',
          '@media (max-width: 767px)': {
            background: `linear-gradient(180deg, var(--bc-colors-beta-700), var(--bc-colors-beta-900))`,
          },
        },
      }}
    />
  );
}
