import { CSSObject } from '@emotion/react';
import { TDot, TDotContainer } from './Carousel.styles';

export const Dot = ({ selected }: TDot): CSSObject => ({
  bg: selected ? '#FCD360' : 'whiteAlpha.200',
  w: '38px',
  h: '3px',
});

export const DotContainer = ({
  numberOfSlides,
  index,
}: TDotContainer): CSSObject => ({
  bg: 'transparent',
  //   borderLeftRadius: index === 0 ? 'md' : '0',
  pl: index === 0 ? '0.5' : '0',
  //   borderRightRadius: index === numberOfSlides - 1 ? 'md' : '0',
  pr: index === numberOfSlides - 1 ? '0.5' : '0',
});

export const CarouselWrapper: CSSObject = {
  '& .control-dots ': {
    bg: 'transparent',
  },
};
