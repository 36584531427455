import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  'button[data-cy="updateDepositLimitButton"]': {
    position: 'relative',
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.400, beta.300)',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
    color: 'blackAlpha.800',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    zIndex: 1,
    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '2px',
      left: '2px',
      width: 'calc(100% - 4px)',
      height: 'calc(100% - 4px)',
      zIndex: '3',
      borderRadius: 'base',
      bgGradient: 'linear(to-b, beta.300, beta.400)',
    },
    _hover: {
      bgGradient: 'linear(to-b, beta.500, beta.500)',
      _before: {
        bgGradient: 'linear(to-b, beta.200, beta.200)',
      },
    },
    ':active, &[data-active=true]': {
      bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      color: 'alpha.300',
      textShadow: 'none',
      _before: {
        display: 'none',
      },
    },
    _disabled: {
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },
};
