import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logoDesktop from '@/assets/bet575/images/logo/logo.png';
import logoMb from '@/assets/bet575/images/logo/logoMb.svg';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image src={logoDesktop} alt={THEME_NAME.bet575} mx="auto" mb="4" />
      </Show>
      <Hide above="lg">
        <Image src={logoMb} alt={THEME_NAME.bet575} h="14" />
      </Hide>
    </Link>
  );
}
