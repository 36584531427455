import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFutbol } from '@fortawesome/pro-light-svg-icons';
import { IconToggle } from '../../styles/Toggle.styles';

export default function IconSport() {
  const [searchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  return (
    <IconToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
      <FontAwesomeIcon icon={faFutbol} />
    </IconToggle>
  );
}
