import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'beta.500',

      background: {
        bgGradient: [
          'linear(to-b, beta.600, beta.600)',
          null,
          'linear(to-b, beta.400, beta.500)',
        ],
        display: 'flex',
        flexDir: 'column',
        borderRadius: 'lg',
        px: ['0', null, null, '1.5'],
        py: ['0', null, null, '1'],
        mb: ['0', null, null, '4'],
        boxShadow:
          '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(10, 15, 19, 1) inset, 0px 0px 0px 5px #4DB0C2 inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        borderRadius: 'none',
        background: 'alpha.500',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.40)',
        gap: '2',
        maxW: ['100%', '370px', null, null],
        backdropFilter: 'blur(8px)',
      },
      drawerBody: {
        label: 'drawerBody',
        '&::-webkit-scrollbar': { display: 'none' },
        '&&': {
          px: '2',
        },
      },
      icon: {
        ...base.icon,
        boxSize: '34px',
        mr: ['1', null, '2.5'],
        bgGradient: ['transparent', null, 'linear(to-b, alpha.500, alpha.300)'],
        boxShadow: [
          'unset',
          null,
          '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(10, 15, 19, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.5) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
        ],
        filter: [
          'drop-shadow(0px 0px 9px rgba(28, 213, 255, 0.5))',
          null,
          'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.3))',
        ],
        color: ['alpha.200', null, 'blackAlpha.800'],
        p: ['1.5', null, '2'],
        borderRadius: 'md',
      },
      headerSX: {
        cursor: 'default',
        color: 'alpha.300',
        fontSize: 'xs',
        height: 'auto',
        mb: '0',
        py: 2,
        mt: ['3', null, '0'],
        fontWeight: 'bold',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
        textShadow: ['none', null, '0px 0px 8px rgba(254, 196, 77, 0.5)'],
        pointerEvents: 'none',
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: [null, null, null, 'md'],
        mb: '0',
      },
      link: {
        ...base.link,
        textTransform: 'uppercase',
        fontSize: 'xs',
        fontWeight: 'bold',
        height: ['40px', null, '48px'],
        color: 'white',
        fontFamily: 'accent',
        textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
        background: 'transparent',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
        py: ['1', null, '1.5'],
        px: ['1.5', null, '2'],
        ml: '0',
        '&&': {
          mb: '0',
        },
        ':last-child': {
          borderBottom: 'none',
          boxShadow: 'unset',
          mb: '0',
        },

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          color: 'alpha.300',
          textShadow: '0px 0px 10px rgba(254, 196, 77, 0.8)',
          bgGradient: ['unset', null, 'linear(to-b, #4193A5, #357A88)'],
          '.icon, .svgIcon': {
            boxShadow: [
              'unset',
              null,
              '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
            ],
            bgGradient: [
              'unset',
              null,
              'linear(to-b, blackAlpha.600, blackAlpha.600)',
            ],
            color: 'alpha.300',
            filter: [
              'drop-shadow(0px 0px 6px rgba(254, 196, 77, 0.6))',
              null,
              'drop-shadow(0px 0px 8px rgba(223, 177, 45, 0.6))',
            ],
          },
        },

        ':active': {
          background: 'alpha.700',
          opacity: '1',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
        },

        '> a, .dropDownIcon, a': {
          color: 'alpha.100',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '2'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        h: '45px',
        mb: '1.5',
        '.dropDownInner': {
          px: '2.5',
          color: 'beta.200',
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
          textShadow: '0px 0px 12px #FF6E20',
          'svg:not(.dropDownIcon)': {
            fill: 'gamma.400',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'gamma.500',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
