import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    w: '100%',
    my: 2,
  },
  buttonCashOutDesc: {
    w: '100%',
    height: '29px',
    fontWeight: 'bold',
    fontSize: 'sm',
    textTransform: 'uppercase',
    color: 'blackAlpha.800',
    paddingTop: '1px',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    sx: {
      '.cashoutValue': {
        color: 'blackAlpha.800',
      },
      '&:hover': {
        '.cashoutValue': {
          color: 'blackAlpha.800',
        },
      },
      '&:active': {
        '.cashoutValue': {
          color: 'blackAlpha.800',
        },
      },
    },
  },
  buttonCashOutAmount: {
    variant: 'primary',
    height: '29px',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    _hover: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
      color: 'beta.900',
    },
  },
  flexOddsBoostBanner: {
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.15)',
    sx: {
      svg: {
        color: 'beta.400',
      },
    },
  },
  textOddsBoost: {
    color: 'beta.400',
    fontWeight: 'bold',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'accent',
    pt: '0.5',
  },
  iconOddsBoost: {
    fill: 'beta.400',
    color: 'beta.400',
  },
  vstackBetsWrapper: {
    w: '100%',
    ml: 'inherit',
  },
  buttonGroupWrapper: {
    w: '100%',
  },
  iconCashOut: {
    fill: 'currentColor',
    mt: 1,
  },
  cashoutText: {
    color: 'currentColor',
    pt: '1',
  },
  cashoutValue: {
    lineHeight: 'normal',
    pt: '0.5',
  },
};
export const LinkVenueName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  ml: '26px',
};
export const RaceTime: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
  ml: '26px',
};
export const BonusBetCheckMark: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const BonusBetText: CSSObject = {
  color: 'beta.100',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  bg: 'blackAlpha.500',
  borderRadius: 'base',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
  px: '2',
  py: '4px',
  lineHeight: 'normal',
};
export const BetReturn: CSSObject = {
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
  backdropFilter: 'blur(4px)',
  color: 'white',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
};
export const BoxBetReturnValue: CSSObject = {
  py: '0.5',
  p: '1',
  fontWeight: 'bold',
  color: 'beta.500',
};
export const BoxBetReturnText: CSSObject = {
  my: '1',
  px: '1',
  borderRight: '1px solid',
  borderRightColor: 'whiteAlpha.400',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  color: 'orange.500',
  bg: 'white',
  borderColor: 'white',
};
