import { CSSObject, FlexProps } from '@chakra-ui/react';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  py: '2',
  px: '1',
  mx: '1',
  borderRadius: 'base',
  color: 'white',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const TextSubtitle: CSSObject = {
  color: 'alpha.200',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});

export const flexEventProps: FlexProps = {
  gap: '1',
  pb: '0',
};

export const flextEventSubTitleProps: FlexProps = {
  pt: '1',
};
