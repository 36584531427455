import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  fontFamily: 'accent',
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'gray.300',
  borderRadius: 'sm',
  color: 'gray.600',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const LinkRaceContainer: CSSObject = {
  bgGradient: 'linear(180deg, beta.600, beta.800)',
  color: 'white',
  fontSize: '13px',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px #00000040',
  mb: '2.5',
  transition: 'all .2s ease-in-out',
  _hover: {
    opacity: 0.8,
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  color: 'alpha.300',
  textShadow: '0px 2px 5px 0px #00000040',
  fontSize: 'base',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
  fontSize: '0.8125rem',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.300',
  filter: 'drop-shadow(0px 3px 4px #00000033)',
};

export const BoxColumn: CSSObject = {
  mb: ['2', null, '0'],
};
