import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    bg: 'blackAlpha.500',
    w: ['100% + 8px', null, '100%'],
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
    borderRadius: ['none', null, 'md'],
    minH: '9',
    h: 'auto',
    px: '4',
    mx: ['-2', null, '0'],
    my: '3',
  },
  link: {
    boxShadow: 'none',
    border: 'none',
    fontSize: 'sm',
    textShadow: 'none',
    fontWeight: 'bold',
    lineHeight: '1.7',
    borderRadius: 'none',
    fontFamily: 'accent',
    h: '10',
    color: 'white',
    textTransform: 'uppercase',
    borderBottom: '3px solid',
    borderBottomColor: 'transparent',
    px: '3',
    pt: '2',
    pb: '1',
    sx: {
      '&.active': {
        color: 'beta.300',
        border: 'none',
        borderBottom: '3px solid',
        borderBottomColor: 'beta.300',
      },
    },
  },
};
