export const colors = {
  alpha: {
    100: '#FFE3AB',
    200: '#FFD47B',
    300: '#FEC44D',
    400: '#E1A62D',
    500: '#EC9734',
    600: '#C97A1D',
    700: '#AC6613',
    800: '#884D09',
    900: '#573003',
  },
  beta: {
    100: '#8ED7E7',
    200: '#62B4C6',
    300: '#408C9C',
    400: '#347988',
    500: '#295963',
    600: '#295963',
    700: '#1D4A52',
    800: '#143D45',
    900: '#0A1D21',
  },
};
