import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: '0',
  '&&': { px: ['0', null, null, '2'], pb: ['0', '1', null, '0'] },

  '.subTabBar ': {
    bg: 'beta.300',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.1)',
    borderTopRadius: 'none',
    mt: 0,
  },
});
export const TextTitle = (): CSSObject => ({
  mt: 0,
  fontWeight: 'bold',
  fontSize: '22px',
  textTransform: 'uppercase',
  color: 'beta.300',
  fontFamily: 'heading',
  mx: '0',
  px: ['10px', null, null, '0'],
  pt: '0',
});
export const BoxHeading: CSSObject = {
  mb: '-1',
};
export const BoxWrapper: CSSObject = {
  borderRadius: 'lg',
  minHeight: '0',
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.25) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
  bg: 'alpha.400',
  mx: ['1', null, '0'],
  px: ['0', null, '2'],
  fontFamily: 'accent',
};
export const WrapperContainer: CSSObject = {
  px: ['2', null, null, '2'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
};
