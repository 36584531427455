import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'epsilon.400',
  borderX: '2px',
  borderTop: '2px',
  borderColor: 'epsilon.200',
  borderTopColor: 'epsilon.200',
  borderTopRadius: 'md',
  w: '100%',
  mt: ['unset', '2'],
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  pb: '0',
  '&&': { px: '4', pb: ['1', null, '2'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
  mr: '2',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: [null, null, null, 'base'],
  minHeight: '0',
};

export const WrapperContainer: CSSObject = {
  px: '0',
  bg: 'epsilon.400',
  borderX: '2px',
  borderBottom: '2px',
  borderColor: 'epsilon.200',
  borderBottomColor: 'epsilon.200',
  borderTopRadius: 'none',
  borderBottomRadius: 'md',
  color: 'delta.50',
  boxShadow: '0px 4px 0px #6E7373',
  mb: '2',
};
