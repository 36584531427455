import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'md',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  _last: {
    mb: '0',
  },
};
export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
};
export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'normal',
  position: 'relative',
  top: '-0.5',
};
export const FlexDeductionsContainer: CSSObject = {
  mx: '2',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
  alignItems: 'center',
};
export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    _notFirst: {
      borderLeft: '1px solid',
      borderColor: ['transparent', null, 'whiteAlpha.200'],
      pl: ['0', null, '2'],
    },
  },
};
export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
};
export const TextDeductions: CSSObject = {
  color: 'beta.300',
  w: 'max-content',
};
export const TextScratchedInfo: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const ScratchedText: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
};

export const RunnerPropositionWrapper: CSSObject = {
  columnGap: '1.5',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  boxSize: '7',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.3)',
};
