export const zh = {
  'home.horsesheading': '赛马',
  'home.greyhoundsheading': '赛狗',
  'home.haveAPunt': '查看比赛数据卡',
  'home.harnessheading': '赛马车',
  'generic.privacyPolicy': '隐私政策',
  'generic.refresh': '刷新',
  'generic.stake': '注金“',
  'generic.password:': '密码',
  'generic.home': '首页',
  'generic.racing': '首页',
  'generic.sport': '体育',
  'generic.all': '所有板球',
  'generic.termsconditions': '条款和条件',
  'generic.promoPolicyTandC': '促销活动条款和条件',
  'generic.responsiblegambling': '负责任博彩',
  'generic.responsiblegamblingtools': '负责任的博彩工具',
  'generic.logout': '登出',
  'sidenav.responsible': '负责任博彩',
  'sideNav.promotions': '促销活动',
  'sidenav.home': '首页',
  'sidenav.deposit': '存入资金',
  'sidenav.contactus': '联系我们',
  'sidenav.viewall': '查看全部',
  'sidenav.myaccount': '我的账户',
  'betslip.betslipmodal.totalstake': '总注额:',
  'legal.responsible.tagline4':
    'What are you prepared to lose today? Set a deposit limit.',
  'legal.responsible.caption': 'For free and confidential support call 1800 858 858 or visit <cta>gamblinghelponline.org.au</cta>',
  'maintenance.heading': 'maintenance.heading translation to go here',
  'maintenance.error': '我们目前正在对网站进行维护。',
  'onboarding.locationnotallowed.header': '龙在九天之外。',
  'onboarding.locationnotallowed.subheader': 'onboarding.locationnotallowed.subheader translation goes here',
  'onboarding.step2.titleInputLabel': '称谓',
  'onboarding.locationnotallowed.bodycopy': `{themeName} 仅面向澳大利亚客户提供服务。如果您身在澳大利亚，但认为错误地看到了此页面，请通过 service@goldenbet888.com.au 联系我们。`,
  'onboarding.locationnotallowed.registration': `{themeName} 是一家经澳大利亚维多利亚州博彩与赌场管理委员会注册的博彩公司，同时也是维多利亚州博彩业协会的成员。`,
  'error.heading': `身陷龙潭之中。`,
  'error.description': `<bold>我们对此深表歉意。已有工作人员正在处理此问题。</bold>`,
  'onboarding.register.createAccount': '创建账户',
  'account.overview': '概览',
  'account.sidenav.mybets': '我的投注',
  'account.sidenav.deposit': '存款',
  'account.sidenav.withdraw': '提款',
  'account.sidenav.limit': '存款限额',
  'account.sidenav.transactions': '交易',
  'account.sidenav.account': '账户',
  'account.sidenav.settings': '设置',
  'account.overview.email': '邮箱地址',
  'account.overview.firstName': '名',
  'account.overview.middleName': '中间名',
  'account.overview.lastName': '姓',
  'account.overview.mobile': '手机',
  'account.overview.residentialAddress': '居住地址',
  'onboarding.step2.addressinputmanualentry': '看不到您的地址？请手动输入',
  'onboarding.step2.dobinputlabel': '出生日期',
  'onboarding.step1.ageacknowledgement': '点击“立即加入”，即表示我确认我已年满 18 周岁，目前未处于自我禁止状态，已阅读并同意 Golden888 的条款和条件以及隐私政策。',
  'onboarding.step1.promotionacknowledgement': '我同意接收 Golden888 的信息和推广材料，并且我了解自己可以随时取消此服务。',
  'onboarding.step1.login': '已有账户？',
  'onboarding.step1.logincta': '登录',
  'account.depositlimit.setlimit.subheading': '存款限额能帮助您设定预算，并且控制可以从个人资金中用于投注的金额。',
  'onboarding.depositLimit.completeSignup': '继续',
  'onboarding.step1.emailinputempty': '需要电子邮箱地址',
  'onboarding.forgotpassword.loginCta': '返回登录页面',
  'onboarding.forgotpassword.button': '发送重置链接',
  'onboarding.forgotpassword.successtext': '我们已向 user@gmail.com.au 发送了重置链接',
  'helpcentre.promotions.promotions': '促销活动',
  'sideNav.quickLinks': '快速链接',
  'betslip.betslipmodal.types.single': '单注',
  'racing.generic.win': '奖金',
  'racing.featured.moreRaces': '更多赛事',
  'racing.featured.race': '精选赛事',
  'racing.exotics.totalstake': '总注额',
  'racing.racedetails.runnerheading': '参赛者',
  'racing.racedetails.fixedheading': '固定',
  'racing.errorMessages.noRacesAvailable': '当前没有可用的竞速',
  'racing.errorMessages.noRacesAvailableSubtext':
  '请考虑选择其他日期再试一次。',
  'racing.raceDetails.track': '赛道：',
  'racing.raceDetails.weather': '天气：',
  'racing.raceDetails.distance': '距离：',
  'racing.generic.flucs': '波动',
  'racing.generic.insights': '分析',
  'racing.generic.tote': 'TOTE',
  'racing.raceDetails.toteWinHeading': '获胜 MD',
  'racing.raceDetails.totePlaceHeading': '排名 MD',
  'racing.mysteryBet.rollover': '滚入',
  'racing.mysteryBet.viewInMyBets': '在我的投注中查看',
  'betSlip.potentialReturns': '预计回报:',
  'betslip.betslipmodal.types.multi': '过关投注',
  'betslip.betslipbetcard.multis.multiaddmorecta': '要构建过关投注，请添加更多选项以增加您的潜在回报。',
  'footer.copyright': '版权所有 © {themeName}',
  'footer.ourpartners': '我们的合作伙伴',
  'footer.weaccept': '我们接受',
  'footer.quicklinks': '快速链接',
  'account.overview.myAccount': '我的账户',
  'account.settings.terms': '条款和条件',
  'account.settings.privacy': '隐私政策',
  'header.betslipBtn.desc': '投注单',
  'contactus.title': '帮助与支持',
  'betSlip.estPayout': '预计返还:',
  'betslip.betslipmodal.placebets': '投注',
  'footer.subheading': '必须年满 18 岁',
  'contactus.phoneinternational': '(国际)',
  'generic.winplace': '取胜/名次',
  'betslip.betslipbetcard.combos': '组合',
  'betslip.betslipmodal.boxed': '合票',
  'mysteryBet.rolledOver': '已滚入',
  'mysteryBet.viewInMyBets': '在我的投注中查看',
  'generic.sortBy': '排序方式',
  'generic.sortBy.time': '时间',
  'generic.upcoming': '近期赛事',
  'account.deposit.depositMatchPromoBannerHeader':
  '您有一笔存款匹配礼金可用！',
  'account.deposit.depositMatchPromoBannerText': '我们将为您的下一笔最高 50 澳元存款提供额外 50% 用于投注的匹配礼金。最高 25 澳元的礼金投注额！',
  'account.deposit.depositMatch.activate': '立即激活',
  'account.deposit.labels.selectdeposit': '信用卡/借记卡存款',
  'account.deposit.labels.amount': '金额',
  'account.deposit.labels.minimumdepositamount':
    '最低存款金额为 10 澳元',
  'account.deposit.buttons.addcardanddeposit': '立即添加银行卡并存款',
  'generic.close': '关闭',
  'account.deposit.confirmation.complete': '存款完成',
  'betslip.betslipmodal.emptybetslipsubheading':
  '您的投注单为空。如果要开始，请添加您的竞速和体育选项。',
  'betslip.betslipmodal.emptybetslipheading': '就像一块幸运饼干，其中的信息静待您来揭示。',
  'account.takeabreak.importantdetailslogindesc':
  '休息结束后，您立即就可以重新登录您的账户。',
  'account.takeabreak.importantdetailsemailtitle': '1. 电子邮件和提醒',
  'account.takeabreak.importantdetailslogintitle': '2. 登录',
  'account.lifetimeexclusion.importantdetailsalertsdesc':
  '您将不再收到 {themeName} 发送的电子邮件和其他提醒。这些将在您的休息确认开始后 24 小时内终止。',
  'account.lifetimeexclusion.importantdetailstitle': '重要详细信息',
  'account.lifetimeexclusion.confirm':
  '按下“继续”，即表示我理解：',
  'legal.responsible.section.takeaction.lifeexclusion.button':
    'Lifetime Exclusion',
  'account.lifetimeexclusion.importantdetailsbalancedesc':
  '您 {themeName} 账户中的任何资金将自动转入您最后一次使用的提款方式。如果您之前从未提款或希望将这些资金提取到其他账户，请致电 1800 888 888 联系客户服务。',
  'account.lifetimeexclusion.importantdetailsbalancetitle':
  '1. {themeName} 余额',
  'account.takeabreak.importantdetailstitle': '重要详细信息',
  'account.lifetimeexclusion.confirmstepfunds':
  '我 {themeName} 账户中的所有资金将转入我最后一次使用的付款方式',
  'account.takeabreak.information':
  '您可以选择短暂休息 1 至 30 天，给自己一些远离投注的时间。您的休息将在确认后立即开始。',
  'account.takeabreak.pagetitle': '休息一下',
  'account.lifetimeexclusion.confirmlifetimeexclusion':
  '禁止条款将立即生效',
  'account.lifetimeexclusion.informationtext3':
  '您将来也不能开设新账户。您的禁止将在确认后立即开始。继续操作后，您将无法再使用 {themeName} 投注或在 Golden888 开设有效账户。',
  'account.lifetimeexclusion.information':
  '无期限自我禁止 如果您正在考虑限制投注 您可以选择短暂休息 (1 天至 30 天) 或长期休息 (6 个月至 5 年）。"',
  'account.closeaccount.currentpassword': '当前密码',
  'account.closeaccount.confirmpendingbets':
    '任何未决投注将保持有效，任何未决提款将得到处理。',
  'account.closeaccount.confirmpendingbalance':
  '账户中的任何余额可通过向 service@golden888.com.au 发电子邮件联系我们的客户服务团队进行提现。',
  'account.closeaccount.confirmopenaccounts':
  '您同意在账户关闭期间不再开设任何账户。您可以随时通过 service@golden888.com.au 联系我们的客户服务团队，重新开启您的账户。',
  'account.closeaccount.text2':
  '如果您只想更改营销偏好（包括取消订阅），请点击此处。',
  'account.closeaccount.text1': '您可以在此页面关闭您的账户',
  'legal.responsible.section.takeaction.deactivate.one': '关闭您的账户',
  'generic.gamblingvic': '澳大利亚维多利亚州博彩与赌场管理委员会',
  'legal.responsible.titles.getintouch': '联系我们',
  'legal.responsible.section.findoutmore.three': '通过电话、面对面或在线与 Gamblers Help 友好的工作人员聊天。这类聊天免费、保密、专业并且提供全天候服务。',
  'legal.responsible.section.findoutmore.two': '不确定您的博彩习惯？',
  'legal.responsible.subheading':
  '我们致力于保护和帮助我们的客户控制他们的博彩行为。我们提供多种支持选项以满足不同需求。',
  'legal.responsible.section.findoutmore.one': `与 Gambler's Help 的专业人员聊天`,
  'account.currentlimit.limitperioduntil': '设置限额时长',
  'account.transactions.activitystatements.pagetitle': '活动对账单',
  'account.transactions.bonusbets.pagetitle': '礼金投注',
  'account.transactions.activitystatements.title': '月度活动对账单',
  'Fixed Win': '固定赢',
  'sports.allsports': '所有板球',
  'raceType.Thoroughbreds': '赛马',
  'raceType.Greyhounds': '赛狗',
  'raceType.Harness': '赛马车',
  'racing.tabbar.yesterday': '昨天',
  'racing.tabbar.nexttojump': '下一场比赛',
  'racing.tabbar.today': '今天',
  'racing.tabbar.tomorrow': '明天',
  'racing.tabbar.Monday': 'Monday',
  'racing.tabbar.Tuesday': 'Tuesday',
  'racing.tabbar.Wednesday': 'Wednesday',
  'racing.tabbar.Thursday': 'Thursday',
  'racing.tabbar.Friday': '星期五',
  'racing.tabbar.Saturday': '星期六',
  'racing.tabbar.Sunday': 'Sunday',
  'filters.location.ausnz': 'Aus/NZ',
  'filters.location.intl': 'Int\'l',
  'racing.raceDetails.winHeading': 'Win',
  'racing.raceDetails.winHeadingFO': 'Win FO',
  'racing.raceDetails.placeHeading': 'Place',
  'racing.raceDetails.placeHeadingFO': 'Place FO',
  'racing.raceDetails.bestToteWinHeading': 'Win BT',
  'racing.raceRunnerItem.runnerName':
  '{isHarnessRacing, select, true {{number}. {displayName}} other {{number}. {displayName} <span>({barrierNumber})</span>}}',
  'legal.responsible.tagline6': 'What are you really gambling with?',
  'generic.bestToteAcronym': 'BT',
  'generic.toteAcronym': 'MD',
  'generic.open': 'Open',
  'generic.recent': 'Recent',
  'generic.fav': 'Fav',
  'racing.raceRunnerItem.scratchedInfo':
  '{hasScratchTime, select, true {Scratched <time>{scratchTime, time, ::dd/MM/yy, H:mm}</time>} other {Scratched}}',
  'legal.responsible.tagline2': 'Think. Is this a bet you really want to place?',
  'betslip.betslipbetcard.stakeprefix': '$',
  'generic.fixedWin': 'Fixed Win',
  'racing.generic.numberRunner': 'No. / Runner',
  'footer.enquiries': 'For any enquiries email us at <link>{email}</link>',
  'footer.footerparagraph':
  "{themeName} gambling operations are governed by our <rglink>Responsible Gambling Code of Conduct</rglink>. Think! About your choices. Call Gambler's Help or Gambling Help on <telLink>1800 858 858</telLink> or visit <link>gamblershelp.com.au</link> or <link>gamblinghelponline.org.au</link>. Call Gambler's Help Youthline on <telLink>1800 262 376</telLink>. Gamble Responsibly.",
  'footer.footerbottomtext':
  'In relation to South Australian residents, our gambling operations are also governed by the South Australian Responsible Gambling <link>Code of Practice</link>. Think of the people who need your support. Gamble Responsibly.',
  'footer.betStop':
  'BetStop - the National Self-Exclusion Register is a free service for people who want to exclude from all Australian online wagering providers. For more info visit <cta>www.betstop.gov.au</cta>',
  'account.overview.balance': 'Balance',
  'account.overview.bonusBets': 'Bonus Bets',
  'account.deposit.titles.main': 'Make A Deposit',
  'account.overview.withdrawable': 'Withdrawable',
  'account.overview.details': 'Details',
  'account.overview.dateOfBirth': 'Date of Birth',
  'account.overview.toChange':
  'To change your details, contact <bu>{themeName} support</bu>',
  'account.overview.marketingPreferences': 'Marketing Preferences',
  'account.overview.logout': 'Log Out',
  'account.promotionsnotifications.notificationsenabled':
  'Yes, I would like to receive promotional offers',
  'account.promotionsnotifications.email': 'Email',
  'account.promotionsnotifications.sms': 'SMS',
  'generic.savechanges': 'Save Changes',
  'generic.stakeWithValue': 'Stake: {stake}',
  'account.mybetscard.potentialreturns': 'Est. Return',
  'account.deposit.creditCardMessage':
    'Please note that we are unable to accept credit card deposits. Please ensure that you utilise your preferred debit card to continue to enjoy uninterrupted betting with us!',
  'account.withdraw.withdrawfundstitle': 'Withdrawal',
  'account.withdraw.addaccount.makeawithdrawal': 'Make a withdrawal',
  'account.withdraw.addaccount.withdrawaldescription':
  'Withdrawals may take up to 1-3 days to reach your account. Only turned over funds can be withdrawn.',
  'account.withdraw.addbank': 'Add new Bank account',
  'account.withdraw.withdrawalamountlabel': 'Amount',
  'account.withdraw.withdrawalbalancelabel': 'Available Balance: ',
  'account.withdraw.requests.status.pending': 'Pending',
  'account.withdraw.requests.status.cancelled': 'Cancelled',
  'account.withdraw.requests.status.approved': 'Approved',
  'account.withdraw.requests.status.processed': 'Processed',
  'account.withdraw.requests.status.error': 'Error',
  'account.withdraw.requests.cancelbutton': 'Cancel',
  'account.withdraw.increaseamountbutton.ten': '+$10',
  'account.withdraw.increaseamountbutton.twentyfive': '+$25',
  'account.withdraw.increaseamountbutton.fifty': '+$50',
  'account.withdraw.increaseamountbutton.hundred': '+$100',
  'account.withdraw.increaseamountbutton.twohundred': '+$200',
  'account.withdraw.increaseamountbutton.fivehundred': '+$500',
  'account.withdraw.requestwithdrawalbutton': 'Withdraw',
  'account.withdraw.requests.heading': 'Withdrawal Request',
  'onboarding.login.footerInfo': 'Need an account? <link>Join now</link>',
  'onboarding.login.formTitle': 'Sign in to continue',
  'account.overview.promotionalPreferences': 'Promotional Preferences',
  'legal.responsible.tagline3': "What's gambling really costing you?",
  'legal.responsible.tagline5': 'Imagine what you could be buying instead.',
  'account.currentlimit.heading': 'Deposit Limit',
  'account.depositlimit.setlimit.subheading2':
  'If you wish to change your existing Deposit Limit, you can do so at any time.  Increasing your limit does require a 7 day cooling-off period before it will take effect.  Lowering your limit, however will come into effect immediately.',
  'account.currentlimit.depositlimitlabel': 'Current limit',
  'account.depositlimit.setlimit.inputplaceholder':
  'Enter a new deposit limit amount',
  'account.currentlimit.newlimitlabel': 'Limit amount',
  'account.currentlimit.limitperiodlabel': 'Restriction period',
  'account.currentlimit.removelimitbuttontext': 'Remove limit',
  'account.currentlimit.savelimitbuttontext': 'Update limit',
  'account.transactions.transactions.pagetitle': 'Transactions',
  'account.transactions.type.credit': 'Credit',
  'account.transactions.type.bet': 'Bet Stake',
  'account.transactions.eventDateTime': 'Event Date/Time',
  'account.transactions.id': 'ID',
  'account.transactions.betId': 'Bet ID',
  'account.transactions.type.winnings': 'Winnings',
  'legal.responsible.section.findOutMore.four.heading':
  'BetStop - National Self Exclusion Register',
  'legal.responsible.section.findOutMore.four.description':
  'If you feel you need to take a break or permanently self exclude from all bookmakers licensed in Australia, you can do this through the BetStop service. See the <cta>BetStop Website</cta> for more information. ',
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'account.promotionpreferences.coinjar': 'Enable BetSlip Promotions',
  'legal.responsible.section.takeaction.deactivate.two':
    'You can choose to close your account by deactivating it here, or by calling {phonenumber}.',
  'legal.responsible.section.takeaction.lifeexclusion.two':
  'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.depositlimit.two':
  'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.lifeexclusion.one': ' ',
  'legal.responsible.section.takeaction.break.two':
  'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.tagline1': "Chances are you're about to lose.",
  'legal.responsible.titles.takeaction': 'Take Action',
  'legal.responsible.section.takeaction.depositlimit.button': 'Set limit',
  'legal.responsible.section.takeaction.deactivate.button': 'Close My Account',
  'legal.responsible.section.takeaction.break.button': 'Take a Break',
  'account.personaldetails.header': '个人资料',
  'account.personaldetails.firstNameInputLabel': '名字',
  'account.personaldetails.middleNameInputlabel': '中间名',
  'account.personaldetails.lastNameInputLabel': '姓',
  'account.personaldetails.emailInputLabel': '电子邮件',
  'account.personaldetails.mobileInputLabel': '手机号码',
  'account.personaldetails.addressInputLabel': '地址',
  'account.personaldetails.dobInputLabel': '出生日期',
  'home.viewracecard': '查看比赛数据卡',
};

type KeysProps = keyof typeof zh;
export type ZhKeysProps = Partial<{ [K in KeysProps]: string }>;
