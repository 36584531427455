import * as puntgenie from './Transactions.styles.puntgenie';
import * as goldenrush from './Transactions.styles.goldenrush';
import * as slambet from './Transactions.styles.slambet';
import * as goldenbet888 from './Transactions.styles.goldenbet888';
import * as hyperbet from './Transactions.styles.hyperbet';
import * as chromabet from './Transactions.styles.chromabet';
import * as bet575 from './Transactions.styles.bet575';
import * as pyramidbet from './Transactions.styles.pyramidbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  puntgenie,
  goldenrush,
  slambet,
  goldenbet888,
  hyperbet,
  chromabet,
  bet575,
  pyramidbet,
};
