import * as betgalaxy from './Header.styles.betgalaxy';
import * as betprofessor from './Header.styles.betprofessor';
import * as betroyale from './Header.styles.betroyale';
import * as puntcity from './Header.styles.puntcity';
import * as puntgenie from './Header.styles.puntgenie';
import * as questbet from './Header.styles.questbet';
import * as sterlingparker from './Header.styles.sterlingparker';
import * as vikingbet from './Header.styles.vikingbet';
import * as volcanobet from './Header.styles.volcanobet';
import * as wellbet from './Header.styles.wellbet';
import * as juicybet from './Header.styles.juicybet';
import * as goldenrush from './Header.styles.goldenrush';
import * as forsport from './Header.styles.forsport';
import * as fiestabet from './Header.styles.fiestabet';
import * as junglebet from './Header.styles.junglebet';
import * as oldgill from './Header.styles.oldgill';
import * as sugarcastle from './Header.styles.sugarcastle';
import * as buffalobet from './Header.styles.buffalobet';
import * as titanbet from './Header.styles.titanbet';
import * as templebet from './Header.styles.templebet';
import * as slambet from './Header.styles.slambet';
import * as gigabet from './Header.styles.gigabet';
import * as betstride from './Header.styles.betstride';
import * as goldenbet888 from './Header.styles.goldenbet888';
import * as hyperbet from './Header.styles.hyperbet';
import * as bet777 from './Header.styles.bet777';
import * as chromabet from './Header.styles.chromabet';
import * as bet575 from './Header.styles.bet575';
import * as betblast from './Header.styles.betblast';
import * as pyramidbet from './Header.styles.pyramidbet';
import * as magnumbet from './Header.styles.magnumbet';
import { themeName } from '@/helpers/getThemeConfig';

const imports = {
  betgalaxy,
  betprofessor,
  betroyale,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  juicybet,
  goldenrush,
  forsport,
  fiestabet,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  templebet,
  slambet,
  gigabet,
  betstride,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
  pyramidbet,
  magnumbet,
} as Record<string, any>;

export const styleImport = imports[themeName];
