import { CSSObject } from '@chakra-ui/react';
import errorBg from '@/assets/hyperbet/images/bg.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: 'center',
  minH: '100dvh',
  bg: `url(${errorBg}) center/cover no-repeat`,
  fontFamily: 'body',
});

export const MaintenanceContainer = (): CSSObject => ({
  alignItems: 'center',
  color: 'white',
  contain: 'paint',
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  maxW: 'container.md',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',

  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },
});

export const ContentHeadingText: CSSObject = {
  color: 'beta.300',
  textShadow: '0px 0px 8px #ADF10680',
  fontFamily: 'heading',
  w: ['278px', '468px'],
  fontSize: ['xl', null, '32px'],
  lineHeight: 'none',
  mb: '4',
  mt: '4',
  textTransform: 'uppercase',
  '&[data-ismaintenance="true"]': {
    w: ['278px', '500px'],
  },
};

export const ContentBodyText: CSSObject = {
  span: {
    display: 'block',
    fontSize: ['sm', null, 'md'],
    fontWeight: 'semibold',
    mb: '4',
  },
  fontWeight: 'normal',
  fontSize: ['xs', null, 'sm'],
  mb: '4',
};

export const buttonRefreshProps: CustomButtonProps = {
  minW: '110px',
  lineHeight: 'normal',
  fontSize: 'xs',
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'white',
};
