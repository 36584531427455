import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  mx: '0',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  flexDir: 'column',
  alignItems: 'start',
  mb: '2',
  p: '2',
  px: '2',
  py: '2',
  borderRadius: 'md',
  gap: '1',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  _last: {
    mb: '0',
  },
};

export const RacerName: CSSObject = {
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
  color: 'white',
  fontWeight: 'bold',
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'white',
  position: 'relative',
  top: '-0.5',
  fontWeight: 'normal',
  fontSize: '10px',
};
