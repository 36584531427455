import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '190px',
    mr: '3',
    height: 'fit-content',
    py: '3px',
    px: '1px',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.08) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: 'md',
    overflow: 'hidden',
    bg: 'rgba(0, 209, 255, 0.25)',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    pos: 'relative',
    _before: {
      content: '""',
      position: 'absolute',
      h: '1px',
      bg: 'rgba(0, 0, 0, 0.25)',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      borderBottom: '1px',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: 'none',
    },
    _first: {
      borderTopRadius: 'md',
      _before: {
        display: 'none',
      },
    },
    _last: {
      borderBottomRadius: 'md',
    },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
  },
  accordionHeadingText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    textColor: 'white',
    textTransform: 'capitalize',
    fontFamily: 'accent',
    fontSize: 'sm',
  },
  accordionIcon: {
    color: 'alpha.100',
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
  },
};
