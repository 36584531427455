import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'odds',
    boxSize: '34px',
    minWidth: '34px',
    minHeight: '34px',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-closed="true"]:not(&[data-active="true"])': {
        bg: 'transparent',
        border: '2px solid',
        borderColor: 'whiteAlpha.200',
        color: 'white',
        textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
        boxShadow: 'none',
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
  pt: '3px',

  '&&': {
    '.quaddie': {
      borderColor: '#FFC138',
      borderStyle: 'solid',
      borderWidth: '2px',
      color: 'white',
      boxShadow: 'none',

      '&[data-active="false"]': {
        bg: 'transparent',
        boxShadow: 'none',
        color: 'white',
        p: '14px',
      },

      '&.active': {
        boxShadow: 'none',
        bg: 'blackAlpha.600',
        color: 'beta.300',
      },
      '&:hover': {
        bg: 'blackAlpha.400',
      },
    },
  },
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: 'transparent',
  p: '4px',
  borderRadius: 'base',
  boxShadow: 'none',
  color: 'white',
  flexDirection: 'row',
};

export const TextRaceConditions: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    w: '1px',
    h: '4',
    bg: 'whiteAlpha.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'blackAlpha.700',
  fontWeight: 'bold',
  bg: 'gamma.100',
  boxShadow: '0px 2px 5px 0px hsla(0, 0%, 0%, 0.3)',
  textTransform: 'uppercase',
  border: '1px solid rgba(255, 255, 255, 0.16)',
};

export const RaceStatusText: CSSObject = {
  color: 'orange.800',
  bg: 'orange.400',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  textTransform: 'none',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  py: '3',
};

export const RaceRunnerList: CSSObject = {
  bg: 'transparent',
  p: '0',
};

export const TextTitle: CSSObject = {
  fontFamily: 'accent',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xl',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: 'white',
  letterSpacing: '1px',
};

export const RaceName: CSSObject = {
  fontFamily: 'body',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
  color: 'white',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  mt: '1',
  pb: '1.5',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  '.chakra-switch__track': {
    background: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
    '& .chakra-switch__thumb': {
      background: 'white',
      '&[data-checked]': {
        bg: 'beta.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
  pb: '2',
  alignItems: 'center',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '56px',
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  mb: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};
