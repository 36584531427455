import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '4'],
  mb: ['2', null, 'unset'],
  mt: 'unset',
  borderRadius: 'lg',
  bg: 'alpha.400',
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.25) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
};

export const BoxWrapper: CSSObject = {
  px: '3',
  pt: '3',
  pb: '2',
};

export const TextHeading: CSSObject = {
  color: 'beta.300',
  fontSize: 'lg',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'heading',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'blackAlpha.800',
  mr: '0',
  textAlign: 'center',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  width: 'full',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  _hover: {
    bgGradient: 'linear(to-b, beta.300, beta.300)',
  },
};
