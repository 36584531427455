import React from 'react';
import { Flex, Img, Hide, Show } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logo from '@/assets/pyramidbet/images/logoOnboardingError.png';
import Heading from '../components/Heading';
import Body from '../components/Body';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: (
      <Img
        src={logo}
        alt={REACT_APP_THEME_NAME}
        alignSelf="center"
        w="160px"
        mt="2"
        mb="0"
      />
    ),
    heading: (
      <Flex direction="column" alignItems="center">
        <Heading />
      </Flex>
    ),
    desc: <Body />,
    body: (
      <Global styles={{ body: { background: 'var(--bc-colors-beta-500)' } }} />
    ),
  },
};

export default template;
