import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: 'xl',
    color: 'gamma.500',
    textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Angkor',
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    px: ['0', '4'],
    py: '2',
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'none',
    pos: 'relative',
    pb: '2.5',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: '#0B3A5B',
    borderRadius: 'none',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    sx: {
      '&&': {
        mb: '2.5',
      },
    },
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    gap: '1',
    color: 'gamma.500',
    textShadow: '0px 3px 2px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Angkor',
    textTransform: 'capitalize',
  },
  headingIcon: {
    color: 'alpha.300',
    sx: {},
  },
  propositionButton: {
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    borderColor: 'beta.900',
    borderWidth: '1px',
    h: '100%',
  },
  buttonSport: {
    color: 'beta.900',
    borderColor: 'beta.900',
  },
  buttonAllSports: {
    borderColor: 'beta.900',
    color: 'beta.900',
    _active: {
      border: 'none',
      color: 'white',
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      '&:after': {
        display: 'none',
      },
    },
  }
};
