import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/hyperbet/images/bg.png';

export const WrapperFlexOnboarding: CSSObject = {
  bgImage: `url(${bg}), linear-gradient(180deg, var(--bc-colors-beta-800), var(--bc-colors-beta-900))`,
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, '10px'],
  boxShadow: [null, null, '0px 4px 30px 0px rgba(0, 0, 0, 0.40)'],
  pos: 'relative',
  w: ['100dvw', null, 'unset'],
  p: '1px',
  fontFamily: 'body',
  borderWidth: ['0px', '0px', '2px'],
  borderColor: 'beta.300',
  '.chakra-input': {
    color: 'white',
    fontWeight: 'normal',
    fontSize: 'xs',
    '&:focus, &:active, &:hover': {
      color: 'white',
    },
  },
};

export const WrapperContent: CSSObject = {
  fontFamily: 'body',
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '500px'],
  h: '100%',
  borderRightRadius: [null, null, '12px'],
  color: 'white',
  bg: 'alpha.500',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  '.chakra-form__error-message': {
    bg: 'red.600',
    color: 'white',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    borderRadius: 'base',
    p: '1',
    fontWeight: 'medium',
    fontSize: 'xs',
  },
};

export const Title: CSSObject = {
  color: 'beta.300',
  fontFamily: 'heading',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 'md',
  textTransform: 'uppercase',
  display: 'flex',
  mb: '4',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  fontSize: 'xs',
  '&&&': {
    borderRadius: 'base',
    border: 'none',
    color: 'white',
    fontWeight: 'normal',
    '&&:hover, &&:focus': {
      color: 'white',
      opacity: 0.95,
      borderLeft: '2px solid',
      borderLeftColor: 'beta.300',
    },
    _placeholder: {
      color: 'white',
    },
  },
};
