import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: '0.8125rem',
  textShadow: '0px 3px 4px 0px #00000033',
  fontWeight: 'semibold',
  fontFamily: 'accent',
  textTransform: 'uppercase',
};

export const TextBetCount: CSSObject = {
  fontSize: '2xs',
  fontFamily: 'accent',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'alpha.300',
  pos: 'relative',
  bottom: '2px',
};
export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'blackAlpha.800',
  minW: 'unset',
  bgGradient: 'linear(180deb, alpha.300 0%, alpha.500 100%)',
  borderRadius: 'md',
  boxShadow: '0px 3px 4px 0px #00000033',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient: 'linear(180deb, alpha.300 0%, alpha.500 100%)',
  },
  _focus: {
    bgGradient: 'linear(180deb, alpha.300 0%, alpha.500 100%)',
  },
  _after: {
    display: 'none',
  },
};
export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};
export const GroupIcon: CSSObject = {
  color: 'white',
};
