import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/bet575/images/racing/mysteryBetLogo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: { variant: 'odds' },
  imageLogo: {
    w: ['175px', '250px'],
    src: mysteryLogo,
    pt: '7px',
  },
  iconMystery: {
    mb: '1',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    fontWeight: 'bold',
  },
  flexWrapperMysteryLabelView: {
    color: 'white',
    fontFamily: 'body',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
    sx: {
      '&&': {
        svg: {
          mb: '0.5',
        },
      },
    },
  },
  buttonAddToBetslip: {
    variant: 'solid',
    sx: {
      '&&': {
        borderLeftRadius: 'none',
        boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
        ms: '0',
        fontSize: 'sm',
        fontWeight: 'bold',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
        px: '1.5',
        _disabled: {
          opacity: 1,
          bg: 'blackAlpha.400',
          boxShadow:
            '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
          color: 'beta.400',
        },
      },
    },
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    borderRightRadius: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontFamily: 'body',
    fontWeight: 'bold',
    sx: {
      '&&': {
        px: [2, 3],
        svg: {
          mb: '0.5',
        },
      },
    },
  },
  flexWrapperMysteryRolloverView: {
    w: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    px: '3',
    color: 'gamma.200',
    overflow: 'hidden',
    mx: '4',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexWrapperPlacedBetViewGroup: {
    gap: 1,
  },
  flexWrapperMysteryPlacedBetView: {
    fontFamily: 'body',
    fontWeight: 'semibold',
    textShadow: 'none',
    fontStyle: 'normal',
    fontSize: 'xs',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: [0, '1.5'],
    justifyContent: 'center',
    w: 'full',
  },
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
    pl: [0, 2],
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'alpha.200',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'base',
    boxSize: '10',
    p: '2',
    cursor: 'pointer',
  },
  tooltipIcon: {
    fill: 'beta.400',
    filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25))',
  },
  flexWrapperWithTooltip: {
    gap: 1,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    fontSize: 'xs',
    color: 'white',
    fontFamily: 'body',
    fontWeight: 'medium',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  textPriceInfo: {
    fontSize: 'xs',
    color: 'gamma.100',
    fontFamily: 'body',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontStyle: 'normal',
  },
  textRollover: {
    fontSize: 'xs',
    color: 'gamma.100',
    fontFamily: 'body',
    fontWeight: 'medium',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textError: {
    color: 'gamma.500',
    fontFamily: 'body',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontStyle: 'normal',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: ['7', null, '10'],
    color: 'white',
    mr: [-2, 0],
  },
  iconRace: { color: 'beta.300', mb: '2', mr: 1.5 },
  textSpanWhite: {
    color: 'beta.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    whiteSpace: 'nowrap',
    _last: {
      color: 'white',
    },
  },
  buttonRollOver: {
    variant: 'secondary',
    h: '7',
    fontSize: 'sm',
    fontWeight: 'bold',

    sx: {
      _disabled: {
        pointerEvents: 'none',
        opacity: 1,
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'alpha.100',
      },
    },
  },
  buttonMyBets: {
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  spanSelectionText: {
    color: 'gamma.100',
  },
  spanPositionType: {
    color: 'beta.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
};
