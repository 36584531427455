import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggle from '@/assets/magnumbet/images/switch/toggle.png';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'lg',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, 'unset'],
    mb: 4,
    gap: '2',
    boxShadow: [
      null,
      null,
      '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px #804284 inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
    ],
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    h: '16',
    pos: 'relative',
  }),
});
const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'accent',
    fontWeight: 'extrabold',
    fontSize: 'sm',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 8px rgba(252, 211, 96, 0.5)'
      : '0px 1px 0px rgba(255, 255, 255, 0.08)',
    color: isActive ? 'beta.300' : 'blackAlpha.600',
    px: '1.5',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;
const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height))',
    '--switch-track-width': '78px',
    '--switch-track-height': '40px',
    bg: 'transparent',
    pos: 'relative',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: `url(${toggle})`,
        boxSize: '9',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '2px',
        top: '2px',
        right: '0',
      },
    },
    '.chakra-switch__track': {
      boxShadow:
        '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
      bg: 'blackAlpha.500',
      borderRadius: '9px',
      _before: {
        bg: 'blackAlpha.600',
        h: '8px',
        w: '52px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        boxShadow:
          '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
        content: '""',
        pos: 'absolute',
        left: '3.5',
        top: '18px',
      },
    },
  }),
});
export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;
  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }
  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }
  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;
  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>
      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        <FormattedMessage id="sport" defaultMessage="Sports" />
      </TextToggle>
    </FlexWrapper>
  );
}
