import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  borderRadius: 'md',
  boxSize: '10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'transparent',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'beta.300',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bg: 'rgba(78, 178, 255, 0.14)',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'md',
  w: '100%',
  h: '64px',
  _hover: {
    bg: 'alpha.600',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'sm',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  mt: '1.5',
  fontFamily: 'accent',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontFamily: 'accent',
  fontWeight: 'bold',
  color: 'beta.300',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'whiteAlpha.600',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'alpha.100',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  color: 'beta.300',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'beta.300',
  border: 'none',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
