import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { 
    spacing: '1', 
    pt: '2',
    sx: {
      '&[data-isonsportsall="true"]': {
        pb: '4',
        mb: '2',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderColor: 'blackAlpha.400',
        borderRadius: 'none',
        boxShadow: '0 1px rgba(255, 255, 255, .07)',
      },
    },
  },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear(to-b, alpha.400, alpha.500)',
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
    borderRadius: 'md',
    border: '2px',
    borderColor: 'white',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    fontSize: '2xs',
    fontWeight: 'bold',
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    color: 'rgba(0, 0, 0, 0.50)',
    sx: {
      '&.active': {
        bg: 'blackAlpha.400',
        fontWeight: 'bold',
        fontSize: '2xs',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.40)',
        color: 'white',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
        svg: {
          color: 'alpha.400',
        },
      },
      svg: {
        color: 'beta.500',
      },
    },
  },
};
