import { CSSObject } from '@chakra-ui/react';
import bgFeaturedRace from '@/assets/pyramidbet/images/background/bgFeaturedRace.png';
import bgFeaturedRaceMb from '@/assets/pyramidbet/images/background/bgFeaturedRaceMb.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    w: '42px',
    minH: '32px',
    borderRadius: 'md',
    lineHeight: 'normal',
  },
  svgMoreRacesArrowProps: {
    boxSize: 4,
    color: 'alpha.200',
  },
};

export const BoxCard: CSSObject = {
  p: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${bgFeaturedRaceMb})`, `url(${bgFeaturedRace})`],
    bgRepeat: 'no-repeat',
    bgSize: ['cover'],
    bgPos: ['top', null, '20% 30%'],
    position: 'absolute',
    h: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', 'md'],
    boxShadow:
      '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(10, 15, 19, 1) inset, 0px 0px 0px 5px rgba(252, 211, 96, 0.15) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
    m: 'px',
  },
  bg: '#143D4599',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'md',
  minH: '200px',
  pt: '4',
  pb: ['2', '4'],
  px: '3',
  mb: '4',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  position: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mt: ['6', '3'],
  mb: ['11', null, '20px'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    fontFamily: 'accent',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'transparent',
  bgGradient: 'linear(180deg, alpha.300, alpha.500)',
  backgroundClip: 'text',
  fontFamily: 'heading',
  fontSize: ['2xl', null, '27px'],
  textTransform: 'uppercase',
  fontWeight: 'normal',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'alpha.300',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px #0000004D)',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'accent',
  bg: 'gray.200',
  borderRadius: '3px',
  color: 'gray.600',
  px: '1',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '42px',
  bg: 'whiteAlpha.200',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px 0px #00000040',
  backdropFilter: 'blur(8px)',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
};

export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
