import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'alpha.400',
  borderRadius: 'lg',
  border: 'none',
  gap: '3',
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.25) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
  p: '4',
  m: 'auto',
};

export const HeaderText: CSSObject = {
  color: 'beta.300',
  fontSize: 'md',
  fontFamily: 'heading',
  fontWeight: 'bold',
  textShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
  p: '0',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const ContentContainer: CSSObject = {
  '&&': {
    color: 'white',
    fontWeight: 'medium',
  },
};

export const CloseButton: CSSObject = {
  display: 'none',
};
