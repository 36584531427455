import { CSSObject } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  p: 1,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.500',
};
export const CardContainer: CSSObject = {
  border: 'none',
  bg: 'blackAlpha.500',
  borderRadius: 'md',
  p: 1.5,
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'whiteAlpha.300',
  '.multiDivider': {
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    width: 'unset',
  },
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  alignItems: 'start',
  '> svg': {
    color: 'beta.300',
    fill: 'beta.300',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
};
