import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'beta.300';
      }
      if (state === 'ended') {
        return 'red.500';
      }
      return 'alpha.200';
    },
    color: state === 'prior' ? 'white' : 'blackAlpha.800',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    px: 1,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontFamily: 'accent',
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
});
