import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'beta.500', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'beta.500'] },
};

export const FlexHeadingRow: CSSObject = {};

export const FlexHeading: CSSObject = {
  color: 'white',
  top: ['12', '84px'],
  position: 'unset',
  mb: '1',
  px: '1',

  'div:has(button[data-active])': {
    gap: 1,
  },
};

export const TextHeading: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'white',
  pt: '1',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'alpha.800',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  border: '1px solid rgba(255, 255, 255, 0.08)',
  borderRadius: 'md',
  p: '1.5',
  boxShadow: '0px 0px 2px 1px rgba(255, 255, 255, 0.25) inset',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.3))',
  svg: {
    color: 'blackAlpha.700',
  },
};

export const BoxPageWrapper: CSSObject = {
  m: '0',
  mb: '4',
  p: '0.5',
  px: '1',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  mx: '0',
  borderRadius: 'md',
};

export const BoxTableWrapper: CSSObject = {
  p: '0',
  'div:last-of-type': {
    div: {
      borderBottom: 'none',
      borderBottomLeftRadius: 'md',
      borderBottomRightRadius: ['md', null, 'none'],
      mb: 'px',
    },
  },
};

export const TextVenueName: CSSObject = {
  color: 'white',
  fontFamily: 'body',
  fontWeight: 'extrabold',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextVenueCountry: CSSObject = {
  fontFamily: 'body',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'beta.400',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextRaceResults: CSSObject = {
  color: 'white',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  color: 'blackAlpha.700',
  filter: 'drop-shadow(0px 2px 5 rgba(0, 0, 0, 0.3))',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'gamma.300';
  })(),
});

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? ['transparent', null, 'alpha.100'] : 'transparent',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: ['none', null, '1px solid'],
  borderBottomColor: ['unset', null, 'whiteAlpha.200'],
  borderRightColor: ['unset', null, 'whiteAlpha.200'],

  _hover: {
    background: isToteMultiAvailable
      ? 'alpha.300'
      : ['blackAlpha.300', null, 'alpha.300'],
  },
  _last: {
    borderRight: 'none',
  },
});

export const FlexRow: CSSObject = {
  mb: ['px', null, '0'],
  borderRadius: 'none',
  contain: 'unset',
  borderBottomColor: ['blackAlpha.300', null, 'unset'],
  boxShadow: ['0px 1px 0px 0px rgba(255, 255, 255, 0.07)', null, 'unset'],
  _last: {
    borderBottom: 'none',
    boxShadow: 'none',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  bg: '#3E557C',
  py: ['2', null, '1.5'],
  borderTop: 'none',
  borderBottom: ['1px dashed', null, '1px solid'],
  borderRight: ['none', null, '1px solid'],
  borderBottomColor: ['rgba(0, 0, 0, 0.25)', null, 'whiteAlpha.200'],
  borderRightColor: ['unset', null, 'whiteAlpha.200'],
};

export const CenterTH = (): CSSObject => ({
  bg: '#2F405D',
  color: 'white',
  textAlign: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'extrabold',
  lineHeight: 'normal',
  py: '2.5',
  border: '1px solid',
  borderLeft: 'none',
  borderColor: 'whiteAlpha.200',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _first: {
    bg: 'blackAlpha.300',
  },
});

export const ButtonChevron: CSSObject = {
  color: 'white',
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  boxSize: '7',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BadgeRaceNumber: CSSObject = {
  bg: 'gray.300',
  color: 'gray.600',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
