import { BoxProps, CSSObject } from '@chakra-ui/react';

export const TextFormInfo: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  fontFamily: 'accent',
};

export const BoxWrapper: BoxProps = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  maxW: ['120px', 'unset'],
};
