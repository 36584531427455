import * as betgalaxy from './Placed.styles.betgalaxy';
import * as betprofessor from './Placed.styles.betprofessor';
import * as betroyale from './Placed.styles.betroyale';
import * as goldenrush from './Placed.styles.goldenrush';
import * as juicybet from './Placed.styles.juicybet';
import * as puntcity from './Placed.styles.puntcity';
import * as puntgenie from './Placed.styles.puntgenie';
import * as questbet from './Placed.styles.questbet';
import * as sterlingparker from './Placed.styles.sterlingparker';
import * as vikingbet from './Placed.styles.vikingbet';
import * as volcanobet from './Placed.styles.volcanobet';
import * as wellbet from './Placed.styles.wellbet';
import * as fiestabet from './Placed.styles.fiestabet';
import * as junglebet from './Placed.styles.junglebet';
import * as buffalobet from './Placed.styles.buffalobet';
import * as titanbet from './Placed.styles.titanbet';
import * as slambet from './Placed.styles.slambet';
import * as gigabet from './Placed.styles.gigabet';
import * as betstride from './Placed.styles.betstride';
import * as hyperbet from './Placed.styles.hyperbet';
import * as bet777 from './Placed.styles.bet777';
import * as chromabet from './Placed.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  buffalobet,
  betgalaxy,
  betprofessor,
  betroyale,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  fiestabet,
  junglebet,
  titanbet,
  slambet,
  gigabet,
  betstride,
  hyperbet,
  bet777,
  chromabet,
};
