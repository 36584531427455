import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'solid',
  height: '40px',
  pt: ['0.5', null, 'px'],
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};

export const FlexWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  borderBottomRadius: 'lg',
  pos: 'relative',
  borderTopColor: 'blackAlpha.300',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  _before: {
    opacity: '0.2',
    bottom: 'calc(100% + 1px)',
    content: '""',
    h: '4',
    left: '0',
    pointerEvents: 'none',
    pos: 'absolute',
    right: '0',
    transitionDuration: 'normal',
    transitionProperty: 'common',
  },
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
    mt: '0',
  },

  '&&': {
    '.chakra-form__label': {
      color: 'white',
    },
    '.chakra-input__group': {
      border: 'none',
      boxShadow: 'none',
      p: '0',
    },
    '.chakra-input__left-addon': {
      w: '10',
      border: 'none',
      fontWeight: 'bold',
      borderLeftRadius: 'md',
    },
    '.chakra-input': {
      w: 'full',
      borderLeftRadius: 'none',
      bg: 'blackAlpha.600',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: 'alpha.400',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  borderBottomRadius: 'lg',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  borderTop: '4px solid',
  borderColor: 'beta.400',
  bg: 'alpha.400',
  h: '9',
  px: '4',
  py: '2',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'body',
  '&&': {
    fontWeight: 'bold',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'alpha.300',
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',

  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    },
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-4px',
  width: '24px',
  height: '24px',
  color: 'white',
};
