import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  pt: '18',
  textAlign: 'center',
  mx: 'auto',
  w: 'full',
};
export const BetSlipEmptyHeading: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'lg',
  lineHeight: '1',
  color: 'transparent',
  bgGradient: 'linear(180deg, alpha.300, alpha.500)',
  backgroundClip: 'text',
  fontFamily: 'heading',
  textTransform: 'uppercase',
  maxW: '200px',
  my: '0',
  mx: 'auto',
  textAlign: 'center',
};
export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'normal',
  fontFamily: 'accent',
  fontSize: 'sm',
  color: 'white',
  my: '1.5',
  mx: '6',
};
export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};
