import { CSSObject } from '@chakra-ui/react';
import featuredMatchMb from '@/assets/magnumbet/images/feature/featSportMb.png';
import featuredMatchDt from '@/assets/magnumbet/images/feature/featSportDesktop.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '230px'],
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.25) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
  bgImage: [`url(${featuredMatchMb})`, null, `url(${featuredMatchDt})`],
  bgSize: ['cover', 'auto 100%'],
  bgRepeat: 'no-repeat',
  bgPos: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'accent',
  border: 'none',
  borderRadius: 'lg',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
};

export const HeadingSlogan: CSSObject = {
  color: 'beta.400',
  fontSize: ['36px', '40px'],
  textTransform: 'capitalize',
  fontWeight: 'normal',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  fontFamily: 'subHeading',
  lineHeight: 'normal',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '500px',
  mb: '3',
  mt: '0',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '65%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'alpha.200',
  border: '2px solid',
  borderColor: 'alpha.200',
  fontSize: 'xs',
  fontWeight: 'black',
  borderRadius: 'lg',
  fontFamily: 'accent',
  textShadow: 'none',
  boxShadow: 'none',
};

export const TextMisc: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textShadhow: 'none',
  span: {
    color: 'whiteAlpha.300',
    mx: 1,
  },
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: ['15px', null, 'lg'],
  fontWeight: 'bold',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontSize: '2xl',
  fontWeight: 'bold',
  color: 'beta.300',
  textTransform: 'uppercase',
};

export const puntButtonProps: CSSObject = {
  fontSize: 'xs',
  color: 'blackAlpha.800',
  textTransform: 'uppercase',
  h: '9',
};
