import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'accent',
  fontWeight: 'bold',
};

export const LinkItem: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  bg: 'rgba(0, 209, 255, 0.25)',
  border: 'none',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
  h: '10',
  mb: ['1', '2'],
  p: '3',
  _last: { mb: '0' },

  _hover: { bg: 'alpha.600', color: 'beta.300' },
};

export const IconSport: CSSObject = {
  color: 'beta.300',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'beta.300',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};

export const TextName: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  transform: 'uppercase',
};
