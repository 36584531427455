export const colors = {
  alpha: {
    100: '#965EBF',
    200: '#9E62A1',
    300: '#6F4272',
    400: '#582E5B',
    500: '#4A284D',
    600: '#3F1B42',
    700: '#3D1241',
    800: '#2F1B31',
    900: '#241836',
  },
  beta: {
    100: '#FFF0C5',
    200: '#FDE195',
    300: '#FCD360',
    400: '#DFB12D',
    500: '#BF951B',
    600: '#957109',
    700: '#765803',
    800: '#5C4502',
    900: '#3F2F02',
  },
};
