import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  color: 'white',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: '0',
  '&&': { px: ['0', null, null, '4'], pb: ['0', '1', null, '0'] },

  '.tabBar': {},

  '.subTabBar ': {
    bg: 'beta.400',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.1)',
    borderTopRadius: 'none',
    mt: 0,
  },
});
export const TextTitle = (): CSSObject => ({
  mt: 0,
  fontWeight: 'bold',
  fontSize: 'md',
  textTransform: 'uppercase',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  mx: '0',
  px: ['10px', null, null, '0'],
  pt: ['3', null, '0'],
});
export const BoxHeading: CSSObject = {
  mb: '-1',
};
export const BoxWrapper: CSSObject = {
  borderRadius: 'md',
  minHeight: '0',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  mx: ['1', null, '0'],
  fontFamily: 'body',
};
export const WrapperContainer: CSSObject = {
  px: ['2', null, null, '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
};
