import React, { ReactElement } from 'react';
import { Show } from '@chakra-ui/react';
import { FlexWrapper, ToggleItemsWrapper } from '../styles/Toggle.styles';
import Switch from '../components/Switch/Switch';
import TextRacing from '../components/TextRacing/TextRacing';
import TextSport from '../components/TextSport/TextSport';
import IconRacing from '../components/IconRacing/IconRacing';
import IconSport from '../components/IconSport/IconSport';

const Wrapper = ({ children }: { children?: ReactElement }) => (
  <Show below="md">
    <FlexWrapper>{children}</FlexWrapper>
  </Show>
);

export const TemplateToggle = [
  { name: 'wrapper', component: <Wrapper /> },
  { 
    name: 'textRace', 
    component: (
      <ToggleItemsWrapper>
        <IconRacing />
        <TextRacing />
      </ToggleItemsWrapper>
    ) 
  },
  { name: 'switch', component: <Switch /> },
  { 
    name: 'textSport', 
    component: (
      <ToggleItemsWrapper>
        <IconSport />
        <TextSport />
      </ToggleItemsWrapper>
    ) 
  },
];

export default TemplateToggle;