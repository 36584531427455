import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  color: 'white',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.08) inset',
  h: 'full',
  border: 'none',
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'white',
  },
};
export const TextTitle: CSSObject = {
  color: 'white',
};
