import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  alignItems: 'center',
  borderBottom: '2px solid',
  borderColor: 'beta.300',
  h: '16',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
  color: 'beta.300',
};
