import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',
    fontSize: '2xs',
    color: 'beta.200',
    height: '5',
    borderWidth: '2px',
    borderColor: 'beta.300',
    borderStyle: 'solid',
    mb: '1',
    px: '3',

    _hover: {
      '&&': {
        background: 'transparent',
      },
    },
  },
};

export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  width: ['100%', null, '102%', '100%'],
  left: [null, null, '0'],
  bg: 'alpha.300',
  borderTop: '2px',
  borderTopColor: 'beta.300',
};

export const buttonKeysProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  fontSize: 'md',
  borderWidth: '2px',
  borderColor: 'beta.300',
  borderStyle: 'solid',
  color: 'beta.300',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
  boxShadow: '0px 0px 0px 3px #000 inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.15) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
  fontWeight: 'bold',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  fontFamily: 'Roboto',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  },
};

export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  fontSize: 'md',
  fontFamily: 'Roboto',
  border: 'none',
  color: 'white',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  textShadow: '0px 2.82px 3.761px rgba(0, 0, 0, 0.30)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
};

export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'secondary',
  height: '100%',
  fontSize: 'md',
  fontFamily: 'Roboto',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  color: 'alpha.800',
  border: 'none',
  fontWeight: 'bold',
  textShadow: '0px 2.82px 3.761px rgba(255, 255, 255, 0.30)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
  _before: {
    boxShadow: '',
  },
};
