import { CSSObject } from '@emotion/react';

export const buttonIncreaseProps: CSSObject = {
  variant: 'odds',
  pt: 'px',
  h: '6',
};

export const IncreaseAmountWrapper: CSSObject = {
  gap: '1',
};
