import { CSSObject } from '@chakra-ui/react';

export const ButtonShowMore: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.500 0%, gamma.600 100%)',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  color: 'blackAlpha.700',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.300 100%)',
    bg: 'gamma.300',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    color: 'blackAlpha.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.500',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvent: 'none',
    bgGradient: 'linear(180deg, gamma.500 0%, gamma.600 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const FlexBetCard: CSSObject = {
  rowGap: '2',
  w: '100%',
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};
