import { CSSObject } from '@chakra-ui/react';
import bgFeaturedMatch from '@/assets/pyramidbet/images/background/bgFeaturedMatch.png';
import bgFeaturedMatchMb from '@/assets/pyramidbet/images/background/bgFeaturedMatchMb.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '220px'],
  bgImage: [`url(${bgFeaturedMatchMb})`, `url(${bgFeaturedMatch})`],
  bgSize: 'cover',
  boxShadow:
    '0px 0px 0px 1px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(10, 15, 19, 1) inset, 0px 0px 0px 5px rgba(252, 211, 96, 0.15) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
  border: '1px solid',
  borderColor: 'rgba(252, 211, 96, 1)',
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'accent',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  borderRadius: 'lg',
};

export const HeadingSlogan: CSSObject = {
  color: 'transparent',
  bgGradient: 'linear(180deg, alpha.300, alpha.500)',
  backgroundClip: 'text',
  fontSize: ['xl', null, null, '31px'],
  textTransform: 'uppercase',
  mt: '4',
  fontFamily: 'heading',
  fontWeight: 'normal',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '1',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: ['beta.100', 'beta.200'],
  border: '2px solid',
  borderColor: ['beta.100', 'beta.200'],
  fontSize: 'sm',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: 'none',
  boxShadow: 'none',
  mt: '1',
  mb: '2',
};

export const TextMisc: CSSObject = {
  bg: 'whiteAlpha.300',
  color: 'alpha.300',
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: 'bold',
  textShadow: 'none',
  span: {
    color: 'whiteAlpha.300',
    mx: 1,
  },
  boxShadow: '0px 0px 0px 1px #FFFFFF1A inset, 0px 4px 4px 0px #00000044',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  fontWeight: 'bold',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontSize: '2xl',
  fontWeight: 'bold',
  color: 'beta.300',
  textTransform: 'uppercase',
};

export const puntButtonProps: CSSObject = {
  fontSize: 'xs',
  textTransform: 'uppercase',
  color: 'blackAlpha.700',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  lineHeight: 'normal',
  pt: '0.5',
  h: '9',
  _after: {
    opacity: 0,
  },
};
