import React from 'react';
import { useSearchParams } from 'react-router-dom';
import IconSvg from '@/components/common/IconSvg';
import { getIconBySportName } from '@/helpers/utils';
import { ERaceType } from '@/lib/DBModels';
import { IconToggle } from '../../styles/Toggle.styles';

export default function IconRacing() {
  const [searchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };

  return (
    <IconToggle
      data-cy="racing-toggle-text"
      isActive={!category || category === 'racing'}
    >
      <IconSvg name={getIconBySportName(ERaceType.Horse)} />
    </IconToggle>
  );
}
