import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    bg: 'transparent',
    mt: '1',
  },
  body: {
    color: 'white',
    pt: '3',
    px: '0',
    pb: '0',
    fontSize: 'sm',
    fontWeight: 'semibold',
  },
};
