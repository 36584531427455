import { CSSObject } from '@chakra-ui/react';
import { TBodyTextProps } from '../types';

export const BodyText = ({ heading }: TBodyTextProps): CSSObject => ({
  '&&': {
    fontSize: heading ? 'sm' : 'xs',
    fontWeight: heading ? 'semibold' : 'medium',
    color: 'white',
    lineHeight: '1.3',
  },
});

export const RadioText: CSSObject = {
  '&&': {
    fontSize: 'xs',
    mb: '2',
    fontWeight: 'medium',
    color: 'white',
  },
};

export const DepositLimitContainer: CSSObject = {
  mt: '4',
  border: '1px dashed',
  borderColor: 'whiteAlpha.500',
  borderRadius: 'base',
  maxW: ['100vw', null, '500px'],
  p: '4',
  '.chakra-input__group': {
    bg: 'blackAlpha.500',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
  },
  '.chakra-input__left-addon': {
    bg: 'beta.300',
    fontSize: 'xs',
    fontWeight: 'semibold',
    borderRadius: 'base',
    h: '4',
    color: 'alpha.500',
    px: '1',
    mx: '2',
  },
  '.chakra-select': {
    bg: 'blackAlpha.500',
    fontSize: 'xs',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-select__icon-wrapper': {
    color: 'beta.300',
  },
  '#limitAmount-input': {
    fontSize: 'xs',
    fontWeight: 'medium',
    bg: 'blackAlpha.200',
    color: 'white',
    border: 'none',
    h: '9',
    px: '0',
    boxShadow: 'none',
    paddingLeft: '10px',
    ':hover, :focus': {
      bg: 'blackAlpha.100',
      border: 'none',
    },
  },
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'beta.300',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontFamily: 'accent',
  mb: ['4', null, '5'],
  pt: [null, null, '1'],
  gap: '2',
};

export const ButtonSubmit: CSSObject = {
  color: 'blackAlpha.700',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 'xs',
};

export const RadioLimitChoice: CSSObject = {
  bg: 'white',
  border: 'none',
  mb: '2',
  '&[data-checked]': {
    bg: 'gamma.500',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'gamma.500',
  },
  '&[data-checked]:hover': {
    bg: 'gamma.600',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'gamma.600',
  },
};
