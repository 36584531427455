/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { usePunterData } from '@/store/AuthStore';
import { trackEvent } from '@/lib/analytics';
import Empty from './Empty/Empty';
import { DisplayedBetTypes } from '../../Services/Betslip.config';
import Footer from './Footer/Footer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  EBetSlipBetSubmissionType,
  EBetSlipViewMode,
  TBetSlipBet,
  TBetSlipBetMulti,
} from '../../Services/Betslip.types';
import Placed from './Placed/Placed';
import { useBetsCount, useSupportedBets } from '../../Services/Betslip.hooks';
import GeneralError from './GeneralError';
import BetCard from '../BetCard';
import Group from './Group/Group';
import EmptyBetsModal from '../BetSlipEmptyModal';
import {
  checkOddsDecreased,
  checkOddsIncreased,
} from '../../Services/Betslip.utils';
import { OddsChangedBanner } from '../OddsChangedBanner/OddsChangedBanner';
import {
  setBetIDFocused,
  setHasKeypad,
} from '../../../../redux/Betslip.slices';
import { BetslipVStack, FlexBetGroupContainer } from './Modal.styles';
import {
  TMoMSchema,
  getBetSlipStoreActions,
  transformBetForLegacy,
  useBetSlipBets,
  useMoMBet,
  useMoMBetError,
} from '@/store/BetSlipStore';
import { BetslipRefSchema } from './ModalWeb';
import { useAuth } from '@/hooks/useAuth';
import { checkMultiValidity } from '../MultiCard/Services/MultiBet.utils';
import LuckyDipContainer from '../LuckyDip/LuckyDip';
import EmptyMulti from '../MultiCard/EmptyMulti';
import { useMapErrorToReason } from './MoM/utils';
import { EmptyMoM } from './MoM/InvalidMoM/InvalidMoM';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { MomCard } from './MoM/MoMCard';
import { useQueryPromotionPreferences } from '@/api/preferences/preferences.hooks';
import { useQuerySupportedBets } from '@/api/punter/supportedBets/supportedBets.hooks';
import { PlacedTreasureHunt } from './Placed/PlacedTreasureHunt';

/** Types */
type TUseModalContainerProps = {
  bets: TBetSlipBet[];
};

type TUseModalProps = Pick<TModalViewProps, 'bets' | 'moMBet'>;

type TModalViewProps = {
  bets: TBetSlipBet[];
  betslipRef: BetslipRefSchema;
  betsTypesToDisplay: EBetSlipBetSubmissionType[];
  viewMode: EBetSlipViewMode;
  generalError: boolean;
  hasKeypad: boolean;
  hasOddsChange: boolean;
  oddsDecreased: boolean | undefined;
  oddsIncreased: boolean | undefined;
  allBetsPlaced: boolean;
  betsCount: number;
  getDisplayBetType: (
    betType: EBetSlipBetSubmissionType
  ) => boolean | undefined;
  showLuckyDip: boolean;
  invalidMulti?: TBetSlipBetMulti;
  moMBet: TMoMSchema | null;
  moMBetError: string | null;
  showTreasureHunt: string;
};
/** Types */

/** View */
const ModalView = ({
  bets,
  betslipRef,
  betsTypesToDisplay,
  viewMode,
  generalError,
  hasKeypad,
  hasOddsChange,
  oddsDecreased,
  oddsIncreased,
  allBetsPlaced,
  betsCount,
  getDisplayBetType,
  showLuckyDip,
  invalidMulti,
  showTreasureHunt,
  moMBet,
  moMBetError,
}: TModalViewProps) => {
  const hasOddsBanner = hasOddsChange && (oddsDecreased || oddsIncreased);
  const bonusChance = useAppSelector((store) => store.betSlip.bonusChance);
  const hasMulti = bets.find((b) => b.id === 'multi') || moMBet;
  const { data } = useQueryPromotionPreferences();
  const spinWheelEnabled = data?.length
    ? data?.[0]?.preference_value === 'Enabled'
    : true;

  const invalidMoMBetReason = useMapErrorToReason(moMBetError);
  const isPromoWin = bets.some(
    (bet) =>
      bet?.confirmation?.gameplay || bet?.confirmation?.promo_tokens.length > 0
  );

  return (
    <>
      <EmptyBetsModal isOpen={viewMode === EBetSlipViewMode.EmptyBets} />

      {generalError && <GeneralError />}
      {showTreasureHunt === '' &&
        allBetsPlaced &&
        (showLuckyDip && spinWheelEnabled ? (
          <LuckyDipContainer isWin={isPromoWin} bonusChance={bonusChance} />
        ) : (
          <Placed />
        ))}
      {/* <LuckyDipContainer /> */}
      {showTreasureHunt !== '' && allBetsPlaced && (
        <PlacedTreasureHunt isWin={showTreasureHunt === 'treasure-hunt'} />
      )}

      <FlexBetGroupContainer isKeypadVisible={hasKeypad}>
        {betsCount === 0 && !moMBet ? (
          <Empty />
        ) : (
          <BetslipVStack
            mb={hasKeypad ? '145px' : undefined}
            ref={(el: HTMLDivElement) => {
              if (betslipRef?.current) {
                betslipRef.current.betsGroupRef.current = el;
              }
            }}
          >
            {betsTypesToDisplay.map((betType, idx) => {
              const filteredBets = bets?.filter((bet) => bet.type === betType);
              const displayBetType = getDisplayBetType(betType);
              const { ReviewingBets } = EBetSlipViewMode;
              const notValidBet =
                !displayBetType ||
                (viewMode === ReviewingBets &&
                  !filteredBets.some((bet) => Number(bet.stake) > 0));

              /**
               * If punter is reviewing their bets, hide any bet
               * type if no stake has been entered.
               */
              if (notValidBet) return null;

              if (
                FEATURE_FLAGS.REACT_APP_MOM_ENABLED &&
                betType === EBetSlipBetSubmissionType.Multi
              ) {
                return null;
              }

              return (
                <Flex
                  id="groupFlex"
                  key={idx}
                  w="100%"
                  flexDir="column"
                  gap="1.5"
                >
                  <Group key={betType} type={betType} bets={filteredBets}>
                    <AnimatePresence exitBeforeEnter>
                      {filteredBets.map((bet) => (
                        <BetCard
                          key={bet.request_id}
                          bet={bet}
                          betslipRef={betslipRef}
                          viewMode={viewMode}
                        />
                      ))}
                    </AnimatePresence>
                  </Group>
                </Flex>
              );
            })}

            {!FEATURE_FLAGS.REACT_APP_MOM_ENABLED && !hasMulti && invalidMulti && (
              <Group
                type={EBetSlipBetSubmissionType.Multi}
                bets={[invalidMulti]}
              >
                <EmptyMulti bet={invalidMulti} />
              </Group>
            )}

            {FEATURE_FLAGS.REACT_APP_MOM_ENABLED &&
              (moMBet?.betSlipStatus === viewMode ||
                (viewMode === EBetSlipViewMode.EditingBets && moMBetError)) && (
                // if there is a mombet and the mombet is the same view as the slip
                <Group type={EBetSlipBetSubmissionType.Multi}>
                  {moMBet && <MomCard moMBet={moMBet} />}
                  {moMBetError && <EmptyMoM reason={invalidMoMBetReason} />}
                </Group>
              )}
          </BetslipVStack>
        )}
      </FlexBetGroupContainer>

      {hasOddsBanner && (
        <>{oddsDecreased && <OddsChangedBanner direction="decrease" />}</>
      )}

      {(betsCount > 0 || moMBet) && <Footer />}
    </>
  );
};
/** View */

/** Controllers */
/**
 * This controller is specifically used within the Zust Container.
 * Once we fully deprecate Redux - this can be moved into the useModal hook.
 */
const useModalContainer = ({ bets }: TUseModalContainerProps) => {
  const [invalidMulti, setInvalidMulti] = useState<TBetSlipBetMulti>();
  const { setBet, updateBet, removeBet } = getBetSlipStoreActions();
  const { multi: hasMulti } = useSupportedBets()?.supportedBets ?? {};
  const { isAuthenticated } = useAuth();
  const isMultiSupported = !isAuthenticated ? true : hasMulti;
  const betsCount = useRef(0);

  const betsEligibleForMulti = bets.filter(
    (bet) => bet.type === 'Single' && !bet.confirmation
  ) as any[];

  /**
   * This hook handles the creation/update/removal of multis.
   */
  useEffect(() => {
    if (isMultiSupported) {
      const multiExists = bets.find(
        (b) => b.id === 'multi'
      ) as TBetSlipBetMulti;

      if (betsEligibleForMulti.length >= 2) {
        const legs = betsEligibleForMulti?.map((bet) => {
          const isSingle = bet.type === 'Single';
          const isSport = isSingle && !bet.priceType;

          return {
            event_id: isSport ? bet.misc.match_id : bet.event_id,
            proposition_id: bet.proposition_id ?? '',
            odds: bet.odds,
            event_icon: bet.event_icon,
            event_market_name: bet.event_market_name,
            event_start_time: isSport
              ? bet.misc.match_start_time
              : bet.event_start_time,
            runner_id: bet.runner_id,
            event_title: bet.event_title,
            event_subtitle: bet.event_subtitle,
            event_data: bet.event_data,
            event_type: bet.event_type,
            eventRule: bet.eventRule,
            eventClosed: false,
            price_type: bet.price_type,
          };
        });

        if (multiExists) {
          const validMulti = checkMultiValidity(multiExists);

          if (!validMulti) {
            removeBet('multi');
          } else if (multiExists.legs.length !== betsEligibleForMulti.length) {
            updateBet({ id: 'multi', values: { legs } });
          }
        } else {
          const _bet = {
            id: 'multi',
            type: 'Multi',
            stake: '',
            legs,
          } as TBetSlipBetMulti;
          const validMulti = checkMultiValidity(_bet);

          if (validMulti) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setBet(_bet as any);
          } else if (!invalidMulti) {
            /**
             * Updates the state to reflect the specific reasons a multi bet
             * is considered invalid which provides the user feedback on
             * the validation failure of a multi bet, enabling a clear
             * understanding of necessary adjustments.
             */
            setInvalidMulti(_bet);
          }
        }
      }

      if (betsEligibleForMulti.length < 2) {
        setInvalidMulti(undefined);
      }

      if (
        betsEligibleForMulti.length < 2 &&
        multiExists &&
        !multiExists.confirmation
      ) {
        removeBet('multi');
      }
    }
  }, [
    bets,
    betsEligibleForMulti,
    invalidMulti,
    isMultiSupported,
    removeBet,
    setBet,
    updateBet,
  ]);

  // When adding new bets after a successful bet placement, reset the state.
  useEffect(() => {
    if (betsCount.current < bets.length) {
      updateBet((store) => {
        const _bets = { ...(store ?? {}) };
        const keys = Object.keys(_bets);
        const placedBetKeys = keys.filter(
          (key) => _bets[key].confirmation?.status === 'Placed'
        );
        placedBetKeys.forEach((key) => delete _bets[key]);
        return _bets;
      });
    }
    betsCount.current = bets.length;
  }, [bets.length, updateBet]);

  return {
    invalidMulti,
  };
};

const useModal = ({
  bets,
  moMBet,
}: TUseModalProps): Omit<
  TModalViewProps,
  'bets' | 'betslipRef' | 'moMBet' | 'moMBetError'
> => {
  const dispatch = useAppDispatch();
  const { data: supportedBets } = useQuerySupportedBets();
  const supportsLD = supportedBets?.lucky_dip;
  const punterProfile = usePunterData();
  const {
    viewMode,
    generalError,
    hasKeypad,
    displayOddsChangeBanner,
    bonusChance,
  } = useAppSelector((state) => state.betSlip);
  const oddsDecreased = checkOddsDecreased(
    bets,
    undefined,
    moMBet ?? undefined
  );
  const oddsIncreased = checkOddsIncreased(
    bets,
    undefined,
    moMBet ?? undefined
  );
  const { allBetsPlaced, betsCount, someBetsPlaced } = useBetsCount({ bets });
  const { getDisplayBetType } = useSupportedBets();

  const betsTypesToDisplay = DisplayedBetTypes.filter((type) =>
    bets?.some((bet) => type === bet.type)
  );

  /**
   * TODO: This should be deprecated now
   * reset the keypad if there is nothing to bet on
   */
  useEffect(() => {
    if (!betsTypesToDisplay.length) {
      dispatch(setBetIDFocused(''));
      dispatch(setHasKeypad(false));
    }
  }, [betsTypesToDisplay.length, dispatch]);

  useEffect(() => {
    // If any bets are placed send a analytics event
    if (someBetsPlaced) {
      trackEvent('betPlaced', { punterId: punterProfile?.punter_id });
    }
  }, [someBetsPlaced, punterProfile?.punter_id]);

  let showTreasureHunt = '';
  if (bets.length > 0) {
    bets.forEach((bet) => {
      if (bet?.confirmation?.gameplay?.game === 'treasure-hunt') {
        showTreasureHunt = bet?.confirmation?.gameplay?.game;
        return;
      }
      if (
        bet?.confirmation?.gameplay?.game === 'no-treasure' &&
        showTreasureHunt === ''
      ) {
        showTreasureHunt = bet?.confirmation?.gameplay?.game;
      }
    });
  }

  return {
    betsTypesToDisplay,
    viewMode,
    generalError,
    hasKeypad,
    hasOddsChange: displayOddsChangeBanner,
    oddsDecreased,
    oddsIncreased,
    allBetsPlaced,
    betsCount,
    showTreasureHunt,
    getDisplayBetType,
    showLuckyDip: (bonusChance?.isLDVisible && supportsLD) || false,
  };
};
/** Controllers */

/** Containers */
/**
 * Once we've fully migrated out of redux, this should be the only container.
 */
function ModalContainer(
  props: Pick<TModalViewProps, 'bets' | 'betslipRef' | 'invalidMulti'> & {
    moMBet: TMoMSchema | null;
    moMBetError: string | null;
  }
) {
  const { bets, moMBet, moMBetError } = props;
  const values = useModal({ bets, moMBet });
  return (
    <ModalView
      {...props}
      {...values}
      moMBet={moMBet}
      moMBetError={moMBetError}
    />
  );
}

const ModalReduxContainer = (props: Pick<TModalViewProps, 'betslipRef'>) => {
  const { betslipRef } = props;
  const bets = useAppSelector((state) => state.betSlip.bets);
  return (
    <ModalContainer
      bets={bets}
      betslipRef={betslipRef}
      moMBet={null}
      moMBetError={null}
    />
  );
};

const ModalZustandContainer = (props: Pick<TModalViewProps, 'betslipRef'>) => {
  const { betslipRef } = props;
  const betsNew = useBetSlipBets() ?? {};

  const moMBet = useMoMBet();
  const moMBetError = useMoMBetError();

  const keys = Object.keys(betsNew);
  const bets = keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  const { invalidMulti } = useModalContainer({ bets });

  return (
    <ModalContainer
      moMBet={moMBet}
      moMBetError={moMBetError}
      bets={bets}
      betslipRef={betslipRef}
      invalidMulti={invalidMulti}
    />
  );
};

export default FEATURE_FLAGS.HAS_NEW_BS
  ? ModalZustandContainer
  : ModalReduxContainer;

/** Containers */
