import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading': 'Ready to place some bets?',
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your Racing and Sports selections.',
  'onboarding.locationnotallowed.header': 'The sands are out of reach',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing PyramidBet from outside of Australia',
  'error.heading': 'The sands have shifted',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.heading': `Pharaoh’s workers are busy`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
};
