import { Search } from '@styled-icons/bootstrap/Search';
import { CompetitionListSchema } from '@/views/sports/components/CompetitionList/styles/CompetitionList.styles';

export const competitionListStyles: CompetitionListSchema = {
  skeleton: {
    startColor: 'beta.300',
    endColor: 'beta.400',
    minW: 'full',
    h: '20',
    borderRadius: 'lg',
    flex: 1,
    mt: '8',
  },
  input: {
    name: 'x',
    sxWrapper: {
      mb: '0',
    },
    border: '1px',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
    borderRadius: 'md',
    color: 'white',
    mb: '0',
    h: '34px',
    lineHeight: '60px',
    textShadow: 'none',

    _hover: {
      '&&': {
        bg: 'blackAlpha.600',
      },
      borderColor: 'alpha.300',
      color: 'white',
    },

    _focus: {
      '&&': {
        bg: 'blackAlpha.600',
        color: 'alpha.300',
      },
      boxShadow: 'xl',
      borderColor: 'alpha.300',
      color: 'white',
      textShadow: 'sm',
    },
    _placeholder: {
      color: 'whiteAlpha.500',
      fontWeight: 'bold',
      fontSize: '2xs',
    },
    sx: {
      '&&': {
        bg: 'blackAlpha.600',
      },
    },
  },
  inputRightIcon: {
    boxSize: '5',
    color: 'alpha.300',
    as: Search,
    sx: {},
  },
  accordion: {
    color: 'white',
    mb: '3',
    variant: 'unstyled',
    w: 'full',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    py: '1',
    borderTop: '1px',
    borderTopColor: 'blackAlpha.300',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    _first: {
      borderTop: 'none',
      pt: '0',
    },
  },
  accordionButton: {
    px: '0',
    pos: 'relative',
    _hover: { bg: 'transparent' },
  },
  hStack: {
    w: 'full',
    alignItems: 'center',
  },
  flex: { alignItems: 'center', gap: 2, flex: 1, textAlign: 'left' },
  accordionButtonIconSvg: {
    color: 'gamma.400',
    fontSize: 'md',
    sx: {},
  },
  accordionButtonText: {
    fontSize: 'md',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'gamma.400',
  },
  marketTypesFlex: {
    mr: '2',
    gap: '2',
  },
  marketTypesText: {
    w: ['50px', '100px'],
    fontSize: '2xs',
    fontWeight: 'bold',
  },
  accordionButtonInnerIcon: {
    pos: 'absolute',
    right: '0',
    boxSize: '6',
    color: 'beta.800',
    sx: {},
  },
  accordionPanel: {
    p: '0',
    mb: '3',
  },
};
