import { BoxProps, CSSObject } from '@chakra-ui/react';

export const wrapperProps: BoxProps = {
  h: '100%',
  minW: ['unset', null, '460px'],
  maxW: ['100vw', null, null],
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'body',
  sx: {
    'div:first-child': {
      display: 'flex',
      flexDir: 'column',
      fontSize: 'xs',
      fontWeight: 'bold',
      alignItems: 'flex-start',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mb: '1',
      },
      '.chakra-link': {
        mb: '4',
        color: 'gamma.100',
        textTransform: 'unset',
        fontSize: 10,
      },
    },
  },
  bg: 'alpha.300',
  borderTop: ['2px', '2px', '0px'],
  borderColor: 'beta.400',
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',
  '+ a': {
    display: 'none',
  },
};

export const linkLoginProps: CSSObject = {
  fontWeight: 'bold',
  color: 'beta.500',
  textTransform: 'uppercase',
  fontSize: 'xs',
};

export const ForgotPasswordButton: CSSObject = {
  color: 'alpha.500',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  textShadow: 'none',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 'xs',
};

export const Title: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
