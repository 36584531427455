import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: { fontSize: 'md', color: 'alpha.600' },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: ['2', null, null, '0'],
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
    mt: '2',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'gray.700',
  },
  scrollButtonGroup: {
    variant: 'filter',
    bg: 'none',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: 'blackAlpha.400',
    borderRadius: 'none',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
    pb: '3',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    gap: '1',
    color: 'alpha.600',
    fontWeight: 'medium',
  },
  headingIcon: {
    color: 'alpha.600',
    sx: {},
  },
  propositionButton: {
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    border: '1px',
    borderColor: 'whiteAlpha.400',
  },
  buttonSport: {
    borderColor: 'delta.500',
    _hover: {
      color: 'delta.500',
    },
    _active: {
      bgGradient: 'linear(to-b, delta.500, delta.500)',
      border: 'none',
      color: 'alpha.700',
      '&:after': {
        display: 'none',
      },
    },
  },
  buttonAllSports: {
    _active: {
      bgGradient: 'linear(to-b, delta.500, delta.500)',
      border: 'none',
      color: 'alpha.700',
      '&:after': {
        display: 'none',
      },
    },
  },
  pageButtonWellWrapperOverride: {
    borderRadius: 'md',
  },
};
