import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.5))',
    },
  },
  iconButtonScrollRight: {
    variant: 'solid',
    color: 'blackAlpha.800',
    border: 'none',
    h: 9,
    w: 4,
    mt: 'px',
    ml: '1',
    minW: 'unset',
    borderRightRadius: 'base',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.2)',
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
      ':before': {
        borderRightRadius: 'sm',
        borderLeftRadius: 'none',
      },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.5))',
    },
  },
  iconButtonScrollLeft: {
    variant: 'solid',
    color: 'blackAlpha.800',
    border: 'none',
    h: 9,
    w: 4,
    mt: 'px',
    minW: 'unset',
    borderLeftRadius: 'base',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    sx: {
      ':active': {
        '&:after': {
          content: 'none',
        },
      },
      ':before': {
        borderRightRadius: 'none',
        borderLeftRadius: 'sm',
      },
    },
  },
  flexWrapper: {
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  },
  buttonGroupSports: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    borderRadius: 'base',
    display: 'inline-flex',
    alignItems: 'center',
    flex: '1',
    overflowX: 'auto',
    pl: '1',
    py: ['1', null, '0'],
    spacing: '1',
    whiteSpace: 'nowrap',
    sx: {
      '::-webkit-scrollbar': { display: 'none' },
      '> button:first-child': {
        _after: {
          display: 'none',
        },
      },
    },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'block'],
    ml: '1',
    isAttached: true,
  },
};
