import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';
import gradientBorder from '@/assets/betstride/images/gradient-border.svg';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    pt: [IS_MOBILE_APP ? '2' : '0', null, 'unset'],
    mx: 'unset',
    mb: 4,
    bg: ['transparent', null, null, 'rgba(0, 209, 255, 0.25)'],
    boxShadow: [
      'unset',
      null,
      null,
      '0px 2px 20px 0px rgba(0, 0, 0, 0.4), 0px 0px 5px 0px rgba(33, 193, 255, 0.25) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset',
    ],
    borderRadius: 'md',
    pos: 'relative',
    _before: {
      content: ['none', null, null, '""'],
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
      borderImage: `url(${gradientBorder}) 50`,
      pointerEvents: 'none',
      borderImageWidth: '50px',
    },
  },
  boxHeading: {
    pt: '0',
    mb: 0,
    ...(!IS_MOBILE_APP && {
      pb: '9',
    }),
    color: 'white',
  },
  textTitle: {
    mt: '0',
    color: 'beta.300',
    fontSize: 'lg',
    fontWeight: 'bold',
    fontFamily: 'accent',
    textTransform: 'uppercase',
    textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderBottomRadius: 'md',
        pb: '2.5',
        display: 'flex',
        gap: '4',
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pb: '2',
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: '100%',
    boxShadow:
      '0px 4px 6px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
    borderRadius: 'md',
    px: '3',
    py: '3',
    bg: 'rgba(0, 209, 255, 0.25)',
  },
};
export const BoxHeading: CSSObject = {
  pt: '0',
  mb: 0,
  ...(!IS_MOBILE_APP && {
    pb: '9',
  }),
  color: 'white',
};
