import { NewMatchCardSchema } from '@/views/sports/components/NewMatchCard/styles/NewMatchCard.styles';

export const newMatchCardStyles: NewMatchCardSchema = {
  boxWrapper: {
    border: '1px solid',
    borderColor: 'blackAlpha.300',
    borderRadius: 'lg',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    overflow: 'hidden',
    padding: '2px',
  },
  bodyStack: {
    p: 2,
    borderTopRadius: 'base',
    m: '0',
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    fontWeight: 'bold',
  },
  titleHeading: {
    cursor: 'pointer',
    as: 'h5',
    textTransform: 'uppercase',
    textAlign: 'start',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'bold',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.24)',
    color: 'white',
  },
  footerFlex: {
    as: 'footer',
    bg: 'alpha.700',
    boxShadow: '0px -1px 0px 0px rgba(255, 255, 255, 0.1)',
    fontSize: '2xs',
    fontWeight: 'semibold',
    px: '1',
    py: '1',
    borderTop: '1px',
    borderColor: 'blackAlpha.300',
    m: '0',
    borderBottomRadius: 'base',
    alignItems: 'center',
    gap: '1',
  },
  footerStack: {
    direction: 'row',
  },
  footerStackDivider: {
    height: '2.5',
    borderColor: 'whiteAlpha.400',
  },
  footerSportNameFlex: {
    align: 'center',
    gap: '1',
    maxW: '85%',
    w: 'fit-content',
    mr: '2',
  },
  footerSportNameIconSvg: {
    color: 'beta.300',
    fontSize: 'md',
  },
  footerCompetitionNameText: {
    color: 'white',
    fontWeight: 'normal',
    sx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 'initial',
    },
  },
  footerCountDown: {
    sxWrapper: {
      mr: 'auto',
    },
  },
  footerDuotoneSgmLogo: {
    h: 'auto',
    primaryColor: 'alpha.500',
    secondaryColor: 'beta.600',
    w: '10',
    sx: {
      svg: {
        color: 'beta.300',
      },
    },
  },
  footerMatchLink: {
    alignItems: 'center',
    display: 'flex',
    lineHeight: 'initial',
    fontWeight: 'normal',
  },
  footerMatchLinkIcon: {
    color: 'white',
    fontSize: 'md',
  },
};
