import { MatchDetailsStylesSchema } from './NewMatchDetailsPage.styles';

export const matchDetailsStyles: MatchDetailsStylesSchema = {
  headerFlex: {
    flexDir: 'row',
    justify: 'space-between',
    gap: ['2', null, 'unset'],
  },
  headerHeading: {
    alignItems: 'center',
    display: 'flex',
    flexDir: 'row',
    fontSize: 'lg',
    fontWeight: 'bold',
    fontFamily: 'accent',
    color: 'white',
    textTransform: 'uppercase',
    sx: {
      'span:not(.chakra-badge)': {
        whiteSpace: 'normal',
      },
    },
  },
  headerIconButton: {
    color: 'white',
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: {
      opacity: '.8',
    },
  },
  headerSkeletonText: {
    sx: {
      '.state-prior': { color: 'white' },
    },
  },
  headerVsBox: {
    color: 'beta.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  bodyStack: {
    color: 'white',
    p: '0',
    minH: '90vh',
  },
  bodyInnerWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sgmLogo: {
    color: 'beta.500',
    h: '1.1em',
    ml: '2',
    mb: '0.5',
    w: 'auto',
  },
  scrollButtonGroup: {
    h: '9',
    variant: 'filter',
    boxShadow: 'none',
    w: 'full',
    sx: {
      'div:last-child': {
        maxH: '34px',
        button: {
          mt: '1px',
        },
      },
    },
  },
  wrapperScrollSgm: {
    gap: '2',
    alignItems: 'center',
    display: 'flex',
    w: 'full',
  },
  toMarketButton: {
    _first: {
      '&[data-active]': {
        _after: {
          display: 'none',
        },
      },
    },
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
    _last: { mr: 2 },
  },

  toMarketButtonV2: {
    minW: 'fit-content',
    px: '2',
    variant: 'tab',
    sx: {
      '&[data-active]': {
        color: 'beta.300',
        fontWeight: 'bold',
        borderBottom: '3px solid',
        borderBottomColor: 'beta.300',
      },
    },
  },
  loadingSkeletonText: {
    my: '4',
    spacing: '4',
    skeletonHeight: '2',
  },
  accordion: {
    display: 'flex',
    flexDir: ['column', null, null, 'row'],
    mx: '-1',
  },
  accordionStack: {
    px: '1',
    w: { lg: '50%' },
    _notFirst: {
      mt: ['2', null, null, '0'],
    },
  },
  accordionItem: {
    borderRadius: 'md',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(255, 255, 255, 0.16)',
    overflow: 'hidden',
  },
  accordionButton: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: 'bold',
    fontFamily: 'body',
    py: '2',
    px: '1',
    mx: '1',
    w: '99%',
    pos: 'relative',
    textAlign: 'left',
    _after: {
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.400',
      bottom: '0',
      boxShadow: '0 1px rgba(255, 255, 255, .07)',
      content: '""',
      left: '0',
      opacity: '0',
      pos: 'absolute',
      right: '0',
      transition: 'inherit',
      zIndex: '1',
    },
    _expanded: {
      _after: { opacity: '1' },
    },
  },

  marketLayoutIconFlex: {
    align: 'center',
    flex: '1',
    gap: '1',
  },
  marketLayoutTooltip: {
    fontSize: 'xs',
  },
  marketLayoutIcon: {
    color: 'white',
    fontSize: 'lg',
  },
  marketLoadingCenter: {
    boxSize: '6',
  },
  centerLoadingSpinner: {
    color: 'white',
    size: 2,
  },
  marketLayoutLoadedIcon: {
    color: 'white',
    fontSize: '2xl',
  },
  marketLayoutAccordionPanel: {
    p: '0',
  },
  cancelButton: {
    variant: 'solid',
    px: '3',
  },
  clearButton: {
    variant: 'solid',
    ml: '3',
    px: '3',
  },
  accordionItemActive: {
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(255, 255, 255, 0.16)',
  },
  scrollButtonGroupV2: {
    variant: 'tab',
    flex: 1,
    position: 'relative',
    minH: '34px',
    bg: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    sx: {
      'div:first-child': {
        pt: '0.5',
        pl: '3',
      },
      'div:last-child': {
        maxH: '34px',
        button: {
          mt: '0',
        },
      },
      ':has(+ button)': {
        w: 'calc(100% - 90px)',
      },
    },
  },
  sgmLogoV2: {
    w: '3em',
    h: '2em',
  },
  sgmBtn: {
    sx: {
      '&&': {
        px: '3',
      },
      'svg[class*="StyledIconBase"]': {
        w: '1.5em',
        h: '1.5em',
      },
    },
  },
  sgmBtnActive: {
    sx: {
      '&&': {
        px: '3',
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'beta.400',
      },
      'svg[class*="StyledIconBase"]': {
        w: '1.5em',
        h: '1.5em',
      },
    },
  },
  countdown: {
    sx: {
      textTransform: 'capitalize',
    },
  },
};
