import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { 
    spacing: '1', 
    pt: '2',
    sx: {
      '&[data-isonsportsall="true"]': {
        pb: '4',
        mb: '2',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderColor: '#192B3B',
        borderRadius: 'none',
        boxShadow: '0px 1px 0px 0px #FFFFFF12',
      },
    },
  },
  link: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'base',
    display: 'flex',
    flex: ['1', null, null, '0 1 auto'],
    fontSize: '2xs',
    fontWeight: 'bold',
    pos: 'relative',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    textAlign: 'center',
    textTransform: 'uppercase',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    zIndex: '1',
    color: 'white',
    sx: {
      '&.active': {
        bgGradient: 'linear(to-b, alpha.400, alpha.500)',
        boxShadow:
          '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px rgba(255, 255, 255, 0.84) inset',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
        fontWeight: 'extrabold',
        fontSize: '2xs',
        _before: {
          display: 'none',
        },
        _after: {
          display: 'none',
        },
        svg: {
          color: 'beta.800',
        },
      },
    },
  },
};
