import { CompetitionsMatchCardSchema } from './CompetitionsMatchCard.styles';

export const competitionsMatchCardStyles: CompetitionsMatchCardSchema = {
  vsBoxWrapper: {
    position: 'relative',
    p: '1',
  },
  vsDivider: {
    borderStyle: 'dashed',
    borderTop: '1px dashed',
    borderTopColor: 'rgba(0, 0, 0, 0.25)',
    borderBottom: '1px dashed',
    borderBottomColor: 'rgba(255, 255, 255, 0.07)',
    ml: '20px',
    opacity: '1',
    w: '97%',
  },
  absoluteCenter: {
    px: '2',
    left: '2',
    fontWeight: '',
    color: 'beta.300',
    fontFamily: 'accent',
    fontSize: 'xs',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
  matchCardOverride: {
    mb: '2',
    _last: {
      mb: '0',
    },
  },
  propositionButtonOverride: {
    w: '100%',
  },
  gridItem: {
    w: '100%',
  },
  flexWrapper: {
    alignItems: 'center',
  },
  flexText: {
    as: 'span',
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'accent',
    fontSize: 'xs',
    color: 'white',
    textTransform: 'capitalize',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
  gridWrapper: {
    gap: 2,
  },
  propositionButtonOverrideSecondary: {
    variant: 'odds',
    w: ['50px', '100px'],
    px: ['0', null, 'inherit'],
    lineHeight: 'normal',
  },
  gridItemTextNoProposition: {
    w: ['50px', '100px'],
    h: 'full',
    minH: '10',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'bold',
    fontSize: 'xs',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'md',
    border: '2px',
    borderColor: 'whiteAlpha.200',
    bg: 'transparent',
  },
  flexAwayTeam: {
    alignItems: 'center',
  },
  flexAwayTeamText: {
    flex: '1',
    textAlign: 'left',
    fontFamily: 'accent',
    fontWeight: 'bold',
    fontSize: 'xs',
    color: 'white',
    textTransform: 'capitalize',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
};
