import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/pyramidbet/images/onboardingAside.png';
import bgMobile from '@/assets/pyramidbet/images/onboardingAsideMb.png';

export const WrapperAside: CSSObject = {
  bg: [
    `url(${bgMobile})`,
    null,
    `url(${bgDesktop}), var(--bc-colors-beta-900)`,
  ],
  bgSize: ['cover', null, 'contain'],
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['alpha.300', null, 'unset'],
  h: ['50vw', '55vw', 'full'],
  borderLeftRadius: [null, null, '10px'],
  borderRightRadius: ['unset'],
  flex: 1,
  a: {
    h: 52,
    img: {
      position: 'absolute',
      maxW: ['72vw', '265px', '220px'],
      top: '100px',
      left: ['200px', '280px', '150px'],
      transform: 'translate(-50%, -50%)',
    },
  },
  fontFamily: 'accent',
};
