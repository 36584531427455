import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/hyperbet/images/close-filter.svg';

const primary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  lineHeight: '1.1',
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.65)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.200, gamma.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  border: '1px solid',
  borderColor: 'transparent',
  _hover: {
    bgGradient: 'linear(to-b, gamma.500, gamma.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.45)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'gamma.300',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'beta.300',
  boxShadow: '0px 0px 10px 0px rgba(173, 241, 6, 0.7)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    borderColor: 'white',
    boxShadow: 'none',
    color: 'beta.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};

const quickAccess = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'bold',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.08)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  border: '1px solid',
  borderColor: 'transparent',
  _hover: {
    bgGradient: 'linear(to-b, beta.500, beta.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
    borderColor: 'rgba(255, 255, 255, 0.65)',
  },
  ':active, &[data-active="true"], &[aria-expanded="true"]': {
    bg: 'white',
    boxShadow: 'none',
    color: 'blackAlpha.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    borderBottomRadius: 'none',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const odds = {
  ...primary,
  fontFamily: 'heading',
  fontWeight: 'normal',
  color: 'blackAlpha.700',
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'whiteAlpha.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    pointerEvents: 'none',
  },
  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const tab = {
  boxShadow: 'none',
  fontSize: 'sm',
  fontWeight: 'normal',
  borderRadius: 'none',
  fontFamily: 'heading',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'blackAlpha.400',
  pt: '1',
  _hover: {
    color: 'beta.300',
  },
  ':active, &[data-active="true"]': {
    color: 'beta.300',
    fontWeight: 'normal',
    borderBottom: '3px solid',
    borderBottomColor: 'beta.300',
    textShadow: '0px 0px 9px #ADF1064D',
  },
};

const filter = {
  borderRadius: 'base',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: '7',
  minW: 'auto',
  p: '2',
  textTransform: 'uppercase',
  border: '2px',
  borderColor: 'white',
  bg: 'transparent',
  _hover: {
    color: 'gamma.300',
    borderColor: 'gamma.300',
    '&:after': {
      border: '1px solid',
      borderColor: 'gamma.300',
      content: '""',
      position: 'absolute',
      display: 'block',
      boxSize: '10px',
      top: '7px',
      right: '2.5',
      borderRadius: 'full',
      pr: '0',
      backgroundImage: `none`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
  _active: {
    bg: 'gamma.300',
    color: 'blackAlpha.700',
    border: 'none',
    '&:after': {
      border: 'none',
      content: '""',
      position: 'absolute',
      display: 'block',
      boxSize: '14px',
      top: '7px',
      right: '2',
      borderRadius: 'full',
      pr: '2',
      backgroundImage: `url(${CloseButton})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
};

const segment = {
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: '5',
  px: '3',
  py: '3px',
  textTransform: 'uppercase',
  bg: 'transparent',
  _hover: {
    color: 'alpha.400',
  },
  _active: {
    bg: 'alpha.400',
    color: 'alpha.700',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    unstyled: () => ({}),
    quickAccess: () => ({
      ...quickAccess,
    }),
    odds: () => ({
      ...odds,
    }),
    tab: () => ({
      ...tab,
    }),
    filter: () => ({
      ...filter,
    }),
    segment: () => ({
      ...segment,
    }),
  },
  defaultProps: { colorScheme: null },
};
