import { ZhKeysProps } from './zh';

export const zh: ZhKeysProps = {
  'header.betslipBtn.desc': '投注单',
  'betslip.betslipmodal.emptybetslipheading': "就像一块幸运饼干，其中的信息静待您来揭示。",
  'betslip.betslipmodal.emptybetslipsubheading':
  '您的投注单为空。如果要开始，请添加您的竞速和体育选项。',
  'onboarding.register.createAccount': '创建账户',
  'onboarding.step1.button': "立即加入",
  'onboarding.locationnotallowed.header': "龙在九天之外。",
  'onboarding.locationnotallowed.subheader': '您似乎正在从澳大利亚境外访问 GoldenBet888。',
  'maintenance.heading': '掌灯维护中。',
  'error.heading': "身陷龙潭之中。",
  'error.description': `<bold>我们对此深表歉意。已有工作人员正在处理此问题。</bold>
  与此同时，你可以刷新页面，或者稍后再试`,
  'maintenance.error': `<bold>W我们目前正在对网站进行维护。</bold>
  与此同时，你可以刷新页面，或者稍后再试`,
  'onboarding.forgotpassword.successtext': '謝謝。我們已經發送了一個重置鏈接到',
  'filters.location.ausnz': '澳新',
  'filters.location.intl': '国际化',
  'racing.racedetails.fixedheading': '固定',
  'racing.raceDetails.winHeading': '赢',
  'racing.raceDetails.winHeadingFO': '赢 FO',
  'racing.raceDetails.placeHeading': '地方',
  'racing.raceDetails.placeHeadingFO': '地方 FO',
  'racing.raceDetails.toteWinHeading': '赢 MD',
  'racing.raceDetails.bestToteWinHeading': '赢 BT',
  'racing.raceDetails.totePlaceHeading': '地方 MD',
  'racing.raceRunnerItem.runnerName':
    '{isHarnessRacing, select, true {{number}. {displayName}} other {{number}. {displayName} <span>({barrierNumber})</span>}}',
  'legal.responsible.tagline6': 'What are you really gambling with?',
  'generic.bestToteAcronym': 'BT',
  'generic.toteAcronym': 'MD',
  'generic.open': '打开',
  'generic.recent': '最近',
  'generic.fav': '最爱',
  'racing.raceRunnerItem.scratchedInfo':
  '{hasScratchTime, select, true {Scratched <time>{scratchTime, time, ::dd/MM/yy, H:mm}</time>} other {Scratched}}',
  'legal.responsible.tagline2': 'Think. Is this a bet you really want to place?','betslip.betslipbetcard.errors.propositionclosed': '此事件已关闭',
  'betslip.betslipbetcard.stakeprefix': '$',
  'generic.fixedWin': '固定赢',
  'racing.generic.numberRunner': 'No. / 赛马',
  'footer.enquiries': '如有任何疑问，请发送电子邮件至 <link>{email}</link>',
  'footer.footerparagraph':
  "{themeName} gambling operations are governed by our <rglink>Responsible Gambling Code of Conduct</rglink>. Think! About your choices. Call Gambler's Help or Gambling Help on <telLink>1800 858 858</telLink> or visit <link>gamblershelp.com.au</link> or <link>gamblinghelponline.org.au</link>. Call Gambler's Help Youthline on <telLink>1800 262 376</telLink>. Gamble Responsibly.",
  'footer.footerbottomtext':
  '关于南澳大利亚居民，我们的赌博活动也受南澳大利亚负责任赌博 <link>行为准则</link> 管辖。请考虑需要您支持的人。理性博彩。',
  'footer.footerbottomtextSaferTools':
  '我们提供一系列工具，帮助您保持理性博彩。请访问我们的 <rglink>安全工具</rglink> 页面了解更多信息。',
  'footer.betStop':
  'BetStop - 国家自我排除注册是一个免费服务，供希望从所有澳大利亚在线博彩提供商中排除的人使用。欲了解更多信息，请访问 <cta>www.betstop.gov.au</cta>',
  'account.overview.balance': '余额',
  'account.overview.bonusBets': '奖金投注',
  'account.overview.pendingWithdrawals': '待处理提款',
  'account.deposit.titles.main': '存款',
  'account.overview.withdrawable': '可提取',
  'account.overview.pendingBets': '待处理投注',
  'account.overview.details': '详情',
  'account.overview.dateOfBirth': '出生日期',
  'account.overview.toChange':
  '如果您需要更改任何信息，请联系我们的客服团队。',
  'account.overview.marketingPreferences': '营销偏好',
  'account.overview.logout': '登出',
  'account.promotionsnotifications.notificationsenabled':
  '通知已启用',
  'account.promotionsnotifications.email': '电子邮件',
  'account.promotionsnotifications.sms': 'SMS',
  'generic.savechanges': '保存更改',
  'generic.stakeWithValue': '赌注：{stake}',
  'account.mybetscard.potentialreturns': '预计回报',
  'account.deposit.creditCardMessage':
    '我们不会在您的信用卡上收取任何费用。',
  'account.withdraw.withdrawfundstitle': '提款',
  'account.withdraw.addaccount.makeawithdrawal': '提款',
  'account.withdraw.addaccount.withdrawaldescription':
  '提款可能需要 1-3 天才能到达您的账户。只有已经投注的资金才能提款。',
  'account.withdraw.addbank': '添加新银行账户',
  'account.withdraw.withdrawalamountlabel': '金额',
  'account.withdraw.withdrawalbalancelabel': '可用余额：',
  'account.withdraw.requests.status.pending': '待处理',
  'account.withdraw.requests.status.cancelled': '已取消',
  'account.withdraw.requests.status.approved': '已批准',
  'account.withdraw.requests.status.processed': '已处理',
  'account.withdraw.requests.status.error': '错误',
  'account.withdraw.requests.cancelbutton': '取消提款请求',
  'account.withdraw.increaseamountbutton.ten': '+$10',
  'account.withdraw.increaseamountbutton.twentyfive': '+$25',
  'account.withdraw.increaseamountbutton.fifty': '+$50',
  'account.withdraw.increaseamountbutton.hundred': '+$100',
  'account.withdraw.increaseamountbutton.twohundred': '+$200',
  'account.withdraw.increaseamountbutton.fivehundred': '+$500',
  'account.withdraw.requestwithdrawalbutton': '提款',
  'account.withdraw.requests.heading': '提款请求',
  'onboarding.login.footerInfo': '还没有账户？<link>立即注册</link>',
  'onboarding.login.formTitle': '登录以继续',
  'account.overview.promotionalPreferences': '推广偏好',
  'legal.responsible.tagline3': "What's gambling really costing you?",
  'legal.responsible.tagline5': 'Imagine what you could be buying instead.',
  'account.currentlimit.heading': '存款限额',
  'account.depositlimit.setlimit.subheading2':
  '如果您希望更改现有的存款限额，您可以随时这样做。增加您的限额需要一个 7 天的冷却期才能生效。然而，降低您的限额将立即生效。',
  'account.currentlimit.depositlimitlabel': '当前限额',
  'account.depositlimit.setlimit.inputplaceholder':
  '输入新的存款限额金额',
  'account.currentlimit.newlimitlabel': '限额金额',
  'account.currentlimit.limitperiodlabel': '限制期',
  'account.currentlimit.removelimitbuttontext': '删除限额',
  'account.currentlimit.savelimitbuttontext': '更新限额',
  'account.transactions.transactions.pagetitle': '交易',
  'account.transactions.type.credit': '信用',
  'account.transactions.type.bet': '投注',
  'account.transactions.eventDateTime': 'Event Date/Time',
  'account.transactions.id': 'ID',
  'account.transactions.betId': 'Bet ID',
  'account.transactions.type.winnings': '奖金',
  'legal.responsible.section.findOutMore.four.heading':
  'BetStop - National Self Exclusion Register',
  'legal.responsible.section.findOutMore.four.description':
  'If you feel you need to take a break or permanently self exclude from all bookmakers licensed in Australia, you can do this through the BetStop service. See the <cta>BetStop Website</cta> for more information. ',
  'legal.responsible.section.takeaction.depositlimit.one': 'Deposit Limit',
  'account.promotionpreferences.coinjar': 'Enable BetSlip Promotions',
  'legal.responsible.section.takeaction.deactivate.one': 'Close Account',
  'legal.responsible.section.takeaction.deactivate.two':
  'You can choose to close your account by deactivating it here, or by calling {phonenumber}.',
  'legal.responsible.section.takeaction.lifeexclusion.two':
  'A lifetime exclusion is permanent and cannot be undone. All of your accounts with {getThemeName} will be closed permanently. You will not be able to set up a new account in the future.',
  'legal.responsible.section.takeaction.depositlimit.two':
  'Set a reasonable budget and control the amount of money deposited from your personal funds that you can afford to bet with.',
  'legal.responsible.section.takeaction.break.one': 'Take A Break',
  'legal.responsible.section.takeaction.lifeexclusion.one': ' ',
  'legal.responsible.section.takeaction.break.two':
  'Take a break by suspending your account temporarily either for the remainder of the day or up to the next 6 months.',
  'legal.responsible.tagline1': "Chances are you're about to lose.",
};
