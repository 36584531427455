import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: '2xl',
    fontFamily: 'Spectral',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    textShadow: '0px 4px 3px rgba(0, 0, 0, 0.15)',
    color: 'white',
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    py: ['2', null, null, '4'],
    px: ['0', null, null, '4'],
  },
  boxWrapper: {
    alignItems: 'center',
    display: [null, null, null, 'flex'],
    justifyContent: 'space-between',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  scrollButtonGroup: {
    bg: 'transparent',
    variant: 'filter',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: '#0B3A5B',
    borderRadius: 'none',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    pb: '2',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'md',
    gap: '1',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: 'Spectral',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  headingIcon: {
    color: 'beta.500',
  },
  propositionButton: {
    flex: '1',
    height: '38px',
  },
  boxSportWrapper: {
    borderColor: 'whiteAlpha.300',
    borderWidth: '1px',
  },
};
