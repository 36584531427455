import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';

export const primary: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'gamma.400',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  color: 'gamma.900',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  fontWeight: '400',
  textTransform: 'uppercase',
  fontSize: '16px',
  fontFamily: 'accent',

  _disabled: {
    background: 'gray.400',
    color: 'gray.600',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow: 'none',
    border: 'none',
    opacity: '1',
    pointerEvents: 'none',
  },

  '&[data-active="true"]': {
    borderRadius: '6px !important',
    background: 'blackAlpha.400',
    boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.30) inset',
    color: 'beta.500',
    textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
  },

  _hover: {
    border: '2px',
    borderColor: 'gamma.400',
    opacity: 0.4,
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md', h: '34px', lineHeight: '34px' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      borderRadius: 'md',
      bg: 'beta.500',
      border: '2px',
      borderColor: 'white',
      boxShadow:
        '0px 2px 5px 0px rgba(0, 0, 0, 0.45), 0px 0px 0px 2px rgba(254, 186, 255, 0.70) inset',
      color: 'beta.700',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
      fontSize: '16px',
      fontWeight: '400',
      fontFamily: 'accent',
      textTransform: 'uppercase',

      _hover: {
        opacity: 0.4,
      },

      '&[data-active="true"]': {
        background: 'blackAlpha.400',
        boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.30) inset',
        color: 'beta.500',
        textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
        border: 'none',
      },
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'white',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'full',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
      cursor: 'auto !important',
    }),
    ghost: {
      ...primary,
      bg: undefined,
      boxShadow: undefined,
      textShadow: undefined,

      _hover: {
        bg: 'alpha.50',
      },

      _focus: undefined,

      _active: {
        bg: 'alpha.100',
      },
    },
    filter: {
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'extrabold',
      h: '7',
      minW: 'auto',
      p: '2',
      textTransform: 'uppercase',
      border: '2px',
      borderColor: 'beta.500',
      bg: 'transparent',
      _hover: {
        borderColor: 'beta.400',
      },
      _active: {
        bg: 'beta.500',
        color: 'blackAlpha.600',
        border: 'none',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        bg: 'gamma.500',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
    tab: {
      h: 6,
      color: 'gamma.900',
      bgGradient: 'linear(to-b, gamma.500, gamma.600)',
      fontSize: '2xs',
      textTransform: 'uppercase',
      borderRadius: 'base',
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
      fontWeight: 'bold',
      letterSpacing: '0.38px',
      minW: 'fit-content',
      px: '2',
      py: '1',
      borderWidth: '2px',
      borderColor: 'gamma.400',
      fontFamily: 'Montserrat',

      _hover: {
        opacity: '0.4',
      },

      span: {
        textShadow: '1px 1px 0px rgba(255, 255, 255, 0.50)',
      },

      '&[data-active="true"]': {
        color: 'beta.700',
        bgGradient: 'linear(to-b, beta.500, beta.500)',
        borderColor: 'beta.500',

        _hover: {
          opacity: '1',
        },
      },
    },
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
        _hover: {
          '&&': {
            border: 'none',
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
