import { CSSObject } from '@chakra-ui/react';

export const StartTime: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '8px',
  fontStyle: 'normal',
  fontFamily: 'accent',
  fontWeight: 'bold',
  color: 'white',
  borderRadius: '3px',
  bg: 'gray.500',
  px: '1',
  pt: '0.5',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
};
