import { MutableRefObject, ReactNode, RefObject } from 'react';
import { TPunterBetRequest } from '@/api/punter/punter.types';
import { EEventType, TPriceType } from '@/lib/DBModels';
import { DeepPartial } from '@/lib/types';
import { TExtendedProposition } from '@/views/sports/MatchDetailPage/services/MatchDetailPage.types';
import { TSelection } from '@/views/races/bets/SRMulti/components/Selections/services/Selections.hooks';
import { TSelections } from '@/views/races/bets/Blended/components/Selections/services/Blended.hooks';
import { TMysteryBetData } from '@/api/racing/mysteryBet/mysteryBet.types';

export type TBetSlipTypeGroupProps = {
  type: EBetSlipBetSubmissionType;
  bets?: TBetSlipBet[];
  children: ReactNode;
};

// 👀 If you change anything here ensure you update generateNewBetSlipBet in Betslip.utils.ts
export type TBetSlipBetBase = {
  id?: string;
  type: EBetSlipBetSubmissionType;
  request_id: string; // Unique per bet item. Generated by the generator
  is_bonus_bet: boolean;
  stake: number | string; // Stored as whole dollars
  gameplay?: TBetSlipBonusChanceType | null; // bonus chance promotional modals and applies to betslip
  stakeInputString?: string; // Entering .0 causes the stake to clear to 0, so this is used if punter wants to enter a stake under $0.10
  errors: string[]; // List of invalid fields for the bet
  confirmation?: TBetSubmissionConfirmation; // Placeholder for the returned confirmation from API
  event_id?: string;
};

export type TBetSlipBetAPIInput = {
  type: EBetSlipBetSubmissionType;
  request_id: string;
  is_bonus_bet?: boolean;
  stake: number;
  legs?: (TBetSlipMultiLeg | TBetSlipBlendedLeg)[];
  sub_type?: TBetSlipBetSubmissionExoticsType;
  selection?: number[][];
  odds?: number;
  proposition_id?: string;
  race_id?: string;
  win_odds?: number;
  place_odds?: number;
  field_size?: number;
  runner_id?: string;
  win_proposition_id?: string;
  place_proposition_id?: string;
  channel: string;
  price_type?: TPriceType;
  rollovers?: TRollover[];
};

export type TBetSlipMultiLeg = {
  proposition_id: string;
  odds: number;
  field_size?: number;
  price_type?: TPriceType;
};

export type TBetSlipBlendedLeg = {
  proposition_id: string;
};

export enum EOddsChangeDirection {
  Increase = 'Increase',
  Decrease = 'Decrease',
}

export type TMultiLeg = {
  event_id?: string;
  proposition_id: string;
  odds: number;
  event_icon?: string;
  event_type?: string;
  event_market_name: string;
  event_start_time: string;
  event_title: string;
  event_subtitle: string;
  runner_id?: string;
  isSelected?: boolean;
  eventRule?: EEventRule;
  event_data?: TEventData;
  oddsChangeDirection?: EOddsChangeDirection | undefined;
  // if a multi is submitted and rejected due to an event closing,
  // this should update to true.
  // we don't immediately clear this from the betslip in order to give the
  // the punter visibility on what has changed
  eventClosed?: boolean;
  price_type?: TPriceType;
};

export enum EBetSlipBetSubmissionType {
  ComboMulti = 'ComboMulti',
  Exotics = 'Exotics',
  Multi = 'Multi',
  EachWay = 'EachWay',
  Single = 'Single',
  SRMulti = 'SRMulti',
  SGMulti = 'SGMulti',
  ToteSingle = 'ToteSingle',
  Blended = 'Blended',
  EvenShot = 'even_shot',
  MysteryBet = 'mystery_bet',
  ToteMulti = 'ToteMulti',
}

export enum TBetSlipBetSubmissionExoticsType {
  Exacta = 'Exacta',
  Trifecta = 'Trifecta',
  Quinella = 'Quinella',
  FirstFour = 'FirstFour',
}

export type TBetSlipBonusChanceType = {
  game: string;
  variables: Record<string, unknown>;
  isVisible: boolean;
  isLDVisible: boolean;
};

export type TBetSlipBetExotics = TBetSlipBetBase &
  TBetSlipBetMeta & {
    sub_type: TBetSlipBetSubmissionExoticsType;
    selection: number[][];
    validSelectionAmount: number;
    proposition_id: string;
    hasBeenRounded?: boolean;
    showFlexiChangeBanner: boolean;
  };

export type TBetSlipBetSingle = TBetSlipBetBase &
  TBetSlipBetMeta & {
    odds: number;
    proposition_id: string;
    runner_id?: string;
    number_of_places?: number;
    race_id?: string;
    rollovers?: TRollover[];
  };

export type TBetSlipBetEachWay = TBetSlipBetBase &
  TBetSlipBetMeta & {
    win_odds: number;
    place_odds: number;
    field_size: number;
    runner_id: string;
    win_proposition_id: string;
    place_proposition_id: string;
    original_proposition_type?: string;
  };

export type TBetSlipBetMulti = TBetSlipBetBase &
  TBetSlipBetMeta & {
    legs: TMultiLeg[];
  };

export type TBetslipSRMSelection = {
  name: string;
};

export type TBetSlipBetSRMulti = TBetSlipBetBase &
  TBetSlipBetMeta & {
    odds: number;
    selection: TSelection[][] | number[][];
    race_id: string;
  };

export type TBlenededBetMulti = TBetSlipBetBase &
  TBetSlipBetMeta & {
    odds: number;
    selection: TSelections[];
    race_id: string;
  };

export type TBetSlipBetSGMulti = TBetSlipBetBase &
  TBetSlipBetMeta & {
    selections: TExtendedProposition[] | number[][];
    odds?: number;
  };

type TRollover = {
  race_id: string;
};
export type TBetSlipBetMystery = TBetSlipBetBase &
  TBetSlipBetMeta & {
    odds: number;
    race_id: string;
    rollovers?: TRollover[];
    mystery_bet?: TMysteryBetData;
  };

export type TBetSlipBetCombo = TBetSlipBetBase &
  TBetSlipBetMeta & {
    combos: TBetSlipBetSubmissionMultiComboItem[];
  };

export type TBetSlipBetSubmissionMultiComboItem = {
  proposition_id: string;
  odds: number;
  field_size: number;
};

export enum EBetSubmissionConfirmationStatus {
  Placed = 'Placed',
  Rejected = 'Rejected',
  Pending = 'Pending',
  ReducedStake = 'ReducedStake',
  PreApproved = 'PreApproved',
}

export enum EBetSubmissionRejectionReason {
  OddsChange = 'OddsChange',
  InsufficentFund = 'InsufficentFund',
  PropositionClosed = 'PropositionClosed',
  HighRisk = 'HighRisk',
  AccountClosed = 'AccountClosed',
  ManualRejection = 'ManualRejection',
  UnknownError = 'UnknownError',
  UnsupportedBetType = 'UnsupportedBetType',
  NegativeStake = 'NegativeStake',
  PlaceBetInvalidFieldSize = 'PlaceBetInvalidFieldSize',
  DuplicateRequest = 'DuplicateRequest',
  BetslipRejection = 'BetslipRejection',
  AlreadyProcessedRequest = 'AlreadyProcessedRequest',
  SelectionExpansionRejection = 'SelectionExpansionRejection',
  InsufficientLegsForMulti = 'InsufficientLegsForMulti',
  ExceededMaximumLegsForMulti = 'ExceededMaximumLegsForMulti',
  MultipleLegsInEventForMulti = 'MultipleLegsInEventForMulti',
  InvalidProposition = 'InvalidProposition',
  UnavailableBetType = 'UnavailableBetType',
  MaxStakeExceeded = 'MaxStakeExceeded',
  MultiNotAllowed = 'MultiNotAllowed',
  InvalidPriceType = 'InvalidPriceType',
  FailMinimumStake = 'FailMinimumStake',
  BonusNotAllowed = 'BonusNotAllowed',
  ExceededMaximumBetsForEvenShot = 'ExceededMaximumBetsForEvenShot',
  EvenShotOddsChange = 'EvenShotOddsChange',
  InsufficentBonusFund = 'InsufficentBonusFund',
  MysteryBetOddsChange = 'MysteryBetOddsChange',
  MysteryBetRolloverOddsChange = 'MysteryBetRolloverOddsChange',
  ToteMultiUnavailable = 'ToteMultiUnavailable',
  FundTransferFail = 'FundTransferFail',
}

export type TNewOdds = {
  new_odds: number;
  proposition_id: string;
  bet_uid: string;
  leg_uid: string | null;
};

export type TPromoTokenType = 'recovery' | 'spin-wheel' | 'odds-boost';

type TPropositionInfo = {
  proposition_id: string;
  proposition_name: string;
  proposition_type: string;
  price_type: string;
  venue_name: string;
  race_name: string;
  race_type: string;
  race_number: number;
  runner_number: number;
  barrier_number: number;
  leg_num: number | null;
};

export type TBetSubmissionConfirmation = DeepPartial<{
  request_id?: string;
  status?: EBetSubmissionConfirmationStatus;
  rejection_reason?: EBetSubmissionRejectionReason;
  is_betslip_rejection?: boolean;
  changed_odds?: TNewOdds[];
  field_size?: number;
  reduced_stake?: number;
  stake_reduction?: number;
  closed_propositions?: string[];
  errors?: unknown[];
  gameplay?: TBetSlipBonusChanceType;
  max_stake_limit?: number;
  promo_token_type?: TPromoTokenType;
  promo_tokens: TPromoToken[];
  proposition_info?: TPropositionInfo[];
  haveOddsIncreasedAfterSubmit?: boolean;
}>;

export type TPromoToken = {
  bonus_amount: number;
  boosted_odds: number;
  can_claim: false;
  id: string;
  original_odds: number;
  status: string;
  token_type: TPromoTokenType;
};

export type TBetSlipBetMeta = {
  // We calculate this and display for most types
  potential_returns?: number;

  // Used to maintain the order within the grouping list
  added_at: string;

  // Used to display information
  event_start_time: string;
  event_market_name: string;
  event_icon?: string;
  event_title: string;
  event_subtitle: string;
  twoPlaceDividends?: boolean;
  proposition_id?: string;
  eventRule?: EEventRule;
  price_type?: TPriceType;

  // Used for the anchor links in card header
  race_type?: string;
  meeting_date?: string;
  event_type?: EEventType;
  event_data?: TEventData;
  channel?: EChannel;
};

// Types that can be added to the bet slip
export type TBetSlipBet =
  | TBetSlipBetSingle
  | TBetSlipBetExotics
  | TBetSlipBetCombo
  | TBetSlipBetMulti
  | TBetSlipBetEachWay
  | TBetSlipBetSRMulti
  | TBetSlipBetSGMulti;

export enum EEventRule {
  NoPlace = 'NoPlace',
  TwoPlaceDividends = 'TwoPlaceDividends',
  ThreePlaceDividends = 'ThreePlaceDividends',
}

export type TMultiProposition = {
  event_id: string;
  proposition_id: string;
  odds: number;
  event_type?: EEventType;
  event_icon: string;
  event_market_name: string;
  event_start_time: string;
  event_title: string;
  event_subtitle: string;
  runner_id?: string;
  race_number?: string;
  race_type?: string;
  venue_name?: string;
  venue_id?: string;
  meeting_date?: string;
  competition_name?: string;
  sport_id?: string;
  competition_id?: string;
  eventRule?: EEventRule;
  event_data?: TEventData;
};

export type TEventData = {
  competition_name?: string;
  competition_id?: string;
  sport_id?: string;
  race_number?: number;
  race_id?: string;
  race_meeting_date?: string;
  venue_name?: string;
  venue_id?: string;
  match_name?: string;
  barrier_number?: number;
};

export type TMultiData = {
  request_id: string;
  propositions: TMultiProposition[];
  confirmation: TBetSubmissionConfirmation;
  stake?: number | string;
};

export type TBetSlipState = {
  // This is the total of the sums shown in the footer
  totalStake: number;
  betSlipOpen: boolean;
  potentialReturns: number;

  // This controls the keyboard that is focused and field we are editing
  focusedBet?: string;
  focusedBetField?: string;

  // This is the list of bets
  bets: TBetSlipBet[];

  // These manage state of the view
  viewMode: EBetSlipViewMode; // Editing or reviewing
  showFieldErrors: string[]; // Holds an array of request_id's of the fields that have an error
  generalError: boolean; // This indicates if we have a general error not related to a bet - eg 500 error or network error

  // Handles return from the API
  submissionLoading: boolean;
  hasKeypad: boolean;
  storedDecimal: string;
  betIDFocused: string;

  hasSufficientFunds: boolean;
  displayOddsChangeBanner: boolean;

  // Props closed banner (currently for multis only)
  // Can potentially merge this with displayOddsChanged banner
  // into something like displayRejectionReasonBanner
  displayEventsClosedBanner: boolean;
  updateConfirmFlag: boolean;

  // Bonus chance modals for Promos
  bonusChance: Partial<TBetSlipBonusChanceType> | null;
};

export type TBetCardProps = {
  bet: TBetSlipBet;
  modalRef: MutableRefObject<HTMLElement | undefined>;
  portalRef: RefObject<HTMLDivElement>;
  betsRef: MutableRefObject<
    { id: string; isFocused: boolean; ref: HTMLInputElement }[] | undefined
  >;
  type?: string;
};

export type TMultiBetCardProps = {
  bet: TBetSlipBetMulti;
  betRequest?: TPunterBetRequest;
};

export type TBetSlipFocusedBet = {
  request_id: string;
  field_name: string;
};

export enum EBetSlipViewMode {
  EditingBets = 'edit',
  ReviewingBets = 'review',
  ConfirmBets = 'confirm',
  EmptyBets = 'empty',
}

export type TMultiSlipTypeGroupProps = {
  leg: string;
  children?: ReactNode;
  idx: number;
};

export type TBetApprovalProps = {
  bet?: TBetSlipBet;
  betRequest: TPunterBetRequest | undefined;
};

export type TOddsChangedBannerProps = {
  direction: 'increase' | 'decrease';
  isInFooter?: boolean;
} & any;

export type TMultiBetSlipProps = {
  bet: TMultiProposition;
  children?: ReactNode;
};

export type TAddBetPayload = {
  bet: TBetSlipBet;
  localStorageData?: boolean;
  multisSupported?: boolean;
};

export enum EChannel {
  WEB = 'DesktopBrowser',
  MOBILE_IOS = 'IOSApp',
  MOBILE_ANDROID = 'AndroidApp',
}
