import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonSubmitExclusionProps: CustomButtonProps = {
  height: '38px',
  width: '100%',
  variant: 'solid',
};

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  height: '38px',
  variant: 'solid',
  lineHeight: 'normal',
};

export const FlexConfirmation: CSSObject = {
  bg: 'epsilon.400',
  borderRadius: 'md',
  border: '1px solid',
  borderColor: 'epsilon.200',
};

export const CheckboxExclusion: CSSObject = {
  '&&& > span': { boxShadow: 'none' },
};

export const TextTitle: CSSObject = {
  color: 'white',
};
