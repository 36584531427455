import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/betstride/images/feature/featSportBg.png';
import featuredMb from '@/assets/betstride/images/feature/featSportMb.png';
import gradientBorder from '@/assets/betstride/images/gradient-border.svg';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  border: 'none',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  boxShadow:
    '0px 2px 20px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 5px 0px rgba(33, 193, 255, 0.25) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset',
  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
    borderImage: `url(${gradientBorder}) 50`,
    pointerEvents: 'none',
    borderImageWidth: '50px',
  },
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'beta.300',
  fontSize: 'xs',
  fontWeight: 'bold',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.300',
  fontFamily: 'accent',
  boxShadow: 'unset',
  textShadow: 'none',
};

export const TextMisc: CSSObject = {
  bg: 'rgba(0, 209, 255, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: 'base',
  color: 'beta.300',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  fontWeight: 'extrabold',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  fontWeight: 'bold',
  fontFamily: 'accent',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontFamily: 'accent',
  fontSize: '2xl',
  fontWeight: 'bold',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  color: 'beta.300',
  textTransform: 'uppercase',
};

export const puntButtonProps: CSSObject = {
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  color: 'beta.500',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  fontWeight: 'bold',
  fontSize: 'xs',
  textTransform: 'uppercase',
  h: '9',
  _after: {
    opacity: 0,
  },
};
