import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'lg',
  fontWeight: 'bold',
  fontFamily: 'accent',
  color: 'beta.300',
  textTransform: 'uppercase',
  textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
  pb: '2',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
