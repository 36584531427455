import { MatchesV2Schema } from '@/views/sports/Matches/styles/MatchesV2.styles';

export const matchesV2Styles: MatchesV2Schema = {
  pageHeaderText: {
    color: 'beta.300',
    textShadow: '0px 0px 8px rgba(254, 208, 119, 0.5)',
    fontSize: 'lg',
    fontFamily: 'accent',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  mainBoxWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  navOverride: {
    mb: '0',
  },
  divider: {
    mb: '0',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
};
