import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    pb: '2',
    borderColor: 'blackAlpha.400',
    borderBottomWidth: '1px',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
    _last: {
      border: 'none',
      boxShadow: 'none',
      pb: '0',
    },
  },
};
export default styles;
