import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading': 'Ready to place some bets?',
  'betslip.betslipmodal.emptybetslipsubheading':
  'Your betslip is empty. To get started, add your Racing and Sports selections.',
  'onboarding.locationnotallowed.header': 'High Stakes, But Not in This Place.',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing MagnumBet from outside of Australia.',
  'error.heading': 'Time out.',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.heading': 'Betting on a Better Experience',
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
};
