import { CSSObject } from '@chakra-ui/react';

export const Item: CSSObject = {
  display: 'flex',
  minH: ['10', null, 'unset'],
  justifyContent: 'space-between',
  mx: '0',
  borderTop: 'none',
  flexDir: 'column',
  alignItems: 'start',
  mt: '1',
  mb: '2',
  borderRadius: 'lg',
  gap: '1',
  bg: 'rgba(0, 209, 255, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
  '&&': {
    p: '2',
  },
};

export const RacerName: CSSObject = {
  fontSize: 'xs',
  whiteSpace: ['unset', null, 'nowrap'],
  fontFamily: 'accent',
  fontWeight: 'bold',
  color: 'white',
};

export const TextTableBarrierNumber: CSSObject = {
  color: 'white',
};
