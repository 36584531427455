import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: '9',
    mx: 'auto',
    mb: '4',
    pl: '5',
    py: '1',
    borderRadius: 'lg',
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
    bgGradient: 'linear(180deg, beta.600, beta.400)',
    bg: 'alpha.500',
    border: '3px solid',
    borderColor: '#E57D24',
    sx: {
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    textDecorationColor: 'white',
    gap: '1.5',
    position: 'relative',
    zIndex: '9999',
    color: 'white',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'white',
    zIndex: '9999',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
  pr: '1.5',
};
