import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';

export const RunnerDetails: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  position: 'relative',
};

export const RunnerDescription: CSSObject = {
  color: 'beta.300',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const BetOdds: CSSObject = {
  color: 'white',
};

export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
  position: 'absolute',
  top: '-1',
  pl: '0.5',
};

export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'beta.300';
      case 'pending':
        return 'orange.400';
      default:
        return 'gray.300';
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'beta.600';
      case 'pending':
        return 'orange.800';
      default:
        return 'gray.600';
    }
  },
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
});

export const HeaderSvg: CSSObject = {
  color: 'beta.300',
  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
  boxSize: '5',
};

export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};

export const MultiIcon = () => (
  <IconSvg
    name="MultiBet"
    className="svgIcon"
    color="beta.500"
    boxSize="5"
    mb="1.5"
  />
);

export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
};
