import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
};

export const TextBetCount: CSSObject = {
  fontSize: '2xs',
  fontFamily: 'accent',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'beta.300',
  pos: 'relative',
  bottom: '1',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'blackAlpha.800',
  minW: 'unset',
  bgGradient:
    'linear(to-b, #FFD665, #DFB02D)',
  borderRadius: 'lg',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient:
    'linear(to-b,rgb(249, 201, 71),rgb(209, 159, 21))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  },
  _before: {
    bgImage: 'none',
  },
  _after: {
    opacity: '0',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
