import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/magnumbet/images/feature/featDesktop.png';
import featBgMb from '@/assets/magnumbet/images/feature/featMb.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    borderRadius: 'md',
    w: '42px',
    fontSize: 'xs',
    lineHeight: 'normal',
    sx: {
      '&[data-active]': {
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.35) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
        color: 'beta.400',
        textShadow: '0px 0px 9px rgba(243, 100, 23, 0.6)',
        _before: {
          display: 'none',
        },
      },
    },
  },
};

export const BoxCard: CSSObject = {
  px: '5',
  py: '2.5',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    m: '5px',
    borderRadius: 'base',
  },
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
  bgGradient: [
    'linear(to-b, #3E2853, alpha.900)',
    'linear(to-b, alpha.900, alpha.900)',
  ],
  flex: '2',
  pos: 'relative',
  flexDir: 'column',
  borderRadius: 'lg',
  minH: '250px',
  py: ['2', '4'],
  px: ['0', '3'],
  mx: ['1.5', '0'],
  mb: 0,
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: ['center', null, null, 'space-between'],
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['0', null, '50px'],
  mt: ['50px', null, '0'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
  px: '2',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: {
    base: `"."`,
    md: `". . ."`,
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.2)',
  textTransform: 'capitalize',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'beta.300',
  fontFamily: 'subHeading',
  fontSize: '47px',
  textTransform: 'capitalize',
  textShadow: '0px 2px 8px rgba(36, 24, 54, 1)',
  fontWeight: 'normal',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'beta.300',
    boxSize: '24px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.3))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: '15px',
  color: 'white',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
  ml: '1',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'accent',
  bg: 'gray.300',
  borderRadius: '3px',
  color: 'gray.600',
  px: '0.5',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '17px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: 'full',
  minH: '46px',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  borderRadius: 'md',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
};

export const LinkFeatureCard: CSSObject = {
  display: ['none', null, null, 'block'],
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
