import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const TextSubheading: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const Subheading: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'blackAlpha.400',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};

export const DepositLimitGrid: CSSObject = {
  pb: '20',
};

export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};

export const DepositButton: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const CancelButton: CSSObject = {
  variant: 'secondary',
};

export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};

export const DepositLimitContainer: CSSObject = {
  bg: 'white',
  fontFamily: 'body',
  borderRadius: 'md',
  p: '4',
  color: 'gray.600',
  fontWeight: '700',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  '.chakra-input__left-addon': {
    fontFamily: 'body',
  },
};

export const PendingLimitContainer: CSSObject = {
  border: 'none',
  fontWeight: '700',
  fontSize: 'xs',
};

export const LimitLabel: CSSObject = {
  fontSize: 'xs',
};

export const LimitValue: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
};

export const ModalContentWrapper: CSSObject = {
  p: '0',
};

export const ModalDivider: CSSObject = {
  display: 'none',
};

export const buttonRemoveProps: CSSObject = {
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.400',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  _hover: {
    opacity: '0.8',
  },
  ':active, &[data-active="true"]': {
    color: 'beta.400',
    opacity: '0.8',
  },
  _disabled: {
    opacity: '0.4',
    pointerEvent: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  fontWeight: 'normal',
  width: 'full',
};

export const buttonConfirmModalProps: ButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  width: '50%',
};

export const inputProps: Partial<TIndex> = {
  sx: {
    '&&': {
      fontWeight: 'bold',
      fontFamily: 'body',
      bg: 'blackAlpha.500',
      border: 'none',
      borderRightRadius: 'base',
      fontSize: 'sm',
      '&&:hover': {
        border: 'none',
        bg: 'blackAlpha.700',
      },
      '&&:focus': {
        border: 'none',
        bg: 'blackAlpha.700',
        '&&:hover': {
          bg: 'blackAlpha.700',
        },
      },
      _placeholder: {
        color: 'white',
      },
    },
  },
};

export const DepositLimitModalContentWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  p: '4',
  color: 'white',
  fontWeight: '700',
};

export const DepositLimitInputContainer: CSSObject = {
  mb: '1',
  '#frequency-select': {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-select__wrapper': {
    svg: {
      color: 'white !important',
    },
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    border: 'none',
    '#amount-input': {
      pl: 12,
      color: 'white',
      fontWeight: 'bold',
      caretColor: 'currentColor',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 3px 6px 0px rgba(0, 0, 0, 0.2) inset',
      border: '1px solid',
      borderRadius: 'md',
      borderColor: 'transparent',
      bg: 'blackAlpha.700',

      '&::placeholder': {
        color: 'whiteAlpha.800',
      },

      _hover: {
        border: '1px solid',
        borderColor: 'transparent',
        bg: 'whiteAlpha.400',
        boxShadow: 'none',
      },

      _focus: {
        border: '1px solid',
        borderColor: 'gamma.100',
        boxShadow: 'none',
      },

      '&:-webkit-autofill': {
        caretColor: 'currentColor',
        '&:hover, &:focus, &:active': { caretColor: 'currentColor' },
      },
    },
  },
  '.chakra-input__left-addon': {
    color: 'blackAlpha.700',
    position: 'absolute',
    zIndex: 10,
    h: '34px',
    w: '34px',
    p: '13px',
    top: '50%',
    left: 1,
    transform: 'translateY(-50%)',
    lineHeight: 'normal',
    fontWeight: 'bold',
    borderRadius: 'base',
    fontFamily: 'accent',
    fontSize: 'md',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
    bgGradient: 'linear(to-b, #FFBF36, #EEA200)',
    border: 'none',
    boxShadow:
      '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  },
  '.chakra-form__error-message': {
    bg: 'orange.300',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'orange.800',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
  '.chakra-select__icon-wrapper': {
    color: 'beta.300',
  },
};
