import React, { Fragment, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { en as baseEn } from '@/intl/en';
import { zh as baseZh } from '@/intl/zh';
import importsEn from '../../intl/en.imports';
import importsZh from '../../intl/zh.imports';
import { getThemeName } from '@/helpers/getThemeConfig';
import { EThemesOG } from '@/constants/themeConfig';

type TIntl = {
  children: ReactNode;
};

const allMessages: Record<string, any> = {
  en: { ...baseEn, ...(importsEn?.en || {}) },
  zh: { ...baseZh, ...(importsZh?.zh || {}) },
};
const themeName = getThemeName();

export default function Intl({ children }: TIntl) {
  const storedLocale = localStorage.getItem('locale') || 'en-AU';

  const locale = themeName === EThemesOG.GoldenBet888 ? storedLocale : 'en';

  return (
    <IntlProvider
      defaultLocale="en-AU"
      locale={locale}
      messages={allMessages[locale] || allMessages.en}
      textComponent={Fragment}
    >
      <>{children}</>
    </IntlProvider>
  );
}
