import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggle from '@/assets/betstride/images/switch/toggle.png';
import gradientBorder from '@/assets/betstride/images/switch/gradient-border.svg';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    fontFamily: 'accent',
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, 'unset'],
    mb: ['1', null, '4'],
    gap: '2',

    bg: ['alpha.400', null, null, 'rgba(0, 209, 255, 0.25)'],
    _before: {
      content: ['none', null, null, '""'],
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
      borderImage: `url(${gradientBorder}) 30`,
      pointerEvents: 'none',
      borderImageWidth: '40px',
    },
    boxShadow: [
      null,
      null,
      null,
      '0px 2px 20px 0px rgba(0, 0, 0, 0.4), 0px 0px 5px 0px rgba(33, 193, 255, 0.25) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(11, 49, 60, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.1) inset',
    ],
    h: '16',
    pos: 'relative',
  }),
});

const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontWeight: 'extrabold',
    fontSize: 'xs',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 8px rgba(251, 201, 21, 0.50)'
      : '0px 0px 8px rgba(67, 187, 255, 0.50)',
    filter: isActive ? 'drop-shadow(0px 0px 8px rgba(254, 208, 119, 0.5))' : '',
    color: isActive ? 'beta.300' : 'white',
    letterSpacing: 'widest',
    px: '3',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
    '--switch-track-width': '46px',
    bg: 'transparent',
    pos: 'relative',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: `url(${toggle})`,
        boxSize: '12',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-18px',
        right: '0',
        top: '-2.5',
      },
    },
    '.chakra-switch__track': {
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.50) inset',
      bg: 'blackAlpha.400',
      borderRadius: 'base',
      _before: {
        bg: 'blackAlpha.500',
        h: '7px',
        w: '34px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
        content: '""',
        pos: 'absolute',
        left: '2',
        top: '2.5',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </TextToggle>
    </FlexWrapper>
  );
}
