import { themeName } from '@/helpers/getThemeConfig';
import * as sterlingparker from './PlayerLayout.styles.sterlingparker';
import * as betgalaxy from './PlayerLayout.styles.betgalaxy';
import * as betprofessor from './PlayerLayout.styles.betprofessor';
import * as betroyale from './PlayerLayout.styles.betroyale';
import * as buffalobet from './PlayerLayout.styles.buffalobet';
import * as fiestabet from './PlayerLayout.styles.fiestabet';
import * as goldenrush from './PlayerLayout.styles.goldenrush';
import * as juicybet from './PlayerLayout.styles.juicybet';
import * as junglebet from './PlayerLayout.styles.junglebet';
import * as puntcity from './PlayerLayout.styles.puntcity';
import * as puntgenie from './PlayerLayout.styles.puntgenie';
import * as questbet from './PlayerLayout.styles.questbet';
import * as wellbet from './PlayerLayout.styles.wellbet';
import * as volcanobet from './PlayerLayout.styles.volcanobet';
import * as vikingbet from './PlayerLayout.styles.vikingbet';
import * as slambet from './PlayerLayout.styles.slambet';
import * as sugarcastle from './PlayerLayout.styles.sugarcastle';
import * as gigabet from './PlayerLayout.styles.gigabet';
import * as templebet from './PlayerLayout.styles.templebet';
import * as oldgill from './PlayerLayout.styles.oldgill';
import * as hyperbet from './PlayerLayout.styles.hyperbet';
import * as goldenbet888 from './PlayerLayout.styles.goldenbet888';
import * as bet777 from './PlayerLayout.styles.bet777';
import * as titanbet from './PlayerLayout.styles.titanbet';
import * as chromabet from './PlayerLayout.styles.chromabet';
import * as bet575 from './PlayerLayout.styles.bet575';
import * as betblast from './PlayerLayout.styles.betblast';
import * as pyramidbet from './PlayerLayout.styles.pyramidbet';
import * as magnumbet from './PlayerLayout.styles.magnumbet';

const styleImports: Partial<Record<string, any>> = {
  sterlingparker,
  betgalaxy,
  betprofessor,
  betroyale,
  buffalobet,
  fiestabet,
  goldenrush,
  juicybet,
  junglebet,
  puntcity,
  puntgenie,
  questbet,
  wellbet,
  volcanobet,
  vikingbet,
  slambet,
  sugarcastle,
  gigabet,
  templebet,
  oldgill,
  hyperbet,
  goldenbet888,
  bet777,
  titanbet,
  chromabet,
  bet575,
  betblast,
  pyramidbet,
  magnumbet,
};
export const styleImport = styleImports[themeName]?.default;
