import { CSSObject, FlexProps } from '@chakra-ui/react';

export const MultiCardWrapper: CSSObject = {
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  bg: 'alpha.400',
  borderRadius: 'md',
  p: '2',
  color: 'white',
  my: '0',
  gap: '0',
};
export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'body',
};
export const HeaderContainer: CSSObject = {
  gap: '2',
  p: '2',
  pb: '0',
};
export const MultiIconSVG: CSSObject = {
  color: 'white',
};
export const MultiCardsContainer: CSSObject = {
  mx: '-2',
  gap: '0',
  py: '0',
};
export const FlexEventDetails: CSSObject = {
  gap: '2',
};
export const EmptyMultiIcon: CSSObject = {
  color: 'alpha.300',
};
export const UnableToCreateMultiWrapper: CSSObject = {
  p: '2.5',
  color: 'white',
  background: 'blackAlpha.300',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 0px 8px 0px rgba(0, 0, 0, 0.2) inset',
  fontSize: 'xs',
  fontWeight: 'medium',
  borderRadius: 'md',
  mb: '3',
};
export const flexEventProps: FlexProps = {
  sx: {
    "[class$='EventTitle']": {
      fontWeight: 'bold',
      fontSize: 'xs',
    },
  },
};
export const MultiCardInputContainer: CSSObject = {
  mb: '2',
};

export const InvalidityBox: CSSObject = {
  color: 'white',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 'medium',
  fontFamily: 'accent',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.30)',
};
