import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  h: '8',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  color: 'white',
  fontWeight: 'bold',
  sx: {
    '&&': {
      mx: '0',
      border: 'none',
      boxShadow: 'none',
      borderBottomRadius: 0,
      textShadow: 'none',
      _before: {
        display: 'none',
      },
      _hover: {
        bgImage: 'none',
      },
      span: {
        lineHeight: '1',
      },
    },
    py: '2',
    bgImage: 'none',
    '&&[data-active="true"]': {
      bg: 'beta.300',
      borderBottom: 'none',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        fontWeight: 'bold',
        fontSize: 'xs',
        color: 'beta.300',
        bg: 'alpha.600',
        textShadow: 'none',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
    },
    '&&&.subTab': {
      _before: {
        display: 'none',
      },
      color: 'alpha.800',
      _hover: {
        boxShadow: '0px -3px 0 0 inset rgba(62, 62, 62, 1)',
        bgImage: 'none',
      },
    },
  },
};

export const BoxWrapper: CSSObject = {
  bg: 'blackAlpha.500',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
  borderRadius: ['none', 'md'],
  px: '3',
  mx: ['1', null, '0'],
  mt: '3',
  mb: '0',
  display: 'flex',
  w: ['98%', null, 'full'],
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  ':has(+ .subTabBar)': {
    borderBottomRadius: 'none',
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  flex: '0 0 auto',
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'beta.300',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',
  _before: {
    content: "' '",
    height: '6px',
    width: '2px',
    backgroundColor: 'alpha.900',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },
  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'alpha.900',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};
