import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { ScrollButtonGroupSchema } from '@/views/sports/components/ScrollButtonGroup/styles/ScrollButtonGroup.styles';

export const scrollButtonGroupStyles: ScrollButtonGroupSchema = {
  iconButtonScrollRightSvg: {
    as: ChevronRight,
    sx: {},
  },
  iconButtonScrollRight: {
    color: 'white',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    h: '34px',
    w: 4,
    bgGradient: 'linear(to-b, alpha.100, alpha.300)',
    boxShadow: '0px 0px 0px 1px hsla(0, 0%, 100%, 0.2) inset',
    border: 0,
    mr: 'px',
    mt: 'px',
    _active: {
      _after: { display: 'none' },
    },
  },
  iconButtonScrollLeftSvg: {
    as: ChevronLeft,
    sx: {},
  },
  iconButtonScrollLeft: {
    color: 'white',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    h: '34px',
    w: 4,
    mt: 'px',
    bgGradient: 'linear(to-b, alpha.100, alpha.300)',
    boxShadow: '0px 0px 0px 1px hsla(0, 0%, 100%, 0.2) inset',
    border: 0,
    _active: {
      _after: { display: 'none' },
    },
  },
  flexWrapper: {
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  },
  buttonGroupSports: {
    bg: 'transparent',
    borderRadius: 'base',
    display: 'inline-flex',
    flex: '1',
    alignItems: 'center',
    overflowX: 'auto',
    pl: '1',
    pb: '0',
    spacing: '1',
    whiteSpace: 'nowrap',
    h: 9,
    sx: { '::-webkit-scrollbar': { display: 'none' } },
  },
  buttonGroupScrolls: {
    display: ['none', null, null, 'inline-flex'],
    ml: '1',
    gap: 1,
    isAttached: true,
    variant: 'filter',
  },
};
