import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};

export const BoxTakeActionTable: CSSObject = {
  bg: 'linear-gradient(180deg, #FFFFFF1F, #FFFFFF0D)',
  borderRadius: 'md',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  border: '0',
  p: '3',
};

export const TextTakeAction: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
};
