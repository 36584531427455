import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  my: '1',
  gap: '1',
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'primary',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};
