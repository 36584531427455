import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',

  sx: {
    py: '1',
    '&&[data-active="true"]': {
      bg: 'beta.300',
      borderBottom: 'none',
      '&>span': {
        borderRadius: 'md',
        px: '1',
        py: '2',
        bg: 'alpha.600',
        color: 'beta.300',
      },
    },
    '&&[data-active]': {
      borderBottom: 'none',
    },
    '&.subTab': {
      color: 'alpha.600',
    },
  },
};

export const BoxWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderRadius: ['none', null, 'md'],
  px: ['10px', '4'],
  mx: '0',
  mt: '3',
  mb: '0',
  display: 'flex',
  w: 'full',
  overflowX: 'auto',
  whiteSpace: 'nowrap',

  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
  },

  '&.subTabBar': {
    bg: 'beta.300',
    boxShadow: 'none',
    borderBottomRadius: 'md',
    borderTopRadius: 'none',
    mt: '0',
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  flex: '0 0 auto',
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'beta.300',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',

  _before: {
    content: "' '",
    height: '5px',
    width: '2px',
    backgroundColor: 'alpha.700',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },

  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'alpha.700',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};
