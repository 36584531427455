import { NavigationSchema } from './Navigation.styles';
import gradientBorder from '@/assets/betstride/images/gradient-border.svg';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',
    pos: 'relative',
    fontFamily: 'accent',
  },
  boxWrapperProps: {
    width: '100%',
    pos: 'relative',
  },
  navigationBoxProps: {
    pos: 'relative',
    p: 0,

    // A gradient-border like effect
    _before: {
      content: ['none', null, null, '""'],
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      filter: 'drop-shadow(0px 1px 0 rgba(0, 0, 0, 0.2))',
      borderImage: `url(${gradientBorder}) 50`,
      pointerEvents: 'none',
      borderImageWidth: '50px',
    },
  },
};
