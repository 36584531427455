import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};
export const BoxTakeActionTable: CSSObject = {
  bg: 'rgba(0, 209, 255, 0.25)',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 6px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  border: '0',
  p: '3',
};
export const TextTakeAction: CSSObject = {
  color: 'white',
};
