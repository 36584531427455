import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '150px',
    mr: '3',
    p: '1.5',
    height: 'fit-content',
    bg: 'alpha.600',
    boxShadow:
      '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.25) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: 'md',
    overflow: 'hidden',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    _first: {
      borderTopRadius: 'md',
    },
    _last: {
      mb: '0',
      borderBottomRadius: 'md',
    },
    pos: 'relative',
    _notFirst: {
      borderTopColor: 'rgba(0, 0, 0, 0.25)',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
    },
    _before: {
      border: 'inherit',
      borderColor: 'rgba(255, 255, 255, 0.05)',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  accordionButton: {
    pl: '1.5',
    pr: '0.5',
  },
  accordionHeadingText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    fontFamily: 'accent',
    letterSpacing: '1px',
    fontSize: 'xs',
  },
  accordionIcon: {
    sx: {
      color: 'white',
    },
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
  },
};
