import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .07)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px rgba(0, 0, 0, 0.3) inset',
    mx: '0.5',
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
      pb: '0',
    },
  },

  propWrapper: {
    justifyContent: 'flex-end',
  },
  btnGroupWrapper: {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    boxShadow: `0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)`,
    minW: '90px',
    px: '2',
    h: '40px',
    border: '1px solid rgba(225, 166, 45, 1)',
  },
  btnGroupText: {
    fontSize: 'xs',
    color: 'blackAlpha.800',
    fontWeight: 'black',
    fontFamily: 'accent',
    h: 'full',
    lineHeight: '3.2',
    bg: 'whiteAlpha.600',
    px: '3',
    borderTop: '1px solid rgba(255, 255, 255, 0.3)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  },
  btnGroupIcon: {
    color: 'blackAlpha.800',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.3))',
  },
  nameWrapper: {
    color: 'white',
    fontFamily: 'accent',
  },
};
export default styles;
