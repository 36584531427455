import { AllLeaguesSchema } from '@/views/sports/AllLeagues/styles/AllLeagues.styles';

export const allLeaguesStyles: AllLeaguesSchema = {
  headerSportText: {
    ml: 2,
    color: 'alpha.700',
    fontSize: 'md',
    fontWeight: 'semibold',
  },
  topLeaguesAccordionItem: {
    borderRadius: 'base',
    overflow: 'hidden',
    bg: 'alpha.50',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    border: 'none',
  },
  accordionButton: {
    color: 'gray.700',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',
      fontSize: 'sm',

      _hover: {
        background: 'gray.200',
      },
    },
  },
  topLeaguesText: { flex: '1', textAlign: 'left', fontWeight: 'semibold' },
  accordionIcon: { color: 'gray.700' },
  accordionPanel: {
    pb: 2,
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
  },
  accordionPanelBoxWrapper: {
    borderLeftWidth: '3px',
    borderColor: 'beta.500',
    px: '2',
  },
  accordionPanelBoxInner: {
    mb: '2',
  },
  accordionPanelBoxInnerLink: {
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: 'sm',
    sx: { _hover: { textDecoration: 'none' } },
  },
  marketGroupsByLetterHeadingText: {
    sx: {
      color: 'alpha.700',
      fontsize: 'md',
      my: '3',
      fontWeight: 'bold',
    },
  },
  marketGroupsByLetterAccordionItem: {
    border: 'none',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    bg: 'alpha.50',
    sx: {
      _last: {
        borderBottomRadius: 'base',
      },
      _first: { borderTopRadius: 'base' },
    },
  },
  marketGroupsByLetterAccordionButton: {
    color: 'gray.700',
    sx: {
      display: 'flex',
      p: '3',
      gap: '2',
      alignItems: 'center',
      alignSelf: 'stretch',

      _hover: {
        background: 'gray.200',
      },
    },
  },
  marketGroupsByLetterTitleText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'semibold',
  },
  marketGroupsByLetterCountryFlag: {
    marginRight: '10px',
    width: '1.2em',
    height: '1.2em',
  },
  marketGroupsByLetterAccordionIcon: {
    color: 'gray.700',
  },
  marketGroupsByLetterAccordionPanel: {
    pb: 4,
  },
  marketGroupsByLetterAccordionPanelBox: {
    borderLeftWidth: '3px',
    borderColor: 'beta.500',
    px: '2',
  },
  marketGroupsByLetterAccordionPanelLink: {
    color: 'gray.700',
    fontWeight: 'semibold',
    fontSize: 'sm',
    sx: { _hover: { textDecoration: 'none' } },
  },
  navWrapper: {
    pb: '4',
    mb: '3',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: '#192B3B',
    borderRadius: 'none',
    boxShadow: '0px 1px 0px 0px #FFFFFF12',
  },
};
