import * as betprofessor from './MatchCard.styles.betprofessor';
import * as betroyale from './MatchCard.styles.betroyale';
import * as fiestabet from './MatchCard.styles.fiestabet';
import * as puntcity from './MatchCard.styles.puntcity';
import * as puntgenie from './MatchCard.styles.puntgenie';
import * as questbet from './MatchCard.styles.questbet';
import * as sterlingparker from './MatchCard.styles.sterlingparker';
import * as vikingbet from './MatchCard.styles.vikingbet';
import * as volcanobet from './MatchCard.styles.volcanobet';
import * as wellbet from './MatchCard.styles.wellbet';
import * as goldenrush from './MatchCard.styles.goldenrush';
import * as juicybet from './MatchCard.styles.juicybet';
import * as forsport from './MatchCard.styles.forsport';
import * as junglebet from './MatchCard.styles.junglebet';
import * as sugarcastle from './MatchCard.styles.sugarcastle';
import * as buffalobet from './MatchCard.styles.buffalobet';
import * as oldgill from './MatchCard.styles.oldgill';
import * as titanbet from './MatchCard.styles.titanbet';
import * as slambet from './MatchCard.styles.slambet';
import * as gigabet from './MatchCard.styles.gigabet';
import * as betstride from './MatchCard.styles.betstride';
import * as templebet from './MatchCard.styles.templebet';
import * as goldenbet888 from './MatchCard.styles.goldenbet888';
import * as hyperbet from './MatchCard.styles.hyperbet';
import * as bet777 from './MatchCard.styles.bet777';
import * as chromabet from './MatchCard.styles.chromabet';
import * as bet575 from './MatchCard.styles.bet575';
import * as betblast from './MatchCard.styles.betblast';
import * as magnumbet from './MatchCard.styles.magnumbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  goldenrush,
  juicybet,
  forsport,
  junglebet,
  sugarcastle,
  buffalobet,
  oldgill,
  titanbet,
  slambet,
  gigabet,
  betstride,
  templebet,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
  magnumbet,
};
