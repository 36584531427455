import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  wrapper: {
    mx: ['0', null, null, '-4'],
    mb: ['0', null, null, '-6'],
    bg: 'alpha.500',
    bottom: '0',
    left: '0',
    position: ['fixed', null, null, 'sticky'],
    right: '0',
    zIndex: 'sticky',
    mt: '3',
  },
  slideBox: {
    color: 'white',
    borderTopWidth: '3px',
    borderTopColor: 'gamma.300',
    borderBottomRadius: 'md',
  },
  accordionItem: {
    borderTop: '3px',
    borderColor: 'gamma.300',
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
    bg: 'alpha.500',
  },
  accordionHeaderText: {
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
    gap: '1',
    px: '4',
    py: '3',
    color: 'white',
    borderTop: '3px',
    borderColor: 'whiteAlpha.400',
  },
  accordionButtonIcon: {
    fontSize: 'sm',
  },
  headerStack: {
    bg: 'alpha.400',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    pb: '2',
    pt: 2.5,
  },
  headerText: {
    flex: '1',
    color: 'beta.600',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
    sx: {
      svg: {
        color: 'beta.600',
      },
    },
  },
  accordionPanel: {
    fontSize: 'xs',
    overflow: 'auto',
    p: '0',
  },
  selectionFlex: {
    bg: 'alpha.400',
    gap: '2',
    px: '4',
    py: '3',
    _notLast: {
      borderBottom: '1px dashed',
      borderColor: 'blackAlpha.500',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      mb: 'px',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
  },
  footerStack: {
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    py: '2.5',
    px: '4',
    pos: 'relative',
    borderTopColor: 'blackAlpha.300',
    borderBottomRadius: 'md',
    _before: {
      opacity: '0.2',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '4',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
  },
  legsText: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    fontWeight: 'extrabold',
    fontSize: '2xs',
  },
  legsChunks: {
    color: 'beta.300',
    fontFamily: 'body',
    fontSize: 'xl',
    fontWeight: 'bold',
  },
  oddsText: {
    color: 'white',
    textTransform: 'uppercase',
    flex: '3',
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    fontSize: '2xs',
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'beta.300',
    fontFamily: 'body',
    fontSize: 'xl',
    fontWeight: 'bold',
  },
  clearButton: {
    variant: 'outline',
    fontWeight: 'normal',
  },
  addToSlipButton: {
    variant: 'secondary',
    fontSize: 'sm',
    px: ['1', '3'],
  },
};
