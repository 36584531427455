import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .07)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px rgba(0, 0, 0, 0.3) inset',
    mx: '0.5',
    _last: {
      borderBottom: 'none',
      boxShadow: 'none',
      pb: '0',
    },
  },
  btnGroupWrapper: {
    borderRadius: '5px',
    bgGradient: 'linear(to-b, alpha.100 0%, alpha.300 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    minW: '90px',
    gap: 0,
    color: 'white',
    fontFamily: 'accent',
  },
  btnGroupText: {
    fontSize: 'xs',
    bg: '#739dd9',
    color: 'alpha.500',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.2)',
    fontWeight: 'bold',
    fontFamily: 'accent',
    h: 'calc(100% - 2px)',
    lineHeight: '38px',
    w: '40px',
  },
  btnGroupIcon: {
    color: 'white',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.6))',
    w: '35px',
    fontWeight: 'black',
  },
  nameWrapper: {
    minW: ['118px', 'unset'],
  },
};
export default styles;
