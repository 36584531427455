import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    borderRadius: 'md',
    my: 2,
    px: '4',
    color: 'white',
    textTransform: 'uppercase',
    sx: {
      button: {
        fontFamily: 'accent',
        fontWeight: 'bold',
        fontSize: 'xs',
        ':active, &[data-active="true"]': {
          color: 'beta.300',
          textShadow: 'unset',
          borderBottom: '3px solid',
          borderBottomColor: 'beta.300',
          borderRadius: 'none',
        },
      },
    },
  },
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'sm',
    mt: '-2',
  },
  promotionWrapper: {
    bg: 'blackAlpha.500',
    my: '3',
    boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: 'md',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    mx: '1px',
  },
  termsAndConditions: {
    color: 'white',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
