import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { Alert } from '@styled-icons/octicons/Alert';

export const EstReturnsLabel: CSSObject = {
  color: 'alpha.300',
  fontWeight: 'black',
  lineHeight: 'normal',
  alignItems: 'center',
  px: '2',
  width: 'auto',
  borderLeft: '1px solid',
  borderColor: 'whiteAlpha.400',
};

export const PendingBetContainer: CSSObject = {
  bg: 'orange.400',
  borderRadius: 'base',
  w: 'full',
  color: 'orange.800',
  justifyContent: 'start',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const BetApprovalText: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'medium',
};

export const BetPendingApprovalText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
};

export const BetRejectedText: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'bold',
  fontFamily: 'accent',
};

export const ApprovedBetContainer: CSSObject = {
  color: 'white',
  bg: 'green.500',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  w: 'full',
  justifyContent: 'start',
};

export const RejectedBetContainer: CSSObject = {
  bg: 'red.600',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  borderRadius: 'base',
  w: 'full',
  justifyContent: 'start',
};

export const FooterContainer: CSSObject = {
  bg: 'blackAlpha.600',
  justifyContent: 'center',
  px: '0',
  h: '6',
  overflow: 'hidden',
  borderRadius: 'base',
  fontSize: '2xs',
  fontFamily: 'accent',
  boxShadow: '0px 1px 0px 0px #FFFFFF21, 0px 2px 4px 0px #00000033 inset',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'accent',
  lineHeight: 'normal',
  textWrap: 'nowrap',
  '&&': {
    mx: '2',
  },
};

export const EstReturnsBlock: CSSObject = {
  color: 'alpha.300',
  fontSize: '2xs',
};

export const IconApprovedBet = () => (
  <Icon as={CheckCircle} boxSize="4" mr="2" />
);

export const IconRejectedBet = () => <Icon as={Alert} boxSize="4" mr="2" />;
