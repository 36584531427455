import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'beta.300',
    fontFamily: 'accent',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  },
  eventMarketName: {
    color: 'alpha.300',
    textTransform: 'capitalize',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'sm',
    color: 'white',
    sx: {
      '> span': { fontSize: '2xs', fontWeight: 'medium' },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '1',
    pt: '1',
    pb: '2',
    borderBottom: '1px',
    borderStyle: 'dashed',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    borderBottomColor: 'blackAlpha.300',
    _first: {
      borderTop: '1px',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      borderTopColor: 'blackAlpha.300',
    },
  },
};
