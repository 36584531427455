import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  p: '3',
  my: 'auto',
};
export const HeaderText: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  p: '0',
  textTransform: 'none',
  fontFamily: 'heading',
};
export const HeaderContainer: CSSObject = {
  p: '0',
};
export const CloseButton: CSSObject = {
  display: 'none',
};
