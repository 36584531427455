import { FlucsSchema } from './Flucs.styles';

export const flucsStyles: FlucsSchema = {
  wrapper: {
    pos: 'relative',
    alignItems: 'center',
  },
  innerWrapper: {
    alignItems: 'center',
    bg: 'alpha.400',
    width: 'fit-content',
    borderRadius: 'md',
    border: '1px solid',
    borderColor: 'alpha.200',
    px: '2',
    py: '1',
    mt: '1',
    mr: ['1', '2'],
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2xs',
    lineHeight: 'normal',
  },
  priceTitle: {
    as: 'p',
    color: 'white',
    fontWeight: 'bold',
    mr: '1',
  },
  priceNumber: {
    color: 'white',
    fontWeight: 'medium',
  },
  priceText: {
    color: 'white',
    mr: ['1', '2'],
    fontSize: '2xs',
    fontWeight: 'medium',
    display: 'flex',
    alignItems: 'center',
    _last: {
      mr: '0',
    },
  },
  priceTextIncreased: {
    color: 'white',
  },
  priceIcon: {
    color: 'red.500',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    ml: '1',
  },
  priceIconIncreased: {
    color: 'beta.300',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    transform: 'rotate(180deg)',
  },
  lottieWrapper: {
    alignItems: 'center',
    boxSize: '6',
    border: 'none',
    borderRadius: 'md',
    bg: 'transparent',
    mt: '0.5',
  },
  tooltipWrapper: {
    borderRadius: 'md',
    bg: '#ED8936',
    p: '3',
    display: 'block',
    position: 'relative',
  },
};
