import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: '#2e3e5a',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'md',
  p: '3',
  color: 'white',
  m: 'auto',
};

export const HeaderText: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  p: '0',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const DepositLimitModalSubheading: CSSObject = {
  '&&': {
    color: 'white',
  },
};

export const CloseButton: CSSObject = {
  display: 'none',
};
