import * as betgalaxy from './EventsClosedBanner.styles.betgalaxy';
import * as betprofessor from './EventsClosedBanner.styles.betprofessor';
import * as betroyale from './EventsClosedBanner.styles.betroyale';
import * as puntcity from './EventsClosedBanner.styles.puntcity';
import * as puntgenie from './EventsClosedBanner.styles.puntgenie';
import * as questbet from './EventsClosedBanner.styles.questbet';
import * as sterlingparker from './EventsClosedBanner.styles.sterlingparker';
import * as volcanobet from './EventsClosedBanner.styles.volcanobet';
import * as wellbet from './EventsClosedBanner.styles.wellbet';
import * as oldgill from './EventsClosedBanner.styles.oldgill';
import * as buffalobet from './EventsClosedBanner.styles.buffalobet';
import * as templebet from './EventsClosedBanner.styles.templebet';
import * as goldenbet888 from './EventsClosedBanner.styles.goldenbet888';
import * as bet777 from './EventsClosedBanner.styles.bet777';
import * as titanbet from './EventsClosedBanner.styles.titanbet';
import * as bet575 from './EventsClosedBanner.styles.bet575';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  volcanobet,
  wellbet,
  oldgill,
  buffalobet,
  templebet,
  goldenbet888,
  bet777,
  titanbet,
  bet575,
};
