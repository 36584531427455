import { SpecialsStylesSchema } from './Specials.styles';

export const specialsStyles: SpecialsStylesSchema = {
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'sm',
    fontFamily: 'accent',
  },
};
