import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bgGradient: 'linear(to-b, beta.700, beta.900)',
    alignItems: 'center',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
    mb: '2.5',
  },
  depositIconProps: {
    color: 'alpha.400',
    boxSize: '8',
    my: 'auto',
  },
};
