import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/magnumbet/images/onboarding-bg.png';

export const WrapperFlexOnboarding: CSSObject = {
  bgImage: `url(${bg})`,
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, '4'],
  borderRadius: [null, null, '12px'],
  boxShadow: [
    null,
    null,
    '0px 7px 20px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 2px rgba(111, 66, 114, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.5) inset',
  ],
  p: ['0', null, null, '3px'],
  pos: 'relative',
  w: ['100dvw', null, 'unset'],
  fontFamily: 'accent',
  border: 'none',
  '.chakra-input': {
    color: 'white',
    fontWeight: 'normal',
    fontSize: 'xs',
    '&:focus, &:active, &:hover': {
      color: 'white',
    },
  },
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '6'],
  px: ['2', null, '6'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '500px'],
  h: '100%',
  borderRightRadius: [null, null, '8px'],
  bg: 'alpha.300',
};

export const Title: CSSObject = {
  color: 'beta.300',
  fontSize: 'lg',
  fontStyle: 'normal',
  fontFamily: 'heading',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  display: 'flex',
  mb: '4',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  fontSize: 'xs',
  '&&&': {
    bg: 'blackAlpha.500',
    borderRadius: 'base',
    boxShadow: '0px 1px 5px 0px #00000026 inset, 0px 1px 0px 0px #FFFFFF1A',
    border: 'none',
    color: 'white',
    fontWeight: 'normal',
    '&&:hover, &&:focus': {
      color: 'white',
      opacity: 0.95,
    },
    _placeholder: {
      color: 'white',
    },
  },
};
