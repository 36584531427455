import Black from '@/assets/magnumbet/fonts/Roboto-Black.ttf';
import BlackItalic from '@/assets/magnumbet/fonts/Roboto-BlackItalic.ttf';
import Bold from '@/assets/magnumbet/fonts/Roboto-Bold.ttf';
import BoldItalic from '@/assets/magnumbet/fonts/Roboto-BoldItalic.ttf';
import ExtraLight from '@/assets/magnumbet/fonts/Roboto-Thin.ttf';
import ExtraLightItalic from '@/assets/magnumbet/fonts/Roboto-ThinItalic.ttf';
import Light from '@/assets/magnumbet/fonts/Roboto-Light.ttf';
import LightItalic from '@/assets/magnumbet/fonts/Roboto-LightItalic.ttf';
import Regular from '@/assets/magnumbet/fonts/Roboto-Regular.ttf';
import Italic from '@/assets/magnumbet/fonts/Roboto-Italic.ttf';
import SemiBold from '@/assets/magnumbet/fonts/Roboto-Medium.ttf';
import SemiBoldItalic from '@/assets/magnumbet/fonts/Roboto-MediumItalic.ttf';
import PoppinsBlack from '@/assets/magnumbet/fonts/Poppins-Black.ttf';
import PoppinsBold from '@/assets/magnumbet/fonts/Poppins-Bold.ttf';
import PoppinsExtraBold from '@/assets/magnumbet/fonts/Poppins-ExtraBold.ttf';
import PoppinsExtraLight from '@/assets/magnumbet/fonts/Poppins-ExtraLight.ttf';
import PoppinsLight from '@/assets/magnumbet/fonts/Poppins-Light.ttf';
import PoppinsMedium from '@/assets/magnumbet/fonts/Poppins-Medium.ttf';
import PoppinsRegular from '@/assets/magnumbet/fonts/Poppins-Regular.ttf';
import PoppinsSemiBold from '@/assets/magnumbet/fonts/Poppins-SemiBold.ttf';
import PoppinsThin from '@/assets/magnumbet/fonts/Poppins-Thin.ttf';
import GreatVibes from '@/assets/magnumbet/fonts/GreatVibes-Regular.ttf';
import AfacadBold from '@/assets/magnumbet/fonts/Afacad-Bold.ttf';
import AfacadMedium from '@/assets/magnumbet/fonts/Afacad-Medium.ttf';
import AfacadRegular from '@/assets/magnumbet/fonts/Afacad-Regular.ttf';
import AfacadSemiBold from '@/assets/magnumbet/fonts/Afacad-SemiBold.ttf';

export const fontFace = `
        @font-face {
          font-family: 'Afacad';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url('${AfacadSemiBold}') format('opentype');
        }
        @font-face {
          font-family: 'Afacad';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${AfacadRegular}') format('opentype');
        }
        @font-face {
          font-family: 'Afacad';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('${AfacadMedium}') format('opentype');
        }
        @font-face {
          font-family: 'Afacad';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${AfacadBold}') format('opentype');
        }
        @font-face {
          font-family: 'GreatVibes';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${GreatVibes}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${PoppinsThin}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url('${PoppinsSemiBold}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${PoppinsRegular}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('${PoppinsMedium}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${PoppinsLight}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 200;
          font-display: swap;
          src: url('${PoppinsExtraLight}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 800;
          font-display: swap;
          src: url('${PoppinsExtraBold}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${PoppinsBold}') format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${PoppinsBlack}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLight}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 100;
          font-display: swap;
          src: url('${ExtraLightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${Light}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 300;
          font-display: swap;
          src: url('${LightItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${Regular}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 400;
          font-display: swap;
          src: url('${Italic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 500;
          font-display: swap;
          src: url('${SemiBoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${Bold}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 700;
          font-display: swap;
          src: url('${BoldItalic}') format('opentype');
        }
      
        @font-face {
          font-family: 'roboto';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${Black}') format('opentype');
        }
        @font-face {
          font-family: 'roboto';
          font-style: italic;
          font-weight: 900;
          font-display: swap;
          src: url('${BlackItalic}') format('opentype');
        }
      `;
