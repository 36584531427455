import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  fontFamily: 'body',
  borderColor: 'transparent',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '2',
    borderRadius: 'md',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    border: '1px solid rgba(255, 255, 255, 0.16)',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  },
  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '2px solid',
    borderTopColor: 'whiteAlpha.200',
    pt: '3',
    '.chakra-text': {
      ':not(:last-child)': {
        color: 'white',
      },
      _notFirst: {
        bg: 'whiteAlpha.400',
        color: 'whiteAlpha.800',
        fontWeight: 'medium',
        textTransform: 'none',
        borderRadius: 'base',
        opacity: '0.64',
        border: 'none',
      },
    },
  },
};

export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  border: 'none',
  fontSize: 'sm',
  borderTopRadius: 'md',
  mx: '1',
  '.chakra-accordion__button': {
    px: '1',
  },
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'white',
  },
  _hover: {
    opacity: '0.8',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
};

export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
  },
};

export const AccordionHeaderLogOut: CSSObject = {
  borderRadius: 'md',
  fontSize: 'sm',
  mt: '-2',
  px: '2',
  mx: '0',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  borderStyle: '1px solid',
  borderColor: 'rgba(255, 255, 255, 0.16)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};

export const IconPerson: CSSObject = {
  boxSize: ['5', null, '5'],
  mr: '1.5',
};

export const TextAccordion: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'black',
  textTransform: 'uppercase',
  color: 'white',
  fontFamily: 'body',
  mr: 'auto',
  mt: '0.5',
};

export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'alpha.400',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  fontWeight: 'normal',
  gap: '1',
  p: '2',
  mt: '2',
  span: {
    textDecoration: 'none',
    fontWeight: 'normal',
  },
};

export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  mb: '0',
  border: 'none',
};
