import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logoDesktop from '@/assets/pyramidbet/images/logo.png';
import logoMb from '@/assets/pyramidbet/images/logoMb.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image src={logoDesktop} alt={THEME_NAME.pyramidbet} mx="auto" />
      </Show>
      <Hide above="lg">
        <Image
          src={logoMb}
          alt={THEME_NAME.pyramidbet}
          h="10"
          minW={95}
          objectFit="contain"
        />
      </Hide>
    </Link>
  );
}
