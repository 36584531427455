import { CompetitionsMenuSchema } from '@/views/sports/components/CompetitionsMenu/styles/CompetitionsMenu.styles';

export const competitionsMenuStyles: CompetitionsMenuSchema = {
  accordion: {
    color: 'white',
    minW: '150px',
    mr: '3',
    height: 'fit-content',
    bg: 'beta.800',
    borderRadius: 'md',
    overflow: 'hidden',
  },
  accordionItem: {
    outline: 'none',
    border: 'none',
    _first: {
      borderTopRadius: 'md',
    },
    _last: {
      mb: '0',
      borderBottomRadius: 'md',
    },
    pos: 'relative',
    _notFirst: {
      borderTopColor: 'rgba(0, 0, 0, 0.25)',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
    },
    _before: {
      border: 'inherit',
      borderColor: 'rgba(255, 255, 255, 0.05)',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  accordionButton: {
    pl: '3',
    pr: '2',
  },
  accordionHeadingText: {
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    fontFamily: 'accent',
    letterSpacing: '1px',
    fontSize: 'xs',
  },
  accordionIcon: {
    sx: {
      color: 'white',
    },
  },
  accordionPanel: {
    p: '0',
    fontSize: 'xs',
  },
};
