import { CSSObject } from '@chakra-ui/react';
import toggle from '@/assets/magnumbet/images/switch/toggle.png';

export const FlexWrapper = {
  borderRadius: 'lg',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: ['1.5', null, 'unset'],
  mb: 4,
  gap: '2',
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(8, 13, 39, 1) inset, 0px 0px 0px 5px #804284 inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  h: '16',
  pos: 'relative',
};
export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'accent',
  fontWeight: 'extrabold',
  fontSize: 'sm',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 8px rgba(252, 211, 96, 0.5)'
    : '0px 1px 0px rgba(255, 255, 255, 0.08)',
  color: isActive ? 'beta.300' : 'blackAlpha.600',
  px: '1.5',
});
export const Switcher = (): CSSObject => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height))',
  '--switch-track-width': '78px',
  '--switch-track-height': '40px',
  bg: 'transparent',
  pos: 'relative',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${toggle})`,
      boxSize: '9',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '2px',
      top: '2px',
      right: '0',
    },
  },
  '.chakra-switch__track': {
    boxShadow:
      '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
    bg: 'blackAlpha.500',
    borderRadius: '9px',
    _before: {
      bg: 'blackAlpha.600',
      h: '8px',
      w: '52px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: '2em',
      boxShadow:
        '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
      content: '""',
      pos: 'absolute',
      left: '3.5',
      top: '18px',
    },
  },
});
