import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'odds',
    minW: '16',
    px: '1',
    _disabled: {
      color: 'white',
      border: '2px solid rgba(255, 255, 255, 0.08)',
    },
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    textTransform: 'capitalize',
    fontWeight: 'extrabold',
    fontFamily: 'body',
    fontSize: '2xs',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    minW: 'fit-content',
  },
  buttonBaseBox: {
    fontSize: 'xs',
    fontWeight: 'bold',
    fontFamily: 'accent',
    lineHeight: 'normal',
    w: 'full',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
