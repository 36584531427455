import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '2',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(8px)',
    borderRadius: 'md',
    border: '1px solid',
    borderColor: 'whiteAlpha.300',
  },
  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '1px solid',
    borderTopColor: 'rgba(255, 255, 255, 0.05)',
    pt: '3',
    px: '3',
    '.chakra-text': {
      fontFamily: 'accent',
      fontSize: 'xs',
      _first: {
        fontWeight: 'semibold',
        color: 'white',
      },
      _notFirst: {
        fontWeight: 'medium',
        borderRadius: 'base',
        bg: 'whiteAlpha.300',
        color: 'whiteAlpha.700',
        opacity: '0.5',
        border: 'none',
      },
    },
  },
};
export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontFamily: 'body',
  fontSize: 'sm',
  '> button': {
    '&[aria-expanded="true"]': {
      borderBottom: '1px solid',
      borderBottomColor: 'blackAlpha.400',
    },
    borderBottom: '1px solid',
    borderBottomColor: 'transparent',
    px: '3',
    py: '1.5',
    lineHeight: 'normal',
  },
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'white',
  },
  _hover: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
};
export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
    border: '1px solid black',
  },
};
export const AccordionHeaderLogOut: CSSObject = {
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(8px)',
  borderRadius: 'md',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  fontSize: 'xs',
  mt: '-2',
  marginLeft: '0',
  marginRight: '0',
  px: '3',
  py: '2.5',
  _hover: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
};
export const IconPerson: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const TextAccordion: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'body',
  mr: 'auto',
};
export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'alpha.600',
  borderRadius: 'md',
  fontSize: 'xs',
  gap: '1',
  p: '2',
  '.chakra-text': {
    fontWeight: 'var(--bc-fontWeights-normal) !important',
    color: 'white',
    textDecoration: 'none',
    lineHeight: 'normal',
  },
  '.chakra-icon': {
    color: 'white',
  },
  mb: '2',
};
export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  border: 'none',
  mb: '0',
};
