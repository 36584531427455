import { ButtonProps, CSSObject } from '@chakra-ui/react';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
  mt: '2',
};
export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'semibold',
};
export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
  width: '100%',
  borderTop: 'none',
  mb: '1',
};
export const DepositLimitGrid: CSSObject = {
  pb: '20',
};
export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};
export const DepositButton: CSSObject = {
  borderRadius: 'base',
  border: 'none',
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'black',
  textTransform: 'uppercase',
  lineHeight: 'normal',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, beta.100, beta.100)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.35) inset',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.100',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};
export const CancelButton: CSSObject = {
  variant: 'secondary',
};
export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};
export const DepositLimitContainer: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  p: '4',
  color: 'white',
  fontWeight: '700',
};
export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};
export const DepositLimitInputContainer: CSSObject = {
  mt: '2.5',
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.100, alpha.200)',
    border: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '#amount-input': {
      bg: 'alpha.800',
      border: '2px solid',
      borderColor: 'alpha.100',
      fontSize: 'sm',
      fontWeight: 'bold',
      color: 'white',
      borderLeft: 'none',
      borderLeftRadius: 'none',
      px: '2',
      fontFamily: 'accent',
      _placeholder: {
        fontWeight: 'bold',
      },
      _hover: {
        bg: 'alpha.500',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 8px 0px rgba(28, 213, 255, 0.5)',
      },
      _focus: {
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'beta.200',
        borderLeft: 'none',
        borderLeftRadius: 'none',
        m: '0',
      },
    },
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, alpha.100, alpha.200)',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-select__wrapper': {
    svg: {
      color: '#1CD5FF !important',
    },
  },
  '#frequency-select': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-form__error-message': {
    bg: 'red.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    pt: '0',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const ModalContentWrapper: CSSObject = {
  p: '0',
};
export const ModalDivider: CSSObject = {
  display: 'none',
};
export const buttonRemoveProps: CSSObject = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, alpha.100, alpha.200)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'black',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.400)',
    color: 'alpha.100',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #1CD5FF inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
  },
  ':active, &[data-active=true]': {
    bg: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};
export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  width: 'full',
  textTransform: 'uppercase',
  fontSize: 'xs',
};
export const buttonConfirmModalProps: ButtonProps = {
  variant: 'solid',
  textTransform: 'uppercase',
};
