import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  mt: '3',
  columnGap: '3',
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '5',
  display: 'inline-block',
  fontSize: '9px',
  fontFamily: 'accent',
  h: '4',
  bg: 'gray.300',
  borderRadius: '3px',
  color: 'gray.600',
  ml: '0.5',
  lineHeight: '17px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const BoxColumn: CSSObject = {
  bg: 'transparent',
  mb: ['0.5', '5'],
};

export const LinkRaceContainer: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  color: 'white',
  borderRadius: 'md',
  boxShadow:
    '0px 7px 20px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  mb: '2.5',
  transition: 'all .2s ease-in-out',
  fontWeight: 'semibold',
  fontSize: '13px',
  svg: {
    color: 'beta.300',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2))',
  },
  _hover: {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
  },
  '.chakra-badge': {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    fontSize: '9px',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  color: 'beta.300',
  fontSize: 'lg',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  mt: '1.5',
  fontFamily: 'heading',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
  fontSize: '13px',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.2)',
  fontFamily: 'accent',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.100',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
