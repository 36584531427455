import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const TakeABreakContainer: CSSObject = {
  color: 'white',
  p: '0',
  b: '0',
  mb: '3',
  borderBottom: 'none',
  fontSize: 'xs',
  strong: {
    fontSize: 'sm',
  },
};

export const TextM: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  mb: '12px',
};

export const TextS: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
};

export const TextInformation: CSSObject = {
  fontSize: 'xs',
  '&:first-of-type': {
    fontWeight: 'semibold',
  },
};

export const HeadingInformation: CSSObject = {
  fontWeight: 'semibold',
  mb: '3',
  fontSize: 'sm',
};

export const TextDateLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
};

export const BoxTakeABreak: CSSObject = {
  p: '0',
  m: '0',
};

export const BoxTakeABreakWrapper: CSSObject = {
  pb: 0,
  border: 'none',
};

export const BoxStyledLink: CSSObject = {
  color: 'beta.300',
};

export const buttonTakeBreakProps: CustomButtonProps = {
  variant: 'primary',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};

export const TextDate: CSSObject = {
  fontSize: '16px',
  color: 'beta.400',
  fontWeight: 'bold',
};

export const InputContainer: CSSObject = {
  '& .chakra-checkbox__label': {
    color: 'white',
  },
  '& .chakra-form__label': {
    color: 'white',
  },
  '& .chakra-checkbox__control': {
    border: 'none',
  },
  fontWeight: 'medium',
  '#period-select': {
    border: 'none',
    color: 'white',
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  },
  '.chakra-select__icon-wrapper': {
    color: 'beta.300',
  },
  option: {
    color: 'black',
  },
};

export const TextConfirmBreak: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
};
