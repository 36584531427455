import { PersonalDetailsSchema } from './PersonalDetails.styles';

export const personalDetailsStyles: PersonalDetailsSchema = {
  wrapperBox: {
    sx: {
      '&&&': {
        '.chakra-input': {
          border: 'none',
          borderLeftRadius: 'md',
          bg: 'whiteAlpha.300',
          color: 'whiteAlpha.700',
          fontSize: 'xs',
          fontWeight: 'medium',
          _hover: {
            border: 'none',
            bg: 'whiteAlpha.300',
          },
        },
        '.chakra-form__label': {
          fontSize: 'xs',
          fontWeight: 'semibold',
        },
      },
    },
  },
};
