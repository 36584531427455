import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { MyBetsToggleSchema } from '@/components/MyBetsToggle/styles/MyBetsToggle.styles';

export const myBetsToggleStyles: MyBetsToggleSchema = {
  textCashOut: { color: 'whiteAlpha.900' },
  flexWrapper: {
    flexDir: 'row',
  },
  checkbox: {
    my: 'auto',
  },
};
export const TabContainer: CSSObject = {
  borderBottom: '0',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
  borderRadius: 'base',
  px: '4',
};
export const buttonAccountTabOptionProps: CustomButtonProps = {
  variant: 'tab',
  pt: '3px',
  mb: 'none',
};
