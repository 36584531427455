import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'solid',
  height: '47px',
  textTransform: 'uppercase',
  lineHeight: '1',
};

export const FlexWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
  },

  '&&': {
    input: {
      fontFamily: 'accent',
      px: '2',
    },
    '.chakra-form__label': {
      color: 'white',
      fontSize: 'xs',
      fontWeight: 'semibold',
      mt: '0',
    },
    '.chakra-input__group': {
      borderRadius: 'md',
      p: '1',
      bg: 'alpha.800',
      border: '2px solid',
      borderColor: 'transparent',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.13), 0px 3px 6px 0px rgba(0, 0, 0, 0.2) inset',
      ':has(#flexi-input:focus, #total-stake-input:focus)': {
        bg: 'blackAlpha.500',
        border: '2px solid',
        borderColor: 'beta.300',
        boxShadow: '0px 0px 6px 0px rgba(252, 211, 96, 0.6)',
      },
      ':has(#flexi-input:hover, #total-stake-input:hover)': {
        bg: 'blackAlpha.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.13), 0px 3px 6px 0px rgba(0, 0, 0, 0.2) inset',
      },
      '#flexi-input, #total-stake-input': {
        fontSize: 'sm',
        fontWeight: 'bold',
        borderLeft: 'none',
        borderLeftRadius: 'none',
        px: '2',
        h: '35px',
        fontFamily: 'accent',
        _placeholder: {
          fontWeight: 'bold',
        },
        _focus: {
          boxShadow: 'none',
          m: '0',
        },
      },
    },
    '.chakra-input__left-addon': {
      h: 'auto',
      w: '35px',
      border: 'none',
      fontWeight: 'bold',
      px: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: 'alpha.300',
  borderY: 'none',
  zIndex: '10',
  mx: ['0', null, '-4'],
  mb: ['0', null, '-4'],
};

export const InfoHeader: CSSObject = {
  borderTop: '4px solid',
  borderColor: 'beta.300',
  bg: 'alpha.300',
  h: '9',
  px: '2.5',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'xs',
  fontWeight: 'bold',
  bg: 'alpha.400',
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    },
  },
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: '32px',
  '+ div': {
    pt: '0',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '237px',
};
