import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: 'alpha.500',
  h: '100%',
  maxH: '100dvh',
  borderRadius: [null, null, 'lg'],
  boxShadow: [null, null, '0px 0px 0px 2px rgba(252, 211, 96, 1) inset,0px 0px 0px 4px rgba(8, 13, 39, 1) inset,0px 0px 0px 5px #804284 inset,0px 7px 20px 0px rgba(0, 0, 0, 0.3)'],
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '8px',
  mx: '3px',
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipContainer: BoxProps = {
  borderRadius: 'base',
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'whiteAlpha.200',
  color: 'white',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'beta.300',
  fontFamily: 'accent',
  fontSize: 'sm',
});

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'secondary',
  height: '6',
  width: '74px',
  margin: 'auto',
  mt: '-5',
  w: 'auto',
  pl: 1,
  pr: 2,
  fontWeight: 'normal',
  color: 'white',

  sx: {
    '.chakra-button__icon': {
      mr: '0',
    },

    '.btn-span': {
      fontWeight: 'bold',
    },
  }
};

export const BetslipVStack: CSSObject = {
  gap: '2.5',
};

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  height: '42px',
  sx: {
    '&&': {
      fontWeight: 'bold',
    },
  },
};
