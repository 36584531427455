import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '4'],
  mb: ['2', null, 'unset'],
  mt: 'unset',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  fontFamily: 'accent',
  boxShadow:
    '0px 2px 5px 0px #00000040, 0px 0px 0px 1px rgba(255, 255, 255, 0.16) inset',
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
};

export const TextHeading: CSSObject = {
  color: 'alpha.300',
  fontSize: 'md',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 2px 5px #00000040',
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(180deg, alpha.300, alpha.500)',
  color: 'blackAlpha.800',
  mr: '0',
  textAlign: 'center',
  fontSize: 'sm',
  fontWeight: 'semibold',
  textTransform: 'uppercase',
  width: 'full',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  _hover: {
    opacity: 0.9,
  },
};
