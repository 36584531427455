import { NavSchema } from './Nav.styles';

export const navStyles: NavSchema = {
  wrapper: { 
    spacing: '1', 
    mb: '2',
    pt: '2',
    sx: {
      '&[data-isonsportsall="true"]': {
        pb: '4',
        mb: '2',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderColor: '#192B3B',
        borderRadius: 'none',
        boxShadow: '0px 1px 0px 0px #FFFFFF12',
      },
    },
  },
  link: {
    color: 'gamma.900',
    bgGradient: 'linear(to-b, gamma.500, gamma.600)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2xs',
    textTransform: 'uppercase',
    borderRadius: 'base',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
    flex: ['1', null, null, '0 1 auto'],
    fontWeight: 'bold',
    minW: 'fit-content',
    px: '2.5',
    py: ['2.5', null, null, '5px'],
    borderWidth: '2px',
    borderColor: 'gamma.400',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
    textAlign: 'center',
    sx: {
      '&.active': {
        bgGradient: 'none',
        bg: 'beta.500',
        color: 'beta.700',
        borderColor: 'beta.500',
        fontSize: '2xs',
        svg: {
          color: 'alpha.600',
        },
      },
    },
  },
};
