import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },
  btnGroupWrapper: {
    borderRadius: '5px',
    bgGradient: 'linear(to-b, alpha.500, gamma.500)',
    color: 'gamma.800',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    minW: '90px',
  },
  btnGroupText: {
    fontSize: 'sm',
    color: 'gamma.800',
    fontWeight: 'black',
    fontFamily: 'roboto',
  },
  btnGroupIcon: {
    color: 'gamma.800',
  },
  name: {
    color: 'gray.700',
  },
};
export default styles;
