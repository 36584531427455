import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';
import bgAccountAccordion from '@/assets/pyramidbet/images/background/bgAccountAccordion.png';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'primary',
    px: '4',
    py: '2',
    fontFamily: 'accent',
    fontSize: 'xs',
  },
};
export const FlexBannerContainer: CSSObject = {
  borderRadius: 'md',
  backgroundRepeat: 'repeat',
  bgColor: 'alpha.500',
  bg: `url(${bgAccountAccordion})`,
  boxShadow:
    '0px 0px 0px 2px rgba(252, 211, 96, 1) inset, 0px 0px 0px 4px rgba(10, 15, 19, 1) inset, 0px 0px 0px 5px rgba(252, 211, 96, 0.15) inset, 0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
  color: 'white',
  gap: '0',
  p: ['3.5', null, '3'],
  minH: '107px',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.400',
};

export const FlexAccountName: CSSObject = {
  fontSize: 'xl',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'alpha.300',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  textAlign: ['start', null, 'end'],
  color: 'white',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontWeight: 'bold',
  color: 'alpha.300',
  textTransform: 'uppercase',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1',
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.300',
  pt: '2',
  fontFamily: 'body',
};

export const TextButton: CSSObject = {
  h: '4',
  pt: 'px',
  color: 'blackAlpha.700',
  fontSize: '0.6875rem', // 11px
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};

export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'alpha.400',
  color: 'white',
  fontSize: '0.6875rem', // 11px
  textTransform: 'uppercase',
  fontFamily: 'accent',
  fontWeight: 'bold',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
  gap: '1',
};
