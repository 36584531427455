import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'beta.500', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'beta.500'] },
};

export const FlexHeadingRow: CSSObject = {};

export const FlexHeading: CSSObject = {
  color: 'white',
  py: '2',
};

export const TextHeading: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  color: 'white',
  pt: '0.5',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'blackAlpha.800',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  border: 'none',
  borderRadius: 'md',
  p: '2',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  m: '0',
  mb: '4',
  p: 'px',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  borderRadius: 'md',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  _last: {
    mb: '0',
  },
};

export const BoxTableWrapper: CSSObject = {
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'extrabold',
  color: 'white',
  fontFamily: 'accent',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'semibold',
  color: 'beta.300',
  fontFamily: 'accent',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextRaceResults: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'orange.400';
  })(),
  color: 'blackAlpha.800',
});

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'alpha.500' : 'transparent',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
  _hover: {
    background: isToteMultiAvailable ? 'alpha.600' : 'blackAlpha.300',
  },
  _last: {
    borderRight: 'none',
  },
});

export const FlexRow: CSSObject = {
  mb: '0',
  borderRadius: 'none',

  _last: {
    borderBottom: 'none',
    'div, a': {
      borderBottom: 'none',
    },
    div: {
      borderBottomLeftRadius: 'md',
    },
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  bg: '#623F65',
  py: '1.5',
  borderColor: 'unset',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderRight: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
};

export const CenterTH = (): CSSObject => ({
  bg: '#5A3A5C',
  color: 'white',
  textAlign: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'extrabold',
  lineHeight: 'normal',
  py: '2.5',
  border: '1px solid',
  borderLeft: 'none',
  borderColor: 'whiteAlpha.200',
  textTransform: 'uppercase',
});

export const ButtonChevron: CSSObject = {
  color: 'white',
  bg: 'blackAlpha.400',
  borderRadius: 'sm',
  boxSize: '7',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BadgeRaceNumber: CSSObject = {
  bg: 'gray.300',
  color: 'gray.600',
  py: '0',
  borderRadius: '3px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
};
